import { CircularProgress, capitalize } from "@mui/material"
import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { as50, asCustomerCompany, asCustomerPhysical } from "../../../../constants/constants/constAndRegex"
import { MapList } from "../../../../constants/functions/functions"
import { pickList } from "../../../../constants/models/models"
import {
  URL_COMPANY,
  URL_PHYSICAL,
  URL_PROJECT,
  URL_PROJECT_TYPE,
  URL_PROPERTIE,
} from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import Input from "../../Reuse/Input/Input/Input"
import InputCheckboxGroup from "../../Reuse/Input/InputCheckboxGroup/InputCheckboxGroup"
import InputDate from "../../Reuse/Input/InputDate/InputDate"
import InputFile from "../../Reuse/Input/InputFile/InputFile"
import InputSelect from "../../Reuse/Input/InputSelect/InputSelect"
import * as S from "./styled"
import InputAutoComplete from "../../Reuse/Input/InputAutoComplete/InputAutoComplete"
import InputAutoCompleteMultiple from "../../Reuse/Input/InputAutoCompleteMultiple/InputAutoCompleteMultiple"
import { ProjectInfo } from "../../../SelectedProjects"

const InputsProject = (props) => {
  const [changeCount, setChangeCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [disableState, setDisableState] = useState(true)
  const [projectType, setProjectType] = useState([])
  const [propertie, setPropertie] = useState([])
  const [physica, setPhysica] = useState([])
  const [company, setCompany] = useState([])
  const [subProjects, setSubProjects] = useState({})
  const [searchName, setSearchName] = useState(null)
  const [searchProject, setSearchProject] = useState(null)

  const {
    states: {
      globalState: { formData },
    },

    functions: { headerGlobal },
  } = useContext(GlobalContext)
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
    setCompareSubType,
  } = props

  const propertieList = MapList({
    state: propertie && propertie,
    value: "id",
    label: "owner",
  })

  const projectTypeList = MapList({
    state: projectType && projectType,
    value: "id",
    label: "project_type",
  })

  const physicaList = MapList({
    state: physica && physica,
    value: "id",
    label: "name",
  })

  const companyList = MapList({
    state: company && company,
    value: "id",
    label: "social_reason",
  })

  const getProjectsType = async () => {
    try {
      const encodedSearchProject = encodeURIComponent(searchProject)
      const valueUrlSearch = `${URL_PROJECT_TYPE}${searchProject ? `?project_type=${encodedSearchProject}` : ""}`
      const response = await axios.get(valueUrlSearch, {
        headers: headerGlobal(),
      })

      setProjectType(response.data.results)
    } catch (error) {
      console.error("Erro:", error)
    }
  }

  useEffect(() => {
    const fetchSubProjects = async (projectTypeId) => {
      try {
        const response = await axios.get(`${URL_PROJECT_TYPE}${projectTypeId}/`, {
          headers: headerGlobal(),
        })
        if (Array.isArray(response.data.projectType_subprojects)) {
          setSubProjects((prevSubProjects) => ({
            ...prevSubProjects,
            [projectTypeId]: response.data.projectType_subprojects.map((sp) => ({
              value: sp.id,
              label: sp.subproject_name,
            })),
          }))
        } else {
          console.log("projectType_subprojects não é um array na resposta.")
        }
      } catch (error) {
        console.error(error)
      }
    }

    // Buscar subprojetos para cada tipo de projeto selecionado
    dataForm?.project_type?.forEach((projectTypeId) => fetchSubProjects(projectTypeId))
  }, [dataForm.project_type]) // Dependências

  const getPhysica = async () => {
    try {
      const encodedSearchName = encodeURIComponent(searchName)
      const valueUrlSearch = `${URL_PHYSICAL}${searchName ? `?name=${encodedSearchName}` : ""}`
      const response = await axios.get(valueUrlSearch, {
        headers: headerGlobal(),
      })
      setPhysica(response.data.results)
    } catch (error) {
      console.error("Erro em DetailClient.js getPhysica", error)
    }
  }

  const getCompany = async () => {
    try {
      const response = await axios.get(URL_COMPANY, {
        headers: headerGlobal(),
      })
      setCompany(response.data.results)
    } catch (error) {
      console.error("Erro em DetailClient.js getCompany", error)
    }
  }
  const getPropertie = async () => {
    try {
      const response = await axios.get(URL_PROPERTIE, {
        headers: headerGlobal(),
      })
      setPropertie(response.data.results)
    } catch (error) {
      console.error("Erro em DetailClient.js getCompany", error)
    }
  }

  const fetchData2 = async () => {
    try {
      await Promise.all([getProjectsType(), getPhysica(), getCompany(), getPropertie()])
    } catch (error) {
      console.error("Erro ao buscar dados: ", error)
    }
  }

  useEffect(() => {
    fetchData2()
  }, [])

  useEffect(() => {
    getPhysica()
  }, [searchName])

  useEffect(() => {
    getProjectsType()
  }, [searchProject])

  return (
    <S.Main>
      <S.LoadingOverlay isLoading={isLoading}>
        <CircularProgress />
      </S.LoadingOverlay>
      <S.H2>projeto</S.H2>

      <S.Box>
        <Input
          label={"Projeto"}
          {...(formData.inputDisable !== true ? { name: "project" } : {})}
          value={dataForm.project || ""}
          onChange={inputForm}
          error={fieldErrors.project || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={as50}
        />
        {/* proprietário */}
        <InputAutoComplete
          label={"Proprietário(a) do Imóvel"}
          {...(formData.inputDisable !== true ? { name: "properties" } : { disabled: true })}
          value={dataForm.properties || ""}
          onChange={inputForm}
          options={propertieList}
          error={fieldErrors.properties}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      </S.Box>

      <S.Box>
        <InputSelect
          label={"Tipo de Pessoa"}
          name="typePeople" 
          value={dataForm.typePeople || ""}
          onChange={inputForm}
          options={pickList.TYPE_CUSTOMER}
          error={fieldErrors.typePeople}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        {dataForm.typePeople === asCustomerPhysical && (
          <InputAutoComplete
            label={"Nome"}
            name="customer_person_physical" 
            value={dataForm.customer_person_physical || ""}
            onChange={inputForm}
            options={physicaList}
            error={fieldErrors.customer_person_physical}
            onInputChange={(_, value) => setSearchName(value)}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )
            }}
          />
        )}

        {dataForm.typePeople === asCustomerCompany && company.length !== 0 && (
          <InputAutoComplete
            label={"Nome"}
            {...(formData.inputDisable !== true ? { name: "customer_person_company" } : { disabled: true })}
            value={dataForm.customer_person_company || ""}
            onChange={inputForm}
            options={companyList}
            error={fieldErrors.customer_person_company}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />
        )}

        {dataForm.typePeople === asCustomerCompany && company.length === 0 && (
          <InputCheckboxGroup
            label={"Cadastre ao menos um cliente jurídico"}
            error={fieldErrors.customer_person_company}
          />
        )}
      </S.Box>

      {dataForm?.project_type?.length > 0 && (
        <S.H2_project>
          ** Atenção: No campo assunto da proposta serão mostrados apenas os dois primeiros projetos
        </S.H2_project>
      )}
      <InputAutoComplete
        label={"Adicionar tipo de projeto"}
        {...(formData.inputDisable !== true ? { name: "project_type" } : {})}
        value={""}
        onChange={(newValue) =>
          inputForm({
            target: { name: "project_type", value: [...(dataForm?.project_type ?? []), newValue.target.value] },
          })
        }
        options={
          dataForm?.project_type?.length > 0
            ? projectTypeList.filter(
                (project) =>
                  !dataForm?.project_type?.some((item) => {
                    return item === project.value
                  }),
              )
            : projectTypeList
        }
        error={fieldErrors.project_type}
        onInputChange={(_, value) => setSearchProject(value)}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )
        }}
      />
      {dataForm?.project_type?.map((projectTypeId) => {
        return (
          <ProjectInfo
            key={projectTypeId}
            id={projectTypeId}
            error={fieldErrors[`subproject_${projectTypeId}`] || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            setDataForm={setDataForm}
            dataForm={dataForm}
          />
        )
      })}

      <S.Box>
        <InputDate
          label={"Data Inicial"}
          {...(formData.inputDisable !== true ? { name: "start_date" } : {})}
          value={dataForm?.start_date || ""}
          onChange={inputForm}
          error={fieldErrors.start_date || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputDate
          label={"Data Final"}
          {...(formData.inputDisable !== true ? { name: "final_date" } : {})}
          value={dataForm?.final_date || ""}
          onChange={inputForm}
          error={fieldErrors.final_date || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputFile
          name="image"
          {...(formData.inputDisable !== true ? { type: "file" } : {})}
          value={dataForm.image || ""}
          error={fieldErrors.image || null}
          dataForm={dataForm}
          setDataForm={setDataForm}
          title={"imóvel"}
          url={URL_PROJECT}
        />
      </S.Box>
    </S.Main>
  )
}

export default InputsProject
