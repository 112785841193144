import React from "react";
import Input from "../../Reuse/Input/Input/Input";

const InputsRecovery = ({
  dataForm,
  inputForm,
  fieldErrors,
  regexMessage,
  conditionMessage,
}) => {
  return (
    <>
      <Input
         label={'E-mail'}
         name={'email'}
         value={dataForm?.email || ''}
         onChange={inputForm}
         error={fieldErrors?.email || null}
         regexMessage={regexMessage}
         conditionMessage={conditionMessage}
      />
    </>
  );
};

export default InputsRecovery;
