import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55rem;
`;

export const H2 = styled.h2`
  display: flex;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  /* margin: 0; */
  color: #006978;
  align-items: center;
  gap: 0.2rem;
`;
