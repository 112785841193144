import axios from "axios"
import React, { useContext, useState } from "react"
import {
  asCadastrar,
  asClean,
  asDefaultErrorAxios,
  asDefaultRegister,
  asSend,
  asSending,
  asSubmit,
} from "../../../../constants/constants/constAndRegex"
import { errorCompany } from "../../../../constants/constants/constError"
import { DATA_COMPANY } from "../../../../constants/constants/dataToSend"
import { requiredCompany } from "../../../../constants/constants/requiredConst"
import { handleErrors, settingState, validateForm } from "../../../../constants/functions/functions"
import { URL_COMPANY } from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import useForm from "../../../../hooks/useForm"
import ClearDialog from "../../../common/Dialog/ClearDialog/ClearDialog"
import Formulary from "../../../common/Formulary/Formulary/Formulary"
import InputsClientCompany from "../../../common/Formulary/InputsClient/InputsClientCompany/InputsClientCompany"
import { Button, ButtonRed } from "../../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../../common/Reuse/TitleModal/TitleModal"
import RegisterClientRepresentative from "../RegisterClientRepresentative/RegisterClientRepresentative"
import * as L from "./styled"

const RegisterClientCompany = (props) => {
  const {
    setters: { setGlobalState },
    functions: { headerGlobal, sayAlteration, successToast, errorToast },
  } = useContext(GlobalContext)
  const [clear, setClear] = useState(false)
  const [viewCompany, setViewCompany] = useState(false)
  const [viewRepresentative, setViewRepresentative] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { register, setRegister } = props

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm()

  const propsList = {
    clear,
    setClear,
    setDataForm,
    setFieldErrors,
    register,
    setRegister,
    regexMessage,
    conditionMessage,
    dataForm,
    inputForm,
    fieldErrors,
  }

  const handlePost = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    const formValid = validateForm({
      specificValidations: requiredCompany,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    })

    if (!formValid) {
      setIsSubmitting(false)
      return errorToast(asDefaultErrorAxios)
    }

    const dataToSend = DATA_COMPANY(dataForm)

    try {
      const response = await axios.post(URL_COMPANY, dataToSend, {
        headers: {
          ...headerGlobal(),
          "Content-Type": "multipart/form-data",
        },
      })
      const companyId = response.data.id
      setGlobalState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
        },
        idCompany: companyId,
      }))
      sayAlteration()
      successToast(asDefaultRegister)
      settingState(setViewCompany, true)
      settingState(setViewRepresentative, true)
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorCompany,
        errorToast,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <L.Main>
        <Formulary onClick={() => settingState(setRegister, !register)}>
          <L.BoxSelect>
            <TitleModal text={asCadastrar} />
          </L.BoxSelect>
          <L.Container>
            {viewCompany === false && (
              <>
                <InputsClientCompany {...propsList} />
                <L.DivGap>
                  <ButtonRed onClick={() => settingState(setClear, !clear)} named={asClean} />
                  <Button
                    type={asSubmit}
                    named={isSubmitting ? asSending : asSend}
                    onClick={(event) => {
                      if (!isSubmitting) {
                        handlePost(event)
                        // toggleBoolean('statusImage', true);
                      }
                    }}
                    disabled={isSubmitting}
                  />
                </L.DivGap>
              </>
            )}
            {viewRepresentative === true && <RegisterClientRepresentative {...propsList} />}
          </L.Container>
        </Formulary>
      </L.Main>

      {/* diálogo de limpar formulário */}
      <ClearDialog {...propsList} />
    </>
  )
}
export default RegisterClientCompany
