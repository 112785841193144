import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asClean,
  asDefaultAlteration,
  asDefaultErrorAxios,
  asEditar,
  asSend,
  asSending,
  asSubmit
} from '../../../../constants/constants/constAndRegex';
import { errorBestowed } from '../../../../constants/constants/constError';
import { DATA_PHYSICAL, DATA_SPOUSE } from '../../../../constants/constants/dataToSend';
import { requiredPhysical, requiredSpouse } from '../../../../constants/constants/requiredConst';
import { handleErrors, settingState, validateForm } from '../../../../constants/functions/functions';
import { URL_BESTOWED } from '../../../../constants/urls/urls';
import { GlobalContext } from '../../../../global/GlobalContext';
import useForm from '../../../../hooks/useForm';
import ClearDialog from '../../../common/Dialog/ClearDialog/ClearDialog';
import Formulary from '../../../common/Formulary/Formulary/Formulary';
import InputsBestowed from '../../../common/Formulary/InputsBestowed/InputsBestowed';
import { Button, ButtonRed } from '../../../common/Reuse/Button/Buttons/Buttons';
import TitleModal from '../../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../../common/StyledCommonModals/StyledCommonModals';
import * as L from '../styled';

const EditBestowed = (props) => {
  const {
    states: {
      globalState: { id, lookAlteration }
    },
    functions: { toggleBoolean, headerGlobal, successToast, errorToast, sayAlteration }
  } = useContext(GlobalContext);
  const [clear, setClear] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { edit, setEdit, erase, setErase } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    clear,
    setClear,
    setFieldErrors
  };
console.log(dataForm)
  const handlePut = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const specificValidations = [...requiredPhysical, ...requiredSpouse];

    const resultValidateForm = validateForm({
      specificValidations,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    const dataToSend = DATA_PHYSICAL(dataForm);
    const spouseData = DATA_SPOUSE(dataForm);
    const combinedData = {
      ...dataToSend,
      ...spouseData
    };

    try {
      await axios.put(`${URL_BESTOWED}${id}/`, combinedData, {
        headers: headerGlobal()
      });
      sayAlteration();
      successToast(asDefaultAlteration);
      settingState(setEdit, !edit);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorBestowed,
        errorToast,
        asDefaultErrorAxios
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_BESTOWED}${id}/`, {
        headers: headerGlobal()
      });
      const data = response.data;
      setDataForm(data);
    } catch (error) {
      console.log('Erro EditBestowed.js: getInfo');
    }
  };

  useEffect(() => {
    getInfo();
  }, [lookAlteration]);

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setEdit, !edit)}>
        <S.BoxSelect>
          <TitleModal text={'editar'} />
        </S.BoxSelect>
        <S.DivGap>
          <InputsBestowed {...propsList} />
        </S.DivGap>
        <L.DivGap>
          <ButtonRed onClick={() => settingState(setClear, !clear)} named={asClean} />
          <Button
            type={asSubmit}
            named={isSubmitting ? asSending : asSend}
            onClick={(event) => {
              if (!isSubmitting) {
                handlePut(event);
                toggleBoolean('statusImage', true);
              }
            }}
            disabled={isSubmitting}
          />
        </L.DivGap>
      </Formulary>
      <ClearDialog {...propsList} />
    </S.Main>
  );
};
export default EditBestowed;
