import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { asDefaultErrorAxios } from "../../../../../constants/constants/constAndRegex"
import { DATA_CLIENT_REPRESENTATIVE, DATA_SPOUSE } from "../../../../../constants/constants/dataToSend"
import { requiredClientRepresentative, requiredSpouse } from "../../../../../constants/constants/requiredConst"
import { changeValue, handleMultErrors, validateMultForm } from "../../../../../constants/functions/functions"
import { URL_LEGAL_REPRESENTATIVE } from "../../../../../constants/urls/urls"
import { GlobalContext } from "../../../../../global/GlobalContext"
import useMultForm from "../../../../../hooks/useMultForm"
import ClearDialogDinamic from "../../../../common/Dialog/ClearDialog/ClearDialogDinamic"
import Formulary from "../../../../common/Formulary/Formulary/Formulary"
import InputsClientRepresentative from "../../../../common/Formulary/InputsClient/InputsClientRepresentative/InputsClientRepresentative"
import { Button, ButtonRed } from "../../../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../../../common/Reuse/TitleModal/TitleModal"
import * as L from "./styled"
const ModalRegisterClientRepresentative = (props) => {
  const {
    states: {
      globalState: { idCompany },
    },
    functions: { toggleBoolean, headerGlobal, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext)
  const [openClearDinamicDialog, setOpenClearDinamicDialog] = useState(false)
  const { setStateRegisterModal, stateRegisterModal } = props
  const {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
  } = useMultForm()
  const specificValidations = [...requiredClientRepresentative]

  const errorHandlers = {
    email: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].email = errors.email[0]
      errorToast("E-mail já está em uso")
    },
    cpf: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].cpf = errors.cpf[0]
      errorToast("CPF já está em uso")
    },
    // Adicione aqui outros tratamentos específicos de erro
  }

  const sendData = async (item) => {
    const dataToSend = DATA_CLIENT_REPRESENTATIVE(item)
    const spouseData = DATA_SPOUSE(item)
    const combinedData = {
      ...dataToSend,
      ...spouseData,
      company: idCompany,
    }

    await axios.post(URL_LEGAL_REPRESENTATIVE, combinedData, {
      headers: {
        ...headerGlobal(),
        "Content-Type": "multipart/form-data",
      },
    })

    return item.id
  }

  const handlePost = async (event) => {
    event.preventDefault()

    const allFormsValid = validateMultForm({
      specificValidations,
      multDataForm,
      setMultFieldErrors,
      setMultRegexMessage,
      setMultConditionMessage,
    })

    if (!allFormsValid) {
      errorToast(asDefaultErrorAxios)
      return
    }

    let newFieldErrors = { ...multFieldErrors }
    let updatedMultDataForm = [...multDataForm]

    for (const item of multDataForm) {
      try {
        await sendData(item)
        updatedMultDataForm = updatedMultDataForm.filter((form) => form.id !== item.id)
      } catch (error) {
        handleMultErrors({ error, item, newFieldErrors, errorHandlers })
      }
    }

    setMultFieldErrors(newFieldErrors)
    setMultDataForm(updatedMultDataForm)

    if (updatedMultDataForm.length === 0) {
      changeValue(stateRegisterModal, setStateRegisterModal)
      sayAlteration()
      successToast("Cadastro realizado")
    }
  }

  const propsList = {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
    openClearDinamicDialog,
    setOpenClearDinamicDialog,
  }

  useEffect(() => {
    addForm()
  }, [])

  return (
    <L.Main>
      <Formulary onClick={() => changeValue(stateRegisterModal, setStateRegisterModal)}>
        <L.BoxSelect>
          <TitleModal text={"cadastrar"} />
        </L.BoxSelect>
        <L.Container>
          {multDataForm?.map((form) => (
            <div key={form.id}>
              <InputsClientRepresentative formId={form.id} {...propsList} />
              <L.H2Custom>
                Representante Legal
                <L.IconButtonModalRed type="button" onClick={() => removeForm(form.id)}>
                  <RemoveIcon />
                </L.IconButtonModalRed>
              </L.H2Custom>
            </div>
          ))}

          <L.H2>
            Representante Legal
            <L.IconButtonModal type="button" onClick={addForm}>
              <AddIcon />
            </L.IconButtonModal>
          </L.H2>

          {multDataForm.length >= 1 && (
            <L.DivGap>
              <ButtonRed
                onClick={() => changeValue(openClearDinamicDialog, setOpenClearDinamicDialog)}
                named={"limpar"}
              />
              <Button
                onClick={(event) => {
                  handlePost(event)
                  toggleBoolean("statusImage", true)
                }}
                named={"enviar"}
              />
            </L.DivGap>
          )}
        </L.Container>
      </Formulary>
      <ClearDialogDinamic {...propsList} />
    </L.Main>
  )
}
export default ModalRegisterClientRepresentative
