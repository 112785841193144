import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { CircularProgress, IconButton } from "@mui/material"
import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import {
  as100,
  as15,
  as255,
  as30,
  as50,
  asAlone,
  asDescriptionGeneral,
  asMarried,
  asOther,
  asStableUnion,
} from "../../../../../constants/constants/constAndRegex"
import { sortAlphabetically } from "../../../../../constants/functions/functions"
import { pickList } from "../../../../../constants/models/models"
import { URL_CITY_BY_ID, URL_LEGAL_REPRESENTATIVE } from "../../../../../constants/urls/urls"
import { GlobalContext } from "../../../../../global/GlobalContext"
import Input from "../../../Reuse/Input/Input/Input"
import InputDate from "../../../Reuse/Input/InputDate/InputDate"
import InputFileDinamic from "../../../Reuse/Input/InputFile/InputFileDinamic"
import InputFormats from "../../../Reuse/Input/InputFormats/InputFormats"
import InputPhone from "../../../Reuse/Input/InputPhone/InputPhone"
import InputSelect from "../../../Reuse/Input/InputSelect/InputSelect"
import * as S from "./styled"

const InputsClientRepresentative = (props) => {
  const {
    states: {
      globalState: { formData, stateList },
    },
    functions: { errorToast },
  } = useContext(GlobalContext)
  const [showContent, setShowContent] = useState(true)
  const [cityList, setCityList] = useState({})
  const [cityList2, setCityList2] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const {
    formId,
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    multInputForm,
    removeForm,
  } = props

  const currentFormData = formId
    ? Array.isArray(multDataForm)
      ? multDataForm?.find((form) => form.id === formId)
      : null
    : null

  const handleInputChange = (e) => {
    const fieldName = e.target.name
    const value = e.target.value
    multInputForm(formId, fieldName, value)
  }

  const handleFileInputChange = (e) => {
    const fieldName = e.target.name
    const file = e.target.files[0]
    dinamicFileChange(formId, fieldName, file)
  }

  const dinamicFileChange = (formId, fieldName, file) => {
    setMultDataForm((prevState) => {
      return prevState.map((form) => {
        if (form.id === formId) {
          return { ...form, [fieldName]: file }
        }
        return form
      })
    })
  }

  const updateMultDataForm = (formId, fieldName, value) => {
    setMultDataForm((prevMultDataForm) => {
      // Verifica se multDataForm é um array e se o formId existe
      if (Array.isArray(prevMultDataForm) && formId) {
        // Encontra o formulário correspondente ao formId
        const updatedFormIndex = prevMultDataForm.findIndex((form) => form.id === formId)
        if (updatedFormIndex !== -1) {
          // Atualiza apenas o campo fieldName do formulário correspondente
          const updatedForm = {
            ...prevMultDataForm[updatedFormIndex],
            [fieldName]: value,
          }
          // Retorna um novo array com o formulário atualizado
          return [
            ...prevMultDataForm.slice(0, updatedFormIndex),
            updatedForm,
            ...prevMultDataForm.slice(updatedFormIndex + 1),
          ]
        }
      }
      // Se não encontrar o formId, retorna multDataForm sem alterações
      return prevMultDataForm
    })
  }

  const handleToggleContent = () => {
    setShowContent(!showContent)
  }

  const check_marital_status =
    currentFormData?.marital_status === asMarried || currentFormData?.marital_status === asStableUnion

  const sortedStateList = sortAlphabetically(stateList)
  const statesUF = sortedStateList?.map((formId) => ({
    value: formId.sigla,
    label: formId.nome,
  }))

  const fetchAndSetCityData = async (formId, stateParam, setState) => {
    setIsLoading(true)
    const url = URL_CITY_BY_ID.replace(":id", stateParam)
    try {
      const response = await axios.get(url)
      setState((prev) => ({ ...prev, [formId]: response.data }))
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
      const errorMessage = error?.response?.data?.detail
      errorToast(errorMessage)
    }
  }

  useEffect(() => {
    if (currentFormData?.place_of_birth_state) {
      fetchAndSetCityData(formId, currentFormData?.place_of_birth_state, setCityList)
    }
  }, [currentFormData?.place_of_birth_state])

  useEffect(() => {
    if (currentFormData?.spouse_place_of_birth_state) {
      fetchAndSetCityData(formId, currentFormData?.spouse_place_of_birth_state, setCityList2)
    }
  }, [currentFormData?.spouse_place_of_birth_state])

  const cityOptions = (formId) => {
    return cityList[formId]?.map((formId) => ({
      value: formId.nome,
      label: formId.nome,
    }))
  }

  const cityOptions2 = (formId) => {
    return cityList2[formId]?.map((index) => ({
      value: index.nome,
      label: index.nome,
    }))
  }

  return (
    <>
      <S.LoadingOverlay isLoading={isLoading}>
        <CircularProgress />
      </S.LoadingOverlay>
      <S.Main>
        <S.H2>representante Legal</S.H2>
        <S.Box>
          <Input
            label={"Representante Legal"}
            {...(formData.inputDisable !== true ? { name: "legal_representative" } : {})}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            value={currentFormData?.legal_representative || ""}
            onChange={handleInputChange}
            length={as100}
            error={multFieldErrors?.[formId]?.legal_representative}
          />

          <S.Custon15rem>
            <InputDate
              label={"Data de Nascimento"}
              {...(formData.inputDisable !== true ? { name: "birth_date" } : {})}
              regexMessage={multRegexMessage?.[formId]}
              conditionMessage={multConditionMessage?.[formId]}
              value={currentFormData?.birth_date || ""}
              onChange={handleInputChange}
              error={multFieldErrors?.[formId]?.birth_date}
            />
          </S.Custon15rem>
        </S.Box>

        <S.Box>
          <InputSelect
            label={"Gênero"}
            {...(formData.inputDisable !== true ? { name: "gender" } : {})}
            value={currentFormData?.gender || ""}
            onChange={handleInputChange}
            options={pickList.TYPE_GENERAL}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            error={multFieldErrors?.[formId]?.gender}
          />

          <InputSelect
            label={"Estado Civil"}
            {...(formData.inputDisable !== true ? { name: "marital_status" } : {})}
            value={currentFormData?.marital_status || ""}
            onChange={handleInputChange}
            options={pickList.TYPE_CIVIL}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            error={multFieldErrors?.[formId]?.marital_status}
          />

          {currentFormData?.marital_status === asOther && (
            <Input
              label={"Estado Civil"}
              {...(formData.inputDisable !== true ? { name: "others_marital_status" } : {})}
              regexMessage={multRegexMessage?.[formId]}
              conditionMessage={multConditionMessage?.[formId]}
              value={currentFormData?.others_marital_status || ""}
              onChange={handleInputChange}
              length={as50}
              error={multFieldErrors?.[formId]?.others_marital_status}
            />
          )}
        </S.Box>

        {currentFormData?.marital_status === asMarried && (
          <S.Box>
            <InputSelect
              label={"Regime de Casamento"}
              {...(formData.inputDisable !== true ? { name: "marriage_regime" } : {})}
              value={currentFormData?.marriage_regime || ""}
              options={pickList.TYPE_REGIMENT}
              length={as50}
              onChange={handleInputChange}
              regexMessage={multRegexMessage?.[formId]}
              conditionMessage={multConditionMessage?.[formId]}
              error={multFieldErrors?.[formId]?.marriage_regime}
            />

            {currentFormData?.marriage_regime === asOther && currentFormData?.marital_status !== asAlone && (
              <Input
                label={"Regime de Casamento"}
                {...(formData.inputDisable !== true ? { name: "others_marriage_regime" } : {})}
                regexMessage={multRegexMessage?.[formId]}
                conditionMessage={multConditionMessage?.[formId]}
                value={currentFormData?.others_marriage_regime || ""}
                onChange={handleInputChange}
                length={as50}
                error={multFieldErrors?.[formId]?.others_marriage_regime}
              />
            )}
          </S.Box>
        )}

        <S.Box>
          <InputSelect
            label={"Nacionalidade"}
            {...(formData.inputDisable !== true ? { name: "nationality" } : {})}
            value={currentFormData?.nationality || ""}
            onChange={handleInputChange}
            options={pickList.TYPE_NATIONALITY}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            error={multFieldErrors?.[formId]?.nationality}
          />
          <InputSelect
            label={"Naturalidade UF"}
            {...(formData.inputDisable !== true ? { name: "place_of_birth_state" } : {})}
            value={currentFormData?.place_of_birth_state || ""}
            onChange={(e) => {
              handleInputChange(e)
              updateMultDataForm(formId, "place_of_birth_town", "")
            }}
            options={statesUF}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            error={multFieldErrors?.[formId]?.place_of_birth_state}
          />

          <InputSelect
            label={"Naturalidade CDD"}
            {...(formData.inputDisable !== true ? { name: "place_of_birth_town" } : {})}
            value={currentFormData?.place_of_birth_town || ""}
            onChange={handleInputChange}
            options={cityOptions(formId)}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            error={multFieldErrors?.[formId]?.place_of_birth_town}
          />
        </S.Box>

        <S.Box>
          <Input
            label={"Filiação"}
            {...(formData.inputDisable !== true ? { name: "affiliation" } : {})}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            value={currentFormData?.affiliation || ""}
            onChange={handleInputChange}
            length={as100}
            error={multFieldErrors?.[formId]?.affiliation}
          />

          <Input
            label={"E-mail"}
            {...(formData.inputDisable !== true ? { name: "email" } : {})}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            value={currentFormData?.email || ""}
            onChange={handleInputChange}
            length={as100}
            error={multFieldErrors?.[formId]?.email}
          />
        </S.Box>

        <S.Box>
          <Input
            label={"Profissão"}
            {...(formData.inputDisable !== true ? { name: "profession" } : {})}
            onChange={handleInputChange}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            value={currentFormData?.profession || ""}
            length={as30}
            error={multFieldErrors?.[formId]?.profession}
          />
          <S.Box>
            <InputPhone
              label={"Telefone"}
              name={"telephone"}
              regexMessage={multRegexMessage?.[formId]}
              conditionMessage={multConditionMessage?.[formId]}
              value={currentFormData?.telephone || ""}
              onChange={handleInputChange}
              error={multFieldErrors?.[formId]?.telephone}
              length={as15}
            />

            <S.BoxFile>
              <InputFormats
                documentType="cpf"
                label={"CPF"}
                {...(formData.inputDisable !== true ? { name: "cpf" } : {})}
                type={"cpf"}
                regexMessage={multRegexMessage?.[formId]}
                conditionMessage={multConditionMessage?.[formId]}
                value={currentFormData?.cpf || ""}
                onChange={handleInputChange}
                error={multFieldErrors?.[formId]?.cpf}
              />

              <InputFileDinamic
                name="cpf_file"
                {...(formData.inputDisable !== true ? { type: "file" } : {})}
                onChange={handleFileInputChange}
                error={multFieldErrors?.[formId]?.cpf_file || null}
                dataForm={multDataForm}
                setDataForm={setMultDataForm}
                formId={formId}
                title={"cpf"}
                regexMessage={multRegexMessage?.[formId]}
                conditionMessage={multConditionMessage?.[formId]}
                url={URL_LEGAL_REPRESENTATIVE}
              />
            </S.BoxFile>
          </S.Box>
        </S.Box>

        <S.Box>
          <S.Box>
            <InputFormats
              documentType="rg"
              label={"RG"}
              {...(formData.inputDisable !== true ? { name: "rg" } : {})}
              type={"rg"}
              regexMessage={multRegexMessage?.[formId]}
              conditionMessage={multConditionMessage?.[formId]}
              value={currentFormData?.rg || ""}
              error={multFieldErrors?.[formId]?.rg}
              onChange={handleInputChange}
            />

            <S.BoxFile>
              <Input
                label={"Org. Emissor"}
                {...(formData.inputDisable !== true ? { name: "rg_sender" } : {})}
                value={(currentFormData?.rg_sender || "").toUpperCase()}
                onChange={handleInputChange}
                length={as30}
                regexMessage={multRegexMessage?.[formId]}
                conditionMessage={multConditionMessage?.[formId]}
                error={multFieldErrors?.[formId]?.rg_sender}
              />

              <InputFileDinamic
                name="rg_file"
                {...(formData.inputDisable !== true ? { type: "file" } : {})}
                onChange={handleFileInputChange}
                error={multFieldErrors?.[formId]?.rg_file || null}
                dataForm={multDataForm}
                setDataForm={setMultDataForm}
                formId={formId}
                title={"rg"}
                regexMessage={multRegexMessage?.[formId]}
                conditionMessage={multConditionMessage?.[formId]}
                url={URL_LEGAL_REPRESENTATIVE}
              />
            </S.BoxFile>
          </S.Box>

          <S.Box>
            <InputFormats
              documentType="cnh"
              label={"CNH"}
              {...(formData.inputDisable !== true ? { name: "cnh" } : {})}
              type={"cnh"}
              regexMessage={multRegexMessage?.[formId]}
              conditionMessage={multConditionMessage?.[formId]}
              value={currentFormData?.cnh || ""}
              error={multFieldErrors?.[formId]?.cnh}
              onChange={handleInputChange}
            />

            <S.BoxFile>
              <Input
                label={"Org. Emissor"}
                {...(formData.inputDisable !== true ? { name: "cnh_sender" } : {})}
                value={(currentFormData?.cnh_sender || "").toUpperCase()}
                onChange={handleInputChange}
                length={as30}
                regexMessage={multRegexMessage?.[formId]}
                conditionMessage={multConditionMessage?.[formId]}
                error={multFieldErrors?.[formId]?.cnh_sender}
              />

              <InputFileDinamic
                name="cnh_file"
                {...(formData.inputDisable !== true ? { type: "file" } : {})}
                onChange={handleFileInputChange}
                error={multFieldErrors?.[formId]?.cnh_file || null}
                dataForm={multDataForm}
                setDataForm={setMultDataForm}
                formId={formId}
                title={"cnh"}
                regexMessage={multRegexMessage?.[formId]}
                conditionMessage={multConditionMessage?.[formId]}
                url={URL_LEGAL_REPRESENTATIVE}
              />
            </S.BoxFile>
          </S.Box>
        </S.Box>

        <S.Box>
          <Input
            label={"Certidão"}
            {...(formData.inputDisable !== true ? { name: "type_certificate" } : {})}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            value={currentFormData?.type_certificate || ""}
            error={multFieldErrors?.[formId]?.type_certificate}
            length={as50}
            onChange={handleInputChange}
          />
          <S.BoxFile>
            <Input
              label={"Descrição"}
              {...(formData.inputDisable !== true ? { name: "certificate" } : {})}
              type={""}
              regexMessage={multRegexMessage?.[formId]}
              conditionMessage={multConditionMessage?.[formId]}
              value={currentFormData?.certificate || ""}
              error={multFieldErrors?.[formId]?.certificate}
              length={as255}
              onChange={handleInputChange}
            />

            <InputFileDinamic
              name="certificate_file"
              {...(formData.inputDisable !== true ? { type: "file" } : {})}
              onChange={handleFileInputChange}
              error={multFieldErrors?.[formId]?.certificate_file || null}
              dataForm={multDataForm}
              setDataForm={setMultDataForm}
              formId={formId}
              title={"cert."}
              regexMessage={multRegexMessage?.[formId]}
              conditionMessage={multConditionMessage?.[formId]}
              url={URL_LEGAL_REPRESENTATIVE}
            />
          </S.BoxFile>
        </S.Box>

        <S.Box>
          <InputSelect
            label={"Qualificação"}
            {...(formData.inputDisable !== true ? { name: "qualification" } : {})}
            value={currentFormData?.qualification || ""}
            onChange={handleInputChange}
            options={pickList.TYPE_QUALIFICATION}
            regexMessage={multRegexMessage?.[formId]}
            conditionMessage={multConditionMessage?.[formId]}
            error={multFieldErrors?.[formId]?.qualification}
          />

          {currentFormData?.qualification === "outros" && (
            <Input
              label={"Qualificação"}
              {...(formData.inputDisable !== true ? { name: "others_qualification" } : {})}
              regexMessage={multRegexMessage?.[formId]}
              conditionMessage={multConditionMessage?.[formId]}
              value={currentFormData?.others_qualification || ""}
              length={as100}
              onChange={handleInputChange}
              error={multFieldErrors?.[formId]?.others_qualification}
            />
          )}
        </S.Box>
      </S.Main>
    </>
  )
}

export default InputsClientRepresentative
