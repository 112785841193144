import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../../../global/GlobalContext';
import * as S from './styled';
import { CircularProgress, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputSelect from '../../../common/Reuse/Input/InputSelect/InputSelect';
import { URL_BESTOWED, URL_CITY_BY_ID, URL_ZIP_CODE } from '../../../../constants/urls/urls';
import { pickList } from '../../../../constants/models/models';
import Input from '../../../common/Reuse/Input/Input/Input';
import {
  as100,
  as15,
  as255,
  as30,
  as50,
  as9,
  asAlone,
  asMarried,
  asOther,
  asStableUnion,
  check_marital_status
} from '../../../../constants/constants/constAndRegex';
import InputDate from '../../../common/Reuse/Input/InputDate/InputDate';
import InputFile from '../../../common/Reuse/Input/InputFile/InputFile';
import { removeNonNumericCharacters, sortAlphabetically } from '../../../../constants/functions/functions';
import InputPhone from '../../Reuse/Input/InputPhone/InputPhone';
import InputFormats from '../../Reuse/Input/InputFormats/InputFormats';
import InputMultiLine from '../../Reuse/Input/InputMultiLine/InputMultiLine';

const InputsBestowed = (props) => {
  const {
    states: {
      globalState: { formData, stateList }
    },
    functions: { headerGlobal, errorToast, inputChange }
  } = useContext(GlobalContext);

  const inputFileUrl = URL_BESTOWED;
  const [changeCount, setChangeCount] = useState(0);
  const [showContent, setShowContent] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage
  } = props;
  const handleToggleContent = () => {
    setShowContent(!showContent);
  };

  const sortedStateList = sortAlphabetically(stateList);

  const statesUF = sortedStateList?.map((index) => ({
    value: index.sigla,
    label: index.nome
  }));

  const fetchAndSetCityData = async (stateParam, updateField) => {
    setIsLoading(true);
    const url = URL_CITY_BY_ID.replace(':id', stateParam);
    try {
      const response = await axios.get(url);
      setDataForm((prevState) => ({
        ...prevState,
        [updateField]: response.data // Atualiza o campo dinamicamente
      }));
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      const errorMessage = error?.response?.data?.detail;
      errorToast(errorMessage);
    }
  };

  useEffect(() => {
    fetchAndSetCityData(dataForm?.place_of_birth_state, 'cityList');
  }, [dataForm?.place_of_birth_state]);

  useEffect(() => {
    fetchAndSetCityData(dataForm?.spouse_place_of_birth_state, 'cityList2');
  }, [dataForm?.spouse_place_of_birth_state]);

  useEffect(() => {
    fetchAndSetCityData(dataForm?.state, 'cityList3');
  }, [dataForm?.state]);

  const cityOptions = dataForm?.cityList?.map((index) => ({
    value: index.nome,
    label: index.nome
  }));

  const cityOptions2 = dataForm?.cityList2?.map((index) => ({
    value: index.nome,
    label: index.nome
  }));

  const cityOptions3 = dataForm?.cityList3?.map((index) => ({
    value: index.nome,
    label: index.nome
  }));

  const getZipCode = async () => {
    setIsLoading(true);
    const cep = removeNonNumericCharacters(dataForm.zip_code);
    const url = URL_ZIP_CODE.replace(':cep', cep);
    try {
      const response = await axios.get(url, { headers: headerGlobal() });
      const { localidade, logradouro, bairro, uf } = response.data;
      setDataForm((prevState) => ({
        ...prevState,
        street: logradouro,
        neighborhood: bairro,
        city: localidade,
        state: uf
      }));
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      console.log('Erro em getZipCode');
      errorToast('Erro ao carregar informações');
    }
  };

  useEffect(() => {
    dataForm.zip_code && setChangeCount((prevCount) => prevCount + 1);
  }, [dataForm.zip_code]);

  useEffect(() => {
    dataForm.zip_code?.length === 8 && changeCount >= 3 && getZipCode() && setChangeCount(0);
  }, [dataForm.zip_code, changeCount]);

  return (
    <>
      {
        <S.LoadingOverlay isLoading={isLoading}>
          <CircularProgress />
        </S.LoadingOverlay>
      }
      <S.Main>
        <S.Custon10>
          <InputSelect
            label={'Tipo de Pessoa'}
            {...(formData.noEdit !== true ? { name: 'verifyType' } : {})}
            value={formData.verifyType || ''}
            onChange={inputChange}
            options={pickList.TYPE_ASS}
          />
        </S.Custon10>
        <S.H2>outorgado</S.H2>
        <S.Box>
          <Input
            label={'Nome Completo'}
            {...(formData.inputDisable !== true ? { name: 'name' } : {})}
            value={dataForm.name || ''}
            onChange={inputForm}
            error={fieldErrors.name || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            length={as100}
          />
          <S.Custon15rem>
            <InputDate
              label={'Data de Nascimento'}
              {...(formData.inputDisable !== true ? { name: 'birth_date' } : {})}
              value={dataForm.birth_date || ''}
              onChange={inputForm}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              error={fieldErrors.birth_date || null}
            />
          </S.Custon15rem>
        </S.Box>

        <S.Box>
          <InputSelect
            label={'Gênero'}
            {...(formData.inputDisable !== true ? { name: 'gender' } : {})}
            value={dataForm.gender || ''}
            onChange={inputForm}
            options={pickList.TYPE_GENERAL}
            error={fieldErrors.gender || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />


          <InputSelect
            label={'Estado Civil'}
            {...(formData.inputDisable !== true ? { name: 'marital_status' } : {})}
            value={dataForm.marital_status || ''}
            options={pickList.TYPE_CIVIL}
            onChange={inputForm}
            error={fieldErrors.marital_status || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />


          {dataForm.marital_status === asOther && (
            <Input
              label={'Estado Civil'}
              {...(formData.inputDisable !== true ? { name: 'others_marital_status' } : {})}
              value={dataForm.others_marital_status || ''}
              onChange={inputForm}
              error={fieldErrors.others_marital_status || null}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              length={as50}
            />
          )}
        </S.Box>

        {(dataForm?.marital_status === asMarried) && (
          <S.Box>
            {dataForm && (
              <InputSelect
                label={'Regime de Casamento'}
                {...(formData.inputDisable !== true ? { name: 'marriage_regime' } : {})}
                value={dataForm.marriage_regime || ''}
                options={pickList.TYPE_REGIMENT}
                onChange={inputForm}
                error={fieldErrors.marriage_regime || null}
                regexMessage={regexMessage}
                conditionMessage={conditionMessage}
              />
            )}

            {dataForm?.marriage_regime === asOther && dataForm?.marital_status !== asAlone && (
              <Input
                label={'Regime de Casamento'}
                {...(formData.inputDisable !== true ? { name: 'others_marriage_regime' } : {})}
                value={dataForm.others_marriage_regime || ''}
                onChange={inputForm}
                error={fieldErrors.others_marriage_regime || null}
                regexMessage={regexMessage}
                conditionMessage={conditionMessage}
                length={as50}
              />
            )}
          </S.Box>
        )}

        <S.Box>
          <InputSelect
            label={'Nacionalidade'}
            {...(formData.inputDisable !== true ? { name: 'nationality' } : {})}
            value={dataForm.nationality || ''}
            options={pickList.TYPE_NATIONALITY}
            onChange={inputForm}
            error={fieldErrors.nationality || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />

          <InputSelect
            label={'Naturalidade UF'}
            {...(formData.inputDisable !== true ? { name: 'place_of_birth_state' } : {})}
            value={dataForm?.place_of_birth_state || ''}
            options={statesUF}
            onChange={(selectedOption) => {
              inputForm(selectedOption);
              setDataForm((prevDataForm) => ({
                ...prevDataForm,
                place_of_birth_town: ""
              }));
            }}
            error={fieldErrors.place_of_birth_state || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />

          <InputSelect
            label={'Naturalidade CDD'}
            {...(formData.inputDisable !== true ? { name: 'place_of_birth_town' } : {})}
            value={dataForm?.place_of_birth_town || ''}
            options={cityOptions}
            onChange={inputForm}
            error={fieldErrors.place_of_birth_town || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />
        </S.Box>
        <S.Box>
          <Input
            label={'Filiação'}
            {...(formData.inputDisable !== true ? { name: 'affiliation' } : {})}
            value={dataForm.affiliation || ''}
            onChange={inputForm}
            error={fieldErrors.affiliation || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            length={as100}
          />
          <S.Box>
            <Input
              label={'Profissão'}
              {...(formData.inputDisable !== true ? { name: 'profession' } : {})}
              value={dataForm.profession || ''}
              onChange={inputForm}
              error={fieldErrors.profession || null}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              length={as255}
            />

            <InputPhone
              label={'Telefone'}
              {...(formData.inputDisable !== true ? { name: 'telephone' } : {})}
              value={dataForm.telephone || ''}
              onChange={inputForm}
              error={fieldErrors.telephone || null}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              length={as15}
            />
          </S.Box>
        </S.Box>

        <S.Box>
          <Input
            label={'E-mail'}
            {...(formData.inputDisable !== true ? { name: 'email' } : {})}
            value={dataForm.email || ''}
            onChange={inputForm}
            error={fieldErrors.email || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            length={as100}
          />
          <S.BoxFile>
            <InputFormats
              documentType={'cpf'}
              label={'CPF'}
              {...(formData.inputDisable !== true ? { name: 'cpf' } : {})}
              value={dataForm.cpf || ''}
              onChange={inputForm}
              error={fieldErrors.cpf || null}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
            />

            <InputFile
              name="cpf_file"
              {...(formData.inputDisable !== true ? { type: 'file' } : {})}
              error={fieldErrors.cpf_file || null}
              dataForm={dataForm}
              setDataForm={setDataForm}
              title={'cpf'}
              url={inputFileUrl}
            />
          </S.BoxFile>
        </S.Box>

        <S.Box>
          <S.Box>
            <InputFormats
              documentType={'rg'}
              label={'RG'}
              {...(formData.inputDisable !== true ? { name: 'rg' } : {})}
              value={dataForm.rg || ''}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              error={fieldErrors.rg || null}
              onChange={inputForm}
            />
            <S.BoxFile>
              <Input
                label={'Org. Emissor'}
                {...(formData.inputDisable !== true ? { name: 'rg_sender' } : {})}
                value={(dataForm.rg_sender || '').toUpperCase()}
                regexMessage={regexMessage}
                conditionMessage={conditionMessage}
                error={fieldErrors.rg_sender || null}
                onChange={inputForm}
                length={as30}
              />

              <InputFile
                name="rg_file"
                {...(formData.inputDisable !== true ? { type: 'file' } : {})}
                dataForm={dataForm}
                setDataForm={setDataForm}
                title={'rg'}
                url={inputFileUrl}
              />
            </S.BoxFile>
          </S.Box>
          <S.Box>
            <InputFormats
              documentType={'cnh'}
              label={'CNH'}
              {...(formData.inputDisable !== true ? { name: 'cnh' } : {})}
              onChange={inputForm}
              error={fieldErrors.cnh || null}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              value={dataForm.cnh || ''}
            />

            <S.BoxFile>
              <Input
                label={'Org. Emissor'}
                {...(formData.inputDisable !== true ? { name: 'cnh_sender' } : {})}
                value={(dataForm.cnh_sender || '').toUpperCase()}
                regexMessage={regexMessage}
                conditionMessage={conditionMessage}
                error={fieldErrors.cnh_sender || null}
                onChange={inputForm}
                length={as30}
              />

              <InputFile
                name="cnh_file"
                {...(formData.inputDisable !== true ? { type: 'file' } : {})}
                error={fieldErrors.cnh_file || null}
                dataForm={dataForm}
                setDataForm={setDataForm}
                title={'cnh'}
                url={inputFileUrl}
              />
            </S.BoxFile>
          </S.Box>
        </S.Box>

        <S.Box>
          <Input
            label={'Certidão'}
            {...(formData.inputDisable !== true ? { name: 'type_certificate' } : {})}
            value={dataForm.type_certificate || ''}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            error={fieldErrors.type_certificate || null}
            onChange={inputForm}
            length={as100}
          />

          <S.BoxFile>
            <Input
              label={'Descrição'}
              {...(formData.inputDisable !== true ? { name: 'certificate' } : {})}
              value={dataForm.certificate || ''}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              error={fieldErrors.certificate || null}
              onChange={inputForm}
              length={as255}
            />

            <InputFile
              name="certificate_file"
              {...(formData.inputDisable !== true ? { type: 'file' } : {})}
              error={fieldErrors.certificate_file || null}
              dataForm={dataForm}
              setDataForm={setDataForm}
              title={'cert.'}
              url={inputFileUrl}
            />
          </S.BoxFile>
        </S.Box>

        <S.Box>
          <S.Custon15rem>
            <InputFormats
              documentType={'cep'}
              label={'CEP'}
              {...(formData.inputDisable !== true ? { name: 'zip_code' } : {})}
              value={dataForm.zip_code || ''}
              onChange={inputForm}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              error={fieldErrors.zip_code || null}
            />
          </S.Custon15rem>
          <Input
            label={'Logradouro'}
            {...(formData.inputDisable !== true ? { name: 'street' } : {})}
            value={dataForm.street || ''}
            onChange={inputForm}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            error={fieldErrors.street || null}
            length={as100}
          />
        </S.Box>

        <S.Box>
          <Input
            label={'Bairro'}
            {...(formData.inputDisable !== true ? { name: 'neighborhood' } : {})}
            value={dataForm.neighborhood || ''}
            onChange={inputForm}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            error={fieldErrors.neighborhood || null}
            length={as100}
          />

          <InputSelect
            label={'UF'}
            {...(formData.inputDisable !== true ? { name: 'state' } : {})}
            value={dataForm.state || ''}
            options={statesUF}
            onChange={(selectedOption) => {
              inputForm(selectedOption);
              setDataForm((prevDataForm) => ({
                ...prevDataForm,
                city: ""
              }));
            }}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            error={fieldErrors.state || null}
          />

          <InputSelect
            label={'Cidade'}
            {...(formData.inputDisable !== true ? { name: 'city' } : {})}
            value={dataForm.city || ''}
            options={cityOptions3}
            onChange={inputForm}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            error={fieldErrors.city || null}
          />

          <Input
            label={'Nº'}
            {...(formData.inputDisable !== true ? { name: 'number' } : {})}
            value={dataForm.number || ''}
            onChange={inputForm}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            error={fieldErrors.number || null}
            length={as9}
          />
        </S.Box>

        <InputMultiLine
          label={'Complemento'}
          {...(formData.inputDisable !== true ? { name: 'complement' } : {})}
          value={dataForm.complement || ''}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          error={fieldErrors.complement || null}
          onChange={inputForm}
        />

        {check_marital_status(dataForm) && (
          <S.Box>
            <S.BoxCont>
              <S.H2>cônjuge</S.H2>
              <IconButton onClick={handleToggleContent}>
                <ExpandMoreIcon />
              </IconButton>
            </S.BoxCont>
          </S.Box>
        )}

        {showContent && check_marital_status(dataForm) && (
          <S.Main>
            <S.Box>
              <Input
                label={'Cônjuge'}
                {...(formData.inputDisable !== true ? { name: 'spouse' } : {})}
                value={dataForm.spouse || ''}
                onChange={inputForm}
                regexMessage={regexMessage}
                conditionMessage={conditionMessage}
                length={as100}
                error={fieldErrors.spouse || null}
              />

              <S.Custon15rem>
                <InputDate
                  label={'Data de Nascimento'}
                  {...(formData.inputDisable !== true ? { name: 'spouse_birth_date' } : {})}
                  value={dataForm.spouse_birth_date || ''}
                  onChange={inputForm}
                  regexMessage={regexMessage}
                  conditionMessage={conditionMessage}
                  error={fieldErrors.spouse_birth_date || null}
                />
              </S.Custon15rem>
            </S.Box>

            <S.Box>
              <InputSelect
                label={'Nacionalidade'}
                {...(formData.inputDisable !== true ? { name: 'spouse_nationality' } : {})}
                value={dataForm.spouse_nationality || ''}
                options={pickList.TYPE_NATIONALITY}
                onChange={inputForm}
                regexMessage={regexMessage}
                conditionMessage={conditionMessage}
                error={fieldErrors.spouse_nationality || null}
              />

              <InputSelect
                label={'Naturalidade UF'}
                {...(formData.inputDisable !== true ? { name: 'spouse_place_of_birth_state' } : {})}
                value={dataForm.spouse_place_of_birth_state || ''}
                options={statesUF}
                onChange={(selectedOption) => {
                  inputForm(selectedOption);
                  setDataForm((prevDataForm) => ({
                    ...prevDataForm,
                    spouse_place_of_birth_town: ""
                  }));
                }}
                regexMessage={regexMessage}
                conditionMessage={conditionMessage}
                error={fieldErrors.spouse_place_of_birth_state || null}
              />

              <InputSelect
                label={'Naturalidade CDD'}
                {...(formData.inputDisable !== true ? { name: 'spouse_place_of_birth_town' } : {})}
                value={dataForm.spouse_place_of_birth_town || ''}
                options={cityOptions2}
                onChange={inputForm}
                error={fieldErrors.spouse_place_of_birth_town || null}
                regexMessage={regexMessage}
                conditionMessage={conditionMessage}
              />
            </S.Box>
            <S.Box>
              <Input
                label={'Filiação'}
                {...(formData.inputDisable !== true ? { name: 'spouse_affiliation' } : {})}
                value={dataForm.spouse_affiliation || ''}
                onChange={inputForm}
                regexMessage={regexMessage}
                conditionMessage={conditionMessage}
                length={as100}
                error={fieldErrors.spouse_affiliation || null}
              />
              <S.Box>
                <Input
                  label={'Profissão'}
                  {...(formData.inputDisable !== true ? { name: 'spouse_profession' } : {})}
                  value={dataForm.spouse_profession || ''}
                  onChange={inputForm}
                  regexMessage={regexMessage}
                  conditionMessage={conditionMessage}
                  length={as30}
                  error={fieldErrors.spouse_profession || null}
                />

                <InputPhone
                  label={'Telefone'}
                  {...(formData.inputDisable !== true ? { name: 'spouse_telephone' } : {})}
                  value={dataForm.spouse_telephone || ''}
                  onChange={inputForm}
                  regexMessage={regexMessage}
                  conditionMessage={conditionMessage}
                  error={fieldErrors.spouse_telephone || null}
                />
              </S.Box>
            </S.Box>

            <S.Box>
              <S.BoxFile>
                <InputFormats
                  documentType={'cpf'}
                  label={'CPF'}
                  {...(formData.inputDisable !== true ? { name: 'spouse_cpf' } : {})}
                  {...(formData.inputDisable !== true ? { type: 'file' } : {})}
                  value={dataForm.spouse_cpf || ''}
                  onChange={inputForm}
                  regexMessage={regexMessage}
                  conditionMessage={conditionMessage}
                  error={fieldErrors.spouse_cpf || null}
                />

                <InputFile
                  name="spouse_cpf_file"
                  {...(formData.inputDisable !== true ? { type: 'file' } : {})}
                  value={dataForm.spouse_cpf_file || ''}
                  error={fieldErrors.spouse_cpf_file || null}
                  dataForm={dataForm}
                  setDataForm={setDataForm}
                  title={'cpf'}
                  url={inputFileUrl}
                />
              </S.BoxFile>
              <S.BoxFile>
                <Input
                  label={'Certidão de Casamento'}
                  {...(formData.inputDisable !== true ? { name: 'spouse_wedding_certificate' } : {})}
                  value={dataForm.spouse_wedding_certificate || ''}
                  error={fieldErrors.spouse_wedding_certificate || null}
                  regexMessage={regexMessage}
                  conditionMessage={conditionMessage}
                  length={as255}
                  onChange={inputForm}
                />

                <InputFile
                  name="spouse_wedding_certificate_file"
                  {...(formData.inputDisable !== true ? { type: 'file' } : {})}
                  error={fieldErrors.spouse_wedding_certificate_file || null}
                  dataForm={dataForm}
                  setDataForm={setDataForm}
                  title={'cas.'}
                  url={inputFileUrl}
                />
              </S.BoxFile>
            </S.Box>

            <S.Box>
              <S.Box>
                <InputFormats
                  documentType={'rg'}
                  label={'RG'}
                  {...(formData.inputDisable !== true ? { name: 'spouse_rg' } : {})}
                  value={dataForm.spouse_rg || ''}
                  regexMessage={regexMessage}
                  conditionMessage={conditionMessage}
                  error={fieldErrors.spouse_rg || null}
                  onChange={inputForm}
                />

                <S.BoxFile>
                  <Input
                    label={'Org. Emissor'}
                    {...(formData.inputDisable !== true ? { name: 'spouse_rg_sender' } : {})}
                    value={(dataForm.spouse_rg_sender || '').toUpperCase()}
                    regexMessage={regexMessage}
                    conditionMessage={conditionMessage}
                    error={fieldErrors.spouse_rg_sender || null}
                    length={as30}
                    onChange={inputForm}
                  />

                  <InputFile
                    name="spouse_rg_file"
                    {...(formData.inputDisable !== true ? { type: 'file' } : {})}
                    error={fieldErrors.spouse_rg_file || null}
                    dataForm={dataForm}
                    setDataForm={setDataForm}
                    title={'rg'}
                    url={inputFileUrl}
                  />
                </S.BoxFile>
              </S.Box>

              <S.Box>
                <InputFormats
                  documentType={'cnh'}
                  label={'CNH'}
                  {...(formData.inputDisable !== true ? { name: 'spouse_cnh' } : {})}
                  value={dataForm.spouse_cnh || ''}
                  regexMessage={regexMessage}
                  conditionMessage={conditionMessage}
                  error={fieldErrors.spouse_cnh || null}
                  onChange={inputForm}
                />

                <S.BoxFile>
                  <Input
                    label={'Org. Emissor'}
                    {...(formData.inputDisable !== true ? { name: 'spouse_cnh_sender' } : {})}
                    value={(dataForm.spouse_cnh_sender || '').toUpperCase()}
                    regexMessage={regexMessage}
                    conditionMessage={conditionMessage}
                    error={fieldErrors.spouse_cnh_sender || null}
                    length={as30}
                    onChange={inputForm}
                  />

                  <InputFile
                    name="spouse_cnh_file"
                    {...(formData.inputDisable !== true ? { type: 'file' } : {})}
                    error={fieldErrors.spouse_cnh_file || null}
                    dataForm={dataForm}
                    setDataForm={setDataForm}
                    title={'cnh'}
                    url={inputFileUrl}
                  />
                </S.BoxFile>
              </S.Box>
            </S.Box>
          </S.Main>
        )}
      </S.Main>
    </>
  );
};

export default InputsBestowed;
