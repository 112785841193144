import * as M from '@mui/material';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const BoxSelect = styled.div`
  margin-top: 1rem;
`;

export const Container = styled.div`
  display: flex;
  width: 55rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

export const DivGap = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 1rem;
`;

export const DivSub = styled.form`
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: auto;
`;

export const IconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;
