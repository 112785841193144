import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import * as S from '../../common/StyledCommonModals/StyledCommonModals';
import { GlobalContext } from '../../../global/GlobalContext';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import InputsLetterOfAttorney from '../../common/Formulary/InputsLetterOfAttorney/InputsLetterOfAttorney';
import { URL_LETTER_OF_ATTORNEY } from '../../../constants/urls/urls';
import useForm from '../../../hooks/useForm';
import { settingState } from '../../../constants/functions/functions';

const DetailLetterOfAttorney = (props) => {
  const {
    states: {
      globalState: { id },
    },
    functions: { toggleBoolean, headerGlobal },
  } = useContext(GlobalContext);
  const { detail, setDetail } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
  };

  // const getInfo = () => {
  //   axios
  //     .get(`${URL_LETTER_OF_ATTORNEY}${id}/`, { headers: headerGlobal() })
  //     .then((response) => {
  //       const activeAsString = String(response.data.active); // Converte o booleano para string
  //       const appointmentAsString = String(response.data.appointment); // Converte o booleano para string
  //       const bestowedIds = response.data.bestowed.map((item) => item.id);

  //       setDataForm((prevState) => ({
  //         ...prevState, // Mantém o estado existente
  //         ...response.data, // Atualiza com os novos valores de response.data
  //         verifySelect:
  //           response.data.customer?.type === 'physical' ? 'customer_person_physical' : 'customer_person_company',
  //         bestowed: bestowedIds, // Só inclui os IDs do bestowed
  //         active: activeAsString, // Atualiza o valor de 'active' para ser uma string
  //         appointment: appointmentAsString, // Atualiza o valor de 'appointment' para ser uma tring
  //       }));
  //     })
  //     .catch((error) => {
  //       console.log('Erro: EditLetterOfAttorney.js getInfo');
  //     });
  // };

  // useEffect(() => {
  //   getInfo();
  // }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getInfo = async () => {
      try {
        const response = await axios.get(`${URL_LETTER_OF_ATTORNEY}${id}/`, { headers: headerGlobal(), cancelToken: source.token });
        const activeAsString = String(response.data.active);
        const appointmentAsString = String(response.data.appointment);
        const bestowedIds = response.data.bestowed.map((item) => item.id);

        setDataForm((prevState) => ({
          ...prevState,
          ...response.data,
          verifySelect: response.data.customer?.type === 'physical' ? 'customer_person_physical' : 'customer_person_company',
          bestowed: bestowedIds,
          active: activeAsString,
          appointment: appointmentAsString,
        }));
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled.', error.message);
        } else {
          console.log('Error: EditLetterOfAttorney.js getInfo', error);
        }
      }
    };

    getInfo();

    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, []);

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setDetail, !detail)}>
        <S.DivGap>
          <TitleModal text="detalhes" />
          <InputsLetterOfAttorney {...propsList} />
        </S.DivGap>
      </Formulary>
    </S.Main>
  );
};
export default DetailLetterOfAttorney;
