import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import {
  asCadastrar,
  asCustomerPhysical,
  asDefaultErrorAxios,
  asDefaultRegister,
  asSend,
  asSending,
  asSubmit,
} from "../../../constants/constants/constAndRegex"
import { errorPropertie } from "../../../constants/constants/constError"
import { requiredPropertie } from "../../../constants/constants/requiredConst"
import { handleErrors, settingState, validateForm } from "../../../constants/functions/functions"
import { URL_PROPERTIE } from "../../../constants/urls/urls"
import { GlobalContext } from "../../../global/GlobalContext"
import useForm from "../../../hooks/useForm"
import ClearDialog from "../../common/Dialog/ClearDialog/ClearDialog"
import Formulary from "../../common/Formulary/Formulary/Formulary"
import InputsPropertie from "../../common/Formulary/InputsPropertie/InputsPropertie"
import { Button, ButtonRed } from "../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../common/Reuse/TitleModal/TitleModal"
import * as S from "../../common/StyledCommonModals/StyledCommonModals"
import * as L from "./styled"

const RegisterPropertie = (props) => {
  const {
    states: {
      globalState: { formData },
    },
    setters: { setGlobalState },
    functions: { headerGlobal, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext)
  const [clear, setClear] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { register, setRegister } = props

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm()

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    register,
    setRegister,
    clear,
    setClear,
    setFieldErrors,
  }
  // const handlePost = async (event) => {
  //   event.preventDefault();
  //   setIsSubmitting(true);

  //   const resultValidateForm = validateForm({
  //     specificValidations: requiredPropertie,
  //     dataForm,
  //     setFieldErrors,
  //     setRegexMessage,
  //     setConditionMessage,
  //   });

  //   if (!resultValidateForm) {
  //     setIsSubmitting(false);
  //     return errorToast(asDefaultErrorAxios);
  //   }

  //   const formData = new FormData();

  //   // Adiciona campos condicionais
  //   if (dataForm.status === 'completa') {
  //     formData.append('completa', '');
  //   } else if (dataForm.status === 'pendente') {
  //     formData.append('pendente', '');
  //   }

  //   // Adicione a imagem ao FormData apenas se existir
  //   if (dataForm.image) {
  //     formData.append('image', dataForm.image);
  //   } else {
  //     formData.append('image', '');
  //   }

  //   // Adicione os outros campos, exceto 'image'
  //   Object.keys(dataForm).forEach((key) => {
  //     if (!['image'].includes(key)) {
  //       formData.append(key, dataForm[key]);
  //     }
  //   });

  //   try {
  //     await axios.post(URL_PROPERTIE, formData, {
  //       headers: headerGlobal(),
  //     });
  //     sayAlteration();
  //     successToast(asDefaultRegister);
  //     settingState(setRegister, !register);
  //   } catch (error) {
  //     handleErrors({
  //       error,
  //       setFieldErrors,
  //       errorMessages: errorPropertie,
  //       errorToast,
  //     });
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  const handlePost = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    const resultValidateForm = validateForm({
      specificValidations: requiredPropertie,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    })

    if (!resultValidateForm) {
      setIsSubmitting(false)
      return errorToast(asDefaultErrorAxios)
    }

    const formData = new FormData()

    // Adiciona campos condicionais
    if (dataForm.status === "completa") {
      formData.append("completa", "")
    } else if (dataForm.status === "pendente") {
      formData.append("pendente", "")
    }

    // Adicione a imagem ao FormData apenas se existir
    formData.append("image", dataForm.image || "") // Adiciona a imagem se existir, caso contrário, adiciona um valor padrão vazio

    // Adicione os outros campos, exceto 'image'
    Object.keys(dataForm).forEach((key) => {
      if (!["image"].includes(key)) {
        formData.append(key, dataForm[key])
      }
    })

    try {
      await axios.post(URL_PROPERTIE, formData, {
        headers: headerGlobal(),
      })
      sayAlteration()
      successToast(asDefaultRegister)
      settingState(setRegister, !register)
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorPropertie,
        errorToast,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      formData: {
        verifySelect: asCustomerPhysical,
        noEdit: false,
        inputDisable: false,
      },
    }))
  }, [])

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setRegister, !register)}>
        <S.DivGap>
          <TitleModal text={asCadastrar} />
          <InputsPropertie {...propsList} />
        </S.DivGap>
        <L.DivGap>
          <ButtonRed
            onClick={formData.inputDisable !== true ? () => settingState(setClear, !clear) : undefined}
            named={"limpar"}
          />
          <Button
            type={formData.inputDisable !== true ? asSubmit : undefined}
            named={isSubmitting ? asSending : asSend}
            onClick={(event) => {
              if (!isSubmitting) {
                handlePost(event)
              }
            }}
            disabled={isSubmitting}
          />
        </L.DivGap>
      </Formulary>
      <ClearDialog {...propsList} />
    </S.Main>
  )
}
export default RegisterPropertie
