import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asClean,
  asDefaultAlteration,
  asDefaultErrorAxios,
  asEditar,
  asSend,
  asSending,
  asSubmit
} from '../../../../constants/constants/constAndRegex';
import { errorPhysical } from '../../../../constants/constants/constError';
import { DATA_PHYSICAL, DATA_SPOUSE } from '../../../../constants/constants/dataToSend';
import { requiredPhysical, requiredSpouse } from '../../../../constants/constants/requiredConst';
import { handleErrors, settingState, validateForm } from '../../../../constants/functions/functions';
import { URL_PHYSICAL } from '../../../../constants/urls/urls';
import { GlobalContext } from '../../../../global/GlobalContext';
import useForm from '../../../../hooks/useForm';
import ClearDialog from '../../../common/Dialog/ClearDialog/ClearDialog';
import Formulary from '../../../common/Formulary/Formulary/Formulary';
import InputsClientPhysical from '../../../common/Formulary/InputsClient/InputsClientPhysical/InputsClientPhysical';
import { Button, ButtonRed } from '../../../common/Reuse/Button/Buttons/Buttons';
import TitleModal from '../../../common/Reuse/TitleModal/TitleModal';
import * as L from './styled';
const EditClientPhysical = (props) => {
  const {
    states: {
      globalState: { formData, id }
    },
    functions: { headerGlobal, successToast, sayAlteration, errorToast, toggleBoolean }
  } = useContext(GlobalContext);
  const [clear, setClear] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { edit, setEdit } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage
  } = useForm();

  const propsList = {
    setFieldErrors,
    setDataForm,
    dataForm,
    fieldErrors,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
    clear,
    setClear
  };
  console.log(dataForm)
  const handlePut = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const specificValidations = [...requiredPhysical, ...requiredSpouse];

    const resultValidateForm = validateForm({
      specificValidations,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    const dataToSend = DATA_PHYSICAL(dataForm);
    const spouseData = DATA_SPOUSE(dataForm);
    const combinedData = {
      ...dataToSend,
      ...spouseData
    };

    try {
      await axios.put(`${URL_PHYSICAL}${id}/`, combinedData, {
        headers: headerGlobal()
      });
      sayAlteration();
      successToast(asDefaultAlteration);
      settingState(setEdit, !edit);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorPhysical,
        errorToast
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${formData.axiosUrl}${id}/`, {
        headers: headerGlobal()
      });
      const data = response.data;
      setDataForm(data);
    } catch (error) {
      console.log('Erro em getInfo');
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <L.Main>
        <Formulary onClick={() => settingState(setEdit, !edit)}>
          <L.BoxSelect>
            <TitleModal text={asEditar} />
          </L.BoxSelect>
          <L.Container>
            <InputsClientPhysical {...propsList} />
          </L.Container>
          <L.DivGap>
            <ButtonRed onClick={() => settingState(setClear, !clear)} named={asClean} />
            <Button
              type={formData.inputDisable !== true ? asSubmit : undefined}
              named={isSubmitting ? asSending : asSend}
              onClick={(event) => {
                if (!isSubmitting) {
                  handlePut(event);
                  toggleBoolean('statusImage', true);
                }
              }}
              disabled={isSubmitting}
            />
          </L.DivGap>
        </Formulary>
      </L.Main>

      {/* diálogo de limpar formulário */}
      <ClearDialog {...propsList} />
    </>
  );
};
export default EditClientPhysical;
