import React, { useContext } from 'react';
import { as255, as50 } from '../../../../constants/constants/constAndRegex';
import { GlobalContext } from '../../../../global/GlobalContext';
import Input from '../../Reuse/Input/Input/Input';
import * as S from './styled';

const InputsSubProjectTypes = (props) => {
  const {
    states: {
      globalState: { formData },
    },
  } = useContext(GlobalContext);
  const {
    formId,
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
  } = props;

    const currentFormData = formId
      ? Array.isArray(multDataForm)
        ? multDataForm.find((form) => form.id === formId)
        : null
      : null;


  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    multInputForm(formId, fieldName, value);
  };


  return (
    <S.Main>
      <S.H2>Subprojeto</S.H2>
      <Input
        label={'Nome'}
        {...(formData.inputDisable !== true ? { name: 'subproject_name' } : {})}
        value={currentFormData?.subproject_name || ''}
        onChange={handleInputChange}
        error={multFieldErrors?.[formId]?.subproject_name}
        regexMessage={multRegexMessage?.[formId]}
        conditionMessage={multConditionMessage?.[formId]}
        length={as255}
      />
    </S.Main>
  );
};

export default InputsSubProjectTypes;
