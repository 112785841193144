import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import {
  asClean,
  asDefaultAlteration,
  asDefaultErrorAxios,
  asSend,
  asSending,
  asSubmit,
} from "../../../../constants/constants/constAndRegex"
import { errorCompany } from "../../../../constants/constants/constError"
import { DATA_COMPANY } from "../../../../constants/constants/dataToSend"
import { requiredCompany } from "../../../../constants/constants/requiredConst"
import { handleErrors, settingState, validateForm } from "../../../../constants/functions/functions"
import { URL_COMPANY } from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import useForm from "../../../../hooks/useForm"
import ClearDialog from "../../../common/Dialog/ClearDialog/ClearDialog"
import Formulary from "../../../common/Formulary/Formulary/Formulary"
import InputsClientCompany from "../../../common/Formulary/InputsClient/InputsClientCompany/InputsClientCompany"
import { Button, ButtonRed } from "../../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../../common/Reuse/TitleModal/TitleModal"
import EditClientLegalRepresentative from "../EditClientLegalRepresentative/EditClientLegalRepresentative"
import * as L from "./styled"
const EditClientCompany = (props) => {
  const {
    states: {
      globalState: { id, lookAlteration },
    },
    setters: { setGlobalState },
    functions: { headerGlobal, successToast, sayAlteration, errorToast, toggleBoolean },
  } = useContext(GlobalContext)
  const [clear, setClear] = useState(false)
  const [viewCompany, setViewCompany] = useState(false)
  const [viewRepresentative, setViewRepresentative] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { edit, setEdit } = props

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm()

  const propsList = {
    setViewRepresentative,
    viewRepresentative,
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    clear,
    setClear,
  }

  const handlePost = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)
    const resultValidateForm = validateForm({
      specificValidations: requiredCompany,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    })

    if (!resultValidateForm) {
      setIsSubmitting(false)
      return errorToast(asDefaultErrorAxios)
    }

    const dataToSend = DATA_COMPANY(dataForm)
    try {
      await axios.put(`${URL_COMPANY}${id}/`, dataToSend, {
        headers: headerGlobal(),
      })
      sayAlteration()
      setGlobalState((prevState) => ({
        ...prevState,
        sentCompany: true,
      }))
      successToast(asDefaultAlteration)
      setViewCompany(true)
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorCompany,
        errorToast,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_COMPANY}${id}/`, {
        headers: headerGlobal(),
      })
      setDataForm(response.data)
    } catch (error) {
      console.log("Error EditClientCompany: getInfo")
    }
  }

  useEffect(() => {
    getInfo()
  }, [])

  useEffect(() => {
    if (viewCompany && viewRepresentative === true) settingState(setEdit, !edit)
  }, [lookAlteration])

  return (
    <>
      <L.Main>
        <Formulary onClick={() => settingState(setEdit, !edit)}>
          <L.BoxSelect>
            <TitleModal text={"editar"} />
          </L.BoxSelect>
          {viewCompany === false && (
            <>
              <L.Container>
                <InputsClientCompany {...propsList} />
              </L.Container>
              <L.DivGap>
                <ButtonRed onClick={() => settingState(setClear, !clear)} named={asClean} />
                <Button
                  type={asSubmit}
                  named={isSubmitting ? asSending : asSend}
                  onClick={(event) => {
                    if (!isSubmitting) {
                      handlePost(event)
                      toggleBoolean("statusImage", true)
                    }
                  }}
                  disabled={isSubmitting}
                />
              </L.DivGap>
            </>
          )}

          <L.Container>{viewRepresentative === false && <EditClientLegalRepresentative {...propsList} />}</L.Container>
        </Formulary>
      </L.Main>

      {/* diálogo de limpar formulário */}
      <ClearDialog {...propsList} />
    </>
  )
}

export default EditClientCompany
