import { useState } from "react"
import { asCpf, asRg } from "../constants/constAndRegex"

export const getSelectedLetterOfAttorney = (globalState) => {
  return globalState.selectedLetterOfAttorney
}

// Ordernar pelo id
export const sortedState = (index) => {
  if (!index) {
    return
  }
  index.sort((a, b) => b.id - a.id)
}

// Ordernar em ordem alfabetica
export const sortAlphabetically = (index) => {
  if (!Array.isArray(index) || index.length === 0) {
    return []
  }
  return index.slice().sort((a, b) => a.nome.localeCompare(b.nome))
}

// Filtra os dados de acordo com a pesquisa
export const FilteredData = ({ client }) => {
  const [searchName, setSearchName] = useState("")
  const [selectedType, setSelectedType] = useState("")

  const filteredData = Array.isArray(client)
    ? client.filter((item) => {
        if (selectedType && item.tipo_pessoa !== selectedType) {
          return false
        }
        if (searchName && !item.nome.toLowerCase().includes(searchName.toLowerCase())) {
          return false
        }
        return true
      })
    : []

  return {
    searchName,
    setSearchName,
    selectedType,
    setSelectedType,
    filteredData,
  }
}

export const formatActive = (value) => {
  if (value === true) {
    return "Ativo"
  } else if (value === false) {
    return "Inativo"
  } else {
    return "-"
  }
}

export const formtPayment = (payment) => {
  if (payment === "a_vista") {
    return "À vista"
  } else if (payment === "parcelado") {
    return "Parcelado"
  } else {
    return "-"
  }
}

export const formtProgress = (progress) => {
  if (progress === "aprovada") {
    return "Aprovada"
  } else if (progress === "nao_aprovada") {
    return "Não Aprovada"
  } else {
    return "-"
  }
}

export const formtTypePeople = (tipoPessoa) => {
  if (tipoPessoa === "pessoa_fisica") {
    return "Pessoa Física"
  } else if (tipoPessoa === "pessoa_juridica") {
    return "Pessoa Jurídica"
  } else {
    return "-"
  }
}

export const formatDocumentTipology = (value) => {
  if (value === "procuracao") {
    return "Procuração"
  } else if (value === "subestabelecimento_de_procuracao_com_reserva_de_poderes") {
    return "Subestabelecimento de Procuração com Reserva de Poderes"
  } else if (value === "subestabelecimento_de_procuracao_sem_reserva_de_poderes") {
    return "Subestabelecimento de Procuração sem Reserva de Poderes"
  } else {
    return "-"
  }
}

export const formatLetterDoc = (value) => {
  if (value === "procuracao_particular") {
    return "Procuração Particular"
  } else if (value === "subestabelecimento_de_procuracao") {
    return "Subestabelecimento de Procuração"
  } else {
    return "-"
  }
}

export const formatRegimeCasamento = (value) => {
  if (value === "comunhao_universal_de_bens") {
    return "Comunhão universal de bens"
  } else if (value === "comunhao_parcial_de_bens") {
    return "Comunhão parcial de bens"
  } else if (value === "separaçao_total_de_bens") {
    return "Separação total de bens"
  } else if (value === "separacao_obrigatoria_de_bens") {
    return "Separação obrigatória de bens"
  } else if (value === "outros") {
    return "Outros"
  } else {
    return "-"
  }
}

export const formatDivisionsInvolved = (value) => {
  if (value === "reparticoes_publicas_municipal") {
    return "Repartições públicas Municipal"
  } else if (value === "estadual_e_federal") {
    return "Estadual e Federal"
  } else if (value === "reparticoes_publicas_municipal_e_estadual") {
    return "Repartições públicas Municipal e Estadual"
  } else if (value === "registrador_de_imoveis_e_notas") {
    return "Registrador de Imóveis e Notas"
  } else if (value === "outros") {
    return "Outros"
  } else {
    return "-"
  }
}

export const formatQualificacao = (values, extraInfo = {}) => {
  if (!values || values.length === 0) {
    return "-"
  }

  if (!Array.isArray(values)) {
    values = [values]
  }

  values = values.flatMap((value) => {
    if (value) {
      return value.split(",").map((item) => item.trim())
    } else {
      return []
    }
  })

  return values
    .map((value) => {
      if (value === "socio_administrador") {
        return "Sócio Administrador"
      } else if (value === "administrador") {
        return "Administrador"
      } else if (value === "socio") {
        return "Sócio"
      } else if (value === "procurador") {
        return "Procurador"
      } else if (value === "outros") {
        return extraInfo.others_qualification || "Outros" // Usa o valor do objeto extraInfo se estiver disponível
      } else {
        return formatCapitalize(value)
      }
    })
    .join(", ")
}

export const formatCapitalize = (string) => {
  if (!string || typeof string !== "string") return "-"
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const formatPhone = (value) => {
  const phoneNumber = String(value).replace(/\D/g, "") // Garante que o valor seja uma string e remove caracteres não numéricos

  if (phoneNumber.length === 0) {
    return "" // Retorna uma string vazia se o número for vazio
  }

  if (phoneNumber.length > 10) {
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 3)}.${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7)}`
  } else {
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6)}`
  }
}

export const formatPhoneNumber = (value) => {
  const phoneNumber = value.replace(/\D/g, "") // Remove todos os caracteres não-numéricos

  if (phoneNumber.length === 0 || !value) {
    return "" // Retorna uma string vazia se o número for vazio
  }

  let formattedPhoneNumber = ""

  if (phoneNumber.length <= 2) {
    return phoneNumber
  }

  if (phoneNumber.length <= 3) {
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`
  }

  if (phoneNumber.length <= 7) {
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 3)}.${phoneNumber.slice(3)}`
  }

  return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 3)}.${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7)}`
}

export const formatUpper = (string) => {
  if (!string) return null
  return string.toUpperCase()
}

export const formatLower = (string) => {
  return string ? string.toLowerCase() : "-"
}

export const formatDate = (data) => {
  if (!data) return ""
  const [ano, mes, dia] = data.split("-")
  return `${dia}/${mes}/${ano}`
}

export const formatDocType = (type, value) => {
  if (!type || !value) {
    return
  }
  if (type === asRg) {
    return formatRG(value)
  } else if (type === asCpf) {
    return formatCPF(value)
  }
  return removeNonNumericCharacters(value)
}

export const formatAddress = (address) => {
  if (!address || typeof address !== "string") return "-"

  const formattedAddress = address
    .toLowerCase()
    .split(",")
    .map((part) => part.trim())
    .map((part) => {
      if (part.includes("-")) {
        const [beforeDash, afterDash] = part.split("-")
        const formattedPart = `${beforeDash.charAt(0).toUpperCase() + beforeDash.slice(1)} - ${afterDash.toUpperCase()}`
        return formattedPart
      } else {
        return part.charAt(0).toUpperCase() + part.slice(1)
      }
    })
    .join(", ")

  return formattedAddress
}

// checkbox padrão que verifica e remove se tiver ids repetidos
// requer 3 parâmetros
// ex: handleCheckbox(id, selected, setSelected)}
// export const handleCheckbox = (id, state, setter) => {
//   const functionIndex = state.findIndex((typeId) => typeId === id);

//   if (functionIndex === -1) {
//     setter((prevSelected) => [...prevSelected, id]);
//   } else {
//     setter((prevSelected) => {
//       const newSelected = [...prevSelected];
//       newSelected.splice(functionIndex, 1);
//       return newSelected;
//     });
//   }
// };

// limpa os inputs e excluir o local storage
// para notificar requer parâmetro de "notify"
// para não notificar qualquer coisa diferente de notify ex: "sadfasdf"
// ex:  clearFormulary("noNotify", setData, showDialog(false));
// export const clearFormulary = (notificationMode = 'notify', setter, dialog) => {
//   setter([]);
//   dialog();
//   localStorage.removeItem('registerProject');
//   if (notificationMode === 'notify') {
//     toast.success('Formulário limpo', {
//       position: toast.POSITION.TOP_RIGHT,
//       autoClose: 1000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//     });
//   }
// };

// remove tudo que não é número
export const removeNonNumericCharacters = (index) => {
  if (index === null || index === undefined) {
    return index // retorna o valor original
  }
  const strIndex = String(index)
  const sanitized = strIndex.replace(/\D/g, "")

  return sanitized === strIndex ? index : sanitized // retorna o valor original se ele não foi alterado
}

export const formatExceptDe = (nome) => {
  if (!nome) {
    return ""
  }
  return nome
    .toLowerCase()
    .split(" ")
    .map((palavra) => (palavra === "de" ? palavra : palavra.charAt(0).toUpperCase() + palavra.slice(1)))
    .join(" ")
}

export const handleFind = ({ index, stateGlobal, storage, monitorStateGlobal }) => {
  const obj = stateGlobal?.data?.find((obj) => obj.id === index)
  if (obj) {
    localStorage.setItem(storage, JSON.stringify(obj))
    monitorStateGlobal()
  } else {
    console.log("Not found")
  }
}

// Função para formatar RG
export const formatRG = (value) => {
  const rg = value?.replace(/\D/g, "")

  if (rg.length === 0) {
    return ""
  } else if (rg.length <= 2) {
    return rg
  } else if (rg.length <= 5) {
    return `${rg.slice(0, 2)}.${rg.slice(2)}`
  } else if (rg.length <= 8) {
    return `${rg.slice(0, 2)}.${rg.slice(2, 5)}.${rg.slice(5)}`
  } else if (rg.length <= 11) {
    return `${rg.slice(0, 2)}.${rg.slice(2, 5)}.${rg.slice(5, 8)}-${rg.slice(8)}`
  } else {
    return `${rg.slice(0, 2)}.${rg.slice(2, 5)}.${rg.slice(5, 8)}.${rg.slice(8, 11)}-${rg.slice(11)}`
  }
}

// Função para formatar CPF
export const formatCPF = (value) => {
  const cpf = value?.replace(/\D/g, "")
  if (cpf?.length === 0) {
    return ""
  } else if (cpf?.length <= 3) {
    return cpf
  } else if (cpf?.length <= 6) {
    return `${cpf?.slice(0, 3)}.${cpf?.slice(3)}`
  } else if (cpf?.length <= 9) {
    return `${cpf?.slice(0, 3)}.${cpf?.slice(3, 6)}.${cpf?.slice(6)}`
  } else {
    return `${cpf?.slice(0, 3)}.${cpf?.slice(3, 6)}.${cpf?.slice(6, 9)}-${cpf?.slice(9)}`
  }
}

// Função para formatar CNPJ
export const formatCNPJ = (value) => {
  const cnpj = value?.replace(/\D/g, "")
  if (cnpj?.length === 0) {
    return ""
  } else if (cnpj?.length <= 2) {
    return cnpj
  } else if (cnpj?.length <= 5) {
    return `${cnpj.slice(0, 2)}.${cnpj?.slice(2)}`
  } else if (cnpj?.length <= 8) {
    return `${cnpj?.slice(0, 2)}.${cnpj?.slice(2, 5)}.${cnpj?.slice(5)}`
  } else if (cnpj?.length <= 12) {
    return `${cnpj?.slice(0, 2)}.${cnpj?.slice(2, 5)}.${cnpj?.slice(5, 8)}/${cnpj?.slice(8)}`
  } else {
    return `${cnpj?.slice(0, 2)}.${cnpj?.slice(2, 5)}.${cnpj?.slice(5, 8)}/${cnpj?.slice(8, 12)}-${cnpj?.slice(12, 14)}`
  }
}

export const formatCPForCNPJ = (value) => {
  const identification = value?.replace(/\D/g, "")
  if (!identification) return ""

  // Formata como CPF se tiver 11 dígitos
  if (identification.length === 11) {
    return `${identification.slice(0, 3)}.${identification.slice(3, 6)}.${identification.slice(
      6,
      9,
    )}-${identification.slice(9, 11)}`
  }

  // Formata como CNPJ se tiver 14 dígitos
  if (identification.length === 14) {
    return `${identification.slice(0, 2)}.${identification.slice(2, 5)}.${identification.slice(
      5,
      8,
    )}/${identification.slice(8, 12)}-${identification.slice(12, 14)}`
  }

  return identification // Retornar o valor original se não for CPF nem CNPJ
}

export const MapList = ({ state, value, label }) => {
  if (!Array.isArray(state)) {
    return []
  }
  const map =
    state?.map((index) => ({
      value: index[value],
      label: formatCapitalize(index[label]), // Capitaliza o rótulo da opção
    })) || []
  return map
}

export const MapListId = ({ state, value, label }) => {
  if (!Array.isArray(state)) {
    return
  }

  const map =
    state?.map((index) => ({
      value: index[value],
      label: index[label],
    })) || []

  // Ordenar o array pelo ID, colocando o mais novo por último
  map.sort((a, b) => a.value - b.value)

  return map
}

export const currentDate = () => {
  const today = new Date()
  const year = today.getFullYear()
  let month = today.getMonth() + 1
  let day = today.getDate()

  if (month < 10) {
    month = "0" + month
  }

  if (day < 10) {
    day = "0" + day
  }

  return `${year}-${month}-${day}`
}

export const formatName = (name) => {
  if (!name) return null

  // Separa as partes do nome usando o caractere '_'
  const parts = name.split("_")

  // Transforma a primeira letra de cada parte em maiúscula e o restante em minúscula
  const formattedParts = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())

  // Junta as partes formatadas em um único nome
  const formattedName = formattedParts.join(" ")

  return formattedName
}

export const capitalizeFirstString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatZipCode = (value) => {
  const zipCode = value?.replace(/\D/g, "")

  if (zipCode?.length === 0) {
    return ""
  } else if (zipCode?.length <= 2) {
    return zipCode
  } else if (zipCode?.length <= 5) {
    return `${zipCode?.slice(0, 2)}.${zipCode?.slice(2)}`
  } else {
    return `${zipCode?.slice(0, 2)}.${zipCode?.slice(2, 5)}-${zipCode?.slice(5, 8)}`
  }
}

export const extractDigits = (value) => (value ? value.replace(/\D/g, "") : "")

export const validateField = (condition, field) => {
  if (condition) {
    return { [field]: true }
  }
  return {}
}

export const ClearState = (setParam1, setParam2) => {
  if (setParam1 && typeof setParam1 === "function") {
    setParam1({})
  }

  if (setParam2 && typeof setParam2 === "function") {
    setParam2({})
  }
}

export const settingState = (setParam, value) => {
  if (!setParam || value === undefined || value === null) {
    return
  }
  setParam(value)
}

export const changeValue = (param, setParam) => {
  if (typeof setParam !== "function") {
    console.error("setParam deve ser uma função")
    return
  }

  if (typeof param !== "boolean") {
    console.error("param deve ser um valor booleano")
    return
  }

  setParam(!param)
}

export const generateId = () => {
  const now = Date.now().toString(36)
  const random = Math.random().toString(36).substr(2)
  return `${now}-${random}`
}

export const extractFileNameAndFormat = (filePath) => {
  if (!filePath) {
    return
  }
  const parts = filePath.split("/")
  const fileNameWithFormat = parts[parts.length - 1]
  return fileNameWithFormat // Retorna o nome completo do arquivo sem tentar separar
}

export const validateForm = ({
  specificValidations,
  dataForm,
  setFieldErrors,
  setRegexMessage,
  setConditionMessage,
}) => {
  if (!specificValidations || !dataForm || !setFieldErrors || !setRegexMessage || !setConditionMessage) {
    return // ou lançar um erro, dependendo de sua preferência
  }
  let newFieldErrors = {}
  let newRegexMessage = {}
  let newConditionMessage = {}
  let isValid = true

  specificValidations.forEach((validation) => {
    const value = dataForm[validation.field]
    const conditionFailed = validation.check && validation.check(dataForm)

    // Verifica se o valor não é nulo, indefinido ou vazio antes de aplicar o regex
    const regexFailed = validation.regex && value && value !== "" && !validation.regex.test(value)

    if (conditionFailed) {
      newFieldErrors[validation.field] = true
      newConditionMessage[validation.field] = validation.conditionMessage
      isValid = false
    }

    if (regexFailed) {
      newFieldErrors[validation.field] = true
      newRegexMessage[validation.field] = validation.regexMessage
      isValid = false
    }
  })
  setFieldErrors(newFieldErrors)
  setRegexMessage(newRegexMessage)
  setConditionMessage(newConditionMessage)

  return isValid
}

// export const validateForm = ({
//   specificValidations,
//   dataForm,
//   setFieldErrors,
//   setConditionMessage
// }) => {
//   if (!specificValidations || !dataForm || !setFieldErrors || !setConditionMessage) {
//     return; // ou lançar um erro, dependendo de sua preferência
//   }

//   let newFieldErrors = {};
//   let newConditionMessage = {};
//   let isValid = true;

//   specificValidations.forEach((validation) => {
//     const value = dataForm[validation.field];
//     const conditionFailed = validation.check && validation.check(dataForm);

//     // Verifica se o valor é vazio ou irregular
//     const fieldInvalid = !value || conditionFailed;

//     if (fieldInvalid) {
//       newFieldErrors[validation.field] = true;
//       newConditionMessage[validation.field] = 'Verifique se o campo está vazio ou irregular';
//       isValid = false;
//     }
//   });

//   setFieldErrors(newFieldErrors);
//   setConditionMessage(newConditionMessage);

//   return isValid;
// };

export const handleErrors = ({ error, setFieldErrors, errorMessages, errorToast }) => {
  if (!error || !error || !setFieldErrors || !setFieldErrors) {
    console.error("Erro ou função setFieldErrors inválida fornecida para handleErrors.")
    return
  }

  if (!errorMessages || !errorMessages || !errorToast || !errorToast) {
    console.error("Falta parâmetro em handleErrors")
    return
  }

  // Verifica se não existiu resposta
  if (!error.response || !error.response.data) {
    errorToast("Erro interno: sem conexão")
    return
  }

  // Verifica se a resposta é um erro CORS
  if (typeof error.response.data === "string" && error.response.data.includes("CORS")) {
    errorToast("Erro interno: código 500")
    return
  }

  // Tratando o caso em que o erro está sob uma chave 'detail'.
  const detailError = error.response.data.detail
  if (detailError) {
    // const foundKey = Object.keys(errorMessages).find((messageKey) => error.response.data.detail.includes(messageKey));

    // if (foundKey) {
    //   const response = errorMessages[foundKey];
    //   const fieldsToUpdate = Array.isArray(response.fields)
    //     ? response.fields.reduce((acc, field) => ({ ...acc, [field]: true }), {})
    //     : { [response.fields]: true };
    const foundKey = Object.keys(errorMessages).find((messageKey) => detailError.includes(messageKey))
    if (foundKey) {
      const response = errorMessages[foundKey]
      const fieldsToUpdate = Array.isArray(response.field)
        ? response.field.reduce((acc, field) => ({ ...acc, [field]: true }), {})
        : { [response.field]: true }

      setFieldErrors((prevState) => ({
        ...prevState,
        ...fieldsToUpdate,
      }))

      errorToast(response.message)
      return
    } else {
      errorToast(detailError) // Exibir a mensagem de erro diretamente se não houver correspondência.
      return
    }
  }

  // Processamento padrão para outros tipos de erros.
  let hasHandledError = false
  Object.keys(error.response.data).forEach((key) => {
    const errorMessage = Array.isArray(error.response.data[key])
      ? error.response.data[key][0]
      : error.response.data[key]
    const foundKey = Object.keys(errorMessages).find((messageKey) => {
      return errorMessage.message.includes(messageKey)
    })

    if (foundKey) {
      hasHandledError = true
      const response = errorMessages[foundKey]
      const fieldsToUpdate = Array.isArray(response.field)
        ? response.field.reduce((acc, field) => ({ ...acc, [field]: true }), {})
        : { [response.field]: true }

      setFieldErrors((prevState) => ({
        ...prevState,
        ...fieldsToUpdate,
      }))
      errorToast(response.message)
    }
  })

  // Se nenhum dos erros foi manuseado, exibir a mensagem de erro do backend
  if (!hasHandledError) {
    const errorData = error.response.data
    let formattedErrorMessage = ""
    let fieldsToUpdate = {}

    Object.keys(errorData).forEach((key, index, array) => {
      formattedErrorMessage += `${key}: ${
        Array.isArray(errorData[key]) ? errorData[key].join(", ") : errorData[key].message
      }`
      fieldsToUpdate[key] = true // Marcar o campo correspondente como erro

      if (index < array.length - 1) {
        formattedErrorMessage += "; "
      }
    })

    setFieldErrors((prevState) => ({
      ...prevState,
      ...fieldsToUpdate,
    }))

    errorToast(formattedErrorMessage)
  }
}

export const validateMultForm = ({
  specificValidations,
  multDataForm,
  setMultFieldErrors,
  setMultRegexMessage,
  setMultConditionMessage,
}) => {
  if (
    !specificValidations ||
    !multDataForm ||
    !setMultFieldErrors ||
    !setMultRegexMessage ||
    !setMultConditionMessage
  ) {
    console.error("Todos os parâmetros necessários devem ser fornecidos para validateForm")
    return false // ou lançar um erro, dependendo de sua preferência
  }

  let allFormsAreValid = true // Presume que todos os formulários são válidos inicialmente

  let allFieldErrors = {}
  let allRegexMessages = {}
  let allConditionMessages = {}

  multDataForm?.forEach((representativeValue) => {
    let newFieldErrors = {}
    let newRegexMessage = {}
    let newConditionMessage = {}
    let isValid = true

    specificValidations.forEach((validation) => {
      const value = representativeValue[validation.field]
      const conditionFailed = validation.check ? validation.check(representativeValue) : false
      // Adicionando verificação extra para valores nulos, indefinidos ou vazios
      const regexFailed = validation.regex && value && value !== "" && !validation.regex.test(value)

      if (conditionFailed || regexFailed) {
        newFieldErrors[validation.field] = true
        newConditionMessage[validation.field] = conditionFailed ? validation.conditionMessage : ""
        newRegexMessage[validation.field] = regexFailed ? validation.regexMessage : ""
        isValid = false
      }
    })

    if (!isValid) {
      allFormsAreValid = false // Marca que pelo menos um formulário é inválido
    }

    const formId = representativeValue.id
    allFieldErrors[formId] = newFieldErrors
    allRegexMessages[formId] = newRegexMessage
    allConditionMessages[formId] = newConditionMessage
  })

  // Atualizar os estados com os objetos acumulativos
  setMultFieldErrors(allFieldErrors)
  setMultRegexMessage(allRegexMessages)
  setMultConditionMessage(allConditionMessages)

  return allFormsAreValid // Retorna true se todos os formulários são válidos, false caso contrário
}

// export const validateMultForm = ({
//   specificValidations,
//   multDataForm,
//   setMultFieldErrors,
// }) => {
//   if (
//     !specificValidations ||
//     !multDataForm ||
//     !setMultFieldErrors
//   ) {
//     console.error('Todos os parâmetros necessários devem ser fornecidos para validateForm');
//     return false; // ou lançar um erro, dependendo de sua preferência
//   }

//   let allFormsAreValid = true; // Presume que todos os formulários são válidos inicialmente

//   let allFieldErrors = {};

//   multDataForm?.forEach((representativeValue) => {
//     let newFieldErrors = {};
//     let isValid = true;

//     specificValidations.forEach((validation) => {
//       const value = representativeValue[validation.field];
//       const conditionFailed = validation.check ? validation.check(representativeValue) : false;
//       // Adicionando verificação extra para valores nulos, indefinidos ou vazios
//       const regexFailed = validation.regex && value && value !== '' && !validation.regex.test(value);

//       if (conditionFailed || regexFailed) {
//         newFieldErrors[validation.field] = true;
//         isValid = false;
//       }
//     });

//     if (!isValid) {
//       allFormsAreValid = false; // Marca que pelo menos um formulário é inválido
//     }

//     const formId = representativeValue.id;
//     allFieldErrors[formId] = newFieldErrors;
//   });

//   // Atualizar o estado com o objeto acumulativo de erros de campo
//   setMultFieldErrors(allFieldErrors);

//   return allFormsAreValid; // Retorna true se todos os formulários são válidos, false caso contrário
// };

export const handleMultErrors = ({ error, item, newFieldErrors, errorHandlers }) => {
  if (error.response && error.response.data) {
    const errors = error.response.data
    const formId = item.id
    newFieldErrors[formId] = newFieldErrors[formId] || {}

    Object.keys(errors).forEach((errorKey) => {
      if (errorHandlers[errorKey]) {
        errorHandlers[errorKey](errors, formId, newFieldErrors)
      }
    })
  }
}

export const phrase = () => {
  const currentHour = new Date().getHours()
  if (currentHour >= 5 && currentHour < 12) {
    return "Bom dia"
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Boa tarde"
  } else {
    return "Boa noite"
  }
}

export const formatMoney = (value) => {
  // Converte para formato numérico
  const number = parseFloat(value)

  // Verifica se é um número válido
  if (isNaN(number)) {
    return "Valor inválido"
  }

  if (number === 0) {
    return "-"
  }

  // Formata como moeda brasileira
  const formatMoney = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  })

  return formatMoney.format(number)
}

export const formatAsPercentage = (value) => {
  const numberValue = parseFloat(value)

  if (isNaN(numberValue)) {
    return "Invalid value"
  }

  const formattedValue = (numberValue * 1).toFixed(2).replace(/\.00$/, "")

  return formattedValue + "%"
}

export const formatAsInstallments = (value) => {
  const numberValue = parseFloat(value)

  if (isNaN(numberValue)) {
    return "Invalid value"
  }

  if (numberValue === 0) {
    return "-"
  }
  const formattedValue = (numberValue * 1).toFixed(2).replace(/\.00$/, "")

  return formattedValue
}

export const removeZero = (number) => {
  // Convertendo para float para remover os zeros à direita
  const floatValue = parseFloat(number)

  // Convertendo de volta para string
  const stringValue = floatValue.toString()

  return stringValue
}
