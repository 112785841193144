import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../../../global/GlobalContext';
import useVerifyFields from '../../../../hooks/useVerifyFields';

const RegisterClient = (props) => {
  const {
    states: {
      globalState: { typePeople },
    },
    setters: { setGlobalState },
  } = useContext(GlobalContext);
  const { RegisterFields } = useVerifyFields(props);

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      formData: {
        verifyType: typePeople,
        noEdit: false,
        inputDisable: false,
      },
      // passDelRepresentative: false,
      // sentCompany: false,
    }));
  }, []);

  return <>{RegisterFields}</>;
};
export default RegisterClient;
