import Router from "./routes/Router"
import React from "react"
import { GlobalState } from "./global/GlobalState"
import styled from "styled-components"
import { ToastContainer } from "react-toastify"

const StyledAppContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`

export const App = () => {
  return (
    <StyledAppContainer>
      <ToastContainer />
      <GlobalState>
        <Router />
      </GlobalState>
    </StyledAppContainer>
  )
}

export default App
