import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asCadastrar,
  asCustomerCompany,
  asCustomerPhysical,
  asDefaultErrorAxios,
  asDefaultRegister,
  asSend,
  asSending,
  asSubmit,
} from '../../../constants/constants/constAndRegex';
import { DATA_LETTEROFATTORNEY } from '../../../constants/constants/dataToSend';
import { requiredLetterOfAttorney } from '../../../constants/constants/requiredConst';
import { changeValue, handleErrors, settingState, validateForm } from '../../../constants/functions/functions';
import { URL_LETTER_OF_ATTORNEY } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import useForm from '../../../hooks/useForm';
import ClearDialog from '../../common/Dialog/ClearDialog/ClearDialog';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import InputsLetterOfAttorney from '../../common/Formulary/InputsLetterOfAttorney/InputsLetterOfAttorney';
import { Button, ButtonRed } from '../../common/Reuse/Button/Buttons/Buttons';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../common/StyledCommonModals/StyledCommonModals';
import * as L from './styled';
import { errorLetterOfAttorney } from '../../../constants/constants/constError';

const RegisterLetterOfAttorney = (props) => {
  const {
    states: {
      globalState: { formData },
    },
    setters: { setGlobalState },
    functions: { headerGlobalString, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext);
  const [clear, setClear] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, setRegister } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    clear,
    setClear,
    setFieldErrors,
  };

  const handlePost = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const specificValidations = requiredLetterOfAttorney;

    const resultValidateForm = validateForm({
      specificValidations,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    const dataToSend = DATA_LETTEROFATTORNEY(dataForm);
    let combinedData = {
      ...dataToSend,
    };

    if (dataForm.verifySelect === asCustomerPhysical) {
      combinedData.customer_person_physical = dataForm.customer_person_physical;
      combinedData.customer_person_company = null;
    } else if (dataForm.verifySelect === asCustomerCompany) {
      combinedData.customer_person_company = dataForm.customer_person_company;
      combinedData.customer_person_physical = null;
    }

    try {
      await axios.post(URL_LETTER_OF_ATTORNEY, combinedData, { headers: headerGlobalString() });
      sayAlteration();
      successToast(asDefaultRegister);
      settingState(setRegister, !register);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorLetterOfAttorney,
        errorToast,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      formData: {
        verifySelect: asCustomerPhysical,
        noEdit: false,
        inputDisable: false,
      },
    }));
  }, []);

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setRegister, !register)}>
        <S.DivGap>
          <TitleModal text={asCadastrar} />
          <InputsLetterOfAttorney {...propsList} />
        </S.DivGap>
        <L.DivGap>
          <ButtonRed
            onClick={formData.inputDisable !== true ? () => settingState(setClear, !clear) : undefined}
            named={'limpar'}
          />
          <Button
            type={formData.inputDisable !== true ? asSubmit : undefined}
            named={isSubmitting ? asSending : asSend}
            onClick={(event) => {
              if (!isSubmitting) {
                handlePost(event);
              }
            }}
            disabled={isSubmitting}
          />
        </L.DivGap>
      </Formulary>
      <ClearDialog {...propsList} />
    </S.Main>
  );
};
export default RegisterLetterOfAttorney;
