export const pickList = {
  TYPE_ASS: [
    {
      value: "ass_pessoa_fisica",
      label: "Pessoa Física",
    },
    {
      value: "ass_pessoa_juridica",
      label: "Pessoa Jurídica",
    },
  ],

  TYPE_CIVIL: [
    // asAlone
    {
      value: "solteiro(a)",
      label: "Solteiro(a)",
    },
    // asMarried
    {
      value: "casado(a)",
      label: "Casado(a)",
    },
    {
      value: "divorciado(a)",
      label: "Divorciado(a)",
    },
    {
      value: "separado(a)",
      label: "Separado(a)",
    },
    {
      value: "uniao_estavel",
      label: "União Estável",
    },
    {
      value: "outros",
      label: "Outros",
    },
  ],

  TYPE_DIVISIONS_INVOLVED: [
    {
      value: "reparticoes_publicas_municipal",
      label: "Repartições públicas Municipal",
    },
    {
      value: "reparticoes_publicas_municipal_e_estadual",
      label: "Repartições públicas Municipal e Estadual",
    },
    {
      value: "reparticoes_publicas_municipal_estadual_e_federal",
      label: "Repartições públicas Municipal, Estadual e Federal",
    },
    {
      value: "registrador_de_imoveis_e_notas",
      label: "Registrador de Imóveis e Notas",
    },
    {
      value: "outros",
      label: "Outros",
    },
  ],

  TYPE_DOCUMENT: [
    {
      value: "rg",
      label: "RG",
    },
    {
      value: "cpf",
      label: "CPF",
    },
    {
      value: "cnh",
      label: "CNH",
    },
  ],

  TYPE_DOCUMENT_TIPOLOGY: [
    {
      value: "procuracao_particular",
      label: "Procuração Particular",
    },
    {
      value: "subestabelecimento_de_procuracao",
      label: "Subestabelecimento de Procuração",
    },
  ],

  TYPE_GENERAL: [
    {
      value: "m",
      label: "Masculino",
    },
    {
      value: "f",
      label: "Feminino",
    },
  ],

  TYPE_GRANTED: [
    {
      value: "bruno_ender",
      label: "BRUNO ENDER",
    },
    {
      value: "denise_oliveira",
      label: "DENISE OLIVEIRA",
    },
    {
      value: "bruno_ender_&_denise_oliveira",
      label: "BRUNO ENDER E DENISE OLIVEIRA",
    },
  ],

  TYPE_NATIONALITY: [
    {
      value: "BR",
      label: "Brasileiro(a)",
    },
    {
      value: "NE",
      label: "Estrangeiro(a)",
    },
  ],

  TYPE_NATURALITY: [
    {
      value: "AC",
      label: "Acre",
    },
    {
      value: "AL",
      label: "Alagoas",
    },
    {
      value: "AP",
      label: "Amapá",
    },
    {
      value: "AM",
      label: "Amazonas",
    },
    {
      value: "BA",
      label: "Bahia",
    },
    {
      value: "CE",
      label: "Ceará",
    },
    {
      value: "DF",
      label: "Distrito Federal",
    },
    {
      value: "ES",
      label: "Espírito Santo",
    },
    {
      value: "GO",
      label: "Goiás",
    },
    {
      value: "MA",
      label: "Maranhão",
    },
    {
      value: "MT",
      label: "Mato Grosso",
    },
    {
      value: "MS",
      label: "Mato Grosso do Sul",
    },
    {
      value: "MG",
      label: "Minas Gerais",
    },
    {
      value: "PA",
      label: "Pará",
    },
    {
      value: "PB",
      label: "Paraíba",
    },
    {
      value: "PR",
      label: "Paraná",
    },
    {
      value: "PE",
      label: "Pernambuco",
    },
    {
      value: "PI",
      label: "Piauí",
    },
    {
      value: "RJ",
      label: "Rio de Janeiro",
    },
    {
      value: "RN",
      label: "Rio Grande do Norte",
    },
    {
      value: "RS",
      label: "Rio Grande do Sul",
    },
    {
      value: "RO",
      label: "Rondônia",
    },
    {
      value: "RR",
      label: "Roraima",
    },
    {
      value: "SC",
      label: "Santa Catarina",
    },
    {
      value: "SP",
      label: "São Paulo",
    },
    {
      value: "SE",
      label: "Sergipe",
    },
    {
      value: "TO",
      label: "Tocantins",
    },
  ],

  TYPE_POWER: [
    {
      value: "exclusivos",
      label: "Exclusivos",
    },
    {
      value: "limitados",
      label: "Limitados",
    },
    {
      value: "ilimitados",
      label: "Ilimitados",
    },
    {
      value: "outros",
      label: "Outros",
    },
  ],

  TYPE_QUALIFICATION: [
    {
      value: "socio_administrador",
      label: "Sócio Administrador",
    },
    {
      value: "administrador",
      label: "Administrador",
    },
    {
      value: "socio",
      label: "Sócio",
    },
    {
      value: "procurador",
      label: "Procurador",
    },
    {
      value: "outros",
      label: "Outros",
    },
  ],

  TYPE_REGIMENT: [
    {
      value: "comunhao_universal_de_bens",
      label: "Comunhão Universal de Bens",
    },
    {
      value: "comunhao_parcial_de_bens",
      label: "Comunhão Parcial de Bens",
    },
    {
      value: "separacao_total_de_bens",
      label: "Separação Total de Bens",
    },
    {
      value: "separacao_obrigatoria_de_bens",
      label: "Separação Obrigatória de Bens",
    },
    {
      value: "outros",
      label: "Outros",
    },
  ],

  TYPE_STEP: [
    {
      value: "inicio",
      label: "Início",
    },
    {
      value: "meio",
      label: "Meio",
    },
    {
      value: "fim",
      label: "Fim",
    },
  ],

  TYPE_CUSTOMER: [
    { value: "customer_person_physical", label: "Pessoa Física" },
    { value: "customer_person_company", label: "Pessoa Jurídica" },
  ],

  TYPE_TRUE_OR_FALSE: [
    { value: "sim", label: "Sim" },
    { value: "não", label: "Não" },
  ],

  TYPE_QUALITY_POWERS:[
    { value: "individualmente", label: "Individualmente" },
    { value: "em_conjunto", label: "Em conjunto" },
  ],
  TYPE_GRANTS:[
    { value: "requerer", label: "Requerer" },
    { value: "retirar", label: "Retirar" },
    { value: "assinar", label: "Assinar" },
    { value: "representar", label: "Representar" },
    { value: "apresentar_documentos", label: "Apresentar Documentos" },
    { value: "alegar_e_prestar_declaracoes_e_informacoes", label: "Alegar e prestar declarações e informações" },
    { value: "outros", label: "Outros" },
  ],
  TYPE_COMPLET_OR_NO :[
    { value: "completa", label: "Completa" },
    { value: "pendente", label: "Pendente" },
  ],
  TYPE_PAYMENTMETHOD :[
    { value: "a_vista", label: "À Vista" },
    { value: "parcelado", label: "Parcelado" },
  ],
  TYPE_PROGRESS: [
    {
      value: "nao_aprovada",
      label: "Não Aprovada",
    },
    {
      value: "aprovada",
      label: "Aprovada",
    },
  ],
};
