import styled from "styled-components";
export const Custon10 = styled.div`
  display: flex;
  min-width: 10rem;
`;

export const LoadingOverlay = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;
export const StyledForm = styled.div`
padding: 0;
margin: 0;
border: 0;
`;

export const Box = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;
export const BoxFile = styled.div`
  display: flex;
  gap: 0.2rem;
  width: 100%;
`;

export const BoxCont = styled.div`
  display: flex;
align-items: center;
`;

export const Custon15rem = styled.div`
  display: flex;
  width: 15rem;
`;

export const Custon8rem = styled.div`
  display: flex;
  width: 8rem;
`;

export const H2 = styled.h2`
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  color: #006978;
`;

export const DivGap = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-evenly;
`;
