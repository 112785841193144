// token
export const asAccessToken = 'a_tk';
export const asRefreshToken = 'r_tk';

// estático
export const asPeople = 'ass_pessoa_fisica';
export const asCompany = 'ass_pessoa_juridica';
export const asOther = 'outros';
export const asCustomerPhysical = 'customer_person_physical';
export const asCustomerCompany = 'customer_person_company';
export const asStableUnion = 'uniao_estavel';
export const asMarried = 'casado(a)';
export const asAlone = 'solteiro(a)';
export const asSocialPhysica = 'fisica';
export const asSocialCompany = 'jurídica';
export const asCPF = 'CPF';
export const asCNPJ = 'CNPJ';
export const asCnh = 'cnh';
export const asCpf = 'cpf';
export const asRg = 'rg';
export const asBR = 'BR';
export const asCep = 'cep';
export const asClean = 'limpar';
export const asSend = 'enviar';
export const asSending = 'enviando...';
export const asSubmit = 'submit';
export const asConfirm = 'confirmar';
export const asAccept = 'aceitar';
export const asAccepting = 'aceitando...';
export const asBack = 'voltar';
export const asBrazilian = 'brasileiro(a)';
export const asForeigner = 'estrangeiro';
export const asBruno = 'bruno_ender';
export const asDenise = 'denise_oliveira';
export const asBrunoAndDenise = 'bruno_ender_&_denise_oliveira';
export const asYes = 'sim';
export const asNo = 'não';

// número
export const ITEMS_PER_PAGE = 10;
export const as2 = 2;
export const as8 = 8;
export const as9 = 9;
export const as10 = 10;
export const as11 = 11;
export const as14 = 14;
export const as15 = 15;
export const as16 = 16;
export const as20 = 20;
export const as25 = 25;
export const as30 = 30;
export const as50 = 50;
export const as100 = 100;
export const as255 = 255;

// regex
export const asRegexPassword = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z\d]).{8,}$/;
export const asRegexEmail =
  /^([a-z]){1,}([a-z0-9._-]){1,}([@]){1}([a-z]){2,}([.]){1}([a-z]){2,}([.]?){1}([a-z]?){2,}$/i;
export const asRegexLetter = /^[a-zA-Z\s]+$/;
export const asRegexNumber = /^[0-9]+$/;

// texto
export const asDescriptionRegexPassword =
  'Deve conter ao menos um número, uma letra minúscula, uma letra maiúscula, um caractere especial, um tamanho mínimo de 8 caracteres. Ex: 1aA#abcd';
export const asDescriptionRegexEmail = 'Use formato de e-mail válido';
export const asDescriptionRegexLetter = 'Use apenas letras';
export const asDescriptionRegexNumber = 'Use apenas números';
export const asDescriptionRegexSelectOneOption = 'Selecione uma opção';
export const asDescriptionGeneral = 'Verifique se o campo está vazio ou irregular';
export const asDescriptionPhone = 'Verifique se a data está vazia ou irregular';
export const asDescriptionRegexRequired = 'Este campo é obrigatório';
export const asDefaultErrorAxios = 'Verifique os campos destacados';
export const asDefaultAlteration = 'Alteração Realizada';
export const asDefaultRegister = 'Cadastro Realizado';
export const asNameChanged = 'Nome Alterado';
export const asPasswordChanged = 'Senha Alterada';
export const asUnknowError = 'Erro Desconhecido';
export const asReverse = 'reverse';
export const asWelcome = 'Welcome';
export const asZipCodeError = 'Erro ao buscar CEP';
export const asErrorInfo = 'Erro ao carregar informações';
export const asComponentRendered = 'componentRendered';
export const asCadastrar = 'cadastrar';
export const asEditar = 'editar';
export const asDetalhes = 'detalhes';
export const asRecoveryText = 'Um e-mail com as instruções de redefinição de senha foi enviado';
export const asRegisterModal = 'registerModal';
export const asEditModal = 'editModal';
export const asDetailModal = 'detailModal';

// checagem recorrente
export const check_marital_status = (dataForm) =>
  dataForm.marital_status === asMarried ||
  dataForm.marital_status === asStableUnion;

// navegação
export const naveRecovery = '/recovery';
export const naveEbs = '/ebs';
export const naveLogin = '/login';

//
export const stateListTeste = [
  { sigla: 'RO', nome: 'Rondônia' },
  { sigla: 'AC', nome: 'Acre' },
  { sigla: 'AM', nome: 'Amazonas' },
  { sigla: 'RR', nome: 'Roraima' },
  { sigla: 'PA', nome: 'Pará' },
  { sigla: 'AP', nome: 'Amapá' },
  { sigla: 'TO', nome: 'Tocantins' },
  { sigla: 'MA', nome: 'Maranhão' },
  { sigla: 'PI', nome: 'Piauí' },
  { sigla: 'CE', nome: 'Ceará' },
  { sigla: 'RN', nome: 'Rio Grande do Norte' },
  { sigla: 'PB', nome: 'Paraíba' },
  { sigla: 'PE', nome: 'Pernambuco' },
  { sigla: 'AL', nome: 'Alagoas' },
  { sigla: 'SE', nome: 'Sergipe' },
  { sigla: 'BA', nome: 'Bahia' },
  { sigla: 'MG', nome: 'Minas Gerais' },
  { sigla: 'ES', nome: 'Espírito Santo' },
  { sigla: 'RJ', nome: 'Rio de Janeiro' },
  { sigla: 'SP', nome: 'São Paulo' },
  { sigla: 'PR', nome: 'Paraná' },
  { sigla: 'SC', nome: 'Santa Catarina' },
  { sigla: 'RS', nome: 'Rio Grande do Sul' },
  { sigla: 'MS', nome: 'Mato Grosso do Sul' },
  { sigla: 'MT', nome: 'Mato Grosso' },
  { sigla: 'GO', nome: 'Goiás' },
  { sigla: 'DF', nome: 'Distrito Federal' }
];


