import * as S from "../styledButtons/styledButtons";
import * as M from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

export const ButtonIcon = ({ named, icon, type, onClick }) => {
  return (
    <>
      <S.Back type={type} onClick={onClick}>
        {named}
        {icon}
      </S.Back>
    </>
  );
};

export const ButtonIconRed = ({ named, icon, type, onClick }) => {
  return (
    <>
      <S.BackRed type={type} onClick={onClick}>
        {named}
        {icon}
      </S.BackRed>
    </>
  );
};

export const Button = ({ named, icon, type, onClick, disabled }) => {
  return (
    <>
      <S.Green type={type} onClick={onClick} disabled={disabled}>
        {named}
        {icon}
      </S.Green>
    </>
  );
};


export const ButtonRed = ({ onClick, named, icon, type }) => {
  return (
    <>
      <S.Red type={type} onClick={onClick}>
        {named}
        {icon}
      </S.Red>
    </>
  );
};

export const ButtonLarge = ({ onClick, type, named, icon }) => {
  return (
    <>
      <S.LargeGreen type={type} onClick={onClick}>
        {named}
        {icon}
      </S.LargeGreen>
    </>
  );
};

export const ButtonIconSmall = ({ onClick, icon }) => {
  return (
    <>
      <S.IconGreen
        type="submit"
        variant="contained"
        color="secondary"
        onClick={onClick}
      >
        <M.IconButton color="inherit">{icon}</M.IconButton>
      </S.IconGreen>
    </>
  );
};

export const ButtonIconSmallRed = ({ onClick, icon }) => {
  return (
    <>
      <S.IconRed
        type="submit"
        variant="contained"
        color="secondary"
        onClick={onClick}
      >
        <M.IconButton color="inherit">{icon}</M.IconButton>
      </S.IconRed>
    </>
  );
};
