import styled from "styled-components";
import * as M from "@mui/material";

export const Main = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
padding-top: 10rem;

`;


export const DialogContentText = styled(M.DialogContentText)`
  display: flex;
  justify-content: center;
`;

export const Button = styled(M.Button)`
  && {
    border: none ;
    padding: 1rem;
    cursor: pointer;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;

export const ButtonRed = styled(M.Button)`
  && {
    background: none;
    border: none;
    cursor: pointer;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;


export const ContainerForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
width: 25rem;
  gap: 1rem;
`;

export const DivBackContainer = styled.div`
  position: relative;
`;

export const DivBack = styled.div`
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1;
`;

export const ButtonBack = styled(M.Button)`
  && {
    cursor: pointer;
    background: none;
    border: none;
    cursor: pointer;
    width: 5rem;
    height: 2.5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128C8E;
    }
  }
  `;

export const Form = styled.form`
display: flex;
justify-content: center;
flex-direction: column;
gap: 1rem;
`;
