export const errorBestowed = {
  "Bestowed com este cpf já existe.": {
    field: "cpf",
    message: "CPF já cadastrado no sistema",
  },
  "Bestowed com este email já existe.": {
    field: "email",
    message: "E-mail já cadastrado no sistema",
  },
}

export const errorPhysical = {
  "Person Physical com este cpf já existe.": {
    field: "cpf",
    message: "CPF já cadastrado no sistema",
  },
  "Person Physical com este email já existe.": {
    field: "email",
    message: "E-mail já cadastrado no sistema",
  },
  "Insira um endereço de email válido.": {
    field: "email",
    message: "Formato de e-mail inválido",
  },
}

export const errorCompany = {
  "Person Company com este cnpj já existe.": {
    field: "cnpj",
    message: "CNPJ já cadastrado no sistema",
  },
  "Person Company com este email já existe.": {
    field: "email",
    message: "E-mail já cadastrado no sistema",
  },
  "Insira um endereço de email válido.": {
    field: "email",
    message: "Formato de e-mail inválido",
  },
}

export const errorLogin = {
  "Incorrect email or password": {
    field: ["email", "password"],
    message: "E-mail ou senha incorreto",
  },
}

export const errorName = {
  "Incorrect email or password": {
    field: ["email", "password"],
    message: "E-mail ou senha incorreto",
  },
}

export const errorPassword = {
  "Incorrect current password": {
    field: "password",
    message: "Senha atual incorreta",
  },
}

export const errorRegister = {
  "usuário com este E-mail já existe.": {
    field: "email",
    message: "E-mail encontrado na base de dados, utilize outro e-mail",
  },
}

export const errorPropertie = {
  "usuário com este E-mail já existe.": {
    field: "email",
    message: "E-mail encontrado na base de dados, utilize outro e-mail",
  },
}

export const errorProjectType = {
  "ProjectType com este project type já existe.": {
    field: "project_type",
    message: "Tipo de projeto com este nome já existe",
  },
}

export const errorLetterOfAttorney = {
  "Certifique-se de que este campo não tenha mais de 11 caracteres.": {
    field: "cpf",
    message: "Certifique-se de que este campo não tenha mais de 11 caracteres",
  },
  "usuário com este E-mail já existe.": {
    field: "email",
    message: "E-mail encontrado na base de dados, utilize outro e-mail",
  },
}

export const errorCondition = {
  "Um termo de condição semelhante já existe.": {
    field: "term_condition",
    message: "Um termo de condição semelhante já existe",
  },
}

export const errorRecovery = {
  "The email provided is not registered.": {
    field: "email",
    message: "Não existe uma conta com este e-mail",
  },
}

export const errorPropost = {
  "Este campo é obrigatório.": {
    field: "start_date",
    message: "Este campo é obrigatório.",
  },
  "Um número válido é necessário.": {
    field: "discount",
    message: "Um número válido é necessário",
  },
  "Certifique-se de que este valor seja inferior ou igual a 100": {
    field: "discount",
    message: "Certifique-se de que este valor seja inferior ou igual a 100",
  },
  "For 'parcelado' payments, down payment and installments must be set.": {
    fields: "down_payment",
    message: "Para pagamentos parcelados, é necessário definir entrada e parcelas",
  },
}
