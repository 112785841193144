import { useContext, useRef, useState } from "react";
import * as S from "./styledMenuItem";
import { GlobalContext } from "../../../global/GlobalContext";

const ItemMenu = ({
  alt,
  elementStatic,
  elementAnimated,
  text,
  nameComponentRendered,
  disabled,
  subText,
  onClick,
}) => {
  const {
    functions: { changeComponent },
  } = useContext(GlobalContext);

  const [data, setData] = useState(false);
  const resetTime = useRef(null);

  const disabledStyle = disabled
    ? {
        cursor: "not-allowed",
        opacity: 0.5,
        pointerEvents: "none",
      }
    : {};

  return (
    <div
      onMouseEnter={() => {
        setData(true);
        clearTimeout(resetTime.current);
        resetTime.current = setTimeout(() => setData(false), 1000);
      }}
      onMouseLeave={() => {
        setData(false);
      }}
      style={disabledStyle}
    >
      <S.Div
        onClick={() => {
          changeComponent(nameComponentRendered);
          onClick();
        }}
      >
        <S.Text>
          <S.Img alt={alt} src={data ? elementAnimated : elementStatic} />
          {text}
          {subText && <S.SmallText>{subText}</S.SmallText>}
        </S.Text>
      </S.Div>
    </div>
  );
};

export default ItemMenu;

