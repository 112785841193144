import styled from "styled-components";
import * as M from "@mui/material";

export const Main = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: black;
`;

export const Button = styled(M.Button)`
  && {
    border-radius: 0.5rem;
    cursor: pointer;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
