import * as M from '@mui/material';
import React, { useContext, useState } from 'react';
import { asAccept, asAccepting, asSubmit } from '../../../../constants/constants/constAndRegex';
import { ClearState, settingState } from '../../../../constants/functions/functions';
import { GlobalContext } from '../../../../global/GlobalContext';
import { Button, ButtonRed } from '../../Reuse/Button/Buttons/Buttons';
import * as S from '../styledDialogs';

const ClearDialog = (props) => {
  const {
    functions: { successToast },
  } = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setDataForm, setFieldErrors, clear, setClear } = props;

  const mutlFunctionsToSubmit = () => {
    setIsSubmitting(true);
    ClearState(setDataForm, setFieldErrors);
    successToast('Campos limpos');
    setIsSubmitting(false);
    settingState(setClear, !clear);
  };

  return (
    <>
      <S.Dialog open={clear}>
        <S.DialogTitle>Limpar todos os campos?</S.DialogTitle>
        <M.DialogContent>
          <S.DialogContentText>Essa ação não pode ser desfeita</S.DialogContentText>
        </M.DialogContent>

        <S.DialogActions>
          <ButtonRed onClick={() => settingState(setClear, !clear)} named={'cancelar'}></ButtonRed>
          <Button
            type={asSubmit}
            named={isSubmitting ? asAccepting : asAccept}
            onClick={() => {
              mutlFunctionsToSubmit();
            }}
            disabled={isSubmitting}
          />
        </S.DialogActions>
      </S.Dialog>
    </>
  );
};

export default ClearDialog;
