import styled from "styled-components";
import * as M from "@mui/material";

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;
export const ContainerForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Form = styled.form`
  gap: 1rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 25rem;
`;

export const Top = styled.div`
  position: relative;
  top: -2rem;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  font-size: 3rem;
    text-transform: uppercase;
  font-family: "Inter";
  font-weight: 700;
  font-size: 2.5rem;
  color: #006978;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const P = styled.p`
  letter-spacing: 3px;
    text-transform: uppercase;
  font-family: "Inter";
  font-weight: 700;
  font-size: 1rem;
  color: #006978;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const Button = styled(M.Button)`
  && {
    background: none;
    border: none;
    padding: 1rem;
    cursor: pointer;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
export const ButtonLogin = styled(M.Button)`
  && {
    background: none;
    border: none;
    padding: 1rem;
    cursor: pointer;
    height: 3rem;
    width: 25rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;

export const DialogActions = styled(M.DialogActions)``;

export const ButtonRed = styled(M.Button)`
  && {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  text-align: right;
  font-style: italic;
  flex-direction: column;
  gap: 1rem;
`;
export const Div = styled.div`
  display: flex;
   justify-content: space-between;
`;
export const A = styled.a`
cursor: pointer;
`;
