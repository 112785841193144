import React from "react";
import styled from "styled-components";

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  color: #006978;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
`;

const TitleModal = ({ text }) => {
  return (
    <>
      <Title>{text}</Title>
    </>
  );
};

export default TitleModal;
