import * as M from "@mui/material"
import axios from "axios"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { ITEMS_PER_PAGE } from "../../../constants/constants/constAndRegex"
import {
  capitalizeFirstString,
  formatAsInstallments,
  formatAsPercentage,
  formatCapitalize,
  formatDate,
  formatMoney,
  formtPayment,
  formtProgress,
  settingState,
} from "../../../constants/functions/functions"
import { URL_PROPOSAL, URL_REQUEST_DOCUMENTS, URL_PDF_PROPOSAL_MAIL } from "../../../constants/urls/urls"
import { GlobalContext } from "../../../global/GlobalContext"
import EraseDialog from "../../common/Dialog/EraseDialog/EraseDialog"
import H1 from "../../common/Reuse/H1/H1"
import Modal from "../../common/Reuse/Modal/Modal"
import Table from "../../common/Table/Table"
import Conditions from "../Conditions/Conditions/Conditions"
import RegisterPropost from "../RegisterPropost/RegisterPropost"
import * as L from "./styled"
import EditPropost from "../EditPropost/EditPropost"
import DetailPropost from "../DetailPropost/DetailPropost"
import EmailDialog from "../../common/Dialog/EmailDialog/EmailDialog "
import EmailPdfDialog from "../../common/Dialog/EmailPdfDialog/EmailPdfDialog "

const Propost = () => {
  const {
    states: {
      globalState: { lookAlteration, id },
    },
    functions: { headerGlobal },
  } = useContext(GlobalContext)

  const [selectedType, setSelectedType] = useState(() => {
    const savedType = localStorage.getItem("s_propost")
    return savedType || "nao_aprovada"
  })
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [data, setData] = useState("")
  const [allPages, setAllPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchName, setSearchName] = useState(null)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [register, setRegister] = useState(false)
  const [registerTypes, setRegisterTypes] = useState(false)
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [detail, setDetail] = useState(false)
  const [registerSub, setRegisterSub] = useState(false)
  const [erase, setErase] = useState(false)
  const [emailAlert, setEmailAlert] = useState(false)
  const [emailPdf, setEmailPdf] = useState(false)

  const propsList = {
    open,
    setOpen,
    registerTypes,
    setRegisterTypes,
    registerSub,
    setRegisterSub,
    register,
    setRegister,
    edit,
    setEdit,
    detail,
    setDetail,
    setErase,
    erase,
    emailPdf, 
    setEmailPdf,
    emailAlert,
    setEmailAlert,
    id,
  }

  const header = [
    { name: "Nº Proposta", field: "id" },
    { name: "Projeto", field: "project.project", styled: formatCapitalize },
    {
      name: "Cliente",
      field: (x) =>
        x.project?.customer?.type === "company" ? x.project.customer.social_reason : x.project.customer?.name ?? "-",
      styled: formatCapitalize,
    },
    { name: "Termos", field: "proposal_term_condition.term_condition", styled: formatCapitalize },
    { name: "Data Inicial", field: "start_date", styled: formatDate },
    { name: "Data Final", field: "final_date", styled: formatDate },
    { name: "Valor", field: "value", styled: formatMoney },
    { name: "Desconto (%)", field: "discount", styled: formatAsPercentage },
    { name: "Valor Final", field: "final_value", styled: formatMoney },
    { name: "Forma de Pagamento", field: "payment_method", styled: formtPayment },
    { name: "Entrada", field: "down_payment", styled: formatMoney },
    { name: "Parcelas", field: "installments", styled: formatAsInstallments },
    { name: "Valor das Parcelas", field: "installment_value", styled: formatMoney },
    { name: "Progresso", field: "progress", styled: formtProgress },
    { name: "Ações", field: "acoes" },
  ]

  const handlePageChange = (event, value) => {
    setCurrentPageNumber(value)
    if (!searchName) {
      sessionStorage.setItem("cp_propost", value)
      fetchPage(value)
    } else {
      fetchPageName(value)
    }
  }

  // mudar de página com click nos números
  const fetchPage = async (pageToUse) => {
    const url = `${URL_PROPOSAL}?progress=${selectedType}&page=${pageToUse}`
    try {
      const response = await axios.get(url, { headers: headerGlobal() })
      setData(response.data)
      const count = response.data.count
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE)
      setAllPages(pagesToRender)
    } catch (error) {
      console.error("Error", error)
    }
  }

  // mudar de página com click nos números quando há valor no input
  const fetchPageName = async (pageToUse) => {
    setIsLoading(true)
    const encodedSearchName = encodeURIComponent(searchName)
    const url = `${URL_PROPOSAL}?project=${encodedSearchName}&page=${pageToUse}`
    try {
      const response = await axios.get(url, { headers: headerGlobal() })
      setData(response.data)
      const count = response.data.count
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE)
      setAllPages(pagesToRender)
    } catch (error) {
      console.error("Error", error)
    } finally {
      setIsLoading(false)
    }
  }

  // pesquisa pelo input
  const searchForName = async (name, currentPageNumber) => {
    sessionStorage.setItem("p_propost", currentPageNumber)
    const encodedSearchName = encodeURIComponent(name)
    const valueUrlSearch = `${URL_PROPOSAL}?progress=${selectedType}&project=${encodedSearchName}`

    try {
      const response = await axios.get(valueUrlSearch, {
        headers: headerGlobal(),
      })
      setCurrentPageNumber(1)
      setData(response.data)
      const count = response.data.count
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE)
      setAllPages(pagesToRender)
    } catch (error) {
      console.error("Error", error)
    }
  }

  // useEffect de salvar, recuperar número da página e alterar as páginas
  useEffect(() => {
    const handleFetch = async () => {
      const pageNumber = searchName ? currentPageNumber : sessionStorage.getItem("cp_physical") || 1
      setCurrentPageNumber(Number(pageNumber))
      if (searchName) {
        await searchForName(searchName, Number(pageNumber))
      } else {
        await fetchPage(Number(pageNumber))
      }
    }

    localStorage.setItem("progress", selectedType)
    handleFetch()
  }, [searchName, selectedType, lookAlteration])

  // auto complete
  // onOpen
  const handleOpen = () => {
    if (searchName && searchName.length > 0) {
      setAutocompleteOpen(true)
    }
  }

  // onClose
  const handleClose = () => {
    setAutocompleteOpen(false)
  }

  // onChange
  const handleChange = (event, newValue) => {
    setSearchName(newValue || "")
  }

  // onInputChange
  const handleInputChange = (event, newValue) => {
    setAutocompleteOpen(Boolean(newValue && newValue.length > 0))
    setSearchName(newValue)
  }

  // resultados únicos na pesquisa do input
  const uniqueResults = useMemo(() => {
    return [...new Set(data?.results?.map((item) => item.project.project))]
  }, [data])

  // input de pesquisa
  const renderSearchInput = (params) => <L.Search {...params} size="small" label="Nome" variant="outlined" />

  return (
    <L.Main>
      <H1 text={"proposta"} />
      <L.Container>
        <L.BoxTitle>
          <L.Row>
            <L.Select value={selectedType} onChange={(event) => setSelectedType(event.target.value)} displayEmpty>
              <M.MenuItem value="nao_aprovada">{"Não Aprovada"}</M.MenuItem>
              <M.MenuItem value="aprovada">{"Aprovada"}</M.MenuItem>
            </L.Select>

            <M.Autocomplete
              open={autocompleteOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              options={uniqueResults}
              value={searchName}
              onChange={handleChange}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => (option ? formatCapitalize(option.toString()) : "")}
              renderInput={renderSearchInput}
            />
          </L.Row>

          <L.DivAjustButton>
            <L.ButtonMedium
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                settingState(setRegister, !register)
              }}
            >
              {/* nome do botão de cadastro */}
              cadastrar proposta
            </L.ButtonMedium>

            <L.ButtonMedium
              onClick={() => {
                settingState(setOpen, !open)
              }}
            >
              {/* nome do botão de cadastro */}
              termos e condições
            </L.ButtonMedium>
          </L.DivAjustButton>
        </L.BoxTitle>

        <Table
          column={header}
          state={data && data?.results}
          onClickDetail={() => settingState(setDetail, !detail)}
          onClickEdit={() => settingState(setEdit, !edit)}
          onClickDelete={() => settingState(setErase, !erase)}
          onClickEmail={() => settingState(setEmailAlert, !emailAlert)}
          onClickEmailPdf={() => settingState(setEmailPdf, !emailPdf)}
        />

        {/* modal de registrar */}
        {register && <Modal open={register} component={<RegisterPropost {...propsList} />} />}

        {/* Modal de detalhes */}
        {detail && <Modal open={detail} component={<DetailPropost {...propsList} />} />}

        {/* Modal de editar */}
        {edit && <Modal open={edit} component={<EditPropost {...propsList} />} />}

        {/* modal de abrir termos e condições */}
        {open && <Modal open={open} component={<Conditions {...propsList} />} />}

        {/* Dialog de email */}
        {emailAlert && <EmailDialog url={URL_REQUEST_DOCUMENTS} {...propsList} />}

        {/* Dialog de excluir */}
        {erase && <EraseDialog url={URL_PROPOSAL} {...propsList} />}
        
        {/* Dialog de excluir */}
        {emailPdf && <EmailPdfDialog url={URL_PDF_PROPOSAL_MAIL} {...propsList} />}

        {/* Paginação */}
        <L.ContainerEndPosition>
          <L.EndPosition>
            <M.Pagination count={allPages} page={currentPage} onChange={handlePageChange} variant="rounded" />
          </L.EndPosition>
        </L.ContainerEndPosition>
      </L.Container>
    </L.Main>
  )
}
export default Propost
