import styled from "styled-components";
import * as M from "@mui/material";

export const Table = styled.table`
  font-family: Cochin;
  border-radius: 0.3rem;
  background-color: rgb(235, 235, 235);
  border-collapse: collapse;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 0.5rem;
  width: 100%;
`;


export const Th = styled.td`
  padding: 0.5rem;
  background-color: #0a7377;
  color: #fff;
  border: 1px solid #fff;
  white-space: nowrap;
  &.acoes {
    width: 100px; // ou qualquer tamanho fixo que você quiser
  }
`;

export const Td = styled.td`
  border: 1px solid #fff;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  &.acoes {
    width: 100px; // ou qualquer tamanho fixo que você quiser
  }
`;

export const Gap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`;

export const MiniIconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #0a7377;
    }
  }
`;

export const MiniIconButtonModalRed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    color: #cc0000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffffff;
      background-color: #cc0000;
    }
  }
`;
