import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { settingState } from '../../../constants/functions/functions';
import { URL_PROPERTIE } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import useForm from '../../../hooks/useForm';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import InputsPropertie from '../../common/Formulary/InputsPropertie/InputsPropertie';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../common/StyledCommonModals/StyledCommonModals';

const DetailPropertie = (props) => {
  const {
    states: {
      globalState: { id },
    },
    functions: { toggleBoolean, headerGlobal },
  } = useContext(GlobalContext);

  const { detail, setDetail } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const InputProps = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_PROPERTIE}${id}/`, { headers: headerGlobal() });
      setDataForm(response.data);
    } catch (error) {
      console.log('Error getInfo', error);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setDetail, !detail)}>
        <S.DivGap>
          <TitleModal text="detalhes procuração" />
          <InputsPropertie {...InputProps} />
        </S.DivGap>
      </Formulary>
    </S.Main>
  );
};
export default DetailPropertie;
