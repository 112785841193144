import React, { useContext, useState } from 'react';
import axios from 'axios';
import * as S from './styled';
import { useNavigate } from 'react-router-dom';
import {
  asAccessToken,
  asDefaultErrorAxios,
  asRefreshToken,
  naveEbs,
  naveRecovery,
} from '../../constants/constants/constAndRegex';
import { GlobalContext } from '../../global/GlobalContext';
import useForm from '../../hooks/useForm';
import InputsLogin from '../../components/common/Formulary/InputsLogin/InputsLogin';
import { URL_LOGIN, URL_USER } from '../../constants/urls/urls';
import { handleErrors, phrase, validateForm } from '../../constants/functions/functions';
import { requiredLogin } from '../../constants/constants/requiredConst';
import { errorLogin } from '../../constants/constants/constError';

const Login = () => {
  const {
    functions: { successToast, errorToast, headerGlobal, toggleBoolean, changeComponent },
    setters: { setGlobalState },
  } = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const InputProps = {
    regexMessage,
    conditionMessage,
    dataForm,
    inputForm,
    fieldErrors,
  };

  const authenticated = async (data) => {
    const response = await axios.post(`${URL_LOGIN}`, data);
    return response.data;
  };

  const localStorageTokens = (refresh, access) => {
    localStorage.setItem(asRefreshToken, refresh);
    localStorage.setItem(asAccessToken, access);
  };

  const GetName = async () => {
    try {
      const response = await axios.get(URL_USER, {
        headers: headerGlobal(),
      });

      setGlobalState((prevState) => ({
        ...prevState,
        name: response.data.name,
      }));
    } catch (error) {
      console.log(error.response?.data || error.message);
    }
  };

  const handlePost = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const resultValidateForm = validateForm({
      specificValidations: requiredLogin,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    try {
      const { refresh, access } = await authenticated(dataForm);
      localStorageTokens(refresh, access);
      GetName();
      toggleBoolean('card', false);
      changeComponent('Welcome');
      successToast(phrase);
      navigate(naveEbs);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorLogin,
        errorToast,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <S.Main>
      <div>
        <S.Top>
          <S.Title>System Engineering</S.Title>
          <S.P>O SEU SISTEMA DE ENGENHARIA</S.P>
        </S.Top>

        <S.ContainerForm>
          <S.Form>
            <InputsLogin {...InputProps} />
            <S.Row>
              <S.A onClick={() => navigate(naveRecovery)}> Perdeu a senha? </S.A>
              <S.ButtonLogin onClick={handlePost} disabled={isSubmitting}>
                {isSubmitting ? 'entrando...' : 'entrar'}
              </S.ButtonLogin>
            </S.Row>
          </S.Form>
        </S.ContainerForm>
      </div>
    </S.Main>
  );
};

export default Login;
