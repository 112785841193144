import React from 'react';
import Input from '../../Reuse/Input/Input/Input';
import InputPassword from '../../Reuse/Input/InputPassword/InputPassword';

const InputsLogin = ({
  dataForm,
  inputForm,
  fieldErrors,
  regexMessage,
  conditionMessage,
}) => {

  return (
    <>
      <Input
        label={'E-mail'}
        name={'email'}
        value={dataForm?.email || ''}
        onChange={inputForm}
        error={fieldErrors?.email || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
      />

      <InputPassword
        label={'Senha'}
        name={'password'}
        value={dataForm?.password || ''}
        onChange={inputForm}
        error={fieldErrors?.password || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
      />
    </>
  );
};

export default InputsLogin;
