import { useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Client from "../components/Client/Client/Client"
import LetterOfAttorney from "../components/LetterOfAttorney/LetterOfAttorney/LetterOfAttorney"
import Project from "../components/Project/Project/Project"
import Propertie from "../components/Propertie/Propertie/Propertie"
import Propost from "../components/Propost/Propost/Propost"
import Welcome from "../components/Welcome/Welcome"
import { asAccessToken, asPeople } from "../constants/constants/constAndRegex"
import { GlobalContext } from "./GlobalContext"
import { BtnPDF } from "../components/BtnPDF"

export const GlobalState = (props) => {
  const [filteredData, setFilteredData] = useState([])
  const [searchName, setSearchName] = useState("")
  const [globalState, setGlobalState] = useState({
    // formulários
    indexRepresentative: "",
    formData: {},
    fieldErrors: "",
    errorField: "",
    errorsDinamic: [],
    dinamicValues: [],
    bestowed: [],
    compareObjToSend: [],
    // componente renderizado
    componentRendered: "",
    componentName: "",
    // modal
    registerPhysicalModal: false,
    registerCompanyModal: false,
    // requisições
    physica: [],
    company: [],
    granted: [],
    stateList: [],
    project: [],
    proposal: [],
    employee: [],
    letterOfAttorney: [],
    selectedLetterOfAttorney: [],
    listOfAttorney: [],
    equipament: [],
    cost: [],
    subProject: [],
    searchName: "",
    selectedId: null,
    infoToPdf: null,
    // monitoramento de estado
    lookAlteration: false,
    // dialog
    companySent: false,
    pyhsycalSent: false,
    clear: false,
    clearDinamic: false,
    exit: false,
    erase: false,
    EraseDialogLegalRepresentative: false,
    eraseDialogGranted: false,
    representative: false,
    // tipo de pessoa
    typePeople: asPeople,
    valueUrlSend: "",
    includeAddress: false,
    //
    passDelRepresentative: false,
    sentCompany: false,
    card: false,
    modal: false,
    editModal: false,
    registerModal: false,
    grantedModal: false,
    registerGrantedModal: false,
    editGrantedModal: false,
    detailGrantedModal: false,
    detailModal: false,
    registerCompanyPersonModal: false,
    lastIDCompany: null,
    id: null,
    subId: null,
    idCompany: null,
    representativeId: null,
    changePassword: false,
    newPassword: false,
    confirmNewPassword: false,
    new_password: false,
    confirm_new_password: false,
    statusImage: null,
  })

  // função para mexer nos estados
  //toggleBoolean("dialog", "reverse")
  const toggleBoolean = (key, reverse) => {
    const reverseMap = {
      reverse: !globalState[key],
      true: true,
      false: false,
    }
    const newValue = reverse !== undefined ? reverseMap[reverse] : reverseMap.reverse
    setGlobalState((prevState) => ({
      ...prevState,
      [key]: newValue,
    }))
  }

  // altera um valor no estado global
  const toggleData = (state, object) => {
    setGlobalState((prevState) => ({
      ...prevState,
      [state]: object,
    }))
  }

  const sayAlteration = () => {
    setGlobalState((prevState) => ({
      ...prevState,
      lookAlteration: !prevState.lookAlteration,
    }))
  }

  const inputChange = (event) => {
    const { name, value } = event.target

    // Atualiza formData com o novo valor.
    setGlobalState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }))

    // Reseta o estado de erro para esse campo específico.
    setGlobalState((prevState) => ({
      ...prevState,
      fieldErrors: {
        ...prevState.fieldErrors,
        [name]: false,
      },
    }))
  }

  // Mostrar mensagem de sucesso na tela
  const successToast = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    })
  }

  // Mostrar mensagem de erro na tela
  const errorToast = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    })
  }

  // Alterar os componentes mostrados na tela pelo clique no menu
  const changeComponent = (value) => {
    if (!value) return

    const componentMap = {
      Welcome: { component: <Welcome />, name: "Welcome" },
      Client: { component: <Client />, name: "Client" },
      LetterOfAttorney: { component: <LetterOfAttorney />, name: "LetterOfAttorney" },
      Project: { component: <Project />, name: "Project" },
      Propost: { component: <Propost />, name: "Propost" },
      Propertie: { component: <Propertie />, name: "Propertie" },
      Pdf: { component: <BtnPDF />, name: "Propost" },
    }

    setGlobalState((prevState) => ({
      ...prevState,
      componentRendered: componentMap[value].component,
      componentName: componentMap[value].name,
    }))
  }

  // Header para as requisições axios
  const headerGlobal = () => {
    const data = localStorage.getItem(asAccessToken)

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${data}`,
    }
    return headers
  }

  const headerGlobalString = () => {
    const data = localStorage.getItem(asAccessToken)

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data}`,
    }
    return headers
  }

  // estados
  const states = {
    globalState,
    filteredData,
    searchName,
  }

  // setters dos estados
  const setters = {
    setGlobalState,
    setFilteredData,
    setSearchName,
  }

  // funções
  const functions = {
    changeComponent,
    headerGlobal,
    headerGlobalString,
    successToast,
    errorToast,
    toggleBoolean,
    toggleData,
    sayAlteration,
    inputChange,
  }

  return <GlobalContext.Provider value={{ states, setters, functions }}>{props.children}</GlobalContext.Provider>
}
