import styled from "styled-components";
import * as M from "@mui/material";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 55rem;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow-x: hidden;
`;


export const DivGap = styled.div`
  display: flex;
  justify-content: space-evenly;
  /* width: 100%; */
  padding-bottom: 1rem;
`;

export const DivSub = styled.div`
/* display: flex; */
/* justify-content: center; */
/* align-items: center; */
  /* background-color: #fff; */
  /* width: 52rem; */
  /* border-radius: 0.5rem; */
  /* overflow: auto; */
`;

export const H2Custom = styled.h2`
  display: flex;
  justify-content: right;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  color: #006978;
  align-items: center;
  gap: 0.2rem;
  padding-right: 1rem;
`;

export const IconButtonModalRed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #ff3333;
    }
  }
`;
export const IconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;


export const H2 = styled.h2`
  display: flex;
  /* width: 100%; */
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #006978;
  align-items: center;
  gap: 0.2rem;
  padding-left: 1rem;
`;


export const Container = styled.div`
/* width: 25rem; */
/* display: flex; */
/* flex-direction: column; */
/* align-items: center; */
/* padding: 1rem; */
`;
