import styled from "styled-components";

export const Main = styled.div`
width: 100%;

display: flex;
justify-content: center;
align-items: center;
/* background-color: yellow; */
padding-top: 10rem;
`;

export const ContainerForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  `;

export const Form = styled.form`
  gap: 1rem;
  flex-direction: column;
  width: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  `;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
width: 25rem;
  gap: 1rem;
`;

export const StrengthMeterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const StrengthMeterBar = styled.div`
height: 10px;
width: 25%;
margin-right: 5px;
border-radius: 5px;
background-color: ${({ filled }) => (filled ? "#00cc00" : "#ff0000")};
`;

export const Level1Bar = styled(StrengthMeterBar)`
  background-color: #ff0000; /* Vermelho */
`;

export const Level2Bar = styled(StrengthMeterBar)`
  background-color: #ff6600; /* Laranja */
`;

export const Level3Bar = styled(StrengthMeterBar)`
  background-color: #ffcc00; /* Amarelo */
`;

export const Level4Bar = styled(StrengthMeterBar)`
  background-color: #00cc00; /* Verde */
`;

