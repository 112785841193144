import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import {
  asClean,
  asDefaultAlteration,
  asDefaultErrorAxios,
  asSend,
  asSending,
  asSubmit,
} from "../../../../constants/constants/constAndRegex"
import { errorPropertie } from "../../../../constants/constants/constError"
import { DATA_PROJECTTYPE } from "../../../../constants/constants/dataToSend"
import { requiredProjectType } from "../../../../constants/constants/requiredConst"
import { handleErrors, settingState, validateForm } from "../../../../constants/functions/functions"
import { URL_PROJECT_TYPE, URL_SUBPROJECT } from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import useForm from "../../../../hooks/useForm"
import ClearDialog from "../../../common/Dialog/ClearDialog/ClearDialog"
import EraseDialog from "../../../common/Dialog/EraseDialog/EraseDialog"
import Formulary from "../../../common/Formulary/Formulary/Formulary"
import InputsProjectTypes from "../../../common/Formulary/InputsProjectTypes/InputsProjectTypes"
import { Button, ButtonRed } from "../../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../../common/Reuse/TitleModal/TitleModal"
import EditSubProjectTypes from "../../SubProjectTypes/EditSubProjectTypes/EditSubProjectTypes"
import * as L from "./styled"

const EditProjectTypes = (props) => {
  const {
    states: {
      globalState: { lookAlteration, id, subId },
    },
    functions: { headerGlobal, successToast, sayAlteration, errorToast, toggleData },
  } = useContext(GlobalContext)
  const [clear, setClear] = useState(false)
  const [erase, setErase] = useState(false)
  const [view, setView] = useState(false)
  const [viewSub, setViewSub] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { edit, setEdit, setRegisterSub, registerSub, registerOnEdit, setRegisterOnEdit } = props
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm()

  const propsList = {
    setRegisterSub,
    registerSub,
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    clear,
    setClear,
    setFieldErrors,
    view,
    setView,
    viewSub,
    setViewSub,
    erase,
    setErase,
    url: URL_SUBPROJECT,
    id: subId,
    registerOnEdit,
    setRegisterOnEdit,
  }

  const handlePut = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    const resultValidateForm = validateForm({
      specificValidations: requiredProjectType,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    })

    if (!resultValidateForm) {
      setIsSubmitting(false)
      return errorToast(asDefaultErrorAxios)
    }

    const dataToSend = DATA_PROJECTTYPE(dataForm)

    try {
      const response = await axios.put(`${URL_PROJECT_TYPE}${id}/`, dataToSend, {
        headers: headerGlobal(),
      })
      sayAlteration()
      toggleData("id", response.data.id)
      successToast(asDefaultAlteration)
      settingState(setView, !view)
      settingState(setRegisterOnEdit, true)
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorPropertie,
        errorToast,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_PROJECT_TYPE}${id}/`, { headers: headerGlobal() })
      setDataForm(response.data)
    } catch (error) {
      console.log("Error getInfo", error)
    }
  }

  useEffect(() => {
    getInfo()
  }, [])

  useEffect(() => {
    if (view && viewSub === true) settingState(setEdit, !edit)
  }, [lookAlteration])

  return (
    <>
      <L.Main>
        <Formulary onClick={() => settingState(setEdit, !edit)}>
          <L.BoxSelect>
            <TitleModal text={"editar"} />
          </L.BoxSelect>
          {view === false && (
            <>
              <L.Container>
                <InputsProjectTypes {...propsList} />
              </L.Container>
              <L.DivGap>
                <ButtonRed onClick={() => settingState(setClear, !clear)} named={asClean} />
                <Button
                  type={asSubmit}
                  named={isSubmitting ? asSending : asSend}
                  onClick={(event) => {
                    if (!isSubmitting) {
                      handlePut(event)
                    }
                  }}
                  disabled={isSubmitting}
                />
              </L.DivGap>
            </>
          )}
          <L.Container>{viewSub === false && <EditSubProjectTypes {...propsList} />}</L.Container>
        </Formulary>
      </L.Main>

      {/* diálogo de limpar */}
      <ClearDialog {...propsList} />

      {/* diálogo de excluir */}
      <EraseDialog {...propsList} />
    </>
  )
}
export default EditProjectTypes
