import React from 'react';
import {
  as50,
} from '../../../../constants/constants/constAndRegex';
import InputPassword from '../../Reuse/Input/InputPassword/InputPassword';

const InputsPassword = ({
  dataForm,
  inputForm,
  fieldErrors,
  regexMessage,
  conditionMessage,
}) => {
  return (
    <>
      <InputPassword
        label={'Senha'}
        name={'password'}
        value={dataForm?.password || ''}
        onChange={inputForm}
        length={as50}
        error={fieldErrors?.password || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        />

      <InputPassword
        label={'Nova Senha'}
        name={'new_password'}
        value={dataForm?.new_password || ''}
        onChange={inputForm}
        length={as50}
        error={fieldErrors?.new_password || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
      />

      <InputPassword
        label={'Confirmar nova senha'}
        name={'confirm_new_password'}
        value={dataForm?.confirm_new_password || ''}
        onChange={inputForm}
        length={as50}
        error={fieldErrors?.confirm_new_password || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
      />
    </>
  );
};
export default InputsPassword;
