import * as M from '@mui/material';
import React from 'react';

const Modal = ({ open, component }) => {
  return (
    <>
      <M.Modal open={open}>{component}</M.Modal>
    </>
  );
};

export default Modal;
