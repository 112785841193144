import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../../global/GlobalContext';
import useVerifyFields from '../../../hooks/useVerifyFields';
import DetailClient from '../../Client/DetailClient/DetailClient';
import EditClient from '../../Client/EditClient/EditClient';
import EraseDialog from '../../common/Dialog/EraseDialog/EraseDialog';
import Modal from '../../common/Reuse/Modal/Modal';
import RegisterClient from '../RegisterClient/RegisterClient/RegisterClient';
import * as L from './styled';

const Client = () => {
  const {
    states: {
      globalState: { id }
    }
  } = useContext(GlobalContext);
  const [register, setRegister] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState(false);
  const [erase, setErase] = useState(false);
  const propsList = { register, setRegister, edit, setEdit, detail, setDetail, erase, setErase, id };
  const { Rendered, urlSelected } = useVerifyFields(propsList);

  return (
    <L.Main>
      {Rendered}

      {/* modal de cadastrar */}
      {register && <Modal open={register} component={<RegisterClient {...propsList} />} />}

      {/* modal de editar */}
      {edit && <Modal open={edit} component={<EditClient {...propsList} />} />}

      {/* modal de detalhes */}
      {detail && <Modal open={detail} component={<DetailClient {...propsList} />} />}

      {/* diálogo de excluir */}
      {erase && <EraseDialog url={urlSelected} {...propsList} />}
    </L.Main>
  );
};

export default Client;
