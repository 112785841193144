import styled from "styled-components";
import * as M from "@mui/material"

export const Main = styled.div`
display: flex;
/* width: 100%; */
text-align: center;
/* margin-left: 0.5rem; */
/* display: flex; */
justify-content: center;
align-items: center;
/* background-color: red; */
/* height: 100%; */
/* margin-top: 21rem; */
/* width: cal(100vw - 50rem); */
height: calc(100vh - 15rem)
`

export const Top = styled.div`
/* display: flex; */
/* background-color: red; */
/* justify-content: center; */
/* align-items: center; */
/* border-bottom: 1px solid #E4E4E4; */
/* padding-bottom: 0.5rem; */
/* display: flex; */
/* flex-direction: column; */
/* width: 100vw; */
/* height: 100vh; */
/* height: calc(100vh - 4.78rem) */
`;

export const Title = styled.div`
/* margin-top: 2.3rem; */
/* margin-left: 0.15rem; */
font-weight: 600;
font-size: 2rem;
color: #0A7377;
`;

export const P = styled.p`
/* letter-spacing: 3px; */
/* padding-top: 0.5rem; */
`;
