import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asCustomerCompany,
  asCustomerPhysical,
  asDefaultErrorAxios,
  asSend,
  asSending,
  asSubmit,
} from '../../../constants/constants/constAndRegex';
import { DATA_LETTEROFATTORNEY } from '../../../constants/constants/dataToSend';
import { requiredLetterOfAttorney } from '../../../constants/constants/requiredConst';
import { changeValue, handleErrors, settingState, validateForm } from '../../../constants/functions/functions';
import { URL_LETTER_OF_ATTORNEY } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import useForm from '../../../hooks/useForm';
import ClearDialog from '../../common/Dialog/ClearDialog/ClearDialog';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import InputsLetterOfAttorney from '../../common/Formulary/InputsLetterOfAttorney/InputsLetterOfAttorney';
import { Button, ButtonRed } from '../../common/Reuse/Button/Buttons/Buttons';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../common/StyledCommonModals/StyledCommonModals';
import * as L from './styled';
import { errorLetterOfAttorney } from '../../../constants/constants/constError';

const EditLetterOfAttorney = (props) => {
  const {
    states: {
      globalState: { formData, id },
    },
    functions: { headerGlobal, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext);
  const [clear, setClear] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { edit, setEdit } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    clear,
    setClear,
    setFieldErrors,
  };

  const handlePost = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const specificValidations = requiredLetterOfAttorney;

    const resultValidateForm = validateForm({
      specificValidations,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    const dataToSend = DATA_LETTEROFATTORNEY(dataForm);
    let combinedData = {
      ...dataToSend,
    };

    if (dataForm.verifySelect === asCustomerPhysical) {
      combinedData.customer_person_physical = dataForm.customer_person_physical;
      combinedData.customer_person_company = null;
    } else if (dataForm.verifySelect === asCustomerCompany) {
      combinedData.customer_person_company = dataForm.customer_person_company;
      combinedData.customer_person_physical = null;
    }

    try {
      await axios.put(`${URL_LETTER_OF_ATTORNEY}${id}/`, combinedData, { headers: {
        ...headerGlobal(),
        'Content-Type': 'application/json'
      } });
      sayAlteration();
      successToast('Alteração realizada');
      settingState(setEdit, !edit);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorLetterOfAttorney,
        errorToast
      });
    } finally {
      console.log(combinedData)
      setIsSubmitting(false);
    }
  };

  const getInfo = () => {
    axios
      .get(`${URL_LETTER_OF_ATTORNEY}${id}/`, { headers: headerGlobal() })
      .then((response) => {
        const activeAsString = String(response.data.active); // Converte o booleano para string
        const appointmentAsString = String(response.data.appointment); // Converte o booleano para string
        const bestowedIds = response.data.bestowed.map((item) => item.id);

        setDataForm((prevState) => ({
          ...prevState, // Mantém o estado existente
          ...response.data, // Atualiza com os novos valores de response.data
          verifySelect:
            response.data.customer?.type === 'physical' ? 'customer_person_physical' : 'customer_person_company',
          bestowed: bestowedIds, // Só inclui os IDs do bestowed
          active: activeAsString, // Atualiza o valor de 'active' para ser uma string
          appointment: appointmentAsString, // Atualiza o valor de 'appointment' para ser uma tring
        }));
      })
      .catch((error) => {
        console.log('Error getInfo', error);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <S.Main>
        <Formulary onClick={() => settingState(setEdit, !edit)}>
          <S.DivGap>
            <TitleModal text="editar procuração" />
            <InputsLetterOfAttorney {...propsList} />
          </S.DivGap>
          <L.DivGap>
            <ButtonRed onClick={() => changeValue(clear, setClear)} named={'limpar'} />
            <Button
              type={formData.inputDisable !== true ? asSubmit : undefined}
              named={isSubmitting ? asSending : asSend}
              onClick={(event) => {
                if (!isSubmitting) {
                  handlePost(event);
                }
              }}
              disabled={isSubmitting}
            />
          </L.DivGap>
        </Formulary>
      </S.Main>

      {/* diálogo de limpar */}
      <ClearDialog {...propsList} />
    </>
  );
};
export default EditLetterOfAttorney;
