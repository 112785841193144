import * as S from '../styledDialogs';
import * as M from '@mui/material';
import { Button, ButtonRed } from '../../Reuse/Button/Buttons/Buttons';
import { GlobalContext } from '../../../../global/GlobalContext';
import React, { useContext } from 'react';
import axios from 'axios';
import {
  URL_COMPANY,
  URL_LEGAL_REPRESENTATIVE,
} from '../../../../constants/urls/urls';
import { changeValue } from '../../../../constants/functions/functions';

const EraseDialogLegalRepresentative = ({
  stateDeleteModal,
  setStateDeleteModal,
}) => {
  const {
    states: {
      globalState: {
        EraseDialogLegalRepresentative,
        representativeId,
        indexRepresentative,
        id,
      },
    },
    setters: { setGlobalState },
    functions: { toggleBoolean, successToast, headerGlobal, sayAlteration },
  } = useContext(GlobalContext);

  // const getInfo = async () => {
  //   try {
  //     const response = await axios.get(`${URL_COMPANY}${id}/`, {
  //       headers: headerGlobal(),
  //     });

  //     const repIdCompany = response.data.id;

  //     setGlobalState((prevState) => ({
  //       ...prevState,
  //       formData: {
  //         ...prevState.formData,
  //         ...response.data,
  //         actionAtualy: 'editar cliente',
  //       },
  //       idCompany: response.data.id,
  //     }));

  //     getInfoLegalRepresentative(response.data.id);
  //   } catch (error) {
  //     console.log('Erro em DetailClient.js getInfoLegalRepresentative');
  //   }
  // };

  // const getInfoLegalRepresentative = async (repIdCompany) => {
  //   try {
  //     const response = await axios.get(`${URL_LEGAL_REPRESENTATIVE}`, {
  //       headers: headerGlobal(),
  //     });
  //     const data = response.data;

  //     // Verificando se data.results é um array
  //     if (data && data.results && Array.isArray(data.results)) {
  //       const filteredCompanyData = data.results.filter(
  //         (item) => item.company === repIdCompany
  //       );

  //       setGlobalState((prevState) => ({
  //         ...prevState,
  //         dinamicValues: filteredCompanyData,
  //         compareObjToSend: filteredCompanyData,
  //       }));
  //     } else {
  //       console.log("O campo 'results' não é um array ou está vazio.");
  //     }
  //   } catch (error) {
  //     console.log('Erro em DetailClient.js getInfoLegalRepresentative');
  //   }
  // };

  // const removeForm = (event) => {
  //   event.preventDefault();
  //   console.log('removeForm chamada com index: ', indexRepresentative);
  //   setGlobalState((prevState) => ({
  //     ...prevState,
  //     dinamicValues: prevState.dinamicValues.filter(
  //       (_, idx) => idx !== indexRepresentative
  //     ),
  //   }));
  // };

  const handleDelete = () => {
    axios
      .delete(`${URL_LEGAL_REPRESENTATIVE}${representativeId}/`, {
        headers: headerGlobal(),
      })
      .then((response) => {
        // getInfo();
        successToast('Excluído');
        sayAlteration();
        changeValue(stateDeleteModal, setStateDeleteModal);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <S.Dialog open={stateDeleteModal}>
        <S.DialogTitle>Excluir?</S.DialogTitle>
        <M.DialogContent>
          <S.DialogContentText>
            Essa ação não pode ser desfeita
          </S.DialogContentText>
        </M.DialogContent>

        <S.DialogActions>
          <ButtonRed
            onClick={() => changeValue(stateDeleteModal, setStateDeleteModal)}
            named={'cancelar'}
          ></ButtonRed>

          <Button
            onClick={(event) => {
              handleDelete();
              // removeForm(event);
              changeValue(stateDeleteModal, setStateDeleteModal);
            }}
            named={'aceitar'}
          />
        </S.DialogActions>
      </S.Dialog>
    </>
  );
};

export default EraseDialogLegalRepresentative;
