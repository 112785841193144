// empresa
export const DATA_COMPANY = (dataForm) => {
  return {
    social_reason: dataForm.social_reason ?? "",
    zip_code: dataForm.zip_code ?? "",
    street: dataForm.street ?? "",
    neighborhood: dataForm.neighborhood ?? "",
    city: dataForm.city ?? "",
    state: dataForm.state ?? "",
    number: dataForm.number ?? "",
    complement: dataForm.complement ?? "",
    telephone: dataForm.telephone ?? "",
    cnpj: dataForm.cnpj ?? "",
    email: dataForm.email ?? "",
  }
}

// cliente físico
export const DATA_PHYSICAL = (dataForm) => {
  return {
    name: dataForm.name ?? "",
    birth_date: dataForm.birth_date ?? "",
    gender: dataForm.gender ?? "",
    nationality: dataForm.nationality ?? "",
    place_of_birth_state: dataForm.place_of_birth_state ?? "",
    place_of_birth_town: dataForm.place_of_birth_town ?? "",
    marital_status: dataForm.marital_status ?? "",
    others_marital_status: dataForm.others_marital_status ?? "",
    marriage_regime: dataForm.marriage_regime ?? "",
    others_marriage_regime: dataForm.others_marriage_regime ?? "",
    profession: dataForm.profession ?? "",
    affiliation: dataForm.affiliation ?? "",
    telephone: dataForm.telephone ?? "",
    email: dataForm.email ?? "",
    cpf: dataForm.cpf ?? "",
    cpf_file: dataForm.cpf_file ?? "",
    rg: dataForm.rg ?? "",
    rg_sender: dataForm.rg_sender ?? "",
    rg_file: dataForm.rg_file ?? "",
    cnh: dataForm.cnh ?? "",
    cnh_sender: dataForm.cnh_sender ?? "",
    cnh_file: dataForm.cnh_file ?? "",
    type_certificate: dataForm.type_certificate ?? "",
    certificate: dataForm.certificate ?? "",
    certificate_file: dataForm.certificate_file ?? "",
    zip_code: dataForm.zip_code ?? "",
    street: dataForm.street ?? "",
    neighborhood: dataForm.neighborhood ?? "",
    city: dataForm.city ?? "",
    state: dataForm.state ?? "",
    number: dataForm.number ?? "",
    complement: dataForm.complement ?? "",
  }
}

// outrogado
export const DATA_BESTOWED = (dataForm) => {
  return {
    name: dataForm.name ?? "",
    birth_date: dataForm.birth_date ?? "",
    gender: dataForm.gender ?? "",
    nationality: dataForm.nationality ?? "",
    place_of_birth_state: dataForm.place_of_birth_state ?? "",
    place_of_birth_town: dataForm.place_of_birth_town ?? "",
    marital_status: dataForm.marital_status ?? "",
    others_marital_status: dataForm.others_marital_status ?? "",
    marriage_regime: dataForm.marriage_regime ?? "",
    others_marriage_regime: dataForm.others_marriage_regime ?? "",
    profession: dataForm.profession ?? "",
    affiliation: dataForm.affiliation ?? "",
    telephone: dataForm.telephone ?? "",
    email: dataForm.email ?? "",
    cpf: dataForm.cpf ?? "",
    cpf_file: dataForm.cpf_file ?? "",
    rg: dataForm.rg ?? "",
    rg_sender: dataForm.rg_sender ?? "",
    rg_file: dataForm.rg_file ?? "",
    cnh: dataForm.cnh ?? "",
    cnh_sender: dataForm.cnh_sender ?? "",
    cnh_file: dataForm.cnh_file ?? "",
    type_certificate: dataForm.type_certificate ?? "",
    certificate: dataForm.certificate ?? "",
    certificate_file: dataForm.certificate_file ?? "",
    zip_code: dataForm.zip_code ?? "",
    street: dataForm.street ?? "",
    neighborhood: dataForm.neighborhood ?? "",
    city: dataForm.city ?? "",
    state: dataForm.state ?? "",
    number: dataForm.number ?? "",
    complement: dataForm.complement ?? "",
  }
}

// representante legal
export const DATA_LEGAL_REPRESENTATIVE = (dataForm) => {
  return {
    company: dataForm.company ?? "",
    legal_representative: dataForm.legal_representative ?? "",
    birth_date: dataForm.birth_date ?? "",
    email: dataForm.email ?? "",
    gender: dataForm.gender ?? "",
    affiliation: dataForm.affiliation ?? "",
    profession: dataForm.profession ?? "",
    nationality: dataForm.nationality ?? "",
    place_of_birth_state: dataForm.place_of_birth_state ?? "",
    place_of_birth_town: dataForm.place_of_birth_town ?? "",
    marital_status: dataForm.marital_status ?? "",
    others_marital_status: dataForm.others_marital_status ?? "",
    marriage_regime: dataForm.marriage_regime ?? "",
    others_marriage_regime: dataForm.others_marriage_regime ?? "",
    cpf: dataForm.cpf ?? "",
    cpf_file: dataForm.cpf_file ?? "",
    rg: dataForm.rg ?? "",
    rg_sender: dataForm.rg_sender ?? "",
    rg_file: dataForm.rg_file ?? "",
    cnh: dataForm.cnh ?? "",
    cnh_sender: dataForm.cnh_sender ?? "",
    cnh_file: dataForm.cnh_file ?? "",
    type_certificate: dataForm.type_certificate ?? "",
    certificate: dataForm.certificate ?? "",
    certificate_file: dataForm.certificate_file ?? "",
    qualification: dataForm.qualification ?? "",
    others_qualification: dataForm.others_qualification ?? "",
  }
}

// recebe spouse
export const DATA_CLIENT_REPRESENTATIVE = (dataForm) => {
  return {
    legal_representative: dataForm.legal_representative ?? "",
    birth_date: dataForm.birth_date ?? "",
    gender: dataForm.gender ?? "",
    nationality: dataForm.nationality ?? "",
    place_of_birth_state: dataForm.place_of_birth_state ?? "",
    place_of_birth_town: dataForm.place_of_birth_town ?? "",
    marital_status: dataForm.marital_status ?? "",
    others_marital_status: dataForm.others_marital_status ?? "",
    marriage_regime: dataForm.marriage_regime ?? "",
    others_marriage_regime: dataForm.others_marriage_regime ?? "",
    profession: dataForm.profession ?? "",
    affiliation: dataForm.affiliation ?? "",
    telephone: dataForm.telephone ?? "",
    email: dataForm.email ?? "",
    cpf: dataForm.cpf ?? "",
    cpf_file: dataForm.cpf_file ?? null,
    rg: dataForm.rg ?? "",
    rg_file: dataForm.rg_file ?? null,
    rg_sender: dataForm.rg_sender ?? "",
    cnh: dataForm.cnh ?? "",
    cnh_file: dataForm.cnh_file ?? null,
    cnh_sender: dataForm.cnh_sender ?? "",
    type_certificate: dataForm.type_certificate ?? "",
    certificate: dataForm.certificate ?? "",
    certificate_file: dataForm.certificate_file ?? null,
    qualification: dataForm.qualification ?? "",
    others_qualification: dataForm.others_qualification ?? "",
  }
}

// esposa
export const DATA_SPOUSE = (dataForm) => {
  return {
    spouse: dataForm.spouse ?? "",
    spouse_nationality: dataForm.spouse_nationality ?? "",
    spouse_place_of_birth_state: dataForm.spouse_place_of_birth_state ?? "",
    spouse_place_of_birth_town: dataForm.spouse_place_of_birth_town ?? "",
    spouse_birth_date: dataForm.spouse_birth_date ?? "",
    spouse_affiliation: dataForm.spouse_affiliation ?? "",
    spouse_profession: dataForm.spouse_profession ?? "",
    spouse_telephone: dataForm.spouse_telephone ?? "",
    spouse_cpf: dataForm.spouse_cpf ?? "",
    spouse_cpf_file: dataForm.spouse_cpf_file ?? "",
    spouse_rg: dataForm.spouse_rg ?? "",
    spouse_rg_sender: dataForm.spouse_rg_sender ?? "",
    spouse_rg_file: dataForm.spouse_rg_file ?? "",
    spouse_cnh: dataForm.spouse_cnh ?? "",
    spouse_cnh_sender: dataForm.spouse_cnh_sender ?? "",
    spouse_cnh_file: dataForm.spouse_cnh_file ?? "",
    spouse_wedding_certificate: dataForm.spouse_wedding_certificate ?? "",
    spouse_wedding_certificate_file: dataForm.spouse_wedding_certificate_file ?? "",
  }
}

// procuração
export const DATA_LETTEROFATTORNEY = (dataForm) => {
  return {
    customer_person_physical: dataForm.customer_person_physical ?? "",
    customer_person_company: dataForm.customer_person_company ?? "",
    bestowed: dataForm.bestowed ?? "",
    document_tipology: dataForm.document_tipology ?? "",
    power: dataForm.power ?? "",
    others_power: dataForm.others_power ?? "",
    appointment: dataForm.appointment ?? "",
    divisions_involved: dataForm.divisions_involved ?? "",
    others_divisions_involved: dataForm.others_divisions_involved ?? "",
    grants: dataForm.grants ?? "",
    others_grants: dataForm.others_grants ?? "",
    quality_powers: dataForm.quality_powers ?? "",
    process_specification: dataForm.process_specification ?? "",
    validate: dataForm.validate ?? "",
    city: dataForm.city ?? "",
    date: dataForm.date ?? "",
    obs: dataForm.obs ?? "",
    active: dataForm.active ?? "",
  }
}

// imóvel
export const DATA_PROPERTIE = (dataForm) => {
  return {
    owner: dataForm.owner ?? "",
    area: dataForm.area ?? "",
    registration: dataForm.registration ?? "",
    registration_pdf: dataForm.registration_pdf ?? null,
    iptu: dataForm.iptu ?? "",
    iptu_pdf: dataForm.iptu_pdf ?? null,
    image: dataForm.image ?? null,
    zip_code: dataForm.zip_code ?? "",
    street: dataForm.street ?? "",
    neighborhood: dataForm.neighborhood ?? "",
    city: dataForm.city ?? "",
    state: dataForm.state ?? "",
    number: dataForm.number ?? "",
    complement: dataForm.complement ?? "",
    status: dataForm.status ?? "",
  }
}

// projeto
export const DATA_PROJECT = (dataForm) => {
  return {
    project: dataForm.project ?? "",
    project_type: dataForm.project_type ?? "",
    subproject: dataForm.subproject ?? "",
    customer_person_physical: dataForm.customer_person_physical ?? "",
    customer_person_company: dataForm.customer_person_company ?? "",
    start_date: dataForm.start_date ?? "",
    final_date: dataForm.final_date ?? "",
    image: dataForm.image ?? null,
  }
}

// tipo de projeto
export const DATA_PROJECTTYPE = (dataForm) => {
  return {
    project_type: dataForm.project_type ?? "",
  }
}

// subtipo de projeto
export const DATA_SUBPROJECTTYPE = (dataForm) => {
  return {
    subproject_name: dataForm.subproject_name ?? "",
    project_type: dataForm.project_type ?? "",
  }
}

// cláusulas de termos e condições
export const DATA_TERM_CONDITION = (dataForm) => {
  return {
    term_condition: dataForm.term_condition ?? "",
  }
}

// cláusulas de termos e condições
export const DATA_TERM_CONDITION_TYPE = (dataForm) => {
  return {
    clauses: dataForm.clauses ?? "",
    term_condition: dataForm.term_condition ?? "",
  }
}
