import styled from "styled-components";
import * as M from "@mui/material";

// ==============================
export const Card = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Text = styled.div`
  font-size: 0.8rem;
  color: #fff;
  margin-left: 0.2rem;
  white-space: nowrap;
`;
export const Div = styled.div`
`;

export const MiniIconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1.64rem;
    }
    background-color: rgba(10, 115, 119, 1);
    width: 8rem;
    border-radius: 0.3rem;
    cursor: pointer;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: #fff;
      color: #000;
      box-shadow: 0px 0px 0px 1px #0a7377, 0px 4px 4px rgba(0, 0, 0, 0);

      & > div {
        color: #000;
      }
    }
  }
`;

export const MiniIconButtonModalRed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1.55rem;
    }

    width: 5rem;
    background-color: rgba(10, 115, 119, 1);
    border-radius: 0.3rem;
    cursor: pointer;

    &:hover {
      color: #cc0000;
      background-color: #fff;
      box-shadow: 0px 0px 0px 1px #0a7377, 0px 4px 4px rgba(0, 0, 0, 0);
      & > div {
        color: #000;
      }
    }
  }
`;
