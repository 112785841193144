import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  padding: 1rem;
`;

export const Top = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
  flex-direction: row-reverse;
`;

export const DivSub = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: auto;
`;
