import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { asDetalhes } from '../../../constants/constants/constAndRegex';
import { settingState } from '../../../constants/functions/functions';
import { GlobalContext } from '../../../global/GlobalContext';
import useForm from '../../../hooks/useForm';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import InputsClientPhysical from '../../common/Formulary/InputsClient/InputsClientPhysical/InputsClientPhysical';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../common/StyledCommonModals/StyledCommonModals';
const DetailClientPhysical = (props) => {
  const {
    states: {
      globalState: { formData, id, lookAlteration },
    },
    functions: { headerGlobal },
  } = useContext(GlobalContext);
  const { detail, setDetail } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const propsList = {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${formData.axiosUrl}${id}/`, {
        headers: headerGlobal(),
      });
      setDataForm(response.data);
    } catch (error) {
      console.log('Erro DetailClient.js: getInfo');
    }
  };

  useEffect(() => {
    getInfo();
  }, [lookAlteration]);

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setDetail, !detail)}>
        <S.BoxSelect>
          <TitleModal text={asDetalhes} />
        </S.BoxSelect>
        <S.DivGap>{dataForm && <InputsClientPhysical {...propsList} />}</S.DivGap>
      </Formulary>
    </S.Main>
  );
};
export default DetailClientPhysical;
