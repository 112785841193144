import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asDefaultAlteration,
  asDefaultErrorAxios,
  asSend,
  asSending,
  asSubmit,
} from '../../../../constants/constants/constAndRegex';
import { DATA_CLIENT_REPRESENTATIVE, DATA_SPOUSE } from '../../../../constants/constants/dataToSend';
import { requiredClientRepresentative, requiredSpouse } from '../../../../constants/constants/requiredConst';
import {
  changeValue,
  handleMultErrors,
  settingState,
  validateMultForm,
} from '../../../../constants/functions/functions';
import { URL_COMPANY, URL_LEGAL_REPRESENTATIVE } from '../../../../constants/urls/urls';
import { GlobalContext } from '../../../../global/GlobalContext';
import useMultForm from '../../../../hooks/useMultForm';
import ClearDialogDinamic from '../../../common/Dialog/ClearDialog/ClearDialogDinamic';
import EraseDialogLegalRepresentative from '../../../common/Dialog/EraseDialog/EraseDialogLegalRepresentative';
import InputsClientRepresentative from '../../../common/Formulary/InputsClient/InputsClientRepresentative/InputsClientRepresentative';
import { Button, ButtonRed } from '../../../common/Reuse/Button/Buttons/Buttons';
import Modal from '../../../common/Reuse/Modal/Modal';
import ModalRegisterClientRepresentative from '../../RegisterClient/RegisterClientRepresentative/ModalRegisterClientRepresentative/ModalRegisterClientRepresentative';
import * as L from './styled';
const EditClientLegalRepresentative = (props) => {
  const {
    states: {
      globalState: { lookAlteration, id },
    },
    setters: { setGlobalState },
    functions: { headerGlobal, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext);
  const [clear, setClear] = useState(false);
  const [stateRegisterModal, setStateRegisterModal] = useState(false);
  const [stateDeleteModal, setStateDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { viewRepresentative, setViewRepresentative } = props;

  const {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
  } = useMultForm();

  const propsList = {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
    clear,
    setClear,
  };
  const errorHandlers = {
    email: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].email = errors.email[0];
      errorToast('E-mail já está em uso');
    },
    cpf: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].cpf = errors.cpf[0];
      errorToast('CPF já está em uso');
    },
  };

  const sendData = async (item) => {
    const dataToSend = DATA_CLIENT_REPRESENTATIVE(item);
    const spouseData = DATA_SPOUSE(item);
    const combinedData = {
      ...dataToSend,
      ...spouseData,
      company: id,
    };

    await axios.put(`${URL_LEGAL_REPRESENTATIVE}${item.id}/`, combinedData, {
      headers: {
        ...headerGlobal(),
        'Content-Type': 'multipart/form-data',
      },
    });

    return item.id;
  };

  const handlePut = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const allFormsValid = validateMultForm({
      specificValidations: requiredClientRepresentative,
      multDataForm,
      setMultFieldErrors,
      setMultRegexMessage,
      setMultConditionMessage,
    });

    if (!allFormsValid) {
      setIsSubmitting(false);
      errorToast(asDefaultErrorAxios);
      return;
    }

    let newFieldErrors = { ...multFieldErrors };
    let updatedMultDataForm = [...multDataForm];

    for (const item of multDataForm) {
      try {
        await sendData(item);
        updatedMultDataForm = updatedMultDataForm.filter((form) => form.id !== item.id);
      } catch (error) {
        handleMultErrors({ error, item, newFieldErrors, errorHandlers });
      }
    }

    setMultFieldErrors(newFieldErrors);
    setMultDataForm(updatedMultDataForm);

    if (updatedMultDataForm.length === 0) {
      changeValue(viewRepresentative, setViewRepresentative);
      sayAlteration();
      setIsSubmitting(false);
      successToast(asDefaultAlteration);
    }
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_COMPANY}${id}/`, {
        headers: headerGlobal(),
      });
      let data = response.data.legal_representatives;
      // Ordena os dados pelo campo 'id' em ordem crescente
      data.sort((a, b) => a.id - b.id);

      setMultDataForm(data);
      setGlobalState((prevState) => ({
        ...prevState,
        idCompany: id,
      }));
    } catch (error) {
      console.log('Error getInfo');
    }
  };

  useEffect(() => {
    getInfo();
  }, [lookAlteration]);

  return (
    <L.Main>
      <L.DivSub>
        {Array.isArray(multDataForm) &&
          multDataForm?.map((form) => (
            <div key={form.id}>
              <InputsClientRepresentative formId={form.id} {...propsList} />

              <L.H2Custom>
                Deletar Representante
                <L.IconButtonModalRed
                  type="button"
                  onClick={(event) => {
                    changeValue(stateDeleteModal, setStateDeleteModal);
                    setGlobalState((prevState) => ({
                      ...prevState,
                      representativeId: form.id,
                    }));
                  }}
                >
                  <RemoveIcon />
                </L.IconButtonModalRed>
              </L.H2Custom>
            </div>
          ))}

        <L.H2>
          Representante Legal
          <L.IconButtonModal type="button" onClick={() => settingState(setStateRegisterModal, !stateRegisterModal)}>
            <AddIcon />
          </L.IconButtonModal>
        </L.H2>

        <L.Container>
          {multDataForm.length >= 1 && (
            <L.DivGap>
              <ButtonRed onClick={() => settingState(setClear, !clear)} named={'limpar'} />
              <Button
                type={asSubmit}
                named={isSubmitting ? asSending : asSend}
                onClick={(event) => {
                  if (!isSubmitting) {
                    handlePut(event);
                  }
                }}
                disabled={isSubmitting}
              />
            </L.DivGap>
          )}
        </L.Container>
      </L.DivSub>

      {/* modal de registrar */}
      <Modal
        open={stateRegisterModal}
        component={
          <ModalRegisterClientRepresentative
            setStateRegisterModal={setStateRegisterModal}
            stateRegisterModal={stateRegisterModal}
          />
        }
      />

      {/* diálogo de excluir */}
      <EraseDialogLegalRepresentative
        open={stateDeleteModal}
        setStateDeleteModal={setStateDeleteModal}
        stateDeleteModal={stateDeleteModal}
      />

      <ClearDialogDinamic {...propsList} />
    </L.Main>
  );
};

export default EditClientLegalRepresentative;
