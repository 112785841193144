import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import {
  asCustomerCompany,
  asCustomerPhysical,
  asDefaultAlteration,
  asDefaultErrorAxios,
  asSend,
  asSending,
  asSubmit,
} from "../../../constants/constants/constAndRegex"
import { errorPropertie } from "../../../constants/constants/constError"
import { requiredProject } from "../../../constants/constants/requiredConst"
import { changeValue, handleErrors, settingState, validateForm } from "../../../constants/functions/functions"
import { URL_PROJECT } from "../../../constants/urls/urls"
import { GlobalContext } from "../../../global/GlobalContext"
import useForm from "../../../hooks/useForm"
import ClearDialog from "../../common/Dialog/ClearDialog/ClearDialog"
import Formulary from "../../common/Formulary/Formulary/Formulary"
import InputsProject from "../../common/Formulary/InputsProject/InputsProject"
import { Button, ButtonRed } from "../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../common/Reuse/TitleModal/TitleModal"
import * as S from "../../common/StyledCommonModals/StyledCommonModals"
import * as L from "./styled"

const EditProject = (props) => {
  const {
    states: {
      globalState: { formData, id },
    },
    functions: { headerGlobal, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext)
  const [openClearDialog, setOpenClearDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [compareSubType, setCompareSubType] = useState("")
  const { edit, setEdit } = props

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm()

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    openClearDialog,
    setOpenClearDialog,
    setFieldErrors,
    setCompareSubType,
  }

  const handlePut = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    // Validação não foi alterada, então continua igual
    const dynamicValidations = requiredProject(dataForm)
    const resultValidateForm = validateForm({
      specificValidations: dynamicValidations,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    })
    if (!resultValidateForm) {
      setIsSubmitting(false)
      return errorToast(asDefaultErrorAxios)
    }

    // Inicializa o FormData
    const formData = new FormData()

    // Adiciona cada valor de project_type individualmente
    if (dataForm.project_type && Array.isArray(dataForm.project_type)) {
      dataForm.project_type.forEach((projectTypeId) => {
        formData.append("project_type", projectTypeId) // Adiciona cada ID como um campo separado
      })
    }

    Object.keys(dataForm).forEach((key) => {
      if (key === "image") {
        // Verifica se 'image' é uma string, null ou undefined
        // e então define o valor como string vazia para enviar
        const isImageField = typeof dataForm[key] === "string" || dataForm[key] === null || dataForm[key] === undefined
        formData.append(key, isImageField ? "" : dataForm[key])
      } else if (key.startsWith("subproject_")) {
        // Para cada valor dentro do array de subprojetos, adiciona ao formData
        dataForm[key].forEach((subprojectId) => {
          formData.append("subproject", subprojectId)
        })
      } else if (
        !["typePeople", "customer_person_company", "customer_person_physical", "project_type", "image"].includes(key)
      ) {
        // Adiciona outros campos ao FormData, exceto os tratados separadamente e project_type que já foi tratado
        formData.append(key, dataForm[key])
      }
    })

    // Adiciona campos condicionais com base na seleção do usuário
    if (dataForm.typePeople === asCustomerPhysical) {
      // Se typePeople é asCustomerPhysical, adiciona customer_person_physical ao formData
      formData.append("customer_person_physical", dataForm.customer_person_physical)
    } else if (dataForm.typePeople === asCustomerCompany) {
      // Se não, e se typePeople é asCustomerCompany, adiciona customer_person_company ao formData
      formData.append("customer_person_company", dataForm.customer_person_company)
    }

    try {
      await axios.put(`${URL_PROJECT}${id}/`, formData, {
        headers: headerGlobal(),
      })
      sayAlteration()
      successToast(asDefaultAlteration)
      settingState(setEdit, !edit)
    } catch (error) {
      console.log("objeto que foi tentando enviar", formData)
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorPropertie,
        errorToast,
      })
      console.error("Erro ao tentar enviar", error)
    } finally {
      setIsSubmitting(false)
    }
  }

  //   useEffect(() => {
  //     const source = axios.CancelToken.source();

  //     const getInfo = async () => {
  //       try {
  //         const response = await axios.get(`${URL_PROJECT}${id}/`, {
  //           headers: headerGlobal(),
  //           cancelToken: source.token
  //         });
  //         const data = response.data;
  // console.log('data:', data)
  //         const type = data.customer.type === 'physical' ? asCustomerPhysical : asCustomerCompany;
  //         const person = data.customer.id
  //         const propertie = data.project_properties.id

  //         // Extrai os IDs dos tipos de projeto
  //         const projectTypeIds = data.project_projectype?.map((item) => item.id);

  //         // Prepara o estado inicial para subprojects
  //         const initialSubprojectsState = {};
  //         data.project_projectype.forEach((projectType) => {
  //           initialSubprojectsState[`subproject_${projectType.id}`] = projectType?.subprojects?.map(
  //             (subproject) => subproject.id
  //           );
  //         });

  //         setDataForm({
  //           ...data,
  //           customer_person_physical:person,
  //           customer_person_company:person,
  //           typePeople: type,
  //           properties: propertie,
  //           project_type: projectTypeIds,
  //           ...initialSubprojectsState // Adiciona os IDs dos subprojects sob chaves dinâmicas
  //         });
  //       } catch (error) {
  //         if (axios.isCancel(error)) {
  //           console.log('Request canceled.', error.message);
  //         } else {
  //           console.log('Error getInfo', error);
  //         }
  //       }
  //     };

  //     getInfo();

  //     return () => {
  //       source.cancel('Operation canceled by the user.');
  //     };
  //   }, []);

  useEffect(() => {
    const source = axios.CancelToken.source()

    const getInfo = async () => {
      try {
        const response = await axios.get(`${URL_PROJECT}${id}/`, {
          headers: headerGlobal(),
          cancelToken: source.token,
        })
        const data = response.data
        console.log("data:", data)

        const type = data.customer.type === "physical" ? asCustomerPhysical : asCustomerCompany
        const person = data.customer.id
        const propertie = data.project_properties ? data.project_properties.id : null

        // Extrai os IDs dos tipos de projeto
        const projectTypeIds = data.project_projectype?.map((item) => item.id)

        // Prepara o estado inicial para subprojects
        let initialSubprojectsState = {}
        data.project_projectype.forEach((projectType) => {
          // Inicializa cada tipo de projeto com um array vazio ou com IDs pré-existentes
          initialSubprojectsState[`subproject_${projectType.id}`] = []
        })

        data.project_subproject.forEach((subproject) => {
          const key = `subproject_${subproject.project_type}`
          // Garante que o array foi inicializado (isso deve ser sempre verdadeiro com a lógica acima)
          if (initialSubprojectsState[key] !== undefined) {
            initialSubprojectsState[key].push(subproject.id)
          }
        })

        setDataForm((prevFormData) => ({
          ...prevFormData,
          ...data,
          customer_person_physical: type === asCustomerPhysical ? person : undefined,
          customer_person_company: type === asCustomerCompany ? person : undefined,
          typePeople: type,
          properties: propertie,
          project_type: projectTypeIds,
          ...initialSubprojectsState,
        }))
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message)
        } else {
          console.log("Error getInfo", error)
        }
      }
    }

    getInfo()

    return () => {
      source.cancel("Operation canceled by the user.")
    }
  }, [])

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setEdit, !edit)}>
        <S.DivGap>
          <TitleModal text={"editar"} />
          <InputsProject {...propsList} />
        </S.DivGap>
        <L.DivGap>
          <ButtonRed onClick={() => changeValue(openClearDialog, setOpenClearDialog)} named={"limpar"} />
          <Button
            type={formData.inputDisable !== true ? asSubmit : undefined}
            named={isSubmitting ? asSending : asSend}
            onClick={(event) => {
              if (!isSubmitting) {
                handlePut(event)
              }
            }}
            disabled={isSubmitting}
          />
        </L.DivGap>
      </Formulary>
      <ClearDialog {...propsList} />
    </S.Main>
  )
}
export default EditProject
