import { CircularProgress } from "@mui/material"
import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { as100, as15, as9, asZipCodeError } from "../../../../../constants/constants/constAndRegex"
import { removeNonNumericCharacters, sortAlphabetically } from "../../../../../constants/functions/functions"
import { pickList } from "../../../../../constants/models/models"
import { URL_CITY_BY_ID, URL_ZIP_CODE } from "../../../../../constants/urls/urls"
import { GlobalContext } from "../../../../../global/GlobalContext"
import Input from "../../../Reuse/Input/Input/Input"
import InputFormats from "../../../Reuse/Input/InputFormats/InputFormats"
import InputPhone from "../../../Reuse/Input/InputPhone/InputPhone"
import InputSelect from "../../../Reuse/Input/InputSelect/InputSelect"
import * as S from "./styled"

const InputsClientCompany = (props) => {
  const {
    states: {
      globalState: { formData, stateList },
    },
    functions: { inputChange, errorToast, headerGlobal },
  } = useContext(GlobalContext)
  const [isLoading, setIsLoading] = useState(false)
  const [changeCount, setChangeCount] = useState(0)
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = props

  // em ordem alfabetica
  const sortedStateList = sortAlphabetically(stateList)

  // Todos os estados do brasil
  const statesUF = sortedStateList?.map((index) => ({
    value: index.sigla,
    label: index.nome,
  }))

  const fetchAndSetCityData = async (stateParam, updateField) => {
    setIsLoading(true)
    const url = URL_CITY_BY_ID.replace(":id", stateParam)
    try {
      const response = await axios.get(url)
      setDataForm((prevState) => ({
        ...prevState,
        [updateField]: response.data,
      }))
      setTimeout(() => {}, 500)
    } catch (error) {
      setTimeout(() => {}, 500)
      const errorMessage = error?.response?.data?.detail
      errorToast(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAndSetCityData(dataForm?.state, "cityList3")
  }, [dataForm?.state])

  const cityOptions3 = dataForm?.cityList3?.map((index) => ({
    value: index.nome,
    label: index.nome,
  }))

  const getZipCode = async () => {
    setIsLoading(true)
    const cep = removeNonNumericCharacters(dataForm.zip_code)
    const url = URL_ZIP_CODE.replace(":cep", cep)
    try {
      const response = await axios.get(url, { headers: headerGlobal() })
      const { localidade, logradouro, bairro, uf } = response.data
      setDataForm((prevState) => ({
        ...prevState,
        street: logradouro,
        neighborhood: bairro,
        city: localidade,
        state: uf,
      }))
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    } catch (error) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
      errorToast(asZipCodeError)
    }
  }

  useEffect(() => {
    dataForm.zip_code && setChangeCount((prevCount) => prevCount + 1)
  }, [dataForm.zip_code])

  useEffect(() => {
    dataForm.zip_code?.length === 8 && changeCount >= 3 && getZipCode() && setChangeCount(0)
  }, [dataForm.zip_code, changeCount])

  return (
    <>
      <S.LoadingOverlay isLoading={isLoading}>
        <CircularProgress />
      </S.LoadingOverlay>
      <S.Main>
        <S.Custon10>
          <InputSelect
            label={"Tipo de Pessoa"}
            {...(formData.noEdit !== true ? { name: "verifyType" } : {})}
            value={formData.verifyType || ""}
            onChange={inputChange}
            options={pickList.TYPE_ASS}
          />
        </S.Custon10>
        <S.H2>pessoa jurídica</S.H2>
        <S.Box>
          <Input
            label={"Razão Social"}
            {...(formData.inputDisable !== true ? { name: "social_reason" } : {})}
            value={dataForm.social_reason || ""}
            onChange={inputForm}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            length={as100}
            error={fieldErrors.social_reason || null}
          />
          <S.Custon15rem>
            <InputFormats
              documentType={"cnpj"}
              label={"CNPJ"}
              {...(formData.inputDisable !== true ? { name: "cnpj" } : {})}
              value={dataForm.cnpj || ""}
              onChange={inputForm}
              number
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              error={fieldErrors.cnpj || null}
            />
          </S.Custon15rem>
        </S.Box>
        <S.Box>
          <Input
            label={"E-mail"}
            {...(formData.inputDisable !== true ? { name: "email" } : {})}
            value={dataForm.email || ""}
            onChange={inputForm}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            length={as100}
            error={fieldErrors.email || null}
          />
          <S.Custon15rem>
            <InputPhone
              label={"Telefone"}
              {...(formData.inputDisable !== true ? { name: "telephone" } : {})}
              value={dataForm.telephone || ""}
              onChange={inputForm}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              length={as15}
              error={fieldErrors.telephone || null}
            />
          </S.Custon15rem>
        </S.Box>

        <S.Box>
          <S.Custon15rem>
            <InputFormats
              documentType={"cep"}
              label={"CEP"}
              {...(formData.inputDisable !== true ? { name: "zip_code" } : {})}
              value={dataForm.zip_code || ""}
              onChange={inputForm}
              regexMessage={regexMessage}
              conditionMessage={conditionMessage}
              error={fieldErrors.zip_code || null}
            />
          </S.Custon15rem>

          <Input
            label={"Logradouro"}
            {...(formData.inputDisable !== true ? { name: "street" } : {})}
            value={dataForm.street || ""}
            onChange={inputForm}
            error={fieldErrors.street || null}
            regexMessage={regexMessage}
            length={as100}
            conditionMessage={conditionMessage}
          />
        </S.Box>
        <S.Box>
          <Input
            label={"Bairro"}
            {...(formData.inputDisable !== true ? { name: "neighborhood" } : {})}
            value={dataForm.neighborhood || ""}
            onChange={inputForm}
            error={fieldErrors.neighborhood || null}
            regexMessage={regexMessage}
            length={as100}
            conditionMessage={conditionMessage}
          />

          <InputSelect
            label={"UF"}
            {...(formData.inputDisable !== true ? { name: "state" } : {})}
            value={dataForm.state || ""}
            onChange={(selectedOption) => {
              inputForm(selectedOption)
              setDataForm((prevDataForm) => ({
                ...prevDataForm,
                city: "",
              }))
            }}
            options={statesUF}
            error={fieldErrors.state || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />

          <InputSelect
            label={"Cidade"}
            {...(formData.inputDisable !== true ? { name: "city" } : {})}
            value={dataForm.city || ""}
            onChange={inputForm}
            options={cityOptions3}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            error={fieldErrors.city || null}
          />
          <Input
            label={"Nº"}
            {...(formData.inputDisable !== true ? { name: "number" } : {})}
            value={dataForm.number || ""}
            onChange={inputForm}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            length={as9}
            error={fieldErrors.number || null}
          />
        </S.Box>
        <Input
          label={"Complemento"}
          {...(formData.inputDisable !== true ? { name: "complement" } : {})}
          value={dataForm.complement || ""}
          onChange={inputForm}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={as100}
          error={fieldErrors.complement || null}
        />
      </S.Main>
    </>
  )
}

export default InputsClientCompany
