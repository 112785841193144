import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  as10,
  as100,
  as30,
  as50,
  as9,
  asErrorInfo,
  stateListTeste,
} from "../../../../constants/constants/constAndRegex";
import {
  removeNonNumericCharacters,
  sortAlphabetically,
} from "../../../../constants/functions/functions";
import { pickList } from "../../../../constants/models/models";
import {
  URL_CITY_BY_ID,
  URL_PROPERTIE,
  URL_ZIP_CODE,
} from "../../../../constants/urls/urls";
import { GlobalContext } from "../../../../global/GlobalContext";
import Input from "../../Reuse/Input/Input/Input";
import InputFile from "../../Reuse/Input/InputFile/InputFile";
import InputFormats from "../../Reuse/Input/InputFormats/InputFormats";
import InputSelect from "../../Reuse/Input/InputSelect/InputSelect";
import * as S from "./styled";

const InputsPropertie = (props) => {
  const {
    states: {
      globalState: { formData },
    },

    functions: { errorToast },
  } = useContext(GlobalContext);
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = props;

  const [changeCount, setChangeCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const sortedStateList = sortAlphabetically(stateListTeste);

  const statesUF = sortedStateList?.map((index) => ({
    value: index.sigla,
    label: index.nome,
  }));

  const fetchAndSetCityData = async (stateParam, updateField) => {
    setIsLoading(true);
    const url = URL_CITY_BY_ID.replace(":id", stateParam);
    try {
      const response = await axios.get(url);
      setDataForm((prevState) => ({
        ...prevState,
        [updateField]: response.data,
      }));
      setTimeout(() => {}, 500);
    } catch (error) {
      setTimeout(() => {}, 500);
      errorToast(asErrorInfo);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataForm?.state) {
      fetchAndSetCityData(dataForm?.state, "cityList");
    }
  }, [dataForm?.state]);

  const cityOptions = dataForm?.cityList?.map((index) => ({
    value: index.nome,
    label: index.nome,
  }));

  const getZipCode = async () => {
    setIsLoading(true);
    const cep = removeNonNumericCharacters(dataForm.zip_code);
    const url = URL_ZIP_CODE.replace(":cep", cep);
    try {
      const response = await axios.get(url);
      const { localidade, logradouro, bairro, uf } = response.data;
      setDataForm((prevState) => ({
        ...prevState,
        street: logradouro,
        neighborhood: bairro,
        city: localidade,
        state: uf,
      }));
      setTimeout(() => {}, 500);
    } catch (error) {
      setTimeout(() => {}, 500);
      errorToast(asErrorInfo);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataForm?.zip_code) {
      setChangeCount((prevCount) => prevCount + 1);
    }
  }, [dataForm?.zip_code]);

  useEffect(() => {
    if (dataForm?.zip_code?.length === 8 && changeCount >= 3) {
      getZipCode();
      setChangeCount(0);
    }
  }, [dataForm?.zip_code, changeCount]);

  return (
    <S.Main>
      <S.LoadingOverlay isLoading={isLoading}>
        <CircularProgress />
      </S.LoadingOverlay>
      <S.H2>imóvel</S.H2>
      <S.Box>
        <Input
          label={"Proprietário(a)"}
          {...(formData.inputDisable !== true ? { name: "owner" } : {})}
          value={dataForm.owner || ""}
          onChange={inputForm}
          error={fieldErrors.owner || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={as50}
        />
        <InputFile
          name="image"
          {...(formData.inputDisable !== true ? { type: "file" } : {})}
          error={fieldErrors.image || null}
          dataForm={dataForm}
          setDataForm={setDataForm}
          title={"imóvel"}
          url={URL_PROPERTIE}
        />
      </S.Box>

      <S.Box>
        <Input
          label={"Registro em Cartório"}
          {...(formData.inputDisable !== true ? { name: "registration" } : {})}
          value={dataForm.registration || ""}
          onChange={inputForm}
          error={fieldErrors.registration || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={as50}
        />
        <InputFile
          name="registration_pdf"
          {...(formData.inputDisable !== true ? { type: "file" } : {})}
          error={fieldErrors.registration_pdf || null}
          dataForm={dataForm}
          setDataForm={setDataForm}
          title={"Registro"}
          url={URL_PROPERTIE}
        />

        <Input
          label={"IPTU/ITR"}
          {...(formData.inputDisable !== true ? { name: "iptu" } : {})}
          value={dataForm.iptu || ""}
          onChange={inputForm}
          error={fieldErrors.iptu || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={as30}
        />
        <InputFile
          name="iptu_pdf"
          {...(formData.inputDisable !== true ? { type: "file" } : {})}
          error={fieldErrors.iptu_pdf || null}
          dataForm={dataForm}
          setDataForm={setDataForm}
          title={"IPTU/ITR"}
          url={URL_PROPERTIE}
        />
      </S.Box>

      <S.Box>
        <Input
          label={"Área (m²)"}
          {...(formData.inputDisable !== true ? { name: "area" } : {})}
          value={dataForm.area || ""}
          onChange={inputForm}
          error={fieldErrors.area || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={as10}
        />

        <InputSelect
          label={"Status"}
          {...(formData.inputDisable !== true ? { name: "status" } : {})}
          value={dataForm.status || ""}
          options={pickList.TYPE_COMPLET_OR_NO}
          onChange={inputForm}
          error={fieldErrors.status || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      </S.Box>

      <S.Box>
        <S.Custon15rem>
          <InputFormats
            documentType={"cep"}
            label={"CEP"}
            {...(formData.inputDisable !== true ? { name: "zip_code" } : {})}
            value={dataForm.zip_code || ""}
            onChange={inputForm}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            error={fieldErrors.zip_code || null}
          />
        </S.Custon15rem>
        <Input
          label={"Logradouro"}
          {...(formData.inputDisable !== true ? { name: "street" } : {})}
          value={dataForm.street || ""}
          onChange={inputForm}
          error={fieldErrors.street || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={as100}
        />
      </S.Box>

      <S.Box>
        <InputSelect
          label={"UF"}
          {...(formData.inputDisable !== true ? { name: "state" } : {})}
          value={dataForm?.state || ""}
          options={statesUF}
          onChange={(selectedOption) => {
            inputForm(selectedOption);
            setDataForm((prevDataForm) => ({
              ...prevDataForm,
              city: "",
            }));
          }}
          error={fieldErrors.state || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputSelect
          label={"Cidade"}
          {...(formData.inputDisable !== true ? { name: "city" } : {})}
          value={dataForm?.city || ""}
          options={cityOptions}
          onChange={inputForm}
          error={fieldErrors.city || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <Input
          label={"Bairro"}
          {...(formData.inputDisable !== true ? { name: "neighborhood" } : {})}
          value={dataForm.neighborhood || ""}
          onChange={inputForm}
          error={fieldErrors.neighborhood || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={as100}
        />
        <Input
          label={"Nº"}
          {...(formData.inputDisable !== true ? { name: "number" } : {})}
          value={dataForm.number || ""}
          onChange={inputForm}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          error={fieldErrors.number || null}
          length={as9}
        />
      </S.Box>

      <S.Box>
        <Input
          label={"Complemento"}
          {...(formData.inputDisable !== true ? { name: "complement" } : {})}
          value={dataForm.complement || ""}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          error={fieldErrors.complement || null}
          onChange={inputForm}
          length={as100}
        />
      </S.Box>
    </S.Main>
  );
};

export default InputsPropertie;