import axios from "axios"
import { URL_PROJECT_TYPE } from "../constants/urls/urls"
import InputCheckboxGroup from "./common/Reuse/Input/InputCheckboxGroup/InputCheckboxGroup"
import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../global/GlobalContext"

export function ProjectInfo({ id, error, regexMessage, conditionMessage, setDataForm, dataForm }) {
  const {
    states: {
      globalState: { formData },
    },

    functions: { headerGlobal },
  } = useContext(GlobalContext)

  const [project, setProject] = useState()

  const getProjectType = async () => {
    try {
      const valueUrlSearch = `${URL_PROJECT_TYPE}${id}`
      const response = await axios.get(valueUrlSearch, {
        headers: headerGlobal(),
      })
      setProject(response.data)
    } catch (error) {
      console.error("Erro:", error)
    }
  }

  const handleDelete = (optionToDelete) => {
    const newValue = dataForm.project_type?.filter((optionValue) => optionValue !== optionToDelete)
    setDataForm({
      ...dataForm,
      project_type: newValue,
    })
  }

  useEffect(() => {
    getProjectType()
  }, [])

  if (!project) {
    return null
  }

  const formattedProject = project.projectType_subprojects.map((subProject) => {
    return { ...subProject, label: subProject.subproject_name, value: subProject.id }
  })

  return (
    <InputCheckboxGroup
      label={`Projeto de ${project.project_type}`}
      {...(formData.inputDisable !== true ? { disabled: false } : { disabled: true })}
      saveName={`subproject_${id}`}
      options={formattedProject}
      error={error}
      regexMessage={regexMessage}
      conditionMessage={conditionMessage}
      setDataForm={setDataForm}
      dataForm={dataForm}
      deleteOption={() => handleDelete(id)}
    />
  )
}
