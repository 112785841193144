import axios from 'axios';
import React, { useContext, useState } from 'react';
import {
  asCadastrar,
  asClean,
  asDefaultErrorAxios,
  asDefaultRegister,
  asSend,
  asSending,
  asSubmit
} from '../../../../constants/constants/constAndRegex';
import { errorPhysical } from '../../../../constants/constants/constError';
import { DATA_PHYSICAL, DATA_SPOUSE } from '../../../../constants/constants/dataToSend';
import { requiredPhysical, requiredSpouse } from '../../../../constants/constants/requiredConst';
import { handleErrors, settingState, validateForm } from '../../../../constants/functions/functions';
import { URL_PHYSICAL } from '../../../../constants/urls/urls';
import { GlobalContext } from '../../../../global/GlobalContext';
import useForm from '../../../../hooks/useForm';
import ClearDialog from '../../../common/Dialog/ClearDialog/ClearDialog';
import Formulary from '../../../common/Formulary/Formulary/Formulary';
import InputsClientPhysical from '../../../common/Formulary/InputsClient/InputsClientPhysical/InputsClientPhysical';
import { Button, ButtonRed } from '../../../common/Reuse/Button/Buttons/Buttons';
import TitleModal from '../../../common/Reuse/TitleModal/TitleModal';
import * as L from './styled';

const RegisterClientPhysical = (props) => {
  const {
    states: {
      globalState: { formData }
    },
    functions: { headerGlobal, successToast, errorToast, sayAlteration }
  } = useContext(GlobalContext);
  const [clear, setClear] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, setRegister } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    setFieldErrors,
    clear,
    setClear
  };

  const handlePost = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    
    const specificValidations = [...requiredPhysical, ...requiredSpouse];

    const resultValidateForm = validateForm({
      specificValidations,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    const dataToSend = DATA_PHYSICAL(dataForm);
    const spouseData = DATA_SPOUSE(dataForm);
    const combinedData = {
      ...dataToSend,
      ...spouseData
    };

    try {
      await axios.post(URL_PHYSICAL, combinedData, {
        headers: headerGlobal()
      });
      sayAlteration();
      successToast(asDefaultRegister);
      settingState(setRegister, !register);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorPhysical,
        errorToast
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <L.Main>
        <Formulary onClick={() => settingState(setRegister, !register)}>
          <L.BoxSelect>
            <TitleModal text={asCadastrar} />
          </L.BoxSelect>
          <L.Container>
            <InputsClientPhysical {...propsList} />
          </L.Container>
          <L.DivGap>
            <ButtonRed
              onClick={formData.inputDisable !== true ? () => settingState(setClear, !clear) : undefined}
              named={asClean}
            />
            <Button
              type={formData.inputDisable !== true ? asSubmit : undefined}
              named={isSubmitting ? asSending : asSend}
              onClick={(event) => {
                if (!isSubmitting) {
                  handlePost(event);
                }
              }}
              disabled={isSubmitting}
            />
          </L.DivGap>
        </Formulary>
      </L.Main>

      {/* diálogo de limpar formulário */}
      <ClearDialog {...propsList} />
    </>
  );
};
export default RegisterClientPhysical;
