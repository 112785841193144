import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

export const FlexBox = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;

export const FixedSize = styled.div`
  width: 12rem;
`;

export const GridCont = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 3fr 2fr 1fr 1fr;
  gap: 1rem;
`;

export const MarginBottom = styled.div`
  margin-bottom: 1rem;
  display: flex;
  width: 12rem;
  align-items: center;
`;

export const Box = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const Custon10 = styled.div`
  display: flex;
  min-width: 10rem;
  margin-top: 1rem;
`;

export const Custon15 = styled.div`
  display: flex;
  min-width: 13rem;
`;

export const LoadingOverlay = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  justify-content: center;
  align-items: center;
  z-index: 999;
`;
