import {
  asCustomerCompany,
  asCustomerPhysical,
  asDescriptionRegexEmail,
  asDescriptionRegexLetter,
  asDescriptionRegexPassword,
  asDescriptionGeneral,
  asMarried,
  asOther,
  asRegexEmail,
  asRegexLetter,
  asRegexPassword,
  check_marital_status,
} from "./constAndRegex"

// pessoa física
export const requiredPhysical = [
  {
    field: "name",
    check: (dataForm) => !dataForm.name,
  },
  {
    field: "birth_date",
    check: (dataForm) => {
      const birthDate = new Date(dataForm.birth_date)
      const today = new Date().setHours(0, 0, 0, 0) // Meia-noite de hoje

      return !dataForm.birth_date || birthDate >= today
    },
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "gender",
    check: (dataForm) => !dataForm.gender,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "nationality",
    check: (dataForm) => !dataForm.nationality,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "affiliation",
    check: (dataForm) => !dataForm.affiliation,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "profession",
    check: (dataForm) => !dataForm.profession,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "telephone",
    check: (dataForm) => !dataForm.telephone || dataForm.telephone?.length < 10,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "email",
    check: (dataForm) => !dataForm.email,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "cpf",
    check: (dataForm) => (!dataForm.cpf && dataForm.cpf_file) || !dataForm.cpf,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "rg",
    check: (dataForm) => !dataForm.rg && dataForm.rg_sender,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "rg_sender",
    check: (dataForm) => !dataForm.rg_sender && dataForm.rg,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "cnh",
    check: (dataForm) => !dataForm.cnh && dataForm.cnh_sender,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "cnh_sender",
    check: (dataForm) => !dataForm.cnh_sender && dataForm.cnh,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "type_certificate",
    check: (dataForm) => !dataForm.type_certificate && dataForm.certificate,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "certificate",
    check: (dataForm) => !dataForm.certificate && dataForm.type_certificate,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "zip_code",
    check: (dataForm) => !dataForm.zip_code || dataForm.zip_code?.length < 8,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "street",
    check: (dataForm) => !dataForm.street,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "neighborhood",
    check: (dataForm) => !dataForm.neighborhood,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "state",
    check: (dataForm) => !dataForm.state,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "city",
    check: (dataForm) => !dataForm.city,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "number",
    check: (dataForm) => !dataForm.number,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "marital_status",
    check: (dataForm) => !dataForm.marital_status,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "others_marital_status",
    check: (dataForm) => dataForm.marital_status === asOther && !dataForm.others_marital_status,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "marriage_regime",
    check: (dataForm) => dataForm.marital_status === asMarried && !dataForm.marriage_regime,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "others_marriage_regime",
    check: (dataForm) => dataForm.marriage_regime === asOther && !dataForm.others_marriage_regime,
    conditionMessage: asDescriptionGeneral,
  },
]

// empresa
export const requiredCompany = [
  {
    field: "social_reason",
    check: (dataForm) => !dataForm.social_reason,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "cnpj",
    check: (dataForm) => !dataForm.cnpj,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "email",
    check: (dataForm) => !dataForm.email,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "telephone",
    check: (dataForm) => !dataForm.telephone || dataForm.telephone?.length < 10,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "zip_code",
    check: (dataForm) => !dataForm.zip_code || dataForm.zip_code?.length < 8,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "street",
    check: (dataForm) => !dataForm.street,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "neighborhood",
    check: (dataForm) => !dataForm.neighborhood,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "state",
    check: (dataForm) => !dataForm.state,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "number",
    check: (dataForm) => !dataForm.number,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "city",
    check: (dataForm) => !dataForm.city,
    conditionMessage: asDescriptionGeneral,
  },
]

// representante da empresa
export const requiredClientRepresentative = [
  {
    field: "legal_representative",
    check: (dataForm) => !dataForm.legal_representative,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "birth_date",
    check: (dataForm) => {
      const birthDate = new Date(dataForm.birth_date)
      const today = new Date().setHours(0, 0, 0, 0) // Meia-noite de hoje

      return birthDate >= today
    },
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "gender",
    check: (dataForm) => !dataForm.gender,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "nationality",
    check: (dataForm) => !dataForm.nationality,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "profession",
    check: (dataForm) => !dataForm.profession,
    conditionMessage: asDescriptionGeneral,
  },

  {
    field: "cpf",
    check: (dataForm) => !dataForm.cpf,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "rg",
    check: (dataForm) => !dataForm.rg && dataForm.rg_sender,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "rg_sender",
    check: (dataForm) => dataForm.rg && !dataForm.rg_sender,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "cnh",
    check: (dataForm) => !dataForm.cnh && dataForm.cnh_sender,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "cnh_sender",
    check: (dataForm) => !dataForm.cnh_sender && dataForm.cnh,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "type_certificate",
    check: (dataForm) => !dataForm.type_certificate && dataForm.certificate,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "certificate",
    check: (dataForm) => !dataForm.certificate && dataForm.type_certificate,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "marital_status",
    check: (dataForm) => !dataForm.marital_status,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "others_marital_status",
    check: (dataForm) => dataForm.marital_status === asOther && !dataForm.others_marital_status,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "marriage_regime",
    check: (dataForm) => dataForm.marital_status === asMarried && !dataForm.marriage_regime,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "others_marriage_regime",
    check: (dataForm) => dataForm.marriage_regime === asOther && !dataForm.others_marriage_regime,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "qualification",
    check: (dataForm) => !dataForm.qualification,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "others_qualification",
    check: (dataForm) => dataForm.qualification === asOther && !dataForm.others_qualification,
    conditionMessage: asDescriptionGeneral,
  },
]

// esposa
export const requiredSpouse = [
  {
    field: "spouse",
    check: (dataForm) => check_marital_status(dataForm) && !dataForm.spouse,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_birth_date",
    check: (dataForm) => check_marital_status(dataForm) && !dataForm.spouse_birth_date,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_nationality",
    check: (dataForm) => check_marital_status(dataForm) && !dataForm.spouse_nationality,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_affiliation",
    check: (dataForm) => check_marital_status(dataForm) && !dataForm.spouse_affiliation,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_profession",
    check: (dataForm) => check_marital_status(dataForm) && !dataForm.spouse_profession,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_telephone",
    check: (dataForm) => {
      const isMarried = check_marital_status(dataForm) // Verifica se o usuário é casado
      const hasSpouseTelephone = dataForm.spouse_telephone // Verifica se o telefone do cônjuge foi fornecido
      const isValidLength = hasSpouseTelephone && dataForm.spouse_telephone.length >= 10 // Garante que o comprimento é de pelo menos 11 caracteres
      return (isMarried && !hasSpouseTelephone) || (hasSpouseTelephone && !isValidLength)
    },
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_cpf",
    check: (dataForm) =>
      (check_marital_status(dataForm) && !dataForm.spouse_cpf && dataForm.spouse_cpf_file) ||
      (check_marital_status(dataForm) && !dataForm.spouse_cpf),
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_wedding_certificate",
    check: (dataForm) =>
      check_marital_status(dataForm) &&
      !dataForm.spouse_wedding_certificate &&
      dataForm.spouse_wedding_certificate_file,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_rg",
    check: (dataForm) =>
      (check_marital_status(dataForm) && !dataForm.spouse_rg && dataForm.spouse_rg_file) ||
      (check_marital_status(dataForm) && !dataForm.spouse_rg && dataForm.spouse_rg_sender),
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_rg_sender",
    check: (dataForm) =>
      (check_marital_status(dataForm) && !dataForm.spouse_rg_sender && dataForm.spouse_rg_file) ||
      (check_marital_status(dataForm) && dataForm.spouse_rg && !dataForm.spouse_rg_sender),
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_cnh",
    check: (dataForm) =>
      (check_marital_status(dataForm) && !dataForm.spouse_cnh && dataForm.spouse_cnh_file) ||
      (check_marital_status(dataForm) && !dataForm.spouse_cnh && dataForm.spouse_cnh_sender),
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "spouse_cnh_sender",
    check: (dataForm) =>
      (check_marital_status(dataForm) && !dataForm.spouse_cnh_sender && dataForm.spouse_cnh_file) ||
      (check_marital_status(dataForm) && dataForm.spouse_cnh && !dataForm.spouse_cnh_sender),
    conditionMessage: asDescriptionGeneral,
  },
]

// procuração
export const requiredLetterOfAttorney = [
  {
    field: "verifySelect",
    check: (dataForm) => !dataForm.verifySelect,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "bestowed",
    check: (dataForm) => !(dataForm.bestowed && dataForm.bestowed?.length > 0),
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "document_tipology",
    check: (dataForm) => !dataForm.document_tipology,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "quality_powers",
    check: (dataForm) => !dataForm.quality_powers,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "power",
    check: (dataForm) => !dataForm.power,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "process_specification",
    check: (dataForm) => !dataForm.process_specification,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "city",
    check: (dataForm) => !dataForm.city,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "date",
    check: (dataForm) => !dataForm.date,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "date",
    check: (dataForm) => dataForm.date > dataForm.validate,
    conditionMessage: "Data de criação não pode ser mais recente do que data de validade",
  },
  {
    field: "validate",
    check: (dataForm) => !dataForm.validate,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "validate",
    check: (dataForm) => dataForm.date > dataForm.validate,
    conditionMessage: "Data de validade precisa ser mais recente do que data de criação",
  },
  {
    field: "active",
    check: (dataForm) => !dataForm.active,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "appointment",
    check: (dataForm) => !dataForm.appointment,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "divisions_involved",
    check: (dataForm) => !(dataForm.divisions_involved && dataForm.divisions_involved?.length > 0),
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "grants",
    check: (dataForm) => !(dataForm.grants && dataForm.grants?.length > 0),
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "others_grants",
    check: (dataForm) => dataForm.grants?.includes(asOther) && !dataForm.others_grants,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "customer_person_physical",
    check: (dataForm) => dataForm.verifySelect === asCustomerPhysical && !dataForm.customer_person_physical,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "customer_person_company",
    check: (dataForm) => dataForm.verifySelect === asCustomerCompany && !dataForm.customer_person_company,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "others_power",
    check: (dataForm) => dataForm.power === asOther && !dataForm.others_power,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "appointment",
    check: (dataForm) => dataForm.appointment?.length === 0,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "others_divisions_involved",
    check: (dataForm) => dataForm.divisions_involved?.includes(asOther) && !dataForm.others_divisions_involved,
    conditionMessage: asDescriptionGeneral,
  },
]

export const requiredLogin = [
  {
    field: "email",
    check: (dataForm) => !dataForm.email,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "password",
    check: (dataForm) => !dataForm.password,
    conditionMessage: asDescriptionGeneral,
  },
]

export const requiredName = [
  {
    field: "name",
    check: (dataForm) => !dataForm.name,
    conditionMessage: asDescriptionGeneral,
    regex: asRegexLetter,
    regexMessage: asDescriptionRegexLetter,
  },
]

export const requiredPassword = [
  {
    field: "password",
    check: (dataForm) => !dataForm.password,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "password",
    check: (dataForm) => dataForm.password === dataForm.new_password,
    conditionMessage: "Senha não pode ser igual a nova senha",
  },
  {
    field: "password",
    check: (dataForm) => dataForm.password?.length < 8,
    conditionMessage: "Precisa ter no mínimo 8 caracteres",
  },
  {
    field: "new_password",
    check: (dataForm) => !dataForm.new_password,
    conditionMessage: asDescriptionGeneral,
    regex: asRegexPassword,
    regexMessage: asDescriptionRegexPassword,
  },
  {
    field: "new_password",
    check: (dataForm) => dataForm.new_password?.length < 8,
    conditionMessage: "Precisa ter no mínimo 8 caracteres",
  },
  {
    field: "new_password",
    check: (dataForm) => dataForm.password === dataForm.new_password,
    conditionMessage: "Senha não pode ser igual a nova senha",
  },
  {
    field: "new_password",
    check: (dataForm) => dataForm.new_password !== dataForm.confirm_new_password,
    conditionMessage: "Nova senha e confirmação estão diferentes",
  },
  {
    field: "confirm_new_password",
    check: (dataForm) => !dataForm.confirm_new_password,
    conditionMessage: asDescriptionGeneral,
    regex: asRegexPassword,
    regexMessage: asDescriptionRegexPassword,
  },
  {
    field: "confirm_new_password",
    check: (dataForm) => !dataForm.confirm_new_password,
    conditionMessage: "Precisa ter no mínimo 8 caracteres",
  },
  {
    field: "confirm_new_password",
    check: (dataForm) => dataForm.password === dataForm.confirm_new_password,
    conditionMessage: "Senha não pode ser igual a confirmação de nova senha",
  },
  {
    field: "confirm_new_password",
    check: (dataForm) => dataForm.confirm_new_password !== dataForm.new_password,
    conditionMessage: "Nova senha e confirmação estão diferentes",
  },
]

export const requiredRegister = [
  {
    field: "name",
    check: (dataForm) => !dataForm.name,
    conditionMessage: asDescriptionGeneral,
    regex: asRegexLetter,
    regexMessage: asDescriptionRegexLetter,
  },
  {
    field: "email",
    check: (dataForm) => !dataForm.email,
    conditionMessage: asDescriptionGeneral,
    regex: asRegexEmail,
    regexMessage: asDescriptionRegexEmail,
  },
  {
    field: "password",
    check: (dataForm) => !dataForm.password,
    conditionMessage: asDescriptionGeneral,
    regex: asRegexPassword,
    regexMessage: asDescriptionRegexPassword,
  },
]

export const requiredRecovery = [
  {
    field: "email",
    check: (dataForm) => !dataForm.email,
    conditionMessage: asDescriptionGeneral,
    regex: asRegexEmail,
    regexMessage: asDescriptionRegexEmail,
  },
]

export const requiredPropertie = [
  {
    field: "owner",
    check: (dataForm) => !dataForm.owner && dataForm.status !== "pendente",
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "area",
    check: (dataForm) => !dataForm.area && dataForm.status !== "pendente",
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "registration",
    check: (dataForm) => !dataForm.registration && dataForm.status !== "pendente",
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "iptu",
    check: (dataForm) => !dataForm.iptu && dataForm.status !== "pendente",
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "zip_code",
    check: (dataForm) => dataForm.status !== "pendente" && (!dataForm.zip_code || dataForm.zip_code?.length < 8),
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "status",
    check: (dataForm) => !dataForm.status,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "street",
    check: (dataForm) => !dataForm.street && dataForm.status !== "pendente",
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "neighborhood",
    check: (dataForm) => !dataForm.neighborhood && dataForm.status !== "pendente",
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "city",
    check: (dataForm) => !dataForm.city && dataForm.status !== "pendente",
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "state",
    check: (dataForm) => !dataForm.state && dataForm.status !== "pendente",
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "number",
    check: (dataForm) => !dataForm.number && dataForm.status !== "pendente",
    conditionMessage: asDescriptionGeneral,
  },
]

export const requiredProject = (dataForm) => {
  const baseValidations = [
    {
      field: "properties",
      check: (dataForm) => !dataForm.properties,
      conditionMessage: asDescriptionGeneral,
    },
    {
      field: "typePeople",
      check: (dataForm) => !dataForm.typePeople,
      conditionMessage: asDescriptionGeneral,
    },
    {
      field: "project",
      check: (dataForm) => !dataForm.project,
      conditionMessage: asDescriptionGeneral,
    },
    {
      field: "project_type",
      check: (dataForm) => !dataForm.project_type || dataForm.project_type?.length <= 0,
      conditionMessage: asDescriptionGeneral,
    },
    {
      field: "customer_person_physical",
      check: (dataForm) => dataForm.typePeople === asCustomerPhysical && !dataForm.customer_person_physical,
      conditionMessage: asDescriptionGeneral,
    },
    {
      field: "customer_person_company",
      check: (dataForm) => dataForm.typePeople === asCustomerCompany && !dataForm.customer_person_company,
      conditionMessage: asDescriptionGeneral,
    },
    {
      field: "start_date",
      check: (dataForm) => !dataForm.start_date,
      conditionMessage: asDescriptionGeneral,
    },
    {
      field: "start_date",
      check: (dataForm) => dataForm.start_date > dataForm.final_date,
      conditionMessage: "Data de criação não pode ser mais recente do que data de validade",
    },
    {
      field: "final_date",
      check: (dataForm) => !dataForm.final_date,
      conditionMessage: asDescriptionGeneral,
    },
    {
      field: "final_date",
      check: (dataForm) => dataForm.start_date > dataForm.final_date,
      conditionMessage: "Data de validade precisa ser mais recente do que data de criação",
    },
  ]

  return baseValidations
}

export const requiredProjectType = [
  {
    field: "project_type",
    check: (dataForm) => !dataForm.project_type,
    conditionMessage: asDescriptionGeneral,
  },
]

export const requiredSubProjectType = [
  {
    field: "subproject_name",
    check: (dataForm) => !dataForm.subproject_name,
    conditionMessage: asDescriptionGeneral,
  },
]

export const requiredCondition = [
  {
    field: "term_condition",
    check: (dataForm) => !dataForm.term_condition,
    conditionMessage: asDescriptionGeneral,
  },
]

export const requiredConditionType = [
  {
    field: "clauses",
    check: (dataForm) => !dataForm.clauses,
    conditionMessage: asDescriptionGeneral,
  },
]

export const requiredPropost = [
  {
    field: "preliminary_study",
    check: (dataForm) => !dataForm.preliminary_study,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "enforcement_regime",
    check: (dataForm) => !dataForm.enforcement_regime,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "project",
    check: (dataForm) => !dataForm.project,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "term_condition",
    check: (dataForm) => !dataForm.term_condition,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "term_condition_type",
    check: (dataForm) => !dataForm.term_condition_type,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "start_date",
    check: (dataForm) => !dataForm.start_date,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "start_date",
    check: (dataForm) => dataForm.start_date > dataForm.final_date,
    conditionMessage: "Data de criação não pode ser mais recente do que data de validade",
  },
  {
    field: "final_date",
    check: (dataForm) => !dataForm.final_date,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "final_date",
    check: (dataForm) => dataForm.start_date > dataForm.final_date,
    conditionMessage: "Data de validade precisa ser mais recente do que data de criação",
  },
  {
    field: "value",
    check: (dataForm) => !dataForm.value,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "discount",
    check: (dataForm) => !dataForm.discount,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "payment_method",
    check: (dataForm) => !dataForm.payment_method,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "payment_method",
    check: (dataForm) => !dataForm.payment_method,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "installments",
    check: (dataForm) => dataForm.payment_method === "parcelado" && !dataForm.installments,
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "down_payment",
    check: (dataForm) => {
      if (dataForm.payment_method === "parcelado" && !dataForm.down_payment) {
        return true // Se o método de pagamento for parcelado e o pagamento inicial estiver ausente, a regra é acionada
      } else if (dataForm.down_payment) {
        const discountedValue = dataForm.value * (1 - dataForm.discount / 100) // Calcula o valor com desconto
        return dataForm.down_payment > discountedValue // Retorna verdadeiro se o pagamento inicial for maior que o valor com desconto
      }
      return false // Retorna falso se todas as condições anteriores falharem
    },
    conditionMessage: asDescriptionGeneral,
  },
  {
    field: "progress",
    check: (dataForm) => !dataForm.progress,
    conditionMessage: asDescriptionGeneral,
  },
]
