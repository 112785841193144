import styled from "styled-components";

export const Container = styled.div``;

export const Div = styled.div`
  display: flex;
  width: 12rem;
  background-color: #0a7377;
  border-radius: 0.3rem;
  border: 1px solid #0a7377;
  cursor: pointer;
  :hover {
    color: black;
    border-radius: 0.3rem;
    backdrop-filter: blur(13.5px);
    background-color: #ffff;
    -webkit-backdrop-filter: blur(13.5px);
  }
`;

export const Img = styled.img`
  height: 1.5rem;
  border-radius: 3px;
  margin-left: 1rem;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #ffffff;
  height: 2.5rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  gap: 1rem;
  :hover {
    color: black;
    background-color: #cac7c7;
    border-radius: 0.5rem;
    background-color: #ffff;
    backdrop-filter: blur(13.5px);
    -webkit-backdrop-filter: blur(13.5px);
  }
`;

export const SmallText = styled.p`
  font-style: italic;
  margin: 0;
  width: 11rem;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
