import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asCustomerCompany,
  asCustomerPhysical
} from '../../../constants/constants/constAndRegex';
import { settingState } from '../../../constants/functions/functions';
import { URL_PROJECT } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import useForm from '../../../hooks/useForm';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import InputsProject from '../../common/Formulary/InputsProject/InputsProject';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../common/StyledCommonModals/StyledCommonModals';

const DetailProject = (props) => {
  const {
    states: {
      globalState: { id, formData }
    },
    functions: { headerGlobal }
  } = useContext(GlobalContext);
  const [openClearDialog, setOpenClearDialog] = useState(false);
  const [compareSubType, setCompareSubType] = useState('');
  const { detail, setDetail } = props;
 console.log(formData.inputDisable)
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    openClearDialog,
    setOpenClearDialog,
    setFieldErrors,
    setCompareSubType
  };

useEffect(() => {
  const source = axios.CancelToken.source();

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_PROJECT}${id}/`, {
        headers: headerGlobal(),
        cancelToken: source.token
      });
      const data = response.data;
      console.log('data:', data);

      const type = data.customer.type === 'physical' ? asCustomerPhysical : asCustomerCompany;
      const person = data.customer.id;
      const propertie = data.project_properties ? data.project_properties.id : null;

      // Extrai os IDs dos tipos de projeto
      const projectTypeIds = data.project_projectype?.map((item) => item.id);

      // Prepara o estado inicial para subprojects
      let initialSubprojectsState = {};
      data.project_projectype.forEach((projectType) => {
        // Inicializa cada tipo de projeto com um array vazio ou com IDs pré-existentes
        initialSubprojectsState[`subproject_${projectType.id}`] = [];
      });

      data.project_subproject.forEach((subproject) => {
        const key = `subproject_${subproject.project_type}`;
        // Garante que o array foi inicializado (isso deve ser sempre verdadeiro com a lógica acima)
        if (initialSubprojectsState[key] !== undefined) {
          initialSubprojectsState[key].push(subproject.id);
        }
      });

      setDataForm((prevFormData) => ({
        ...prevFormData,
        ...data,
        customer_person_physical: type === asCustomerPhysical ? person : undefined,
        customer_person_company: type === asCustomerCompany ? person : undefined,
        typePeople: type,
        properties: propertie,
        project_type: projectTypeIds,
        ...initialSubprojectsState
      }));
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log('Error getInfo', error);
      }
    }
  };


  getInfo();

  return () => {
    source.cancel('Operation canceled by the user.');
  };
}, []);

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setDetail, !detail)}>
        <S.DivGap>
          <TitleModal text='detalhes' />
          <InputsProject {...propsList} />
        </S.DivGap>
      </Formulary>
    </S.Main>
  );
};
export default DetailProject;
