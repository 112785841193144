import React, { useContext, useState } from 'react';
import axios from 'axios';
import * as S from '../styledDialogs';
import * as M from '@mui/material';
import { Button, ButtonRed } from '../../Reuse/Button/Buttons/Buttons';
import { GlobalContext } from '../../../../global/GlobalContext';
import { useNavigate } from 'react-router-dom';
import {
  asAccept,
  asAccepting,
  asAccessToken,
  asRefreshToken,
  asSubmit,
  naveLogin,
} from '../../../../constants/constants/constAndRegex';
import { URL_LOGOUT } from '../../../../constants/urls/urls';

const ExitDialog = () => {
  // estado - global
  const {
    states: {
      globalState: { exit },
    },
    functions: { toggleBoolean, successToast, headerGlobal, errorToast },
  } = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // função - realizar logout
  const handleLogout = () => {
    setIsSubmitting(true);
    axios
      .post(URL_LOGOUT, {}, { headers: headerGlobal() })
      .then((response) => {
        localStorage.removeItem(asRefreshToken);
        localStorage.removeItem(asAccessToken);
        successToast('Volte sempre');
        navigate(naveLogin);
        toggleBoolean('exit', 'reverse');
      })
      .catch((error) => {
        const statusCode = error.response?.status || 'Desconhecido';

        if (statusCode === 400) {
          // Se o status for 400, exibe o toast de sucesso "Volte sempre"
          toggleBoolean('exit', 'reverse');
          successToast('Volte sempre');
          localStorage.removeItem(asRefreshToken);
          localStorage.removeItem(asAccessToken);
          navigate(naveLogin);
        } else {
          const errorMessage = error?.response?.data?.detail;
          errorToast(errorMessage);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <S.Dialog open={exit}>
        <S.DialogTitle>Quer realmente sair?</S.DialogTitle>
        <M.DialogContent>
          <S.DialogContentText>Você sairá desta página</S.DialogContentText>
        </M.DialogContent>

        <S.DialogActions>
          <ButtonRed onClick={() => toggleBoolean('exit', 'reverse')} named={'cancelar'}></ButtonRed>
          <Button
            type={asSubmit}
            named={isSubmitting ? asAccepting : asAccept}
            onClick={(event) => {
              if (!isSubmitting) {
                handleLogout(event);
              }
            }}
            disabled={isSubmitting}
          />
        </S.DialogActions>
      </S.Dialog>
    </>
  );
};

export default ExitDialog;
