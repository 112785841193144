import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const BoxSelect = styled.div`
  margin-top: 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 55rem;
`;

export const DivGap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 1rem;
`;
