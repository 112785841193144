import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import * as S from './styledHome';
import Menu from '../../components/Menu/Menu/Menu';
import Header from '../../components/common/Reuse/Header/Header';
import { GlobalContext } from '../../global/GlobalContext';
import { useProtected } from '../../hooks/useProtected';
import { motion } from 'framer-motion';
import { URL_All_STATES } from '../../constants/urls/urls';
import { CircularProgress } from '@mui/material';
import { asWelcome } from '../../constants/constants/constAndRegex';

const Home = () => {
  const {
    states: {
      globalState: { componentRendered },
    },
    setters: { setGlobalState },
    functions: { changeComponent },
  } = useContext(GlobalContext);

  const fetchStates = async () => {
    try {
      const response = await axios.get(URL_All_STATES);
      setGlobalState((prevState) => ({
        ...prevState,
        stateList: response.data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStates();
    changeComponent(asWelcome);
  }, []);

  useProtected();

  return (
    <S.Main>
      <S.Center>
        <Header />
        <Menu />
        <S.Rendered>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{ duration: 1 }}
            // key={animationTrigger}
          >
            {!componentRendered ? <CircularProgress /> : componentRendered}
          </motion.div>
        </S.Rendered>
      </S.Center>
    </S.Main>
  );
};

export default Home;
