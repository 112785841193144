import styled from "styled-components";
import * as M from "@mui/material";

//=======================================
export const MainContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
`;

//=======================================
export const Main = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100%;
`;
//=======================================
export const SubMain = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: auto;

`;

//=======================================
export const MarginMain = styled.div`
margin: 1rem;
`;

//=======================================
export const Custon10 = styled.div`
width: 10rem;
margin-bottom: 1rem;
`;


export const Top = styled.div`
  display: flex;
  width: 55rem;
  padding-bottom: 1rem;
  flex-direction: row-reverse;
`;

//=======================================
export const DivSub = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: auto;

  ::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #0A7377;
  border-radius: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0A7377;
}
`;

//=======================================
export const DivSubRegisterTypeProject = styled.div`
margin-top: 0.5rem;
  padding: 0.8rem 1.5rem 1.5rem 1.5rem;
  width: 80rem;
  background-color: #fff;
  border-radius: 0.5rem;
  height: 70rem;
  overflow: auto;

  ::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #0A7377;
  border-radius: 0.3rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0A7377;
}
`;


//=======================================
export const FadeWrapper = styled.div`
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
`;
//=======================================
export const DivGap = styled.div`
display: flex;
width: 55rem;
flex-direction: column;
align-items: center;
padding: 1rem;
`;

export const DivGapButtons = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-evenly;
`;

//=======================================
export const DivButtonCLoseEditModal = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;
export const CustonSize = styled.div`
  /* width: 12rem; */
  margin-bottom: 1rem;
`;

//=======================================
export const ButtonCLoseEditModalClient = styled(M.Button)`
  && {
    border-radius: 0.5rem;
    cursor: pointer;
    height: 2.5rem;
    width: 5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;

//=======================================
export const IconButtonRed = styled(M.IconButton)`
  && {
    /* background-color: #0A7377; */
    padding: 1rem;
    cursor: pointer;
    background-color: #ff3333;
    color: #ffffff;
    border-radius: 0.5rem;
    width: 9rem;
    height: 3rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #0A7377;
    }
  }
`;
//=======================================
export const IconButton = styled(M.IconButton)`
  && {
    /* background-color: #0A7377; */
    padding: 1rem;
    cursor: pointer;
    background-color:#1D9E6F;
    color: #ffff;
    border-radius: 0.5rem;
    width: 9rem;
    height: 3rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #0A7377;
    }
  }
`;
//=======================================

export const IconButtonClose = styled(M.IconButton)`
  && {
    /* background-color: #0A7377; */
    padding: 1rem;
    cursor: pointer;
    background-color:#0A7377;
    color: #ffff;
    border-radius: 0.5rem;
    width: 5rem;
    height: 3rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #0A7377;
    }
  }
`;


//=======================================
export const Dialog = styled(M.Dialog)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13.5px);
  -webkit-backdrop-filter: blur(13.5px);
`;

//=======================================
export const DialogTitle = styled(M.DialogTitle)`
  display: flex;
  justify-content: center;
`;

//=======================================
export const DialogContentText = styled(M.DialogContentText)`
  display: flex;
  justify-content: center;
`;

//=======================================
export const ButtonRed = styled(M.Button)`
  && {
    cursor: pointer;
    /* padding: 0.5rem; */
    border-radius: 0.5rem;
    height: 2.5rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;
//=======================================
export const ButtonRedSmall = styled(M.Button)`
  && {
    cursor: pointer;
    /* padding: 0.5rem; */
    border-radius: 0.5rem;
    height: 1.6rem;
    font-size: 0.6rem;
    width: 5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;

//=======================================
export const ButtonSmall = styled(M.Button)`
  && {
    border-radius: 0.5rem;
    height: 1.6rem;
    font-size: 0.6rem;
    width: 5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
//=======================================
export const Button = styled(M.Button)`
  && {
    border-radius: 0.5rem;
    /* padding: 1rem; */
    cursor: pointer;
    height: 2.5rem;
    width: 10rem;
    /* margin-left: 4rem; */
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
//=======================================
export const FormLabel = styled(M.FormLabel)`
  && {
    width: 7.13rem;
  }`;

//=======================================
export const ButtonModal = styled(M.Button)`
  && {
    border-radius: 0.5rem;
    cursor: pointer;
    height: 1.8rem;
    width: max-content;
    font-size: 0.7rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
//=======================================
export const ButtonModalRed = styled(M.Button)`
  && {
    border-radius: 0.5rem;
    cursor: pointer;
    height: 1.8rem;
    font-size: 0.7rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;

//=======================================
export const DivButtonCLoseModal = styled.div`
  display: flex;
  width: 45rem;
  /* justify-content: center; */
  /* justify-content: center; */
flex-direction: row-reverse;
`;
//=======================================
export const DivRow = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`;

//=======================================
export const DivRowModal = styled.div`
  display: flex;
  gap: 0.5rem;
align-items: baseline;
  width: 100%;
`;
//=======================================
export const DivRowModalIcon = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* flex-direction: row; */
  /* gap: 0.5rem; */
/* align-items: baseline; */
  /* width: 20rem; */
/* border: 1px solid yellow; */
`;
//=======================================
export const DivRowCenter = styled.div`
  display: flex;
  gap: 0.5rem;
align-items: center;
  width: 100%;
`;
//=======================================
export const DivControll = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  border: 1px solid red;
  /* padding: 1rem; */
  /* gap: 0.5rem; */
/* align-items: center; */
  /* width: max-content; */
  /* max-height: 1rem; */
  /* flex-wrap: wrap; */
`;
//=======================================
export const DivColumnModal = styled.div`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  gap: 0.5rem;
  /* background: red; */
  width: 100%;
  /* border: 1px solid red; */
`;


//=======================================
export const ButtonCLoseModal = styled(M.IconButton)`
  && {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    height: 2.4rem;
    width: 4rem;
    border-radius: 0.5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;
//=======================================
export const DialogActions = styled(M.DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  margin: 0;
  `;

  //=======================================
  export const NumberField = styled(M.TextField)`
  && {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;


export const FormGroup = styled(M.FormGroup)`&&{
padding-left: 1rem;
max-height: 8rem;
overflow: auto;
}
`;

export const BoxSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

export const LoadingOverlay = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  justify-content: center;
  align-items: center;
  z-index: 999;
`;
