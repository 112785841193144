import * as M from "@mui/material"
import React from "react"

const InputAutoComplete = React.memo(
  ({
    label,
    name,
    value,
    onChange,
    options,
    error,
    disabled,
    conditionMessage,
    regexMessage,
    renderOption,
    onInputChange,
  }) => {
    const isValueInOptions = options?.some((option) => option.value === value)
    const errorMessage =
      error &&
      (conditionMessage[name] ? conditionMessage[name] + ". " : "") + (regexMessage[name] ? regexMessage[name] : "")
    return (
      <>
        <M.Tooltip title={errorMessage} placement="top-start">
          <M.FormControl fullWidth variant="outlined" error={error}>
            <M.InputLabel htmlFor={name} style={{ color: "#666666" }}></M.InputLabel>
            <M.Autocomplete
              id={name}
              options={options}
              value={isValueInOptions ? options?.find((option) => option.value === value) : null}
              onChange={(event, newValue) => {
                onChange({ target: { name, value: newValue ? newValue.value : "" } })
              }}
              onInputChange={onInputChange}
              getOptionLabel={(option) => option.label}
              renderOption={renderOption}
              renderInput={(params) => <M.TextField {...params} label={label} variant="outlined" error={error} />}
              disabled={disabled}
            />
          </M.FormControl>
        </M.Tooltip>
      </>
    )
  },
)
export default InputAutoComplete
