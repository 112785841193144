import React from "react"
import * as M from "@mui/material"

const InputCheckboxGroup2 = ({ label, saveName, options, error, disabled, setDataForm, dataForm }) => {
  const handleInputChangeCheckbox = (event) => {
    const { name, checked } = event.target
    setDataForm((prevState) => {
      const currentFieldData = prevState[saveName] || []
      const nameAsNumber = isNaN(parseInt(name, 10)) ? name : parseInt(name, 10)

      if (checked) {
        if (!currentFieldData.includes(nameAsNumber)) {
          currentFieldData.push(nameAsNumber)
        }
      } else {
        const index = currentFieldData.indexOf(nameAsNumber)
        if (index > -1) {
          currentFieldData.splice(index, 1)
        }
      }

      return {
        ...prevState,
        [saveName]: currentFieldData,
      }
    })
  }

  // return (
  //   <>
  //     <M.FormControl
  //       fullWidth
  //       component="fieldset"
  //       variant="outlined"
  //       error={error}
  //       style={error ? { border: '1px solid red', borderRadius: '4px' } : {}}
  //     >
  //       <M.FormLabel component="legend" style={{ color: '#666666' }}>
  //         {label}
  //       </M.FormLabel>
  //       <M.FormGroup style={{ maxHeight: '30rem', overflowY: 'auto' }}>
  //         {options?.map((option, index) => (
  //           <div key={index} style={{ margin: '8px' }}>
  //             <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #C4C4C4', borderRadius: '4px', padding: '4px' }}>
  //               <M.FormControlLabel
  //                 control={
  //                   <M.Checkbox
  //                     name={String(option.value)}
  //                     checked={Boolean(dataForm[saveName]?.includes(option.value))}
  //                     onChange={handleInputChangeCheckbox}
  //                     disabled={disabled}
  //                   />
  //                 }
  //                 label={M.capitalize(option.label)}
  //               />
  //             </div>
  //           </div>
  //         ))}
  //       </M.FormGroup>
  //     </M.FormControl>
  //   </>
  // );

  return (
    <>
      <M.FormControl
        fullWidth
        component="fieldset"
        variant="outlined"
        error={error}
        style={error ? { border: "1px solid red", borderRadius: "4px" } : {}}
      >
        <M.FormLabel component="legend" style={{ color: "#666666" }}>
          {label}
        </M.FormLabel>
        <M.FormGroup style={{ overflow: "auto" }} row={true}>
          {options?.length > 0 ? (
            options.map((option, index) => (
              <div key={index} style={{ margin: "8px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "1px solid #C4C4C4",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <M.FormControlLabel
                    control={
                      <M.Checkbox
                        name={String(option.value)}
                        checked={Boolean(dataForm[saveName]?.includes(option.value))}
                        onChange={handleInputChangeCheckbox}
                        disabled={disabled}
                      />
                    }
                    label={M.capitalize(option.label)}
                  />
                </div>
              </div>
            ))
          ) : (
            <div style={{ color: "#666666", borderRadius: "4px", padding: "4px" }}>
              Em TERMOS E CONDIÇÕES, vá no TERMO selecionado e crie CLÁUSULAS, para que sejam listadas aqui nas opções.
            </div>
          )}
        </M.FormGroup>
      </M.FormControl>
    </>
  )
}

export default InputCheckboxGroup2
