import * as M from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const DatePickerWrapper = styled(M.TextField)`
  && {
    margin: 0;
    padding: 0;
    & .MuiInputBase-root {
      margin: 0;
      padding: 0;
    }
  }
`;

const InputDate = React.memo(({ label, name, value, required, onChange, error, conditionMessage, regexMessage }) => {
  return (
    <>
      <M.Tooltip
        title={
          error &&
          ((conditionMessage[name] ? conditionMessage[name] : '') || (regexMessage[name] ? regexMessage[name] : ''))
        }
        placement="top-start"
      >
        <DatePickerWrapper
          type="date"
          fullWidth
          margin="normal"
          label={label}
          name={name}
          value={value || ''}
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: '1500-01-01',
            max: '2099-12-31',
            title: 'Formato de data aceito: 00/00/0000',
            maxLength: 10,
          }}
          required={required}
          error={error}
        />
      </M.Tooltip>
    </>
  );
});

export default InputDate;
