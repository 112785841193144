import * as M from "@mui/material"
import axios from "axios"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { ITEMS_PER_PAGE } from "../../../constants/constants/constAndRegex"
import { capitalizeFirstString, formatCapitalize, settingState } from "../../../constants/functions/functions"
import { URL_PROPERTIE } from "../../../constants/urls/urls"
import { GlobalContext } from "../../../global/GlobalContext"
import EraseDialog from "../../common/Dialog/EraseDialog/EraseDialog"
import Modal from "../../common/Reuse/Modal/Modal"
import * as S from "../../common/StyledCommonComponents/StyledCommonComponents"
import Table from "../../common/Table/Table"
import DetailPropertie from "../DetailPropertie/DetailPropertie"
import EditPropertie from "../EditPropertie/EditPropertie"
import RegisterPropertie from "../RegisterPropertie/RegisterPropertie"
import * as L from "./styled"

const Propertie = () => {
  const {
    states: {
      globalState: { lookAlteration, id },
    },
    functions: { headerGlobal },
  } = useContext(GlobalContext)

  const [selectedType, setSelectedType] = useState(() => {
    const savedType = localStorage.getItem("s_propertie")
    return savedType || "completa"
  })
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [data, setData] = useState("")
  const [allPages, setAllPages] = useState(0)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [searchName, setSearchName] = useState(null)
  const [register, setRegister] = useState(false)
  const [edit, setEdit] = useState(false)
  const [detail, setDetail] = useState(false)
  const [erase, setErase] = useState(false)
  const propsList = {
    register,
    setRegister,
    edit,
    setEdit,
    detail,
    setDetail,
    url: URL_PROPERTIE,
    erase,
    setErase,
    id,
  }

  function capitalizeWords(str) {
    if (!str) return "" // retorna string vazia se str é nulo ou indefinido
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")
  }

  const header = [
    {
      name: "Imóvel",
      field: "owner" ?? "-",
      styled: formatCapitalize,
    },
    {
      name: "Endereço",
      field: (x) =>
        `${capitalizeWords(x.street)},${x.number ? " Nº" + x.number : ""}, ${capitalizeWords(
          x.neighborhood,
        )}, ${capitalizeWords(x.city)}-${x.state ? x.state.toUpperCase() : ""}`,
    },
    {
      name: "Área (m²)",
      field: "area" ?? "-",
    },
    {
      name: "Registro em Cartório",
      field: "registration" ?? "-",
    },
    {
      name: "IPTU/ITR",
      field: "iptu" ?? "-",
    },
    { name: "Imagem", field: "image" },
    { name: "Status", field: "status" ?? "-", styled: capitalizeFirstString },
    { name: "Ações", field: "acoes" },
  ]

  const handlePageChange = (event, value) => {
    setCurrentPageNumber(value)
    if (!searchName) {
      sessionStorage.setItem("cp_propertie", value)
      fetchPage(value)
    } else {
      fetchPageName(value)
    }
  }

  // mudar de página com click nos números
  const fetchPage = async (pageToUse) => {
    const url = `${URL_PROPERTIE}?status=${selectedType}&page=${pageToUse}`
    try {
      const response = await axios.get(url, { headers: headerGlobal() })
      setData(response.data)
      const count = response.data.count
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE)
      setAllPages(pagesToRender)
    } catch (error) {
      console.error("Error", error)
    }
  }

  // mudar de página com click nos números quando há valor no input
  const fetchPageName = async (pageToUse) => {
    const encodedSearchName = encodeURIComponent(searchName)
    const url = `${URL_PROPERTIE}?status=${selectedType}&neighborhood=${encodedSearchName}&page=${pageToUse}`

    try {
      const response = await axios.get(url, { headers: headerGlobal() })
      setData(response.data)
      const count = response.data.count
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE)
      setAllPages(pagesToRender)
    } catch (error) {
      console.error("Error", error)
    }
  }

  // pesquisa pelo input
  const searchForName = async (name, currentPageNumber) => {
    sessionStorage.setItem("p_physical", currentPageNumber)
    const encodedSearchName = encodeURIComponent(name)
    const valueUrlSearch = `${URL_PROPERTIE}?status=${selectedType}&neighborhood=${encodedSearchName}`

    try {
      const response = await axios.get(valueUrlSearch, {
        headers: headerGlobal(),
      })
      setCurrentPageNumber(1)
      setData(response.data)
      const count = response.data.count
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE)
      setAllPages(pagesToRender)
    } catch (error) {
      console.error("Error", error)
    }
  }

  // useEffect de salvar, recuperar número da página e alterar as páginas
  useEffect(() => {
    const handleFetch = async () => {
      const pageNumber = searchName ? currentPageNumber : sessionStorage.getItem("cp_propertie") || 1
      setCurrentPageNumber(Number(pageNumber))
      if (searchName) {
        await searchForName(searchName, Number(pageNumber))
      } else {
        await fetchPage(Number(pageNumber))
      }
    }
    localStorage.setItem("s_propertie", selectedType)
    handleFetch()
  }, [searchName, selectedType, lookAlteration])

  const handleOpen = () => {
    if (searchName && searchName.length > 0) {
      setAutocompleteOpen(true)
    }
  }

  const handleClose = () => {
    setAutocompleteOpen(false)
  }

  const handleChange = (event, newValue) => {
    setSearchName(newValue || "")
  }

  const handleInputChange = (event, newValue) => {
    setAutocompleteOpen(Boolean(newValue && newValue.length > 0))
    setSearchName(newValue)
  }

  // resultados únicos na pesquisa do input
  const uniqueResults = useMemo(() => {
    return [...new Set(data?.results?.map((item) => (item.neighborhood && item.status ? item.neighborhood : null)))]
  }, [data])

  // input de pesquisa
  const renderSearchInput = (params) => <L.Search {...params} size="small" label="Bairro" variant="outlined" />

  return (
    <L.Main>
      <L.Container>
        <S.H1>imóvel</S.H1>
        <L.BoxTitle>
          <L.Row>
            <L.Select value={selectedType} onChange={(event) => setSelectedType(event.target.value)} displayEmpty>
              <M.MenuItem value="completa">{"Completa"}</M.MenuItem>
              <M.MenuItem value="pendente">{"Pendente"}</M.MenuItem>
            </L.Select>

            <M.Autocomplete
              open={autocompleteOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              options={uniqueResults}
              value={searchName}
              onChange={handleChange}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => (option ? formatCapitalize(option.toString()) : "")}
              renderInput={renderSearchInput}
            />
          </L.Row>
          <S.DivAjustButton>
            <S.ButtonLarge
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                settingState(setRegister, !register)
              }}
            >
              cadastrar imóvel
            </S.ButtonLarge>
          </S.DivAjustButton>
        </L.BoxTitle>

        {/* Tabela */}
        <Table
          column={header}
          state={data && data.results}
          onClickDetail={() => settingState(setDetail, !detail)}
          onClickEdit={() => settingState(setEdit, !edit)}
          onClickDelete={() => settingState(setErase, !erase)}
        />

        <L.ContainerEndPosition>
          <L.EndPosition>
            <M.Pagination count={allPages} page={currentPageNumber} onChange={handlePageChange} variant="rounded" />
          </L.EndPosition>
        </L.ContainerEndPosition>

        {/* Modal de detalhes */}
        {detail && <Modal open={detail} component={<DetailPropertie {...propsList} />} />}

        {/* Modal de editar */}
        {edit && <Modal open={edit} component={<EditPropertie {...propsList} />} />}

        {/* modal de registrar */}
        {register && <Modal open={register} component={<RegisterPropertie {...propsList} />} />}

        {/* Dialog de excluir */}
        {erase && <EraseDialog {...propsList} />}
      </L.Container>
    </L.Main>
  )
}

export default Propertie
