import * as M from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ITEMS_PER_PAGE, asCompany, asPeople } from '../../../../constants/constants/constAndRegex';
import {
  formatCNPJ,
  formatCapitalize,
  formatLower,
  formatPhone,
  formatQualificacao,
  settingState,
} from '../../../../constants/functions/functions';
import { URL_COMPANY } from '../../../../constants/urls/urls';
import { GlobalContext } from '../../../../global/GlobalContext';
import { ButtonLarge } from '../../../common/Reuse/Button/Buttons/Buttons';
import H1 from '../../../common/Reuse/H1/H1';
import Table from '../../../common/Table/Table';
import * as L from './styled';

const ClientCompany = (props) => {
  const {
    states: {
      globalState: { typePeople, lookAlteration },
    },
    functions: { toggleData, headerGlobal },
  } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [data, setData] = useState('');
  const [allPages, setAllPages] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchName, setSearchName] = useState(null);
  const { register, setRegister, edit, setEdit, detail, setDetail, erase, setErase } = props;

  // // colunas da tabela de pessoa jurídica
  const header = [
    { name: 'Razão Social', field: 'social_reason', styled: formatCapitalize },
    { name: 'CNPJ', field: 'cnpj', styled: formatCNPJ },
    { name: 'Telefone', field: 'telephone', styled: formatPhone },
    { name: 'E-mail', field: 'email', styled: formatLower },
    {
      name: 'Representante Legal',
      field: 'legal_representatives.legal_representative',
      styled: formatCapitalize,
    },
    {
      name: 'Qualificação',
      field: (item) => {
        if (item.legal_representatives) {
          return item.legal_representatives
            .map((rep) => {
              return rep.others_qualification ? rep.others_qualification : rep.qualification;
            })
            .join(', ');
        }
        return null;
      },
      styled: formatQualificacao,
    },
    { name: 'Ações', field: 'acoes' },
  ];

  const handlePageChange = (event, value) => {
    setCurrentPageNumber(value);
    if (!searchName) {
      sessionStorage.setItem('cp_company', value);
      fetchPage(value);
    } else {
      fetchPageName(value);
    }
  };

  // mudar de página com click nos números
  const fetchPage = async (pageToUse) => {
    const url = `${URL_COMPANY}?page=${pageToUse}`;
    setIsLoading(true);

    try {
      const response = await axios.get(url, { headers: headerGlobal() });
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // mudar de página com click nos números quando há valor no input
  const fetchPageName = async (pageToUse) => {
    setIsLoading(true);

    const encodedSearchName = encodeURIComponent(searchName);
    const url = `${URL_COMPANY}?social_reason=${encodedSearchName}&page=${pageToUse}`;

    try {
      const response = await axios.get(url, { headers: headerGlobal() });
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // pesquisa pelo input
  const searchForName = async (name, currentPageNumber) => {
    setIsLoading(true);
    sessionStorage.setItem('p_company', currentPageNumber);
    const encodedSearchName = encodeURIComponent(name);
    const valueUrlSearch = `${URL_COMPANY}?social_reason=${encodedSearchName}`;

    try {
      const response = await axios.get(valueUrlSearch, {
        headers: headerGlobal(),
      });
      setCurrentPageNumber(1);
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect de salvar, recuperar número da página e alterar as páginas
  useEffect(() => {
    const handleFetch = async () => {
      const pageNumber = searchName ? currentPageNumber : sessionStorage.getItem('cp_company') || 1;
      setCurrentPageNumber(Number(pageNumber));
      if (searchName) {
        await searchForName(searchName, Number(pageNumber));
      } else {
        await fetchPage(Number(pageNumber));
      }
    };

    handleFetch();
  }, [searchName, lookAlteration]);

  // auto complete
  // onOpen
  const handleOpen = () => {
    if (searchName && searchName.length > 0) {
      setAutocompleteOpen(true);
    }
  };

  // onClose
  const handleClose = () => {
    setAutocompleteOpen(false);
  };

  // onChange
  const handleChange = (event, newValue) => {
    setSearchName(newValue || '');
  };

  // onInputChange
  const handleInputChange = (event, newValue) => {
    setAutocompleteOpen(Boolean(newValue && newValue.length > 0));
    setSearchName(newValue);
  };

  // resultados únicos na pesquisa do input
  const uniqueResults = useMemo(() => {
    return [...new Set(data?.results?.map((item) => item.social_reason))];
  }, [data]);

  // input de pesquisa
  const renderSearchInput = (params) => <L.Search {...params} size="small" label="Razão Social" variant="outlined" />;

  return (
    <L.Main>
      <L.LoadingOverlay isLoading={isLoading}>
        <M.CircularProgress />
      </L.LoadingOverlay>
      <div>
        <H1 text={'Cliente'} />
        <L.BoxTitle>
          <L.Row>
            <L.Select
              value={typePeople}
              onChange={(event) => toggleData('typePeople', event.target.value)}
              displayEmpty
            >
              <M.MenuItem value={asPeople}>{'Pessoa Física'}</M.MenuItem>
              <M.MenuItem value={asCompany}>{'Pessoa Jurídica'}</M.MenuItem>
            </L.Select>

            <M.Autocomplete
              open={autocompleteOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              options={uniqueResults}
              value={searchName}
              onChange={handleChange}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => (option ? formatCapitalize(option.toString()) : '')}
              renderInput={renderSearchInput}
            />
          </L.Row>

          <ButtonLarge
            onClick={() => {
              settingState(setRegister, !register);
              toggleData('statusImage', null);
            }}
            named={'cadastrar cliente'}
          />
        </L.BoxTitle>

        <Table
          column={header}
          state={data && data.results}
          onClickDetail={() => settingState(setDetail, !detail)}
          onClickEdit={() => settingState(setEdit, !edit)}
          onClickDelete={() => settingState(setErase, !erase)}
        />

        {/* Paginação */}
        <L.ContainerEndPosition>
          <L.EndPosition>
            <M.Pagination count={allPages} page={currentPageNumber} onChange={handlePageChange} variant="rounded" />
          </L.EndPosition>
        </L.ContainerEndPosition>
      </div>
    </L.Main>
  );
};

export default ClientCompany;
