import React, { useContext, useEffect, useState } from "react";
import * as S from "./styled";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import { GlobalContext } from "../../../global/GlobalContext";
import {
  asCompany,
  asCustomerCompany,
  asCustomerPhysical,
  asPeople,
} from "../../../constants/constants/constAndRegex";
import { URL_COMPANY, URL_PHYSICAL } from "../../../constants/urls/urls";

const TableGranted = ({
  column,
  state,
  onClickEdit,
  onClickDetail,
  onClickDelete,
}) => {
  // estado - global
  const {
    functions: { toggleData },
    states: {
      globalState: {

        selectedLetterOfAttorney,

      },
    },
    setters: { setGlobalState },
  } = useContext(GlobalContext);

  // detalhes
  const detail = (item) => {
    toggleData("id", item.id); // adiciona id ao estado global
    onClickDetail(item.id); // passa o id no click

    const verifyType = "cnpj" in item ? asCompany : asPeople; // muda o valor no estado global formData.verifyType de acordo com pessoa física ou jurídica
    const axiosUrl = verifyType === asPeople ? URL_PHYSICAL : URL_COMPANY; // muda o valor no estado global formData.verifyType de acordo com pessoa física ou jurídica

    setGlobalState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        verifyType: verifyType,
        axiosUrl: axiosUrl,
        noEdit: true,
        inputDisable: true,
      },
      passDelRepresentative: false,
      fieldErrors: "",
    })); // adiciona os valores do tipo de pessoa e também a url para envio de axios

    copySelected(item);
  };

  const copySelected = (item) => {
    if (!item) {
      return;
    }
    setGlobalState((prevState) => ({
      ...prevState,
      selectedLetterOfAttorney,
    })); //detalhes da procuração clicada
  };

  // editar
  const edit = (item) => {
    toggleData("id", item.id); // adiciona id ao estado global
    onClickEdit(item.id); // passa o id no click

    // verifyType usado em cliente
    const verifyType = "cnpj" in item ? asCompany : asPeople; // muda o valor no estado global formData.verifyType de acordo com pessoa física ou jurídica
    const axiosUrl = verifyType === asPeople ? URL_PHYSICAL : URL_COMPANY; // muda o valor no estado global formData.verifyType de acordo com pessoa física ou jurídica

    // verifySelect usado em letterOfAttorney
    const verifySelect = // muda o valor no estado global formData.verifySelect de acordo com pessoa física ou jurídica
      item.customer_person_physical !== null
        ? asCustomerPhysical
        : item.customer_person_company !== null
        ? asCustomerCompany
        : "";
    setGlobalState((prevState) => ({
      // adiciona os valores do tipo de pessoa e também a url para envio de axios
      ...prevState,
      formData: {
        ...prevState.formData,
        verifyType: verifyType,
        axiosUrl: axiosUrl,
        verifySelect: verifySelect,
        noEdit: true,
        inputDisable: false,
      },
      passDelRepresentative: true,
      sentCompany: false,
      statusImage: "",
      fieldErrors: "",
    }));
  };

  // deletar
  const handleDelete = (id) => {
    toggleData("id", id); // adiciona id ao estado global
    onClickDelete(id); // passa o id no click
  };

  const sortedState = [...state].sort((a, b) => b.id - a.id); // organiza as tabelas em ordem de id

  const getNestedPropertyValue = (obj, path, styledFn) => {
    if (typeof path === "function") {
      return path(obj);
    }
    const pathArray = path.split(".");
    let value = obj;

    for (const step of pathArray) {
      if (Array.isArray(value)) {
        return value
          .map((item) => getNestedPropertyValue(item, step, styledFn))
          .join(", ");
      }

      if (value && value.hasOwnProperty(step)) {
        value = value[step];
      } else {
        return null;
      }
    }

    return styledFn ? styledFn(value) : value;
  };


  // jsx
  return (
    <>
      <S.Table>
        <thead>
          <tr>
            {column.map((i, index) => (
              <S.Th key={index} className={i.field === "acoes" ? "acoes" : ""}>
                {i.name}
              </S.Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(sortedState) &&
            sortedState.map((x, index) => (
              <tr key={index}>
                {column.map((i, index) => (
                  <S.Td
                    key={index}
                    className={i.field === "acoes" ? "acoes" : ""}
                  >
                    {i.field === "acoes" ? (
                      <S.Gap>

                        <S.MiniIconButtonModal onClick={() => detail(x)}>
                          <PlagiarismIcon />
                        </S.MiniIconButtonModal>

                        <S.MiniIconButtonModal onClick={() => edit(x)}>
                          <EditIcon />
                        </S.MiniIconButtonModal>

                        <S.MiniIconButtonModalRed
                          onClick={() => handleDelete(x.id)}
                        >
                          <DeleteIcon />
                        </S.MiniIconButtonModalRed>
                      </S.Gap>
                    ) : typeof i.styled === "function" ? (
                      i.styled(getNestedPropertyValue(x, i.field))
                    ) : (
                      getNestedPropertyValue(x, i.field) || "-"
                    )}
                  </S.Td>
                ))}
              </tr>
            ))}
        </tbody>
      </S.Table>
    </>
  );
};

export default TableGranted;
