import styled from "styled-components";
import * as M from "@mui/material";

export const Main = styled.div`
  background-color: #0a7377;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 4rem; */
  /* position: relative; */
`;

export const Div = styled.div`
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-weight: 600;

`;

export const Position = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin: 0.5rem;
  z-index: 1;
`;

export const ButtonMenu = styled(M.Button)`
  && {
    cursor: pointer;
    width: 5rem;
    height: 2.3rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
export const P = styled.p`
  color: #ffffff;
  font-weight: 600;
  font-size: 0.9rem;
`;

export const Title = styled.div`
  margin: 0;
  padding: 0;
  /* border: 1px solid red; */
  /* margin-top: 0.5rem; */
  /* margin-bottom: 0.5rem; */
  font-weight: 600;
  font-size: 1.5rem;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ContainerButton = styled.div`
  position: absolute;
  right: 0;
`;
