import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 0.5rem solid #0a7377;
  height: 3.6rem;
  align-items: center;
  position: relative;
`;

export const High = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`;

export const Container = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const Position = styled.div`
 margin-left: auto;
  margin-right: 0.5rem;
`;

export const ItemColumn = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
`;

export const Img = styled.img`
  width: 8rem;
  height: 2rem;
`;
export const Div = styled.div`
display: flex;
align-items: center;
gap: 0.5rem;
`;

export const Name = styled.div`
display: flex;
font-weight: 600;
color: #0A7377;
margin: 0;
padding: 0;
/* padding-top: 1rem; */
`;

