import * as M from "@mui/material"
import React from "react"

const Input = React.memo(
  ({ label, onChange, value, name, length, error, regexMessage, conditionMessage, multiline, maxRows }) => {
    const errorMessage =
      error &&
      (conditionMessage[name] ? conditionMessage[name] + ". " : "") + (regexMessage[name] ? regexMessage[name] : "")

    return (
      <>
        <M.Tooltip title={errorMessage} placement="top-start">
          <M.TextField
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            fullWidth
            inputProps={{
              maxLength: length,
            }}
            error={error}
            multiline={multiline}
            maxRows={maxRows}
          />
        </M.Tooltip>
      </>
    )
  },
)

export default Input
