import * as S from '../styledDialogs';
import * as M from '@mui/material';
import { Button, ButtonRed } from '../../Reuse/Button/Buttons/Buttons';
import { GlobalContext } from '../../../../global/GlobalContext';
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { asAccept, asAccepting, asSubmit } from '../../../../constants/constants/constAndRegex';
import { settingState } from '../../../../constants/functions/functions';

const EraseDialog = (props) => {
  const {
    functions: { successToast, headerGlobal, sayAlteration },
  } = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { url, setErase, id, erase } = props;

  const handleDelete = () => {
    setIsSubmitting(true);
    axios
      .delete(`${url}${id}/`, {
        headers: headerGlobal(),
      })
      .then((response) => {
        sayAlteration();
        successToast('Excluído');
        settingState(setErase, !erase);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <S.Dialog open={erase}>
        <S.DialogTitle>Excluir?</S.DialogTitle>
        <M.DialogContent>
          <S.DialogContentText>Essa ação não pode ser desfeita</S.DialogContentText>
        </M.DialogContent>

        <S.DialogActions>
          <ButtonRed onClick={() => settingState(setErase, !erase)} named={'cancelar'}></ButtonRed>
          <Button
            type={asSubmit}
            named={isSubmitting ? asAccepting : asAccept}
            onClick={() => {
              if (!isSubmitting) {
                handleDelete();
              }
            }}
            disabled={isSubmitting}
          />
        </S.DialogActions>
      </S.Dialog>
    </>
  );
};

export default EraseDialog;
