import styled from "styled-components";
import * as M from "@mui/material";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin: 0.5rem;
`;

export const BoxSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

export const DivGap = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-bottom: 1rem;
`;

export const DivSub = styled.form`
  background-color: #fff;
  width: 55rem;
  border-radius: 0.5rem;
  overflow: auto;
`;

export const H2Custom = styled.h2`
  display: flex;
  justify-content: right;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  color: #006978;
  align-items: center;
  gap: 0.2rem;
  padding-right: 1rem;
`;

export const IconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;

export const Ajust = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

export const DisplayAjust = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Collumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const H2 = styled.h2`
  display: flex;
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #006978;
  align-items: center;
  justify-content: right;
  gap: 0.2rem;
  padding-left: 1rem;
`;

export const Initial = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const Top = styled.div`
  display: flex;
  border: 1px solid red;
  width: 100%;
  align-items: center;
`;

export const BoxSpace = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
margin: 0.5rem;
`;

export const Search = styled(M.TextField)`
  && {
    height: 2.5rem;
    width: 18rem;
    input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const ContainerEndPosition = styled.div``;

export const EndPosition = styled.div`
display: flex;
justify-content: center;
/* position: fixed; */
bottom: 0;
/* right: 50%; */
/* transform: translateX(50%); */
margin-bottom: 0.5rem;
`;

export const Box = styled.div`
display: flex;
width: 55rem;
align-items: center;
padding: 1rem;
`;

export const LoadingOverlay = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
