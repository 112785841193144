import AddIcon from '@mui/icons-material/Add';
import * as M from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ITEMS_PER_PAGE } from '../../../constants/constants/constAndRegex';
import { capitalizeFirstString, formatCPF, formatPhone, settingState } from '../../../constants/functions/functions';
import { URL_BESTOWED } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import EraseDialog from '../../common/Dialog/EraseDialog/EraseDialog';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import Modal from '../../common/Reuse/Modal/Modal';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../common/StyledCommonModals/StyledCommonModals';
import TableGranted from '../../common/TableGranted/TableGranted';
import DetailBestwed from './DetailBestowed/DetailBestowed';
import EditBestowed from './EditBestowed/EditBestowed';
import RegisterBestwed from './RegisterBestowed/RegisterBestowed';
import * as L from './styled';

const Bestowed = (props) => {
  const {
    states: {
      globalState: { lookAlteration, id },
    },
    functions: { headerGlobal },
  } = useContext(GlobalContext);
  const [register, setRegister] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState(false);
  const [erase, setErase] = useState(false);
  const [clear, setClear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [allPages, setAllPages] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchName, setSearchName] = useState(null);
  const [data, setData] = useState('');
  const { openBestowed, setOpenBestowed } = props;

  const propsList = {
    register,
    setRegister,
    edit,
    setEdit,
    detail,
    setDetail,
    openBestowed,
    setOpenBestowed,
    erase,
    setErase,
    clear,
    setClear,
    id,
  };

  const handlePageChange = (event, value) => {
    setCurrentPageNumber(value);
    if (!searchName) {
      sessionStorage.setItem('cp_bestowed', value);
      fetchPage(value);
    } else {
      fetchPageName(value);
    }
  };

  // mudar de página com click nos números
  const fetchPage = async (pageToUse) => {
    const url = `${URL_BESTOWED}?page=${pageToUse}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, { headers: headerGlobal() });
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // mudar de página com click nos números quando há valor no input
  const fetchPageName = async (pageToUse) => {
    setIsLoading(true);
    const encodedSearchName = encodeURIComponent(searchName);
    const url = `${URL_BESTOWED}?name=${encodedSearchName}&page=${pageToUse}`;
    try {
      const response = await axios.get(url, { headers: headerGlobal() });
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // pesquisa pelo input
  const searchForName = async (name) => {
    setIsLoading(true);
    const encodedSearchName = encodeURIComponent(name);
    const valueUrlSearch = `${URL_BESTOWED}?name=${encodedSearchName}`;
    try {
      const response = await axios.get(valueUrlSearch, { headers: headerGlobal() });
      setCurrentPageNumber(1);
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect de salvar, recuperar número da página e alterar as páginas
  useEffect(() => {
    const handleFetch = async () => {
      const pageNumber = searchName ? currentPageNumber : sessionStorage.getItem('cp_bestowed') || 1;
      setCurrentPageNumber(Number(pageNumber));
      if (searchName) {
        await searchForName(searchName, Number(pageNumber));
      } else {
        await fetchPage(Number(pageNumber));
      }
    };

    handleFetch();
  }, [searchName, lookAlteration]);

  // auto complete
  // onOpen
  const handleOpen = () => {
    if (searchName && searchName.length > 0) {
      setAutocompleteOpen(true);
    }
  };

  // onClose
  const handleClose = () => {
    setAutocompleteOpen(false);
  };

  // onChange
  const handleChange = (event, newValue) => {
    setSearchName(newValue || '');
  };

  // onInputChange
  const handleInputChange = (event, newValue) => {
    setAutocompleteOpen(Boolean(newValue && newValue.length > 0));
    setSearchName(newValue);
  };

  // resultados únicos na pesquisa do input
  const uniqueResults = useMemo(() => {
    return [...new Set(data?.results?.map((item) => item.name))];
  }, [data]);

  // input de pesquisa
  const renderSearchInput = (params) => <L.Search {...params} size="small" label="Nome" variant="outlined" />;

  const getInfo = async () => {
    try {
      const response = await axios.get(URL_BESTOWED, {
        headers: headerGlobal(),
      });
      setData(response.data);
    } catch (error) {
      console.log('Error', error);
    }
  };

  useEffect(() => {
    getInfo();
  }, [lookAlteration]);

  const header = [
    {
      name: 'Nome Completo',
      field: 'name',
      styled: capitalizeFirstString,
    },
    { name: 'Telefone', field: 'telephone', styled: formatPhone },
    { name: 'CPF', field: 'cpf', styled: formatCPF },
    { name: 'Ações', field: 'acoes' },
  ];

  return (
    <S.Main>
      <L.LoadingOverlay isLoading={isLoading}>
        <M.CircularProgress />
      </L.LoadingOverlay>
      <Formulary onClick={() => settingState(setOpenBestowed, !openBestowed)}>
        <L.Box>
          <S.BoxSelect>
            <L.Ajust>
              <TitleModal text={'outorgado'} />
              <L.IconButtonModal type="button" onClick={() => settingState(setRegister, !register)}>
                <AddIcon />
              </L.IconButtonModal>
            </L.Ajust>
          </S.BoxSelect>
        </L.Box>

        <L.BoxSpace>
        <M.Autocomplete
          open={autocompleteOpen}
          onOpen={handleOpen}
          onClose={handleClose}
          options={uniqueResults}
          value={searchName}
          onChange={handleChange}
          onInputChange={handleInputChange}
          getOptionLabel={(option) => (option ? capitalizeFirstString(option.toString()) : '')}
          renderInput={renderSearchInput}
        />
          <TableGranted
            column={header}
            state={data && data.results}
            onClickDetail={() => settingState(setDetail, !detail)}
            onClickEdit={() => settingState(setEdit, !edit)}
            onClickDelete={() => settingState(setErase, !erase)}
          />
          <L.EndPosition>
            <M.Pagination count={allPages} page={currentPageNumber} onChange={handlePageChange} variant="rounded" />
          </L.EndPosition>
        </L.BoxSpace>
      </Formulary>

      {/* modal de cadastrar */}
      <Modal open={register} component={<RegisterBestwed {...propsList} />} />

      {/* modal de editar */}
      <Modal open={edit} component={<EditBestowed {...propsList} />} />

      {/* modal de detalhes */}
      <Modal open={detail} component={<DetailBestwed {...propsList} />} />

      {/* diálogo de excluir */}
      <EraseDialog url={URL_BESTOWED} {...propsList} />

    </S.Main>
  );
};

export default Bestowed;
