import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputsRecovery from '../../components/common/Formulary/InputsRecovery/InputsRecovery';
import { Button, ButtonRed } from '../../components/common/Reuse/Button/Buttons/Buttons';
import {
  asDefaultErrorAxios,
  asRecoveryText,
  asSubmit,
  asUnknowError,
  naveLogin
} from '../../constants/constants/constAndRegex';
import { requiredRecovery } from '../../constants/constants/requiredConst';
import { handleErrors, validateForm } from '../../constants/functions/functions';
import { URL_RESET_PASSWORD } from '../../constants/urls/urls';
import { GlobalContext } from '../../global/GlobalContext';
import useForm from '../../hooks/useForm';
import * as S from './styled';
import { errorRecovery } from '../../constants/constants/constError';

const Recovery = () => {
  const {
    functions: { successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const InputProps = {
    regexMessage,
    conditionMessage,
    dataForm,
    inputForm,
    fieldErrors,
  };

  const handlePost = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const resultValidateForm = validateForm({
      specificValidations: requiredRecovery,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    axios
      .post(`${URL_RESET_PASSWORD}`, dataForm)
      .then((response) => {
        successToast(asRecoveryText);
      sayAlteration();
      })
      .catch((error) => {
        handleErrors({
          error,
          setFieldErrors,
          errorMessages: errorRecovery,
          errorToast
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <S.Main>
      <div>
        <S.Top>
          <S.Title>System Engineering</S.Title>
          <S.P>O SEU SISTEMA DE ENGENHARIA</S.P>
        </S.Top>

        <div>
          <S.ContainerForm>
            <S.Form>
              <InputsRecovery {...InputProps} />
              <S.Div>
                <ButtonRed named={'voltar'} onClick={() => navigate(naveLogin)} />
                <Button
                  type={asSubmit}
                  named={isSubmitting ? 'enviando...' : 'enviar'}
                  onClick={(event) => {
                    if (!isSubmitting) {
                      handlePost(event);
                    }
                  }}
                  disabled={isSubmitting}
                />
              </S.Div>
            </S.Form>
          </S.ContainerForm>
        </div>
      </div>
    </S.Main>
  );
};

export default Recovery;
