import styled from "styled-components";
import * as M from "@mui/material"

export const Select = styled(M.Select)`
  && {
    height: 2.5rem;

  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
`;

export const Container = styled.div`
  /* padding: 0 0.5rem; */
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ContainerEndPosition = styled.div``;
export const EndPosition = styled.div`
position: fixed;
bottom: 0;
right: 50%;
transform: translateX(50%);
margin-bottom: 0.5rem;
`;

export const Search = styled(M.TextField)`
  && {
    height: 2.5rem;
    width: 18rem;
    input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
