import * as M from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ITEMS_PER_PAGE } from '../../../constants/constants/constAndRegex';
import { capitalizeFirstString, formatCapitalize, formatDate, settingState } from '../../../constants/functions/functions';
import { URL_PROJECT } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import EraseDialog from '../../common/Dialog/EraseDialog/EraseDialog';
import { ButtonLarge } from '../../common/Reuse/Button/Buttons/Buttons';
import H1 from '../../common/Reuse/H1/H1';
import Modal from '../../common/Reuse/Modal/Modal';
import Table from '../../common/Table/Table';
import DetailProject from '../DetailProject/DetailProject';
import EditProject from '../EditProject/EditProject';
import ProjectTypes from '../ProjectTypes/ProjectTypes/ProjectTypes';
import RegisterProject from '../RegisterProject/RegisterProject';
import * as L from './styled';

const Project = () => {
  const {
    states: {
      globalState: { lookAlteration, id }
    },
    functions: { headerGlobal }
  } = useContext(GlobalContext);

  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [data, setData] = useState('');
  const [allPages, setAllPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [register, setRegister] = useState(false);
  const [registerTypes, setRegisterTypes] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState(false);
  const [registerSub, setRegisterSub] = useState(false);
  const [erase, setErase] = useState(false);

  const propsList = {
    open,
    setOpen,
    registerTypes,
    setRegisterTypes,
    registerSub,
    setRegisterSub,
    register,
    setRegister,
    edit,
    setEdit,
    detail,
    setDetail,
    setErase,
    erase,
    id
  };
  console.log(data)
  const header = [
    { name: 'Projeto', field: 'project', styled: formatCapitalize },
    {
      name: 'Cliente',
      field: (x) =>
        x.customer?.type === 'physical'
          ? x.customer?.name
          : x.customer?.type === 'company'
          ? x.customer?.social_reason
          : '-'
    },
    {
      name: 'Tipo de Projeto',
      field: 'project_projectype.project_type'
    },
    { name: 'Data Inicial', field: 'start_date', styled: formatDate },
    { name: 'Data Final', field: 'final_date', styled: formatDate },
    { name: 'Imagem', field: 'image' },
    { name: 'Ações', field: 'acoes' }
  ];

  const handlePageChange = (event, value) => {
    setCurrentPageNumber(value);
    if (!searchName) {
      sessionStorage.setItem('cp_project', value);
      fetchPage(value);
    } else {
      fetchPageName(value);
    }
  };

  // mudar de página com click nos números
  const fetchPage = async (pageToUse) => {
    const url = `${URL_PROJECT}?page=${pageToUse}`;
    setIsLoading(true);
    try {
      const response = await axios.get(url, { headers: headerGlobal() });
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // mudar de página com click nos números quando há valor no input
  const fetchPageName = async (pageToUse) => {
    setIsLoading(true);
    const encodedSearchName = encodeURIComponent(searchName);
    const url = `${URL_PROJECT}?project=${encodedSearchName}&page=${pageToUse}`;
    try {
      const response = await axios.get(url, { headers: headerGlobal() });
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // pesquisa pelo input
  const searchForName = async (name) => {
    setIsLoading(true);
    const encodedSearchName = encodeURIComponent(name);
    const valueUrlSearch = `${URL_PROJECT}?project=${encodedSearchName}`;
    try {
      const response = await axios.get(valueUrlSearch, { headers: headerGlobal() });
      setCurrentPageNumber(1);
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect de salvar, recuperar número da página e alterar as páginas
  useEffect(() => {
    const handleFetch = async () => {
      const pageNumber = searchName ? currentPageNumber : sessionStorage.getItem('cp_project') || 1;
      setCurrentPageNumber(Number(pageNumber));
      if (searchName) {
        await searchForName(searchName, Number(pageNumber));
      } else {
        await fetchPage(Number(pageNumber));
      }
    };

    handleFetch();
  }, [searchName, lookAlteration]);

 // auto complete
  // onOpen
  const handleOpen = () => {
    if (searchName && searchName.length > 0) {
      setAutocompleteOpen(true);
    }
  };

  // onClose
  const handleClose = () => {
    setAutocompleteOpen(false);
  };

  // onChange
  const handleChange = (event, newValue) => {
    setSearchName(newValue || '');
  };

  // onInputChange
  const handleInputChange = (event, newValue) => {
    setAutocompleteOpen(Boolean(newValue && newValue.length > 0));
    setSearchName(newValue);
  };

  // resultados únicos na pesquisa do input
  const uniqueResults = useMemo(() => {
    const capitalizedResults = data?.results?.map(item => capitalizeFirstString(item.project));
    return [...new Set(capitalizedResults)];
  }, [data]);

  // input de pesquisa
  const renderSearchInput = (params) => <L.Search {...params} size="small" label="Projeto" variant="outlined" />;

  return (
    <L.Main>
      <H1 text={'projeto'} />
      <L.Container>
        <L.BoxTitle>
          <L.Row>
            {/* <L.Select
              value={typePeople}
              onChange={(event) => toggleData('typePeople', event.target.value)}
              displayEmpty
            >
              <M.MenuItem value={asPeople}>{'Pessoa Física'}</M.MenuItem>
              <M.MenuItem value={asCompany}>{'Pessoa Jurídica'}</M.MenuItem>
            </L.Select> */}

            <M.Autocomplete
              open={autocompleteOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              options={uniqueResults}
              value={searchName}
              onChange={handleChange}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option}
              renderInput={renderSearchInput}
            />
          </L.Row>

          <L.DivAjustButton>
            <ButtonLarge
              onClick={() => {
                settingState(setRegister, !register);
              }}
              named={'cadastrar projeto'}
            />
            <L.ButtonMedium
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                settingState(setOpen, !open);
              }}
            >
              tipos de projeto
            </L.ButtonMedium>
          </L.DivAjustButton>
        </L.BoxTitle>
        <Table
          column={header}
          state={data && data.results}
          onClickDetail={() => settingState(setDetail, !detail)}
          onClickEdit={() => settingState(setEdit, !edit)}
          onClickDelete={() => settingState(setErase, !erase)}
        />
        {/* modal de registrar */}
        {register && <Modal open={register} component={<RegisterProject {...propsList} />} />}

        {/* Modal de detalhes */}
        {detail && <Modal open={detail} component={<DetailProject {...propsList} />} />}

        {/* Modal de editar */}
        {edit && <Modal open={edit} component={<EditProject {...propsList} />} />}

        {/* modal de abrir tipos de projeto */}
        {open && <Modal open={open} component={<ProjectTypes {...propsList} />} />}

        {/* Dialog de excluir */}
        {erase && <EraseDialog url={URL_PROJECT} {...propsList} />}

        {/* Paginação */}
        <L.ContainerEndPosition>
          <L.EndPosition>
            <M.Pagination count={allPages} page={currentPage} onChange={handlePageChange} variant="rounded" />
          </L.EndPosition>
        </L.ContainerEndPosition>
      </L.Container>
    </L.Main>
  );
};

export default Project;
