import * as M from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

const documentMasks = {
  cpf: '999.999.999-99',
  cnpj: '99.999.999/9999-99',
  rg: '9999.999.999-999',
  cep: '99999-999',
  cnh: '99999999999'
};

const extractDigits = (value) => value.replace(/\D/g, '');

const InputFormats = React.memo(
  ({
    label,
    name,
    value,
    pattern,
    title,
    required,
    onChange,
    error,
    documentType,
    length,
    regexMessage,
    conditionMessage
  }) => {
    const mask = documentMasks[documentType] || '';
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
      setLocalValue(value ?? '');
    }, [value]);

    const handleLocalChange = useCallback(
      (event) => {
        const numericValue = extractDigits(event.target.value);
        setLocalValue(numericValue);
        onChange({ ...event, target: { ...event.target, name, value: numericValue } });
      },
      [setLocalValue, onChange, name]
    );

    const errorMessage =
    error &&
    (
      (conditionMessage[name] ? conditionMessage[name] + '. ' : '') +
      (regexMessage[name] ? regexMessage[name] : '')
    );
    return (
      <>
        <M.Tooltip title={errorMessage} placement="top-start">
          <div style={{width: '100%'}}>
            <InputMask mask={mask} maskChar="" value={localValue} onChange={handleLocalChange}>
              {(inputProps) => (
                <M.TextField
                  label={label}
                  name={name}
                  fullWidth
                  inputProps={{
                    maxLength: length,
                    pattern: pattern,
                    title: title,
                    ...inputProps
                  }}
                  required={required}
                  error={error}
                />
              )}
            </InputMask>
          </div>
        </M.Tooltip>
      </>
    );
  }
);

export default InputFormats;
