import React from "react"
import * as M from "@mui/material"
import { Delete } from "@mui/icons-material"

const InputCheckboxGroup = ({
  label,
  saveName,
  options,
  error,
  disabled,
  setDataForm,
  dataForm,
  conditionMessage,
  regexMessage,
  deleteOption,
}) => {
  const handleInputChangeCheckbox = (event) => {
    const { name, checked } = event.target
    setDataForm((prevState) => {
      const currentFieldData = prevState[saveName] || []
      const nameAsNumber = isNaN(parseInt(name, 10)) ? name : parseInt(name, 10)

      if (checked) {
        if (!currentFieldData.includes(nameAsNumber)) {
          currentFieldData.push(nameAsNumber)
        }
      } else {
        const index = currentFieldData.indexOf(nameAsNumber)
        if (index > -1) {
          currentFieldData.splice(index, 1)
        }
      }
      return {
        ...prevState,
        [saveName]: currentFieldData,
      }
    })
  }

  const errorMessage =
    error &&
    (conditionMessage?.[saveName] ? conditionMessage?.[saveName] + ". " : "") +
      (regexMessage[saveName] ? regexMessage[saveName] : "")
  return (
    <>
      <M.Tooltip title={errorMessage} placement="top-start">
        <M.FormControl
          fullWidth
          component="fieldset"
          variant="outlined"
          error={error}
          style={error ? { border: "1px solid red", borderRadius: "4px" } : {}}
        >
          <M.Box sx={{ display: "flex", alignItems: "center" }}>
            <M.FormLabel component="legend" style={{ color: "#666666" }}>
              {label}
            </M.FormLabel>
            {deleteOption && (
              <M.Button sx={{ height: "30px", padding: 0, minWidth: "35px" }} onClick={deleteOption}>
                <Delete sx={{ color: "red", width: "20px", height: "20px" }} />
              </M.Button>
            )}
          </M.Box>
          <M.FormGroup row={true}>
            {/* Adicione estes estilos aqui */}
            {options?.map((option, index) => (
              <div key={index}>
                <M.FormControlLabel
                  control={
                    <M.Checkbox
                      name={String(option.value)}
                      checked={Boolean(dataForm[saveName]?.includes(option.value))}
                      onChange={handleInputChangeCheckbox}
                      disabled={disabled}
                    />
                  }
                  sx={{ wordBreak: "break-word" }}
                  label={M.capitalize(option.label)}
                />
              </div>
            ))}
          </M.FormGroup>
        </M.FormControl>
      </M.Tooltip>
    </>
  )
}

export default InputCheckboxGroup
