import * as S from '../styledDialogs';
import * as M from '@mui/material';
import { Button, ButtonRed } from '../../Reuse/Button/Buttons/Buttons';
import { GlobalContext } from '../../../../global/GlobalContext';
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { asAccept, asAccepting, asSubmit } from '../../../../constants/constants/constAndRegex';
import { settingState } from '../../../../constants/functions/functions';

const EmailDialog = (props) => {
  const {
    functions: { successToast, errorToast, headerGlobalString, sayAlteration },
  } = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { url, setEmailAlert, id, emailAlert } = props;

  const handleSend = () => {
    setIsSubmitting(true);
    const requestData = {}; // Se houver dados a serem enviados, defina-os aqui
    axios
      .post(`${url}${id}/`, requestData, { headers: headerGlobalString() })
      .then((response) => {
        sayAlteration();
        successToast('E-mail enviado');
        settingState(setEmailAlert, !emailAlert);
      })
      .catch((error) => {
        console.log(error);
        errorToast('E-mail não enviado')
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };


  return (
    <>
      <S.Dialog open={emailAlert}>
        <S.DialogTitle>Enviar e-mail?</S.DialogTitle>
        <M.DialogContent>
          <S.DialogContentText>Essa ação não pode ser desfeita</S.DialogContentText>
        </M.DialogContent>

        <S.DialogActions>
          <ButtonRed onClick={() => settingState(setEmailAlert, !emailAlert)} named={'cancelar'}></ButtonRed>
          <Button
            type={asSubmit}
            named={isSubmitting ? asAccepting : asAccept}
            onClick={() => {
              if (!isSubmitting) {
                handleSend();
              }
            }}
            disabled={isSubmitting}
          />
        </S.DialogActions>
      </S.Dialog>
    </>
  );
};

export default EmailDialog;
