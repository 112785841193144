import styled from 'styled-components';
import * as M from '@mui/material';

export const ButtonCustom = styled(M.Button)`
  && {
    background-color: #0a7377;
    :hover {
      background-color: #0a7377;
    }
  }
`;

export const IconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
    }
    background-color: #0a7377;
    width: 2rem;
    border-radius: 0.2rem;
    cursor: pointer;
    color: #ffff;
    text-shadow: 10px 20px 20px rgba(0, 0, 0, 0.25);
    &:hover {
      background-color: #128c8e;
    }
  }
`;


export const Dialog = styled(M.Dialog)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13.5px);
  -webkit-backdrop-filter: blur(13.5px);
`;

export const DialogTitle = styled(M.DialogTitle)`
  display: flex;
  justify-content: center;
`;

export const DialogContentText = styled(M.DialogContentText)`
  display: flex;
  justify-content: center;
`;

export const DialogActions = styled(M.DialogActions)``;

export const ButtonRed = styled(M.Button)`
  && {
    background: none;
    border: none;
    cursor: pointer;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;

export const Button = styled(M.Button)`
  && {
    border: none;
    padding: 1rem;
    cursor: pointer;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;

export const Styled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;
export const Span = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  color: #006978;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 3px;
`;

export const ContentStyle = styled.div`
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GapStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
`;
export const GapColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 0.1rem;
  flex-direction: column;
`;

export const Error = styled.div`
  border: 2px solid red;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;


export const IconButtonModalExtraSmall = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    width: 1rem;
    height: 1.1rem;
    border-radius: 0.2rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;

export const IconButtonModalExtraSmallRed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    width: 1rem;
    height: 1.1rem;
    border-radius: 0.2rem;
    cursor: pointer;
    color: #cc0000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffffff;
      background-color: #cc0000;
    }
  }
`;
