import styled from "styled-components";
import * as M from "@mui/material";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Center = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  /* position: absolute; */
`;


export const footer = styled.div`
background-color: #0a7377 ;
height: 0.5rem;
width: 100%;
position: absolute;
  bottom: 0;
`;


export const Rendered = styled.div`
width: 100%;
`;

export const RenderedMenu = styled.div`
`;


export const StyledBox = styled.div`
  position: relative;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  transform: translateY(${(props) => (props.state === 'entered' ? 0 : '100%')});
  opacity: ${(props) => (props.state === 'entered' ? 1 : 0)};

  // Aumentar a largura da barra de rolagem
  ::-webkit-scrollbar {
    width: 20px; // Defina a largura da barra de rolagem
  }

  // Estilize a barra de rolagem conforme desejado
  ::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #AAA;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }
`;

export const DivClock = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
`;
export const DivButtonMenu = styled.div`
  display: flex;
  margin-left: 0.5rem;
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 2rem;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const P = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 1.1rem;
  color: #ffffff;
  font-weight: 600;
`;
export const TopTitle = styled.div``;

export const ButtonMenu = styled(M.Button)`
  && {
    cursor: pointer;
    width: 5rem;
    height: 2.3rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
export const ButtonLogout = styled(M.Button)`
  && {
    cursor: pointer;
    width: 5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;

export const Dialog = styled(M.Dialog)`
  && {
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(13.5px);
    -webkit-backdrop-filter: blur(13.5px);
    padding: 0;
  }
`;

export const DialogTitle = styled(M.DialogTitle)`
  && {
    text-align: center;
  }
`;

export const DialogContentText = styled(M.DialogContentText)`
  && {
    text-align: center;
  }
`;

export const DialogActions = styled(M.DialogTitle)`
  && {
    gap: 1rem;
    display: flex;
    padding: 1rem;
  }
`;

export const Button = styled(M.Button)`
  && {
    border-radius: 0.5rem;
    cursor: pointer;
    height: 2.5rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;

export const ButtonRed = styled(M.Button)`
  && {
    cursor: pointer;
    border-radius: 0.5rem;
    height: 2.5rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;
