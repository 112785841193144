import * as M from "@mui/material"
import styled from "styled-components"

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const Container = styled.div`
  display: flex;
  width: 55rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`

export const H2 = styled.h2`
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  color: #006978;
`

export const ScroolingDiv = styled.div`
  display: flex;
  flex-direction: column; // Coloca os itens em colunas sequencialmente
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0.5rem 0 1rem 0;
  max-width: 55rem;
  gap: 0.2rem;
`

export const H2Custom = styled.h2`
  display: flex;
  justify-content: right;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  color: #006978;
  align-items: center;
  gap: 0.2rem;
  margin-right: 3rem;
`

export const DivGap = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`

export const IconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`

export const IconButtonModalRed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #ff3333;
    }
  }
`

export const Span = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #006978;
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  width: 100%;
`
