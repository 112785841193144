import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { asDetalhes } from '../../../constants/constants/constAndRegex';
import { settingState } from '../../../constants/functions/functions';
import { URL_COMPANY } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import useForm from '../../../hooks/useForm';
import useMultForm from '../../../hooks/useMultForm';
import Formulary from '../../common/Formulary/Formulary/Formulary';
import InputsClientCompany from '../../common/Formulary/InputsClient/InputsClientCompany/InputsClientCompany';
import TitleModal from '../../common/Reuse/TitleModal/TitleModal';
import DetailClientLegalRepresentative from './DetailClientLegalRepresentative';
import * as L from './styledCompany';

const DetailClientCompany = (props) => {
  const {
    states: {
      globalState: { id, lookAlteration },
    },
    functions: { headerGlobal },
  } = useContext(GlobalContext);
  const { detail, setDetail } = props;

  const {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
  } = useMultForm();

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const propsList = {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_COMPANY}${id}/`, {
        headers: headerGlobal(),
      });
      setDataForm(response.data);
    } catch (error) {
      console.log('Error getInfo');
    }
  };

  useEffect(() => {
    getInfo();
  }, [lookAlteration]);

  return (
    <L.Main>
      <Formulary onClick={() => settingState(setDetail, !detail)}>
        <L.BoxSelect>
          <TitleModal text={asDetalhes} />
        </L.BoxSelect>
        <L.DivGap>
          <InputsClientCompany {...propsList} />
          <DetailClientLegalRepresentative />
        </L.DivGap>
      </Formulary>
    </L.Main>
  );
};
export default DetailClientCompany;
