import { useCallback, useState } from "react"
import { generateId } from "../constants/functions/functions"

const useMultForm = () => {
  const [multDataForm, setMultDataForm] = useState([])
  const [multFieldErrors, setMultFieldErrors] = useState([])
  const [multRegexMessage, setMultRegexMessage] = useState([])
  const [multConditionMessage, setMultConditionMessage] = useState([])

  const addForm = useCallback(() => {
    const newForm = {
      id: generateId(),
    }

    setMultDataForm([...multDataForm, newForm])
  }, [multDataForm])

  const removeForm = (formId) => {
    setMultDataForm((prevState) => prevState.filter((form) => form.id !== formId))
  }

  const multInputForm = (formId, fieldName, value) => {
    setMultDataForm((prevState) => {
      // Atualizando o valor do campo no formulário com o ID correspondente
      return prevState.map((form) => {
        if (form.id === formId) {
          return { ...form, [fieldName]: value }
        }
        return form
      })
    })

    setMultFieldErrors((prevState) => {
      // Atualizando o estado de erro para o campo no formulário com o ID correspondente
      return {
        ...prevState,
        [formId]: {
          ...prevState[formId],
          [fieldName]: false, // Reseta o erro para o campo específico
        },
      }
    })
  }

  return {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
  }
}

export default useMultForm
