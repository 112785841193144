import styled from "styled-components";

export const Main = styled.div`
width: 100%;

display: flex;
justify-content: center;
align-items: center;
padding-top: 10rem;
`;

export const ContainerForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
width: 25rem;
  gap: 1rem;
`;

export const Form = styled.form`
  gap: 1rem;
  width: 25rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;


