import React, { useState, useEffect } from 'react';
import * as M from "@mui/material";
import { extractDigits } from '../../../../../constants/functions/functions';

const InputPhone = ({ label, name, value, title, error, onChange, length, regexMessage,
  conditionMessage }) => {

  useEffect(() => {
    setLocalValue(value ? formatPhoneNumber(value) : "");
  }, [value]);

  const formatPhoneNumber = (value) => {
    let extractedDigits = (value);
    if (extractedDigits.length <= 10) {
      extractedDigits = extractedDigits.replace(/(\d{2})(\d)/, "($1) $2");
      extractedDigits = extractedDigits.replace(/(\d{4})(\d)/, "$1-$2");
    } else {
      extractedDigits = extractedDigits.replace(/(\d{2})(\d)/, "($1) $2");
      extractedDigits = extractedDigits.replace(/(\d{1})(\d{4})(\d)/, "$1.$2-$3");
    }
    return extractedDigits;
  };
  const initialValue = value ? formatPhoneNumber(value) : "";
  const [localValue, setLocalValue] = useState(initialValue);

  const handleLocalChange = (event) => {
    const numericValue = extractDigits(event.target.value);
    setLocalValue(formatPhoneNumber(numericValue));

    const modifiedEvent = {
      ...event,
      target: {
        ...event.target,
        name: name, // Utiliza a prop 'name' para identificar o campo
        value: numericValue // Valor não formatado
      }
    };
    onChange(modifiedEvent);
  };

  const errorMessage =
  error &&
  (
    (conditionMessage[name] ? conditionMessage[name] + '. ' : '') +
    (regexMessage[name] ? regexMessage[name] : '')
  );

  return (
    <>
    <M.Tooltip title={errorMessage} placement="top-start">
      <M.TextField
        label={label}
        name={name}
        value={localValue}
        onChange={handleLocalChange}
        fullWidth
        inputProps={{
          title: title,
          maxLength: length,
        }}
        error={error}
      />
      </M.Tooltip>
    </>
  );
};

export default InputPhone;
