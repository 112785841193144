import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import {
  asDefaultAlteration,
  asDefaultErrorAxios,
  asSend,
  asSending,
  asSubmit,
} from "../../../../constants/constants/constAndRegex"
import { DATA_SUBPROJECTTYPE } from "../../../../constants/constants/dataToSend"
import { requiredSubProjectType } from "../../../../constants/constants/requiredConst"
import { handleMultErrors, settingState, validateMultForm } from "../../../../constants/functions/functions"
import { URL_PROJECT_TYPE, URL_SUBPROJECT } from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import useMultForm from "../../../../hooks/useMultForm"
import ClearDialogDinamic from "../../../common/Dialog/ClearDialog/ClearDialogDinamic"
import InputsSubProjectTypes from "../../../common/Formulary/InputsSubProjectTypes/InputsSubProjectTypes"
import { Button, ButtonRed } from "../../../common/Reuse/Button/Buttons/Buttons"
import * as L from "./styled"

const DetailSubProjectTypes = (props) => {
  const {
    states: {
      globalState: { lookAlteration, id },
    },
    setters: { setGlobalState },
    functions: { headerGlobal, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext)
  const [clearDinamicDialog, setClearDinamicDialog] = useState(false)
  // const [registerSub, setRegisterSub] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { viewSub, setViewSub, erase, setErase, setRegisterSub, registerSub, registerOnEdit, setRegisterOnEdit } = props

  const {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
  } = useMultForm()

  const propsList = {
    registerSub,
    setRegisterSub,
    setClearDinamicDialog,
    clearDinamicDialog,
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
    registerOnEdit,
  }

  const errorHandlers = {
    email: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].email = errors.email[0]
      errorToast("E-mail já está em uso")
    },
    cpf: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].cpf = errors.cpf[0]
      errorToast("CPF já está em uso")
    },
  }

  const sendData = async (item) => {
    const dataToSend = DATA_SUBPROJECTTYPE(item)
    const combinedData = {
      ...dataToSend,
      project_type: id,
    }

    await axios.put(`${URL_SUBPROJECT}${item.id}/`, combinedData, {
      headers: {
        ...headerGlobal(),
        "Content-Type": "multipart/form-data",
      },
    })

    return item.id
  }

  const handlePut = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    const allFormsValid = validateMultForm({
      specificValidations: requiredSubProjectType,
      multDataForm,
      setMultFieldErrors,
      setMultRegexMessage,
      setMultConditionMessage,
    })

    if (!allFormsValid) {
      setIsSubmitting(false)
      errorToast(asDefaultErrorAxios)
      return
    }

    let newFieldErrors = { ...multFieldErrors }
    let updatedMultDataForm = [...multDataForm]

    for (const item of multDataForm) {
      try {
        await sendData(item)
        updatedMultDataForm = updatedMultDataForm.filter((form) => form.id !== item.id)
      } catch (error) {
        handleMultErrors({ error, item, newFieldErrors, errorHandlers })
      }
    }

    setMultFieldErrors(newFieldErrors)
    setMultDataForm(updatedMultDataForm)

    if (updatedMultDataForm.length === 0) {
      sayAlteration()
      setIsSubmitting(false)
      settingState(setViewSub, !viewSub)
      successToast(asDefaultAlteration)
    }
  }

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_PROJECT_TYPE}${id}/`, {
        headers: headerGlobal(),
      })
      let data = response.data.projectType_subprojects
      // Ordena os dados pelo campo 'id' em ordem crescente
      data.sort((a, b) => a.id - b.id)

      setMultDataForm(data)
    } catch (error) {
      console.log("Error getInfo")
    }
  }

  useEffect(() => {
    getInfo()
  }, [lookAlteration])

  return (
    <L.Main>
      <L.ScroolingDiv>
        {Array.isArray(multDataForm) &&
          multDataForm?.map((form) => (
            <span key={form.id}>
              <InputsSubProjectTypes formId={form.id} {...propsList} />
              <L.H2Custom>
                Deletar Subtipo de Projeto
                <L.IconButtonModalRed type="button">
                  <RemoveIcon />
                </L.IconButtonModalRed>
              </L.H2Custom>
            </span>
          ))}
      </L.ScroolingDiv>

      <L.H2>
        Subtipo de Projeto
        <L.IconButtonModal type="button">
          <AddIcon />
        </L.IconButtonModal>
      </L.H2>

      <L.DivGap>
        {multDataForm.length >= 1 && (
          <>
            <ButtonRed named={"limpar"} />
            <Button type={asSubmit} named={isSubmitting ? asSending : asSend} disabled={isSubmitting} />
          </>
        )}
      </L.DivGap>

      {/* diálogo de limpar */}
      <ClearDialogDinamic {...propsList} />
    </L.Main>
  )
}

export default DetailSubProjectTypes
