import styled from "styled-components";
import * as M from "@mui/material";

export const Custon10 = styled.div`
  display: flex;
  min-width: 10rem;
`;

export const LoadingOverlay = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 55rem;
`;

export const H2 = styled.h2`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #006978;
  margin: 1rem 0 0 0;
`;

export const IconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;

export const DivGap = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-evenly;
`;

export const Box = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;
export const BoxCont = styled.div`
  display: flex;
align-items: center;
`;

export const ButtonCustom = styled(M.Button)`
  && {
    background-color: #0a7377;
    :hover {
      background-color: #0a7377;
    }
  }
`;

export const BoxFile = styled.div`
  display: flex;
  gap: 0.2rem;
  width: 100%;
`;


export const Custon15rem = styled.div`
  display: flex;
  width: 15rem;
`;
export const Custon2 = styled.div`
  display: flex;
  width: 17.8rem;
`;
