import styled from "styled-components";
import * as M from "@mui/material";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 0.5rem;
`;

export const Table = styled.table`
font-family: Cochin;
  border-radius: 0.3rem;
  background-color: #affce9;
  border-collapse: collapse;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 0.5rem;
  width: 100%;
`;

export const HiddenDiv = styled.td`
 position: fixed;
 top: -9999px;
`;

export const Th = styled.td`
  height: 1.5rem;
  padding: 0.5rem;
  white-space: nowrap;
  background-color: #0a7377;
  color: #fff;
  border: 1px solid #fff;
`;

export const Td = styled.td`
  min-height: 1.5rem;
  padding: 0.5rem;
  border: 1px solid #fff;
  /* max-height: 3rem; */
  /* overflow: auto; */
`;

export const EndPosition = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin-left: -2rem;
`;

export const ContainerEndPosition = styled.div``;

export const Container = styled.div`
  /* padding: 0 0.5rem; */
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const DivAjustButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  width: 100%;
`;

export const SizeTitle = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const H1 = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  color: #006978;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin-top: 1.7rem;
`;

export const ButtonLarge = styled(M.Button)`
  && {
    cursor: pointer;
    width: 15rem;
    height: 2.5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
export const ButtonMedium = styled(M.Button)`
  && {
    cursor: pointer;
    width: 13rem;
    height: 2.5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;

export const Modal = styled(M.Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
`;

export const Dialog = styled(M.Dialog)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13.5px);
  -webkit-backdrop-filter: blur(13.5px);
`;

export const DialogTitle = styled(M.DialogTitle)`
  && {
    text-align: center;
  }
`;

export const DialogContentText = styled(M.DialogContentText)`
  && {
    text-align: center;
  }
`;

export const DialogActions = styled(M.DialogTitle)`
  && {
    gap: 1rem;
    display: flex;
    padding: 1rem;
  }
`;

export const Button = styled(M.Button)`
  && {
    border-radius: 0.5rem;
    cursor: pointer;
    height: 2.5rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
export const ButtonUpload = styled(M.Button)`
  && {
    border-radius: 0.2rem;
    font-size: 10px;
    cursor: pointer;
    height: 1.1rem;
    width: min-content;
    margin: 0.5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;

export const ButtonRed = styled(M.Button)`
  && {
    cursor: pointer;
    border-radius: 0.5rem;
    height: 2.5rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;

export const Box = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const TopTable = styled.div`
  background-color: #0a7377;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  border-radius: 0.3rem;
`;

export const BodyTable = styled.div`
  /* margin: 0 auto; */
`;

export const MiddleTable = styled.div`
  background-color: #0a7377;
  border-radius: 0.3rem;
`;

export const ItemTopTable = styled.div`
  margin: 0 auto;
`;
export const ItemTable = styled.div`
  display: flex;
`;

export const ClienteTable = styled.table`
  border-collapse: collapse;
  position: sticky;
  top: 0;
  z-index: 1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 100%;
  background-color: #fff;
`;

export const ClienteTh = styled.th`
  //cabeçalho
  border: 1px solid #ddd;
  color: white;
  height: 2rem;
  white-space: nowrap;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #0a7377;
  padding: 0.15rem 0.25rem;
`;

export const ClienteTdNoWrap = styled.td`
  border: 1px solid #ddd;
  white-space: nowrap;
  padding: 0.15rem 0.25rem;
`;
export const ClienteDefault = styled.td`
  border: 1px solid #ddd;
  padding: 0.15rem 0.25rem;
`;

export const ClienteTdNome = styled.td`
  border: 1px solid #ddd;
  word-break: break-word;
  padding: 0.15rem 0.25rem;
`;

export const ClienteTdDataInicial = styled.td`
  border: 1px solid #ddd;
  word-break: break-word;
  padding: 0.15rem 0.25rem;
`;
export const ClienteTdDataFinal = styled.td`
  border: 1px solid #ddd;
  word-break: break-word;
  padding: 0.15rem 0.25rem;
`;
export const ClienteTdArea = styled.td`
  border: 1px solid #ddd;
  word-break: break-word;
  padding: 0.15rem 0.25rem;
`;
export const ClienteTdTValor = styled.td`
  border: 1px solid #ddd;

  padding: 0.15rem 0.25rem;
`;
export const ClienteTdLocalizacao = styled.td`
  border: 1px solid #ddd;

  padding: 0.15rem 0.25rem;
`;

export const ClienteTdTipoProjeto = styled.td`
  border: 1px solid #ddd;
  word-break: break-word;
  padding: 0.15rem 0.25rem;
`;

export const ClienteTdDescricao = styled.td`
  border: 1px solid #ddd;
  padding: 0.15rem 0.25rem;
`;

export const ClienteTdEquipamentos = styled.td`
  border: 1px solid #ddd;
  word-break: break-word;
  padding: 0.15rem 0.25rem;
`;

export const ClienteTdFuncionarios = styled.td`
  border: 1px solid #ddd;

  padding: 0.15rem 0.25rem;
  white-space: nowrap;
`;

export const ClienteTd = styled.td`
  border: 1px solid #ddd;
  text-align: center;
`;

export const Capitalize = styled.td`
  text-transform: capitalize;
  border: 1px solid rgba(224, 224, 224, 1);
`;

// ==================================
export const Hidden = styled.div`
  position: fixed;
  left: -9999px;
  top: 0;
`;

// ==================================
export const DivGap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconButton = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1.3rem;
    }
    height: 2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;

export const IconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;
export const MiniIconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1.3rem;
    }
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #ffff;
    background-color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;

export const BorderRed = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  /* width: max-content; */
  border: 1px solid red;
  /* background-color: red; */
  /* width: max-content; */
  /* max-height: 5rem; */
  /* overflow-y: auto; */
  /* overflow-x: auto; */
`;
export const TextFieldModal = styled(M.TextField)`
  && {
    /* height: 1rem; */
    /* padding: 0; */
    /* margin-bottom: 0.5rem; */
  }
`;
export const FormControlLabel = styled(M.FormControlLabel)`
  && {
    margin-right: 0.5rem;
    padding: 0;
  }
`;

export const IconButtonModalRed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #cc0000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffffff;
      background-color: #fe3232;
    }
  }
`;

export const MiniIconButtonModalRed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1.3rem;
    }
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #ffff;
    background-color: #cc0000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffffff;
      background-color: #fe3232;
    }
  }
`;

export const IconButtonRed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1.3rem;
    }
    height: 2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #cc0000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffffff;
      background-color: #fe3232;
    }
  }
`;

export const IconButtonClose = styled(M.IconButton)`
  && {
    padding: 1rem;
    cursor: pointer;
    background-color: #0a7377;
    color: #ffff;
    border-radius: 0.5rem;
    width: 5rem;
    height: 2.5rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #0a7377;
    }
  }
`;

export const ButtonCLoseModal = styled(M.IconButton)`
  && {
    border-radius: 0.5rem;
    cursor: pointer;
    height: 2.5rem;
    height: 2.5rem;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      background-color: #128c8e;
      color: #ffff;
    }
  }
`;

export const Search = styled(M.TextField)`
  && {
    height: 2.5rem;
    width: 18rem;
    input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
export const TextField = styled(M.TextField)`
  && {
    height: 2.5rem;
    input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const Select = styled(M.Select)`
  && {
    height: 2.5rem;
  }
`;

export const FormControl = styled(M.FormControl)`
  && {
    margin: 0;
    padding: 0;
  }
`;

export const SelectPropost = styled(M.Select)`
  && {
    height: 2.2rem;
    width: 6rem;
    padding: 0;
  }
`;

export const TextFieldDescont = styled(M.TextField)`
  && {
    input {
      height: 1rem;
      width: 1.7rem;
    }

    input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
