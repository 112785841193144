export const URL_PHYSICAL = "http://projetosebs.com.br:8000/api/v1/person-physica/";
export const URL_BESTOWED = "http://projetosebs.com.br:8000/api/v1/bestowed/";
export const URL_COMPANY = "http://projetosebs.com.br:8000/api/v1/person-company/";
export const URL_LEGAL_REPRESENTATIVE = "http://projetosebs.com.br:8000/api/v1/legal-representative/";
export const URL_PROPOSAL = "http://projetosebs.com.br:8000/api/v1/proposal/";
export const URL_EQUIPAMENT = "http://projetosebs.com.br:8000/api/v1/equipment/";
export const URL_EMPLOYEE = "http://projetosebs.com.br:8000/api/v1/employee/";
export const URL_PROJECT = "http://projetosebs.com.br:8000/api/v1/project/";
export const URL_PROJECT_TYPE = "http://projetosebs.com.br:8000/api/v1/project-type/";
export const URL_SUBPROJECT = "http://projetosebs.com.br:8000/api/v1/subproject/";
export const URL_LETTER_OF_ATTORNEY = "http://projetosebs.com.br:8000/api/v1/letter-of-attorney/";
export const URL_PROPERTIE = 'http://projetosebs.com.br:8000/api/v1/properties/';
export const URL_ZIP_CODE = 'https://viacep.com.br/ws/:cep/json/';
export const URL_USER = 'http://projetosebs.com.br:8000/api/v1/auth/current-user/';
export const URL_LOGIN = 'http://projetosebs.com.br:8000/api/v1/auth/login/';
export const URL_CADASTRE = 'http://projetosebs.com.br:8000/api/v1/auth/register/';
export const URL_EDIT_USER = 'http://projetosebs.com.br:8000/api/v1/auth/edit/';
export const URL_RESET_PASSWORD = 'http://projetosebs.com.br:8000/api/v1/auth/reset-password/';
export const URL_EDIT_PASSWORD = 'http://projetosebs.com.br:8000/api/v1/auth/edit-password/';
export const URL_REFRESH_PASSWORD = 'http://projetosebs.com.br:8000/api/v1/auth/update-password/';
export const URL_LOGOUT = 'http://projetosebs.com.br:8000/api/v1/auth/logout/';
export const URL_LOGIN_TOKENS = 'http://projetosebs.com.br:8000/token/';
export const URL_LOGIN_REFRESH_TOKEN = 'http://projetosebs.com.br:8000/api/v1/auth/token-refresh/';
export const URL_All_STATES = 'http://servicodados.ibge.gov.br/api/v1/localidades/estados/';
export const URL_CITY_BY_ID = 'http://servicodados.ibge.gov.br/api/v1/localidades/estados/:id/municipios';
export const URL_PDF_PHYSICAL = 'http://projetosebs.com.br:8000/api/v1/pdf-person-physica/';
export const URL_PDF_COMPANY = 'http://projetosebs.com.br:8000/api/v1/pdf-person-company/';
export const URL_PDF_LETTEROFATTORNEY = 'http://projetosebs.com.br:8000/api/v1/pdf-letter-of-attorney/';
export const URL_PDF_PROPOSAL_MAIL = "http://projetosebs.com.br:8000/api/v1/pdf-proposal-email/"
export const URL_PDF_PROPOSAL = 'http://projetosebs.com.br:8000/api/v1/pdf-proposal/';
export const URL_TERMS_CONDITIONS = 'http://projetosebs.com.br:8000/api/v1/terms-conditions/';
export const URL_TERMS_CONDITIONS_TYPE = 'http://projetosebs.com.br:8000/api/v1/terms-conditions-types/';
export const URL_REQUEST_DOCUMENTS = 'http://projetosebs.com.br:8000/api/v1/request-documents/';
