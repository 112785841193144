import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  asClean,
  asDefaultErrorAxios,
  asDefaultRegister,
  asSubmit,
  asWelcome,
  naveEbs,
} from '../../constants/constants/constAndRegex';
import { errorRegister } from '../../constants/constants/constError';
import { requiredRegister } from '../../constants/constants/requiredConst';
import { changeValue, handleErrors, validateForm } from '../../constants/functions/functions';
import { URL_CADASTRE } from '../../constants/urls/urls';
import { GlobalContext } from '../../global/GlobalContext';
import useForm from '../../hooks/useForm';
import ClearDialog from '../common/Dialog/ClearDialog/ClearDialog';
import InputsCadastre from '../common/Formulary/InputsRegister/InputsRegister';
import { Button, ButtonRed } from '../common/Reuse/Button/Buttons/Buttons';
import * as S from './styledRegister';

const Register = () => {
  const {
    functions: { successToast, errorToast, headerGlobal, changeComponent },
  } = useContext(GlobalContext);
  const [openClearDialog, setOpenClearDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const InputProps = {
    regexMessage,
    conditionMessage,
    dataForm,
    inputForm,
    fieldErrors,
  };

  const clearDialogProps = {
    openClearDialog,
    setOpenClearDialog,
    setDataForm,
    setFieldErrors,
  };

  const handlePost = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const resultValidateForm = validateForm({
      specificValidations: requiredRegister,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    try {
      await axios.post(`${URL_CADASTRE}`, dataForm, {
        headers: headerGlobal(),
      });
      successToast(asDefaultRegister);
      changeComponent(asWelcome);
      navigate(naveEbs);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorRegister,
        errorToast,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <S.Main>
        <S.ContainerForm>
          <S.Form>
            <InputsCadastre {...InputProps} />
            <S.Div>
              <ButtonRed onClick={() => changeValue(openClearDialog, setOpenClearDialog)} named={asClean} />
              <Button
                type={asSubmit}
                named={isSubmitting ? 'confirmando...' : 'confirmar'}
                onClick={(event) => {
                  if (!isSubmitting) {
                    handlePost(event);
                  }
                }}
                disabled={isSubmitting}
              />
            </S.Div>
          </S.Form>
        </S.ContainerForm>
        <ClearDialog {...clearDialogProps} />
      </S.Main>
    </>
  );
};

export default Register;
