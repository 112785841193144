import * as M from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ITEMS_PER_PAGE } from '../../../constants/constants/constAndRegex';
import {
  formatCPForCNPJ,
  formatCapitalize,
  formatDate,
  formatLetterDoc,
  settingState
} from '../../../constants/functions/functions';
import { URL_LETTER_OF_ATTORNEY } from '../../../constants/urls/urls';
import { GlobalContext } from '../../../global/GlobalContext';
import EraseDialog from '../../common/Dialog/EraseDialog/EraseDialog';
import Modal from '../../common/Reuse/Modal/Modal';
import * as S from '../../common/StyledCommonComponents/StyledCommonComponents';
import Table from '../../common/Table/Table';
import Bestowed from '../Bestwed/Bestowed';
import DetailLetterOfAttorney from '../DetailLetterOfAttorney/DetailLetterOfAttorney';
import EditLetterOfAttorney from '../EditLetterOfAttorney/EditLetterOfAttorney';
import RegisterLetterOfAttorney from '../RegisterLetterOfAttorney/RegisterLetterOfAttorney';
import * as L from './styled';

const LetterOfAttorney = () => {
  const {
    states: {
      globalState: { id, lookAlteration }
    },
    functions: { headerGlobal, toggleData }
  } = useContext(GlobalContext);

  const [selectedType, setSelectedType] = useState(() => {
    const savedType = localStorage.getItem('s_letter');
    return savedType || 'ativo';
  });
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [data, setData] = useState('');
  const [allPages, setAllPages] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchName, setSearchName] = useState(null);
  const [register, setRegister] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState(false);
  const [erase, setErase] = useState(false);
  const [openBestowed, setOpenBestowed] = useState(false);
  const propsList = {
    register,
    setRegister,
    edit,
    setEdit,
    detail,
    setDetail,
    openBestowed,
    setOpenBestowed,
    id,
    erase,
    setErase
  };

  const header = [
    {
      name: 'Outorgante',
      field: (x) =>
        x.customer && x.customer.type === 'company' ? x.customer.social_reason : x.customer ? x.customer.name : '-',
      styled: formatCapitalize
    },
    {
      name: 'Tipologia do Documento',
      field: 'document_tipology',
      styled: formatLetterDoc
    },
    {
      name: 'Nº Documento',
      field: (x) => (x.customer && x.customer.type === 'company' ? x.customer.cnpj : x.customer ? x.customer.cpf : '-'),
      styled: formatCPForCNPJ
    },
    {
      name: 'Outorgado',
      field: (x) =>
        x.bestowed && x.bestowed.length > 0
          ? x.bestowed.map((bestow, index) => (
              <span key={index}>
                {formatCapitalize(bestow.name)}
                {index < x.bestowed.length - 1 ? ', ' : ''}
              </span>
            ))
          : '-'
    },
    { name: 'Data de Criação', field: 'date', styled: formatDate },
    { name: 'Validade', field: 'validate', styled: formatDate },
    { name: 'Ações', field: 'acoes' }
  ];

  const handlePageChange = (event, value) => {
    setCurrentPageNumber(value);
    if (!searchName) {
      sessionStorage.setItem('cp_letter', value);
      fetchPage(value);
    } else {
      fetchPageName(value);
    }
  };

  // mudar de página com click nos números
  const fetchPage = async (pageToUse) => {
    const url = `${URL_LETTER_OF_ATTORNEY}?active=${selectedType}&page=${pageToUse}`;
    try {
      const response = await axios.get(url, { headers: headerGlobal() });
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    }
  };

  // mudar de página com click nos números quando há valor no input
  const fetchPageName = async (pageToUse) => {
    const encodedSearchName = encodeURIComponent(searchName);
    const url = `${URL_LETTER_OF_ATTORNEY}?active=${selectedType}&customer_name=${encodedSearchName}&page=${pageToUse}`;

    try {
      const response = await axios.get(url, { headers: headerGlobal() });
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    }
  };

  // pesquisa pelo input
  const searchForName = async (name, currentPageNumber) => {
    sessionStorage.setItem('p_physical', currentPageNumber);
    const encodedSearchName = encodeURIComponent(name);
    const encodedSelectedType = encodeURIComponent(selectedType);
    const valueUrlSearch = `${URL_LETTER_OF_ATTORNEY}?active=${encodedSelectedType}&customer_name=${encodedSearchName}`;

    try {
      const response = await axios.get(valueUrlSearch, {
        headers: headerGlobal()
      });
      setCurrentPageNumber(1);
      setData(response.data);
      const count = response.data.count;
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE);
      setAllPages(pagesToRender);
    } catch (error) {
      console.error('Error', error);
    }
  };

  // useEffect de salvar, recuperar número da página e alterar as páginas
  useEffect(() => {
    const handleFetch = async () => {
      const pageNumber = searchName ? currentPageNumber : sessionStorage.getItem('cp_letter') || 1;
      setCurrentPageNumber(Number(pageNumber));
      if (searchName) {
        await searchForName(searchName, Number(pageNumber));
      } else {
        await fetchPage(Number(pageNumber));
      }
    };
    localStorage.setItem('s_letter', selectedType);
    handleFetch();
  }, [searchName, selectedType, lookAlteration]);

  // auto complete
  // onOpen
  const handleOpen = () => {
    if (searchName && searchName.length > 0) {
      setAutocompleteOpen(true);
    }
  };

  // onClose
  const handleClose = () => {
    setAutocompleteOpen(false);
  };

  // onChange
  const handleChange = (event, newValue) => {
    setSearchName(newValue || '');
  };

  // onInputChange
  const handleInputChange = (event, newValue) => {
    setAutocompleteOpen(Boolean(newValue && newValue.length > 0));
    setSearchName(newValue);
  };

  // resultados únicos na pesquisa do input
  const uniqueResults = useMemo(() => {
    return [
      ...new Set(
        data?.results?.map((item) =>
          item.customer && item.customer.type === 'company'
            ? item.customer.social_reason
            : item.customer
            ? item.customer.name
            : null
        )
      )
    ];
  }, [data]);

  // input de pesquisa
  const renderSearchInput = (params) => <L.Search {...params} size="small" label="Outorgante" variant="outlined" />;

  return (
    <L.Main>
      <L.Container>
        <S.H1>Procuração</S.H1>
        <L.BoxTitle>
          <L.Row>
            <L.Select value={selectedType} onChange={(event) => setSelectedType(event.target.value)} displayEmpty>
              <M.MenuItem value="true">{'Ativo'}</M.MenuItem>
              <M.MenuItem value="false">{'Inativo'}</M.MenuItem>
            </L.Select>

            <M.Autocomplete
              open={autocompleteOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              options={uniqueResults}
              value={searchName}
              onChange={handleChange}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => (option ? formatCapitalize(option.toString()) : '')}
              renderInput={renderSearchInput}
            />
          </L.Row>
          <S.DivAjustButton>
            <S.ButtonLarge
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                settingState(setRegister, !register);
                toggleData('formData', '');
              }}
            >
              {/* nome do botão de cadastro */}
              cadastrar procuração
            </S.ButtonLarge>
            <S.ButtonMedium
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                settingState(setOpenBestowed, !openBestowed);
                toggleData('formData', '');
              }}
            >
              {/* nome do botão de cadastro */}
              outorgardo
            </S.ButtonMedium>
          </S.DivAjustButton>
        </L.BoxTitle>
        {/* Tabela */}
        <Table
          column={header}
          state={data && data.results}
          onClickDetail={() => settingState(setDetail, !detail)}
          onClickEdit={() => settingState(setEdit, !edit)}
          onClickDelete={() => settingState(setErase, !erase)}
        />
        <L.ContainerEndPosition>
          <L.EndPosition>
            <M.Pagination count={allPages} page={currentPageNumber} onChange={handlePageChange} variant="rounded" />
          </L.EndPosition>
        </L.ContainerEndPosition>

        {/* modal de cadastrar */}
        {register && <Modal open={register} component={<RegisterLetterOfAttorney {...propsList} />} />}

        {/* modal de editar */}
        {edit && <Modal open={edit} component={<EditLetterOfAttorney {...propsList} />} />}

        {/* modal de detalhes */}
        {detail && <Modal open={detail} component={<DetailLetterOfAttorney {...propsList} />} />}

        {/* diálogo de excluir */}
        {erase && <EraseDialog url={URL_LETTER_OF_ATTORNEY} {...propsList} />}

        {/* outorgado */}
        {/* modal de abrir */}
        {openBestowed && <Modal open={openBestowed} component={<Bestowed {...propsList} />} />}
      </L.Container>
    </L.Main>
  );
};

export default LetterOfAttorney;
