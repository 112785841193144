import AddIcon from "@mui/icons-material/Add"
import * as M from "@mui/material"
import axios from "axios"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { ITEMS_PER_PAGE } from "../../../../constants/constants/constAndRegex"
import { capitalizeFirstString, formatCapitalize, settingState } from "../../../../constants/functions/functions"
import { URL_PROJECT, URL_PROJECT_TYPE } from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import EraseDialog from "../../../common/Dialog/EraseDialog/EraseDialog"
import Formulary from "../../../common/Formulary/Formulary/Formulary"
import Modal from "../../../common/Reuse/Modal/Modal"
import TitleModal from "../../../common/Reuse/TitleModal/TitleModal"
import * as S from "../../../common/StyledCommonModals/StyledCommonModals"
import TableTypeProject from "../../../common/TableTypeProject/TableTypeProject"
import RegisteSubProjectTypes from "../../SubProjectTypes/RegisteSubProjectTypes/RegisteSubProjectTypes"
import DetailProjectTypes from "../DetailProjectTypes/DetailProjectTypes"
import EditProjectTypes from "../EditProjectTypes/EditProjectTypes"
import RegisterProjectTypes from "../RegisterProjectTypes/RegisterProjectTypes"
import * as L from "./styled"

const ProjectTypes = (props) => {
  const {
    states: {
      globalState: { lookAlteration, id },
    },
    functions: { headerGlobal },
  } = useContext(GlobalContext)

  const [isLoading, setIsLoading] = useState(false)
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [data, setData] = useState("")
  const [allPages, setAllPages] = useState(0)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [searchName, setSearchName] = useState(null)
  const [register, setRegister] = useState(false)
  const [registerSub, setRegisterSub] = useState(false)
  const [detail, setDetail] = useState(false)
  const [erase, setErase] = useState(false)
  const [edit, setEdit] = useState(false)
  const [registerOnEdit, setRegisterOnEdit] = useState(false)
  const { open, setOpen } = props

  const propsList = {
    edit,
    setEdit,
    detail,
    setDetail,
    register,
    setRegister,
    erase,
    setErase,
    setRegisterSub,
    registerSub,
    id,
    open,
    setOpen,
    registerOnEdit,
    setRegisterOnEdit,
  }

  const handlePageChange = (event, value) => {
    setCurrentPageNumber(value)
    if (!searchName) {
      sessionStorage.setItem("cp_ProjectTypes", value)
      fetchPage(value)
    } else {
      fetchPageName(value)
    }
  }

  // mudar de página com click nos números
  const fetchPage = async (pageToUse) => {
    const url = `${URL_PROJECT_TYPE}?page=${pageToUse}`
    setIsLoading(true)
    try {
      const response = await axios.get(url, { headers: headerGlobal() })
      setData(response.data)
      const count = response.data.count
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE)
      setAllPages(pagesToRender)
    } catch (error) {
      console.error("Error", error)
    } finally {
      setIsLoading(false)
    }
  }

  // mudar de página com click nos números quando há valor no input
  const fetchPageName = async (pageToUse) => {
    setIsLoading(true)
    const encodedSearchName = encodeURIComponent(searchName)
    const url = `${URL_PROJECT_TYPE}?project_type=${encodedSearchName}&page=${pageToUse}`
    try {
      const response = await axios.get(url, { headers: headerGlobal() })
      setData(response.data)
      const count = response.data.count
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE)
      setAllPages(pagesToRender)
    } catch (error) {
      console.error("Error", error)
    } finally {
      setIsLoading(false)
    }
  }

  // pesquisa pelo input
  const searchForName = async (name) => {
    setIsLoading(true)
    const encodedSearchName = encodeURIComponent(name)
    const valueUrlSearch = `${URL_PROJECT_TYPE}?project_type=${encodedSearchName}`
    try {
      const response = await axios.get(valueUrlSearch, { headers: headerGlobal() })
      setCurrentPageNumber(1)
      setData(response.data)
      const count = response.data.count
      const pagesToRender = Math.ceil(count / ITEMS_PER_PAGE)
      setAllPages(pagesToRender)
    } catch (error) {
      console.error("Error", error)
    } finally {
      setIsLoading(false)
    }
  }

  // useEffect de salvar, recuperar número da página e alterar as páginas
  useEffect(() => {
    const handleFetch = async () => {
      const pageNumber = searchName ? currentPageNumber : sessionStorage.getItem("cp_ProjectTypes") || 1
      setCurrentPageNumber(Number(pageNumber))
      if (searchName) {
        await searchForName(searchName, Number(pageNumber))
      } else {
        await fetchPage(Number(pageNumber))
      }
    }

    handleFetch()
  }, [searchName, lookAlteration])

  // auto complete
  // onOpen
  const handleOpen = () => {
    if (searchName && searchName.length > 0) {
      setAutocompleteOpen(true)
    }
  }

  // onClose
  const handleClose = () => {
    setAutocompleteOpen(false)
  }

  // onChange
  const handleChange = (event, newValue) => {
    setSearchName(newValue || "")
  }

  // onInputChange
  const handleInputChange = (event, newValue) => {
    setAutocompleteOpen(Boolean(newValue && newValue.length > 0))
    setSearchName(newValue)
  }

  // resultados únicos na pesquisa do input
  const uniqueResults = useMemo(() => {
    const capitalizedResults = data?.results?.map((item) => capitalizeFirstString(item.project_type))
    return [...new Set(capitalizedResults)]
  }, [data])

  // input de pesquisa
  const renderSearchInput = (params) => <L.Search {...params} size="small" label="Nome" variant="outlined" />

  const getInfo = async () => {
    try {
      const response = await axios.get(URL_PROJECT_TYPE, {
        headers: headerGlobal(),
      })
      setData(response.data)
    } catch (error) {
      console.log("Error", error)
    }
  }

  useEffect(() => {
    getInfo()
  }, [lookAlteration])

  const header = [
    {
      name: "Tipo de Projeto",
      field: "project_type",
      styled: formatCapitalize,
    },
    {
      name: "Subprojeto",
      field: "projectType_subprojects.subproject_name",
      styled: formatCapitalize,
    },
    { name: "Ações", field: "acoes" },
  ]

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setOpen, !open)}>
        <L.Box>
          <S.BoxSelect>
            <L.Ajust>
              <TitleModal text={"tipo de projeto"} />
              <L.IconButtonModal type="button" onClick={() => settingState(setRegister, !register)}>
                <AddIcon />
              </L.IconButtonModal>
            </L.Ajust>
          </S.BoxSelect>
        </L.Box>
        <L.BoxSpace>
          <M.Autocomplete
            open={autocompleteOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            options={uniqueResults}
            value={searchName}
            onChange={handleChange}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => (option ? M.capitalize(option.toString()) : "")}
            renderInput={renderSearchInput}
          />
          <TableTypeProject
            column={header}
            state={data && data.results}
            onClickDetail={() => settingState(setDetail, !detail)}
            onClickEdit={() => settingState(setEdit, !edit)}
            onClickDelete={() => settingState(setErase, !erase)}
          />
          <L.EndPosition>
            <M.Pagination count={allPages} page={currentPageNumber} onChange={handlePageChange} variant="rounded" />
          </L.EndPosition>
        </L.BoxSpace>
      </Formulary>

      {/* modal de registrar tipos de projeto */}
      <Modal open={register} component={<RegisterProjectTypes {...propsList} />} />

      {/* modal de registrar subtipo de projeto */}
      <Modal open={registerSub} component={<RegisteSubProjectTypes {...propsList} />} />

      {/* Modal de detalhes */}
      <Modal open={detail} component={<DetailProjectTypes {...propsList} />} />

      {/* Modal de editar */}
      <Modal open={edit} component={<EditProjectTypes {...propsList} />} />

      {/* Dialog de excluir */}
      <EraseDialog open={erase} url={URL_PROJECT_TYPE} {...propsList} />
    </S.Main>
  )
}

export default ProjectTypes
