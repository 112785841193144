import React, { useState, useEffect } from 'react';
import * as M from "@mui/material";
import { extractDigits } from '../../../../../constants/functions/functions';

const InputMoney = ({ label, name, value, title, error, onChange, length, regexMessage, conditionMessage }) => {

  useEffect(() => {
    setLocalValue(value ? formatMoney(value) : "");
  }, [value]);

  const formatMoney = (value) => {
    // Converte o valor para string e remove caracteres não numéricos
    let numericValue = extractDigits(value.toString());

    // Se o valor não for vazio, formata como moeda brasileira
    if (numericValue) {
      numericValue = `R$ ${Math.trunc(numericValue).toLocaleString('pt-BR')}`;
    }

    return numericValue;
  };

  const initialValue = value ? formatMoney(value) : "";
  const [localValue, setLocalValue] = useState(initialValue);

  const handleLocalChange = (event) => {
    const numericValue = extractDigits(event.target.value);
    setLocalValue(formatMoney(numericValue));

    const modifiedEvent = {
      ...event,
      target: {
        ...event.target,
        name: name,
        value: numericValue,
      }
    };
    onChange(modifiedEvent);
  };

  const errorMessage =
    error &&
    (
      (conditionMessage[name] ? conditionMessage[name] + '. ' : '') +
      (regexMessage[name] ? regexMessage[name] : '')
    );

  return (
    <>
      <M.Tooltip title={errorMessage} placement="top-start">
        <M.TextField
          label={label}
          name={name}
          value={localValue}
          onChange={handleLocalChange}
          fullWidth
          inputProps={{
            title: title,
            maxLength: length,
          }}
          error={error}
        />
      </M.Tooltip>
    </>
  );
};

export default InputMoney;
