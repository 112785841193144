import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import {
  asCadastrar,
  asDefaultErrorAxios,
  asDefaultRegister,
  asDetalhes,
  asEditar,
  asSend,
  asSending,
  asSubmit,
} from "../../../../constants/constants/constAndRegex"
import { errorPropertie } from "../../../../constants/constants/constError"
import { DATA_PROJECTTYPE, DATA_PROPERTIE } from "../../../../constants/constants/dataToSend"
import { requiredProjectType, requiredPropertie } from "../../../../constants/constants/requiredConst"
import { changeValue, handleErrors, settingState, validateForm } from "../../../../constants/functions/functions"
import { URL_PROJECT_TYPE, URL_PROPERTIE } from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import useForm from "../../../../hooks/useForm"
import ClearDialog from "../../../common/Dialog/ClearDialog/ClearDialog"
import Formulary from "../../../common/Formulary/Formulary/Formulary"
import InputsProjectTypes from "../../../common/Formulary/InputsProjectTypes/InputsProjectTypes"
import { Button, ButtonRed } from "../../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../../common/Reuse/TitleModal/TitleModal"
import * as S from "../../../common/StyledCommonModals/StyledCommonModals"
import * as L from "./styled"
import DetailSubProjectTypes from "../../SubProjectTypes/DetailSubProjectTypes/DetailSubProjectTypes"

const DetailProjectTypes = (props) => {
  const {
    states: {
      globalState: { formData, id },
    },
    setters: { setGlobalState },
    functions: { headerGlobal, successToast, sayAlteration, errorToast, toggleData },
  } = useContext(GlobalContext)
  const [openClearDialog, setOpenClearDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { detail, setDetail } = props
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm()

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
  }

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_PROJECT_TYPE}${id}/`, { headers: headerGlobal() })
      setDataForm(response.data)
    } catch (error) {
      console.log("Error getInfo", error)
    }
  }

  useEffect(() => {
    getInfo()
  }, [])

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setDetail, !detail)}>
        <S.DivGap>
          <TitleModal text={"detalhes"} />
          <InputsProjectTypes {...propsList} />
        </S.DivGap>
        <DetailSubProjectTypes {...propsList} />
      </Formulary>
    </S.Main>
  )
}
export default DetailProjectTypes
