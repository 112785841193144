import React from "react";
import * as S from "./styledError";
import { useNavigate } from "react-router-dom";
import gif404 from "../../images/404page.gif"

const Error = () => {
    const navigate = useNavigate()
  return (
    <S.Main>
       <img src={gif404} alt="gif de error 404" />
        <S.Button onClick={() => navigate("/login")}>Voltar</S.Button>

        <h1>Error 404</h1>

    </S.Main>
  );
};

export default Error;
