import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../global/GlobalContext';
import { asAccessToken, asRefreshToken, naveLogin } from '../constants/constants/constAndRegex';
import { URL_LOGIN_REFRESH_TOKEN } from '../constants/urls/urls';

export const useProtected = () => {
  const {
    states: { globalState },
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  const renewToken = async () => {
    try {
      const refreshToken = localStorage.getItem(asRefreshToken);
      if (!refreshToken) {
        throw new Error('No refresh token available.');
      }

      const requestData = {
        refresh: refreshToken,
      };

      const response = await axios.post(URL_LOGIN_REFRESH_TOKEN, requestData);

      if (response.data.access) {
        localStorage.setItem(asAccessToken, response.data.access);
        console.log('Token renovado com sucesso.');
      } else {
        throw new Error('Access token not received in the response.');
      }
    } catch (error) {
      console.error('Error renewing token:', error);
      navigate(naveLogin);
    }
  };

  const checkToken = async () => {
    console.log('Verificando token de acesso...');
    const accessToken = localStorage.getItem(asAccessToken);
    if (!accessToken) {
      console.log('Token de acesso não encontrado, redirecionando para o login.');
      navigate(naveLogin);
    } else {
      await renewToken();
    }
  };

  useEffect(() => {
    const checkTokens = () => {
      const accessToken = localStorage.getItem(asAccessToken);
      const refreshToken = localStorage.getItem(asRefreshToken);

      if (!accessToken || !refreshToken) {
        console.log('Token não encontrado, redirecionando para o login');
        navigate(naveLogin);
      }
    };

    checkTokens();
    checkToken(); // Chama a verificação imediatamente quando o componente é montado

    // Define um intervalo para verificar o token a cada 50 minutos
    const intervalId = setInterval(checkToken, 50 * 60 * 1000);
    // Limpa o intervalo quando o componente é desmontado
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return null;
};
