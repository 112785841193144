import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Error from '../pages/Error/Error';
import Login from '../pages/Login/Login';
import Recovery from '../pages/Recovery/Recovery';
import RecoveryWithEmail from '../pages/RecoveryEmail/RecoveryWithEmail';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />
        <Route path="*" element={<Error />} />
        <Route path="/ebs" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recovery" element={<Recovery />} />
        <Route path="/recovery/:uid/:token" element={<RecoveryWithEmail />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
