import React, { useContext } from "react"
import { as50 } from "../../../../constants/constants/constAndRegex"
import { GlobalContext } from "../../../../global/GlobalContext"
import Input from "../../Reuse/Input/Input/Input"
import * as S from "./styled"

const InputsProjectTypes = (props) => {
  const {
    states: {
      globalState: { formData },
    },
  } = useContext(GlobalContext)
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = props

  return (
    <S.Main>
      <S.H2>tipo de projeto</S.H2>

      <Input
        label={"Nome"}
        {...(formData.inputDisable !== true ? { name: "project_type" } : {})}
        value={dataForm.project_type || ""}
        onChange={inputForm}
        error={fieldErrors.project_type || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        length={as50}
      />
    </S.Main>
  )
}

export default InputsProjectTypes
