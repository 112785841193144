import React from "react";
import * as S from "./StyledH1"

const H1 =({text})=>{
  return(
    <>
<S.H1>{text}</S.H1>
    </>
  )
}

export default  H1;
