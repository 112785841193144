import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LockResetIcon from '@mui/icons-material/LockReset';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../global/GlobalContext';
import EditName from '../EditName/EditName';
import EditPassword from '../EditPassword/EditPassword';
import Register from '../Register/Register';
import ExitDialog from '../common/Dialog/ExitDialog/ExitDialog';
import * as S from './styledCard';

const Card = () => {
  const {
    functions: { toggleBoolean, toggleData },
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  return (
    <>
      <S.Card>
        <S.Div>
          <S.MiniIconButtonModal
            onClick={() => {
              toggleData('componentRendered', <EditName />);
            }}
          >
            <DriveFileRenameOutlineIcon />
            <S.Text>Editar Nome</S.Text>
          </S.MiniIconButtonModal>
        </S.Div>

        <S.MiniIconButtonModal
          onClick={() => {
            toggleData('componentRendered', <EditPassword />);
          }}
        >
          <LockResetIcon />
          <S.Text>Alterar Senha</S.Text>
        </S.MiniIconButtonModal>

        <S.MiniIconButtonModal
          onClick={() => {
            toggleData('componentRendered', <Register />);
          }}
        >
          <HowToRegIcon />
          <S.Text>Cadastrar User</S.Text>
        </S.MiniIconButtonModal>

        <S.MiniIconButtonModalRed
          onClick={() => {
            toggleBoolean('exit', 'reverse');
          }}
        >
          <ExitToAppIcon />
          <S.Text>Sair</S.Text>
        </S.MiniIconButtonModalRed>

        <ExitDialog
          title="Quer realmente sair?"
          text="Você sairá desta página"
          success="Volte sempre"
          navigateTo={() => navigate('/login')}
        />
      </S.Card>
    </>
  );
};

export default Card;
