import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import * as M from "@mui/material";
import Modal from "@mui/material/Modal";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../../../global/GlobalContext";
import { Button, ButtonRed } from "../../Button/Buttons/Buttons";
import * as S from "./styled";

const InputFile = (props) => {
  const {
    states: {
      globalState: { id, formData },
    },
    functions: { headerGlobal, successToast },
  } = useContext(GlobalContext);
  const { name, type, dataForm, setDataForm, title, url } = props;

  const fileInput = useRef(null);
  const [localPreviewModal, setLocalPreviewModal] = useState(false);
  const [confirmDel, setConfirmDel] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const handleUploadIconClick = () => {
    if (!formData.inputDisable) {
      fileInput.current.click();
    }
  };

  const handleRemoveClick = () => {
    if (!formData.inputDisable) {
      setConfirmDel(true);
    }
  };

  const handleConfirmDel = async () => {
    try {
      const urlToUse = `${url}${id}/delete-file/${name}/`;
      await axios.delete(urlToUse, {
        headers: headerGlobal(),
      });
      successToast("Arquivo apagado");
    } catch (error) {
      console.error("Erro ao deletar o arquivo:", error);
    } finally {
      setDataForm((prevState) => ({
        ...prevState,
        [name]: "",
      }));
      setImageUrl(null);
      setConfirmDel(false);
    }
  };

  const handleFileChange = (event) => {
    setDataForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.files[0],
    }));
  };

  useEffect(() => {
    if (typeof dataForm[name] === "string") {
      setImageUrl(dataForm[name]);
    } else if (dataForm[name]) {
      const objectUrl = URL.createObjectURL(dataForm[name]);
      setImageUrl(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [dataForm, name]);

  return (
    <>
      <input
        type={type}
        accept=".pdf, .doc, .jpg, .png"
        name={name}
        onChange={handleFileChange}
        style={{ display: "none" }}
        ref={fileInput}
      />

      {dataForm[name] ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {title ? (
              <>
                <S.GapStyle>
                  <S.IconButtonModalExtraSmall
                    onClick={() => setLocalPreviewModal(true)}
                  >
                    <FindInPageIcon />
                  </S.IconButtonModalExtraSmall>

                  <S.IconButtonModalExtraSmall
                    onClick={handleUploadIconClick}
                  >
                    <AddPhotoAlternateIcon />
                  </S.IconButtonModalExtraSmall>

                  <S.IconButtonModalExtraSmallRed
                    onClick={() => handleRemoveClick()}
                  >
                    <DeleteIcon />
                  </S.IconButtonModalExtraSmallRed>
                </S.GapStyle>
                <S.Span> imagem </S.Span>
                <S.Span> {title} </S.Span>
              </>
            ) : (
              <S.GapColumn>
                <S.IconButtonModalExtraSmall
                  onClick={() => setLocalPreviewModal(true)}
                >
                  <FindInPageIcon />
                </S.IconButtonModalExtraSmall>
                <S.IconButtonModalExtraSmall
                  onClick={handleUploadIconClick}
                >
                  <AddPhotoAlternateIcon />
                </S.IconButtonModalExtraSmall>
                <S.IconButtonModalExtraSmallRed
                  onClick={() => handleRemoveClick()}
                >
                  <DeleteIcon />
                </S.IconButtonModalExtraSmallRed>
              </S.GapColumn>
            )}
          </div>
        </>
      ) : (
        <>
          {title ? (
            <S.GapColumn>
              <S.IconButtonModal onClick={handleUploadIconClick}>
                <AddPhotoAlternateIcon />
              </S.IconButtonModal>
              <S.Span> {title} </S.Span>
            </S.GapColumn>
          ) : (
            <S.IconButtonModal onClick={handleUploadIconClick}>
              <AddPhotoAlternateIcon />
            </S.IconButtonModal>
          )}
        </>
      )}

      {/* Modal para confirmar exclusão */}
      <S.Dialog open={confirmDel}>
        <S.DialogTitle>Remover o arquivo?</S.DialogTitle>
        <M.DialogContent>
          <S.DialogContentText>
            Essa ação não pode ser desfeita
          </S.DialogContentText>
        </M.DialogContent>

        <S.DialogActions>
          <ButtonRed
            onClick={() => setConfirmDel(false)}
            named={"cancelar"}
          ></ButtonRed>

          <Button onClick={handleConfirmDel} named={"aceitar"} />
        </S.DialogActions>
      </S.Dialog>

      {/* Modal para pré-visualização */}
      <Modal
        open={localPreviewModal}
        onClose={() => {
          setLocalPreviewModal(false);
        }}
        onClick={() => setLocalPreviewModal(false)}
      >
        <S.Styled>
          <S.ContentStyle>
            {imageUrl && (
              /\.(pdf)$/i.test(imageUrl) ? (
                <embed
                  src={imageUrl}
                  type="application/pdf"
                  width="100%"
                  height="500px"
                />
              ) : (
                <img src={imageUrl} alt="Preview" style={{ height: "25rem" }} />
              )
            )}
          </S.ContentStyle>
        </S.Styled>
      </Modal>
    </>
  );
};

export default InputFile;