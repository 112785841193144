import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import {
  asCustomerPhysical,
  asDefaultErrorAxios,
  asSend,
  asSending,
  asSubmit,
} from "../../../../constants/constants/constAndRegex"
import { errorBestowed } from "../../../../constants/constants/constError"
import { DATA_PHYSICAL, DATA_SPOUSE } from "../../../../constants/constants/dataToSend"
import { requiredPhysical, requiredSpouse } from "../../../../constants/constants/requiredConst"
import { handleErrors, settingState, validateForm } from "../../../../constants/functions/functions"
import { URL_BESTOWED } from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import useForm from "../../../../hooks/useForm"
import ClearDialog from "../../../common/Dialog/ClearDialog/ClearDialog"
import Formulary from "../../../common/Formulary/Formulary/Formulary"
import InputsBestowed from "../../../common/Formulary/InputsBestowed/InputsBestowed"
import { Button, ButtonRed } from "../../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../../common/Reuse/TitleModal/TitleModal"
import * as S from "../../../common/StyledCommonModals/StyledCommonModals"
import * as L from "../styled"

const RegisterBestwed = (props) => {
  const {
    states: {
      globalState: { formData },
    },
    setters: { setGlobalState },
    functions: { headerGlobal, successToast, errorToast, sayAlteration },
  } = useContext(GlobalContext)
  const [clear, setClear] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { register, setRegister, openBestowed, setOpenBestowed } = props

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm()

  const handlePost = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    const specificValidations = [...requiredPhysical, ...requiredSpouse]

    const resultValidateForm = validateForm({
      specificValidations,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    })

    if (!resultValidateForm) {
      setIsSubmitting(false)
      return errorToast(asDefaultErrorAxios)
    }

    const dataToSend = DATA_PHYSICAL(dataForm)
    const spouseData = DATA_SPOUSE(dataForm)
    const combinedData = {
      ...dataToSend,
      ...spouseData,
    }

    try {
      await axios.post(URL_BESTOWED, combinedData, {
        headers: headerGlobal(),
      })
      sayAlteration()
      successToast("Cadastro realizado")
      settingState(setRegister, !register)
      settingState(setOpenBestowed, !openBestowed)
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorBestowed,
        errorToast,
        asDefaultErrorAxios,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    clear,
    setClear,
    setFieldErrors,
  }

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      formData: {
        verifySelect: asCustomerPhysical,
        noEdit: false,
        inputDisable: false,
      },
    }))
  }, [])

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setRegister, !register)}>
        <S.BoxSelect>
          <TitleModal text={"cadastrar"} />
        </S.BoxSelect>
        <S.DivGap>
          <InputsBestowed {...propsList} />
        </S.DivGap>
        <L.DivGap>
          <ButtonRed
            onClick={formData.inputDisable !== true ? () => settingState(setClear, !clear) : undefined}
            named={"limpar"}
          />
          <Button
            type={formData.inputDisable !== true ? asSubmit : undefined}
            named={isSubmitting ? asSending : asSend}
            onClick={(event) => {
              if (!isSubmitting) {
                handlePost(event)
                // toggleBoolean('statusImage', true);
              }
            }}
            disabled={isSubmitting}
          />
        </L.DivGap>
      </Formulary>
      <ClearDialog {...propsList} />
    </S.Main>
  )
}
export default RegisterBestwed
