import styled from 'styled-components';
import * as M from '@mui/material';
export const Custon10 = styled.div`
  display: flex;
  min-width: 10rem;
`;
export const LoadingOverlay = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Collumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const Main = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

export const H2 = styled.h2`
  display: flex;
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  color: #006978;
  align-items: center;
  gap: 0.2rem;
`;

export const H2Custom = styled.h2`
  display: flex;
  justify-content: right;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  color: #006978;
  align-items: center;
  gap: 0.2rem;
`;

export const IconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;

export const IconButtonModalNotAllowed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const IconCustom = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      /* font-size: 1rem; */
    }
    border-radius: 0.2rem;
    cursor: pointer;
    background-color: #0a7377;
    color: #ffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;
export const IconCustomRed = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      /* font-size: 1rem; */
    }
    border-radius: 0.2rem;
    cursor: pointer;
    background-color: #cc0000;
    color: #ffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      background-color: #ff3333;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;
export const BoxCustom = styled.div`
  display: flex;
  gap: 0.3rem;
  width: 100%;
`;

export const BoxCont = styled.div`
  display: flex;
  align-items: center;
`;

export const Custon15rem = styled.div`
  display: flex;
  width: 15rem;
`;

export const DisplayAjust = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const DivGap = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-evenly;
`;
