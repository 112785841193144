import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { asCustomerCompany, asCustomerPhysical, asOther } from "../../../../constants/constants/constAndRegex"
import { MapList } from "../../../../constants/functions/functions"
import { pickList } from "../../../../constants/models/models"
import { URL_BESTOWED, URL_COMPANY, URL_PHYSICAL } from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import Input from "../../Reuse/Input/Input/Input"
import InputCheckboxGroup from "../../Reuse/Input/InputCheckboxGroup/InputCheckboxGroup"
import InputDate from "../../Reuse/Input/InputDate/InputDate"
import InputSelect from "../../Reuse/Input/InputSelect/InputSelect"
import * as S from "./styled"
import { CircularProgress } from "@mui/material"

const InputsLetterOfAttorney = (props) => {
  const {
    states: {
      globalState: { formData },
    },

    functions: { headerGlobal },
  } = useContext(GlobalContext)
  const [isLoading, setIsLoading] = useState(false)
  const [physica, setPhysica] = useState([])
  const [company, setCompany] = useState([])
  const [bestowed, setBestowed] = useState([])
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = props

  const physicaList = MapList({
    state: physica && physica,
    value: "id",
    label: "name",
  })

  const companyList = MapList({
    state: company && company,
    value: "id",
    label: "social_reason",
  })

  const bestowedList = MapList({
    state: bestowed && bestowed,
    value: "id",
    label: "name",
  })

  const getBestowed = async () => {
    try {
      const response = await axios.get(URL_BESTOWED, {
        headers: headerGlobal(),
      })
      setBestowed(response.data.results)
    } catch (error) {
      console.error("Erro em DetailClient.js getBestowed", error)
    }
  }

  const getPhysica = async () => {
    try {
      const response = await axios.get(URL_PHYSICAL, {
        headers: headerGlobal(),
      })
      setPhysica(response.data.results)
    } catch (error) {
      console.error("Erro em DetailClient.js getPhysica", error)
    }
  }

  const getCompany = async () => {
    try {
      const response = await axios.get(URL_COMPANY, {
        headers: headerGlobal(),
      })
      setCompany(response.data.results)
    } catch (error) {
      console.error("Erro em DetailClient.js getCompany", error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        await Promise.all([getBestowed(), getPhysica(), getCompany()])
      } catch (error) {
        console.error("Erro ao buscar dados: ", error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  return (
    <S.Main>
      <S.LoadingOverlay isLoading={isLoading}>
        <CircularProgress />
      </S.LoadingOverlay>
      <S.Custon10>
        <InputSelect
          label={"Tipo de Pessoa"}
          {...(formData.noEdit !== true ? { name: "verifySelect" } : {})}
          value={dataForm.verifySelect}
          onChange={inputForm}
          options={pickList.TYPE_CUSTOMER}
          error={fieldErrors.verifySelect}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      </S.Custon10>

      {dataForm.verifySelect === asCustomerPhysical && physica.length !== 0 && (
        <InputSelect
          label={"Outorgante"}
          {...(formData.inputDisable !== true ? { name: asCustomerPhysical } : {})}
          value={dataForm.customer_person_physical || ""}
          onChange={inputForm}
          options={physicaList}
          error={fieldErrors.customer_person_physical}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      )}

      {dataForm.verifySelect === asCustomerPhysical && physica.length === 0 && (
        <InputCheckboxGroup
          label={"Cadastre ao menos um cliente físico"}
          error={fieldErrors.customer_person_physical}
        />
      )}

      {dataForm.verifySelect === asCustomerCompany && company.length !== 0 && (
        <InputSelect
          label={"Outorgante"}
          {...(formData.inputDisable !== true ? { name: asCustomerCompany } : {})}
          value={dataForm.customer_person_company || ""}
          onChange={inputForm}
          options={companyList}
          error={fieldErrors.customer_person_company}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      )}

      {dataForm.verifySelect === asCustomerCompany && company.length === 0 && (
        <InputCheckboxGroup
          label={"Cadastre ao menos um cliente jurídico"}
          error={fieldErrors.customer_person_company}
        />
      )}

      {bestowed.length !== 0 ? (
        <InputCheckboxGroup
          label={"Outorgado"}
          {...(formData.inputDisable !== true ? { disabled: false } : { disabled: true })}
          saveName={"bestowed"}
          options={bestowedList}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          error={fieldErrors.bestowed || null}
          setDataForm={setDataForm}
          dataForm={dataForm}
        />
      ) : (
        <InputCheckboxGroup label={"Cadastre ao menos um outorgado"} error={fieldErrors.bestowed || null} />
      )}

      <S.Box>
        <InputSelect
          label={"Tipologia do Documento"}
          {...(formData.inputDisable !== true ? { name: "document_tipology" } : {})}
          value={dataForm.document_tipology || ""}
          onChange={inputForm}
          options={pickList.TYPE_DOCUMENT_TIPOLOGY}
          error={fieldErrors.document_tipology || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
        <S.Box>
          <InputSelect
            label={"Qualidade de Poderes"}
            {...(formData.inputDisable !== true ? { name: "quality_powers" } : {})}
            value={dataForm.quality_powers || ""}
            onChange={inputForm}
            options={pickList.TYPE_QUALITY_POWERS}
            error={fieldErrors.quality_powers || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />

          <InputSelect
            label={"Subestabelecer"}
            {...(formData.inputDisable !== true ? { name: "appointment" } : {})}
            value={dataForm.appointment || ""}
            onChange={inputForm}
            options={[
              {
                value: "true",
                label: "Sim",
              },
              {
                value: "false",
                label: "Não",
              },
            ]}
            error={fieldErrors.appointment || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />
        </S.Box>
      </S.Box>

      <S.Box>
        <InputSelect
          label={"Poderes"}
          {...(formData.inputDisable !== true ? { name: "power" } : {})}
          value={dataForm.power || ""}
          onChange={inputForm}
          options={pickList.TYPE_POWER}
          error={fieldErrors.power || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        {dataForm.power === asOther && (
          <Input
            label={"Poderes"}
            {...(formData.inputDisable !== true ? { name: "others_power" } : {})}
            value={dataForm.others_power || ""}
            onChange={inputForm}
            error={fieldErrors.others_power || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
          />
        )}
      </S.Box>

      <InputCheckboxGroup
        label={"Outorgas"}
        {...(formData.inputDisable !== true ? { disabled: false } : { disabled: true })}
        saveName={"grants"}
        options={pickList.TYPE_GRANTS}
        error={fieldErrors.grants || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        setDataForm={setDataForm}
        dataForm={dataForm}
      />

      {dataForm.grants?.includes(asOther) && (
        <Input
          label={"Outorgas"}
          {...(formData.inputDisable !== true ? { name: "others_grants" } : {})}
          value={dataForm.others_grants || ""}
          onChange={inputForm}
          error={fieldErrors.others_grants || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      )}
      <InputCheckboxGroup
        label={"Repartições Envolvidas"}
        {...(formData.inputDisable !== true ? { disabled: false } : { disabled: true })}
        saveName={"divisions_involved"}
        options={pickList.TYPE_DIVISIONS_INVOLVED}
        error={fieldErrors.divisions_involved || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        setDataForm={setDataForm}
        dataForm={dataForm}
      />

      {dataForm.divisions_involved?.includes(asOther) && (
        <Input
          label={"Repartições Envolvidas"}
          {...(formData.inputDisable !== true ? { name: "others_divisions_involved" } : {})}
          value={dataForm.others_divisions_involved || ""}
          onChange={inputForm}
          error={fieldErrors.others_divisions_involved || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      )}
      <S.Box>
        <Input
          label={"Especificação do Processo"}
          {...(formData.inputDisable !== true ? { name: "process_specification" } : {})}
          value={dataForm.process_specification || ""}
          onChange={inputForm}
          error={fieldErrors.process_specification || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      </S.Box>

      <S.Box>
        <Input
          label={"Cidade"}
          {...(formData.inputDisable !== true ? { name: "city" } : {})}
          value={dataForm.city || ""}
          onChange={inputForm}
          error={fieldErrors.city || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputDate
          label={"Data da Criação"}
          {...(formData.inputDisable !== true ? { name: "date" } : {})}
          value={dataForm?.date || ""}
          onChange={inputForm}
          error={fieldErrors.date || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputDate
          label={"Data de Validade"}
          {...(formData.inputDisable !== true ? { name: "validate" } : {})}
          value={dataForm?.validate || ""}
          onChange={inputForm}
          error={fieldErrors.validate || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputSelect
          label={"Situação"}
          {...(formData.inputDisable !== true ? { name: "active" } : {})}
          value={dataForm?.active || ""}
          onChange={inputForm}
          options={[
            {
              value: "true",
              label: "Ativo",
            },
            {
              value: "false",
              label: "Inativo",
            },
          ]}
          error={fieldErrors.active || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      </S.Box>
      <Input
        label={"Observação"}
        {...(formData.inputDisable !== true ? { name: "obs" } : {})}
        value={dataForm.obs || ""}
        onChange={inputForm}
        error={fieldErrors.obs || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
      />
    </S.Main>
  )
}

export default InputsLetterOfAttorney
