import styled from "styled-components";
export const Main = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100%;`

export const BoxSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

export const DivGap = styled.div`
display: flex;
width: 55rem;
flex-direction: column;
align-items: center;
padding: 1rem;
`;
