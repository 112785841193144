import MenuIcon from '@mui/icons-material/Menu';
import React, { useContext } from 'react';
import { asReverse } from '../../../../constants/constants/constAndRegex';
import { GlobalContext } from '../../../../global/GlobalContext';
import { ButtonIcon } from '../Button/Buttons/Buttons';
import * as S from './styledHeader';

const Header = () => {
  const {
    functions: { toggleBoolean }
  } = useContext(GlobalContext);

  return (
    <S.Main>
      <S.Div>
        <S.Title>SYSTEM ENGINEERING</S.Title>
        <S.P>O SEU SISTEMA DE ENGENHARIA</S.P>
      </S.Div>

      <S.ContainerButton>
        <ButtonIcon
          onClick={() => {
            toggleBoolean('card', asReverse);
          }}
          icon={<MenuIcon />}
        />
      </S.ContainerButton>
    </S.Main>
  );
};

export default Header;
