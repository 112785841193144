import styled from "styled-components";
import * as M from "@mui/material"

export const Select = styled(M.Select)`
  && {
    height: 2.5rem;

  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
`;

export const Container = styled.div`
  /* padding: 0 0.5rem; */
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ContainerEndPosition = styled.div``;
export const EndPosition = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin-left: -2rem;
`;
