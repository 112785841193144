import styled from "styled-components";
import * as M from "@mui/material";

export const Back = styled(M.Button)`
  && {
    background: none;
    border: none;
    cursor: pointer;
    height: 2.5rem;
    width: 5rem;
    color: #ffffff;
    margin: 0.5rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128C8E;
    }
  }
`;
export const BackRed = styled(M.Button)`
  && {
    background: none;
    border: none;
    cursor: pointer;
    height: 2.5rem;
    width: 5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;

export const Green = styled(M.Button)`
  && {
    background: none;
    border: none;
    cursor: pointer;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128C8E;
    }
  }
`;

export const Red = styled(M.Button)`
  && {
    background: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }

  }
  `;

// ===============================================

export const LargeGreen = styled(M.Button)`
  && {
    cursor: pointer;
    width: 15rem;
    height: 2.5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128C8E;
    }
  }
`;

// ===============================================

export const IconGreen = styled(M.IconButton)`
  && {
    cursor: pointer;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    height: 2.5rem;
    width: 5rem;
    margin: 0.5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128C8E;
    }
  }
`;
export const IconRed = styled(M.IconButton)`
  && {
    cursor: pointer;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    height: 2.5rem;
    width: 5rem;
    margin: 0.5rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;

// ===============================================

