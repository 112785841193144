import React from 'react';
import Input from '../../Reuse/Input/Input/Input';
import { as50 } from '../../../../constants/constants/constAndRegex';

const InputsEditName = ({
  dataForm,
  inputForm,
  fieldErrors,
  regexMessage,
  conditionMessage,
}) => {
  return (
    <>
      <Input
      documentType={'cep'}
        label={'Editar nome'}
        name={'name'}
        value={dataForm?.name || ''}
        onChange={inputForm}
        length={as50}
        error={fieldErrors?.name || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
      />
    </>
  );
};
export default InputsEditName;
