import * as M from '@mui/material';
import React from 'react';

const InputSelect = React.memo(({ label, name, value, onChange, options, error, conditionMessage, regexMessage }) => {
  const errorMessage =
  error &&
  (
    (conditionMessage[name] ? conditionMessage[name] + '. ' : '') +
    (regexMessage[name] ? regexMessage[name] : '')
  );
  return (
    <>
    <M.Tooltip title={errorMessage} placement="top-start">
      <M.FormControl fullWidth variant="outlined" error={error}>
        <M.InputLabel htmlFor={name} style={{ color: '#666666' }}>
          {label}
        </M.InputLabel>
        <M.Select
          label={label}
          name={name}
          value={value || ''}
          onChange={onChange}
        >
          {options?.map((option) => (
            <M.MenuItem key={option?.value} value={option?.value}>
              {M.capitalize(option?.label)}
            </M.MenuItem>
          ))}
        </M.Select>
      </M.FormControl>
      </M.Tooltip>
    </>
  );
});

export default InputSelect;
