import * as S from '../styledDialogs';
import * as M from '@mui/material';
import { Button, ButtonRed } from '../../Reuse/Button/Buttons/Buttons';
import { GlobalContext } from '../../../../global/GlobalContext';
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { asAccept, asAccepting, asSubmit } from '../../../../constants/constants/constAndRegex';
import { settingState } from '../../../../constants/functions/functions';

const EmailPdfDialog = (props) => {
  const {
    functions: { successToast, errorToast, headerGlobal },
  } = useContext(GlobalContext);
  const { url, setEmailPdf, id, emailPdf } = props;
 
  const pdfMail = () => {
    successToast("Aguarde alguns instantes...")
    axios({
      method: "POST",
      url: `${url}${id}/`,
      headers: headerGlobal(),
    })
      .then((response) => {
        successToast("Email enviado com sucesso")
      })
      .catch((error) => {
        console.log("Error pdfLetter", error)
        errorToast("Erro ao gerar PDF")
      })
      .finally(() => {
      })
      settingState(setEmailPdf, !emailPdf)
  }

  return (
    <>
      <S.Dialog open={emailPdf}>
        <S.DialogTitle>Enviar e-mail?</S.DialogTitle>
        <M.DialogContent>
          <S.DialogContentText>Essa ação não pode ser desfeita</S.DialogContentText>
        </M.DialogContent>

        <S.DialogActions>
          <ButtonRed onClick={() => settingState(setEmailPdf, !emailPdf)} named={'cancelar'}></ButtonRed>
          <Button
            type={asSubmit}
            named={asAccept}
            onClick={() => {
                pdfMail();           
            }}
          />
        </S.DialogActions>
      </S.Dialog>
    </>
  );
};

export default EmailPdfDialog;
