import * as M from '@mui/material';
import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const InputPassword = ({
  label,
  onChange,
  value,
  name,
  required,
  length,
  error,
  regexMessage,
  conditionMessage,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <M.Tooltip
        title={
          error &&
          ((conditionMessage[name] ? conditionMessage[name] : '') ||
            (regexMessage[name] ? regexMessage[name] : ''))
        }
        placement="top-start"
      >
        <M.TextField
          label={label}
          name={name}
          value={value || ''}
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          InputProps={{
            maxLength: length,
            endAdornment: (
              <M.IconButton onClick={toggleShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </M.IconButton>
            ),
          }}
          required={required}
          error={error}
          autoComplete={'new-password'}
        />
      </M.Tooltip>
    </>
  );
};

export default InputPassword;
