import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import {
  asCustomerCompany,
  asCustomerPhysical,
  asDefaultErrorAxios,
  asDefaultRegister,
  asSend,
  asSending,
  asSubmit,
} from "../../../constants/constants/constAndRegex"
import { errorPropertie } from "../../../constants/constants/constError"
import { requiredProject } from "../../../constants/constants/requiredConst"
import { handleErrors, settingState, validateForm } from "../../../constants/functions/functions"
import { URL_PROJECT } from "../../../constants/urls/urls"
import { GlobalContext } from "../../../global/GlobalContext"
import useForm from "../../../hooks/useForm"
import ClearDialog from "../../common/Dialog/ClearDialog/ClearDialog"
import Formulary from "../../common/Formulary/Formulary/Formulary"
import InputsProject from "../../common/Formulary/InputsProject/InputsProject"
import { Button, ButtonRed } from "../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../common/Reuse/TitleModal/TitleModal"
import * as S from "../../common/StyledCommonModals/StyledCommonModals"
import * as L from "./styled"

const RegisterProject = (props) => {
  const {
    states: {
      globalState: { formData },
    },
    setters: { setGlobalState },
    functions: { headerGlobal, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext)
  const [clear, setClear] = useState(false)
  const [compareSubType, setCompareSubType] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { register, setRegister } = props

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm()

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    register,
    setRegister,
    setFieldErrors,
    clear,
    setClear,
    setCompareSubType,
  }

  const handlePost = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    // Validação não foi alterada, então continua igual
    const dynamicValidations = requiredProject(dataForm)
    const resultValidateForm = validateForm({
      specificValidations: dynamicValidations,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    })

    if (!resultValidateForm) {
      setIsSubmitting(false)
      return errorToast(asDefaultErrorAxios)
    }

    // Inicializa o FormData
    const formData = new FormData()

    // Adiciona cada valor de project_type individualmente
    if (dataForm.project_type && Array.isArray(dataForm.project_type)) {
      dataForm.project_type.forEach((projectTypeId) => {
        formData.append("project_type", projectTypeId) // Adiciona cada ID como um campo separado
      })
    }

    // Coleta e adiciona todos os valores de subproject_ ao FormData
    Object.keys(dataForm).forEach((key) => {
      if (key.startsWith("subproject_")) {
        // Para cada valor dentro do array de subprojetos, adiciona ao formData
        dataForm[key].forEach((subprojectId) => {
          formData.append("subproject", subprojectId)
        })
      } else if (!["typePeople", "customer_person_company", "customer_person_physical", "project_type"].includes(key)) {
        // Adiciona outros campos ao FormData, exceto os tratados separadamente e project_type que já foi tratado
        formData.append(key, dataForm[key])
      }
    })

    // Adiciona campos condicionais com base na seleção do usuário
    if (dataForm.typePeople === asCustomerPhysical) {
      // Se typePeople é asCustomerPhysical, adiciona customer_person_physical ao formData
      formData.append("customer_person_physical", dataForm.customer_person_physical)
    } else if (dataForm.typePeople === asCustomerCompany) {
      // Se não, e se typePeople é asCustomerCompany, adiciona customer_person_company ao formData
      formData.append("customer_person_company", dataForm.customer_person_company)
    }

    try {
      await axios.post(URL_PROJECT, formData, {
        headers: headerGlobal(),
      })
      // Sucesso na operação
      sayAlteration()
      successToast(asDefaultRegister)
      settingState(setRegister, !register)
    } catch (error) {
      // Trata os erros
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorPropertie,
        errorToast,
      })
      console.error("Erro ao tentar enviar", error)
    } finally {
      console.log("objeto enviado: ", formData)
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      formData: {
        verifySelect: asCustomerPhysical,
        noEdit: false,
        inputDisable: false,
      },
    }))
  }, [])

  return (
    <>
      <S.Main>
        <Formulary onClick={() => settingState(setRegister, !register)}>
          <S.DivGap>
            <TitleModal text={"cadastrar"} />
            <InputsProject {...propsList} />
          </S.DivGap>
          <L.DivGap>
            <ButtonRed
              onClick={formData.inputDisable !== true ? () => settingState(setClear, !clear) : undefined}
              named={"limpar"}
            />
            <Button
              type={formData.inputDisable !== true ? asSubmit : undefined}
              named={isSubmitting ? asSending : asSend}
              onClick={(event) => {
                if (!isSubmitting) {
                  handlePost(event)
                }
              }}
              disabled={isSubmitting}
            />
          </L.DivGap>
        </Formulary>
      </S.Main>
      <ClearDialog {...propsList} />
    </>
  )
}
export default RegisterProject
