import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asClean,
  asDefaultAlteration,
  asDefaultErrorAxios,
  asSend,
  asSending,
  asSubmit
} from '../../../../constants/constants/constAndRegex';
import { errorCondition } from '../../../../constants/constants/constError';
import { DATA_TERM_CONDITION } from '../../../../constants/constants/dataToSend';
import { requiredCondition } from '../../../../constants/constants/requiredConst';
import { handleErrors, settingState, validateForm } from '../../../../constants/functions/functions';
import { URL_TERMS_CONDITIONS, URL_TERMS_CONDITIONS_TYPE } from '../../../../constants/urls/urls';
import { GlobalContext } from '../../../../global/GlobalContext';
import useForm from '../../../../hooks/useForm';
import ClearDialog from '../../../common/Dialog/ClearDialog/ClearDialog';
import EraseDialog from '../../../common/Dialog/EraseDialog/EraseDialog';
import Formulary from '../../../common/Formulary/Formulary/Formulary';
import InputsConditions from '../../../common/Formulary/InputsConditions/InputsConditions';
import { Button, ButtonRed } from '../../../common/Reuse/Button/Buttons/Buttons';
import TitleModal from '../../../common/Reuse/TitleModal/TitleModal';
import EditConditionTypes from '../ConditionTypes/EditConditionTypes/EditConditionTypes';
import * as L from './styled';
import DetailConditionTypes from '../ConditionTypes/DetailConditionTypes/DetailConditionTypes';

const DetailConditions = (props) => {
  const {
    states: {
      globalState: { lookAlteration, id, subId }
    },
    functions: { headerGlobalString, successToast, sayAlteration, errorToast, toggleData }
  } = useContext(GlobalContext);
  const [clear, setClear] = useState(false);
  const [erase, setErase] = useState(false);
  const [view, setView] = useState(false);
  const [viewSub, setViewSub] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { detail, setDetail, setRegisterSub, registerSub, registerOnEdit, setRegisterOnEdit } = props;
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage
  } = useForm();

  const propsList = {
    setRegisterSub,
    registerSub,
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    clear,
    setClear,
    setFieldErrors,
    view,
    setView,
    viewSub,
    setViewSub,
    erase,
    setErase,
    url: URL_TERMS_CONDITIONS_TYPE,
    id: subId,
    registerOnEdit,
    setRegisterOnEdit
  };

  const handlePut = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const resultValidateForm = validateForm({
      specificValidations: requiredCondition,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    const dataToSend = DATA_TERM_CONDITION(dataForm);

    try {
      const response = await axios.put(`${URL_TERMS_CONDITIONS}${id}/`, dataToSend, {
        headers: headerGlobalString()
      });
      sayAlteration();
      toggleData('id', response.data.id);
      successToast(asDefaultAlteration);
      settingState(setView, !view);
      settingState(setRegisterOnEdit, true);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorCondition,
        errorToast
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_TERMS_CONDITIONS}${id}/`, { headers: headerGlobalString() });
      setDataForm(response.data);
    } catch (error) {
      console.log('Error getInfo', error);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <L.Main>
        <Formulary onClick={() => settingState(setDetail, !detail)}>
          <L.BoxSelect>
            <TitleModal text={'detalhes'} />
          </L.BoxSelect>
              <L.Container>
                <InputsConditions {...propsList} />
              </L.Container>
          <DetailConditionTypes {...propsList} />
        </Formulary>
      </L.Main>
    </>
  );
};
export default DetailConditions;
