import * as M from '@mui/material';
import React, { useContext, useState } from 'react';
import { asAccept, asAccepting, asSubmit } from '../../../../constants/constants/constAndRegex';
import { changeValue, settingState } from '../../../../constants/functions/functions';
import { GlobalContext } from '../../../../global/GlobalContext';
import { Button, ButtonRed } from '../../Reuse/Button/Buttons/Buttons';
import * as S from '../styledDialogs';

const ClearDialogDinamic = (props) => {
  const {
    functions: { successToast, toggleData },
  } = useContext(GlobalContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { clearDinamicDialog, setClearDinamicDialog, setMultFieldErrors, setMultDataForm, multDataForm } =
    props;

  const clearStatesDinamic = (setMultFieldErrors, setMultDataForm) => {
    if (setMultFieldErrors && typeof setMultFieldErrors === 'function') {
      setMultFieldErrors([]);
    }

    if (setMultDataForm && typeof setMultDataForm === 'function') {
      // Mantém apenas o id de cada item em multDataForm
      const resetDataForm = multDataForm.map((item) => ({ id: item.id }));
      setMultDataForm(resetDataForm);
    }
  };

  return (
    <>
      <S.Dialog open={clearDinamicDialog}>
        <S.DialogTitle>Limpar todos os campos?</S.DialogTitle>
        <M.DialogContent>
          <S.DialogContentText>Essa ação não pode ser desfeita</S.DialogContentText>
        </M.DialogContent>

        <S.DialogActions>
          <ButtonRed
            onClick={() => settingState(setClearDinamicDialog, !clearDinamicDialog)}
            named={'cancelar'}
          ></ButtonRed>

          <Button
            type={asSubmit}
            named={isSubmitting ? asAccepting : asAccept}
            onClick={() => {
              setIsSubmitting(true);
              clearStatesDinamic(setMultFieldErrors, setMultDataForm);
              toggleData('statusImage', null);
              successToast('Campos limpos');
              setIsSubmitting(false);
              settingState(setClearDinamicDialog, !clearDinamicDialog);
            }}
            disabled={isSubmitting}
          />
        </S.DialogActions>
      </S.Dialog>
    </>
  );
};

export default ClearDialogDinamic;
