import styled from "styled-components";

export const H1 = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  color: #006978;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin-top: 1.7rem;
`;
