import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import * as L from './styled';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InputsClientRepresentative from '../../common/Formulary/InputsClient/InputsClientRepresentative/InputsClientRepresentative';
import { GlobalContext } from '../../../global/GlobalContext';
import {
  URL_COMPANY,
} from '../../../constants/urls/urls';
import useMultForm from '../../../hooks/useMultForm';
import { changeValue } from '../../../constants/functions/functions';
const DetailClientLegalRepresentative = ({setViewRepresentative}) => {
  const {
    states: {
      globalState: { lookAlteration, id },
    },
    setters: { setGlobalState },
    functions: {
      headerGlobal,
    },
  } = useContext(GlobalContext);
  const [stateDeleteModal, setStateDeleteModal] = useState(false);

  const {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
  } = useMultForm();

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_COMPANY}${id}/`, {
        headers: headerGlobal(),
      });
      let data = response.data.legal_representatives;
      // Ordena os dados pelo campo 'id' em ordem crescente
      data.sort((a, b) => a.id - b.id);

      setMultDataForm(data);
    } catch (error) {
      console.log('Erro DetailClient.js: getInfo');
    }
};

  const representativeProps = {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
  };

  useEffect(() => {
    getInfo();
  }, [lookAlteration]);

  return (

      <div>
        {Array.isArray(multDataForm) &&
          multDataForm?.map((form) => (
            <div key={form.id}>
              <InputsClientRepresentative
                formId={form.id}
                {...representativeProps}
              />

              <L.H2Custom>
                Deletar Representante
                <L.IconButtonModalRed
                  type="button"
                >
                  <RemoveIcon />
                </L.IconButtonModalRed>
              </L.H2Custom>
            </div>
          ))}

      </div>
  );
};

export default DetailClientLegalRepresentative;
