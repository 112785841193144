import { useContext } from 'react';
import ClientCompany from '../components/Client/Client/ClientCompany/ClientCompany';
import ClientPhysical from '../components/Client/Client/ClientPhysical/ClientPhysical';
import DetailClientCompany from '../components/Client/DetailClient/DetailClientCompany';
import DetailClientPhysical from '../components/Client/DetailClient/DetailClientPhysical';
import EditClientCompany from '../components/Client/EditClient/EditClientCompany/EditClientCompany';
import EditClientPhysical from '../components/Client/EditClient/EditClientPhysical/EditClientPhysical';
import RegisterClientCompany from '../components/Client/RegisterClient/RegisterClientCompany/RegisterClientCompany';
import RegisterClientPhysical from '../components/Client/RegisterClient/RegisterClientPhysical/RegisterClientPhysical';
import { asCompany, asPeople } from '../constants/constants/constAndRegex';
import { URL_COMPANY, URL_PHYSICAL } from '../constants/urls/urls';
import { GlobalContext } from '../global/GlobalContext';

const useVerifyFields = (props) => {
  const {
    states: {
      globalState: { formData, typePeople },
    },
  } = useContext(GlobalContext);

  const RegisterFields =
    formData.verifyType === asCompany ? <RegisterClientCompany {...props} /> : <RegisterClientPhysical {...props} />;
  const EditFields =
    formData.verifyType === asCompany ? <EditClientCompany {...props} /> : <EditClientPhysical {...props} />;

  const DetailFields =
    formData.verifyType === asCompany ? <DetailClientCompany {...props} /> : <DetailClientPhysical {...props} />;

  const Rendered = typePeople === asPeople ? <ClientPhysical {...props} /> : <ClientCompany {...props} />;

  const urlSelected = typePeople === asPeople ? URL_PHYSICAL : URL_COMPANY;

  return { RegisterFields, EditFields, DetailFields, Rendered, urlSelected };
};

export default useVerifyFields;
