import React, { useContext, useEffect } from "react";
import * as S from "./StyledWelcome";
import { GlobalContext } from "../../global/GlobalContext";
import axios from "axios";
import { URL_USER } from "../../constants/urls/urls";

const Welcome = () => {
  const {
    states: {
      globalState: { name, lookAlteration },
    },
    setters: { setGlobalState },
    functions: { headerGlobal },
  } = useContext(GlobalContext);

  const GetName = async () => {
    try {
      const response = await axios.get(URL_USER, {
        headers: headerGlobal(),
      });

      setGlobalState((prevState) => ({
        ...prevState,
        name: response.data.name,
      }));
    } catch (error) {
      console.log(error.response?.data || error.message);
    }
  };

  useEffect(() => {
    GetName();
  }, [lookAlteration]);

  return (
    <S.Main>
      <S.Top>
        <S.Title>Bem-vindo(a), {name}.
        </S.Title>
      </S.Top>
    </S.Main>
  );
};

export default Welcome;
