import React, { useContext } from 'react';
import { as50 } from '../../../../constants/constants/constAndRegex';
import { GlobalContext } from '../../../../global/GlobalContext';
import Input from '../../Reuse/Input/Input/Input';
import * as S from './styled';

const InputsConditions = (props) => {
  const {
    states: {
      globalState: { formData },
    },
  } = useContext(GlobalContext);
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = props;

  return (
    <S.Main>
      <S.H2>termos e condições</S.H2>

      <Input
        label={'Nome'}
        {...(formData.inputDisable !== true ? { name: 'term_condition' } : {})}
        value={dataForm.term_condition || ''}
        onChange={inputForm}
        error={fieldErrors.term_condition || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        length={as50}
      />
    </S.Main>
  );
};

export default InputsConditions;
