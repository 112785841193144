import React, { useContext, useState } from 'react';
import axios from 'axios';
import useForm from '../../hooks/useForm';
import ClearDialog from '../common/Dialog/ClearDialog/ClearDialog';
import InputsEditName from '../common/Formulary/InputsEditName/InputsEditName';
import * as S from './styledEditName';
import { URL_EDIT_USER } from '../../constants/urls/urls';
import { GlobalContext } from '../../global/GlobalContext';
import { Button, ButtonRed } from '../common/Reuse/Button/Buttons/Buttons';
import {
  asClean,
  asDefaultErrorAxios,
  asNameChanged,
  asSend,
  asSending,
  asSubmit,
  asWelcome,
} from '../../constants/constants/constAndRegex';
import { changeValue, handleErrors, validateForm } from '../../constants/functions/functions';
import { requiredName } from '../../constants/constants/requiredConst';
import { errorName } from '../../constants/constants/constError';

const EditName = () => {
  const {
    functions: { sayAlteration, headerGlobal, successToast, errorToast, changeComponent },
  } = useContext(GlobalContext);
  const [openClearDialog, setOpenClearDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const InputProps = {
    regexMessage,
    conditionMessage,
    dataForm,
    inputForm,
    fieldErrors,
  };

  const clearDialogProps = {
    openClearDialog,
    setOpenClearDialog,
    setDataForm,
    setFieldErrors,
  };

  const handlePut = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const resultValidateForm = validateForm({
      specificValidations: requiredName,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    try {
      await axios.put(`${URL_EDIT_USER}`, dataForm, {
        headers: headerGlobal(),
      });
      successToast(asNameChanged);
      changeComponent(asWelcome);
      sayAlteration();
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorName,
        errorToast
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <S.Main>
      <S.ContainerForm>
        <S.Form>
          <InputsEditName {...InputProps} />
          <S.Div>
            <ButtonRed onClick={() => changeValue(openClearDialog, setOpenClearDialog)} named={asClean} />
            <Button
              type={asSubmit}
              named={isSubmitting ? asSending : asSend}
              onClick={(event) => {
                if (!isSubmitting) {
                  handlePut(event);
                }
              }}
              disabled={isSubmitting}
            />
          </S.Div>
        </S.Form>
      </S.ContainerForm>
      <ClearDialog {...clearDialogProps} />
    </S.Main>
  );
};

export default EditName;
