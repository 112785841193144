import styled from 'styled-components';
import * as M from '@mui/material';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  `;

export const Container = styled.div`
  display: flex;
  width: 55rem;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
`;

export const BoxSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

export const DivGap = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 1rem;

`;

export const DivSub = styled.div`
  background-color: #fff;
  /* padding: 1rem; */
  width: 55rem;
  border-radius: 0.5rem;
  overflow: auto;
`;

export const H2Custom = styled.h2`
  display: flex;
  justify-content: right;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  color: #006978;
  align-items: center;
  gap: 0.2rem;
  padding-right: 1rem;
`;

export const IconButtonModal = styled(M.IconButton)`
  && {
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #0a7377;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      color: #ffff;
      background-color: #128c8e;
    }
  }
`;

export const DisplayAjust = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
export const Collumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;
export const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const H2 = styled.h2`
  display: flex;
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: #006978;
  align-items: center;
  gap: 0.2rem;
  padding-left: 1rem;
`;

export const Initial = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const Top = styled.div`
  display: flex;
  border: 1px solid red;
  width: 100%;
  align-items: center;
`;
