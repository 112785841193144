import { useCallback, useEffect, useState } from "react"

const useForm = () => {
  const [dataForm, setDataForm] = useState({})
  const [fieldErrors, setFieldErrors] = useState({})
  const [regexMessage, setRegexMessage] = useState({})
  const [conditionMessage, setConditionMessage] = useState({})

  useEffect(() => {
    let isFieldErrorUpdated = false
    const newFieldErrors = Object.keys(fieldErrors).reduce((acc, field) => {
      const shouldClearError = fieldErrors[field] && dataForm[field]
      acc[field] = shouldClearError ? false : fieldErrors[field]
      if (shouldClearError) isFieldErrorUpdated = true
      return acc
    }, {})

    if (isFieldErrorUpdated) {
      setFieldErrors(newFieldErrors)
    }
  }, [dataForm])

  const inputForm = useCallback(
    (event, options = {}) => {
      const { name, value } = event.target
      setDataForm((prev) => ({ ...prev, [name]: value }))
    },
    [setDataForm],
  )

  return {
    dataForm,
    setDataForm,
    fieldErrors,
    setFieldErrors,
    inputForm,
    regexMessage,
    conditionMessage,
    setConditionMessage,
    setRegexMessage,
  }
}

export default useForm
