import { CircularProgress } from "@mui/material"
import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { as50 } from "../../../../constants/constants/constAndRegex"
import { MapList, MapListId } from "../../../../constants/functions/functions"
import { pickList } from "../../../../constants/models/models"
import { URL_PROJECT, URL_TERMS_CONDITIONS } from "../../../../constants/urls/urls"
import { GlobalContext } from "../../../../global/GlobalContext"
import Input from "../../Reuse/Input/Input/Input"
import InputAutoComplete from "../../Reuse/Input/InputAutoComplete/InputAutoComplete"
import InputCheckboxGroup from "../../Reuse/Input/InputCheckboxGroup/InputCheckboxGroup"
import InputDate from "../../Reuse/Input/InputDate/InputDate"
import InputSelect from "../../Reuse/Input/InputSelect/InputSelect"
import * as S from "./styled"
import InputMoney from "../../Reuse/Input/InputMoney/InputMoney"
import InputPercentage from "../../Reuse/Input/InputPercentage/InputPercentage "
import InputCheckboxGroup2 from "../../Reuse/Input/InputCheckboxGroup2/InputCheckboxGroup2"

const InputsPropost = (props) => {
  const {
    states: {
      globalState: { formData },
    },

    functions: { headerGlobal },
  } = useContext(GlobalContext)
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = props

  const [changeCount, setChangeCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [projectType, setProjectType] = useState([])
  const [termCondition, setTermCondition] = useState([])
  const [termConditionType, setTermConditionType] = useState([])

  const projectTypeList = MapList({
    state: projectType && projectType,
    value: "id",
    label: "project",
  })

  const termConditionList = MapList({
    state: termCondition && termCondition,
    value: "id",
    label: "term_condition",
  })

  const termConditionTypeList = MapListId({
    state: termConditionType && termConditionType,
    value: "id",
    label: "clauses",
  })

  const fetchData2 = async () => {
    try {
      await Promise.all([getProjectType(), getTermCondition()])
    } catch (error) {
      console.error("Erro ao buscar dados: ", error)
    }
  }

  const getProjectType = async () => {
    try {
      const response = await axios.get(URL_PROJECT, {
        headers: headerGlobal(),
      })
      setProjectType(response.data.results)
    } catch (error) {
      console.error("Erro:", error)
    }
  }

  const getTermCondition = async () => {
    try {
      const response = await axios.get(URL_TERMS_CONDITIONS, {
        headers: headerGlobal(),
      })
      setTermCondition(response.data.results)
    } catch (error) {
      console.error("Erro:", error)
    }
  }

  const getTermConditionType = async () => {
    try {
      const response = await axios.get(`${URL_TERMS_CONDITIONS}${dataForm.term_condition}`, {
        headers: headerGlobal(),
      })
      setTermConditionType(response.data.termscondition_termsconditiontypes)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData2()
  }, [])

  useEffect(() => {
    if (dataForm.term_condition) {
      getTermConditionType()
    }
  }, [dataForm.term_condition])

  return (
    <S.Main>
      <S.LoadingOverlay isLoading={isLoading}>
        <CircularProgress />
      </S.LoadingOverlay>
      <S.H2>Proposta</S.H2>
      <S.Box>
        <InputAutoComplete
          label={"Projeto"}
          {...(formData.inputDisable !== true ? { name: "project" } : { disabled: true })}
          value={dataForm.project || ""}
          onChange={inputForm}
          options={projectTypeList}
          error={fieldErrors.project}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputAutoComplete
          label={"Termos"}
          {...(formData.inputDisable !== true ? { name: "term_condition" } : { disabled: true })}
          value={dataForm.term_condition || ""}
          onChange={(selectedOption) => {
            inputForm(selectedOption)
            setDataForm((prevDataForm) => ({
              ...prevDataForm,
              term_condition_type: "",
            }))
          }}
          options={termConditionList}
          error={fieldErrors.term_condition}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      </S.Box>

      {dataForm.term_condition ? (
        <InputCheckboxGroup2
          label={"Cláusulas"}
          {...(formData.inputDisable !== true ? { disabled: false } : { disabled: true })}
          saveName={"term_condition_type"}
          options={termConditionTypeList}
          error={fieldErrors.term_condition_type || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          setDataForm={setDataForm}
          dataForm={dataForm}
        />
      ) : (
        <></>
      )}

      <S.Box>
        <InputDate
          label={"Data Inicial"}
          {...(formData.inputDisable !== true ? { name: "start_date" } : {})}
          value={dataForm?.start_date || ""}
          onChange={inputForm}
          error={fieldErrors.start_date || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputDate
          label={"Data Final"}
          {...(formData.inputDisable !== true ? { name: "final_date" } : {})}
          value={dataForm?.final_date || ""}
          onChange={inputForm}
          error={fieldErrors.final_date || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputMoney
          label="Valor"
          {...(formData.inputDisable !== true ? { name: "value" } : {})}
          value={dataForm.value || ""}
          onChange={inputForm}
          error={fieldErrors.value || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={12}
        />

        <Input
          label={"Desconto %"}
          {...(formData.inputDisable !== true ? { name: "discount" } : {})}
          value={dataForm.discount || ""}
          onChange={inputForm}
          error={fieldErrors.discount || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={4}
        />
      </S.Box>

      <S.Box>
        <InputSelect
          label={"Método de Pagamento"}
          {...(formData.inputDisable !== true ? { name: "payment_method" } : {})}
          value={dataForm.payment_method || ""}
          onChange={inputForm}
          options={pickList.TYPE_PAYMENTMETHOD}
          error={fieldErrors.payment_method || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />

        <InputMoney
          label="Entrada"
          {...(formData.inputDisable !== true ? { name: "down_payment" } : {})}
          value={dataForm.down_payment || ""}
          onChange={inputForm}
          error={fieldErrors.down_payment || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
          length={14}
        />

        {dataForm?.payment_method === "parcelado" ? (
          <Input
            label={"Qtd. Parcelas"}
            {...(formData.inputDisable !== true ? { name: "installments" } : {})}
            value={dataForm.installments || ""}
            onChange={inputForm}
            error={fieldErrors.installments || null}
            regexMessage={regexMessage}
            conditionMessage={conditionMessage}
            length={as50}
          />
        ) : null}

        <InputSelect
          label={"Progresso"}
          {...(formData.inputDisable !== true ? { name: "progress" } : {})}
          value={dataForm.progress || ""}
          options={pickList.TYPE_PROGRESS}
          onChange={inputForm}
          error={fieldErrors.progress || null}
          regexMessage={regexMessage}
          conditionMessage={conditionMessage}
        />
      </S.Box>

      <Input
        label={"Regime de Execução"}
        {...(formData.inputDisable !== true ? { name: "enforcement_regime" } : {})}
        value={dataForm.enforcement_regime || ""}
        onChange={inputForm}
        error={fieldErrors.enforcement_regime || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        multiline
        maxRows={4}
      />

      <Input
        label={"Estudo Preliminar"}
        {...(formData.inputDisable !== true ? { name: "preliminary_study" } : {})}
        value={dataForm.preliminary_study || ""}
        onChange={inputForm}
        error={fieldErrors.preliminary_study || null}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        size="large"
        multiline
        maxRows={4}
      />
    </S.Main>
  )
}

export default InputsPropost
