import React from 'react';
import { ButtonIconRed } from '../../Reuse/Button/Buttons/Buttons';
import * as S from './Styled';

const Formulary = ({ onClick, children }) => {
  return (
    <S.Main>
      <S.Top>
        <ButtonIconRed onClick={onClick} named={'X'} />
      </S.Top>
      <S.DivSub>{children}</S.DivSub>
    </S.Main>
  );
};
export default Formulary;
