import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { settingState } from '../../../../constants/functions/functions';
import { URL_BESTOWED } from '../../../../constants/urls/urls';
import { GlobalContext } from '../../../../global/GlobalContext';
import useForm from '../../../../hooks/useForm';
import Formulary from '../../../common/Formulary/Formulary/Formulary';
import InputsBestowed from '../../../common/Formulary/InputsBestowed/InputsBestowed';
import TitleModal from '../../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../../common/StyledCommonModals/StyledCommonModals';

const DetailBestwed = (props) => {
  const {
    states: {
      globalState: { id, lookAlteration },
    },
    functions: { headerGlobal },
  } = useContext(GlobalContext);
  const { detail, setDetail } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_BESTOWED}${id}/`, {
        headers: headerGlobal(),
      });
      const data = response.data;
      setDataForm(data);
    } catch (error) {
      console.log('Erro em DetailClient.js getInfo');
    }
  };

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
  };

  useEffect(() => {
    getInfo();
  }, [lookAlteration]);

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setDetail, !detail)}>
        <S.BoxSelect>
          <TitleModal text={'detalhes'} />
        </S.BoxSelect>
        <S.DivGap>
          <InputsBestowed {...propsList} />
        </S.DivGap>
      </Formulary>
    </S.Main>
  );
};
export default DetailBestwed;
