import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asCadastrar,
  asCustomerPhysical,
  asDefaultErrorAxios,
  asDefaultRegister,
  asSend,
  asSending,
  asSubmit
} from '../../../../constants/constants/constAndRegex';
import { errorProjectType } from '../../../../constants/constants/constError';
import { DATA_PROJECTTYPE } from '../../../../constants/constants/dataToSend';
import { requiredProjectType } from '../../../../constants/constants/requiredConst';
import { handleErrors, settingState, validateForm } from '../../../../constants/functions/functions';
import { URL_PROJECT_TYPE } from '../../../../constants/urls/urls';
import { GlobalContext } from '../../../../global/GlobalContext';
import useForm from '../../../../hooks/useForm';
import ClearDialog from '../../../common/Dialog/ClearDialog/ClearDialog';
import Formulary from '../../../common/Formulary/Formulary/Formulary';
import InputsProjectTypes from '../../../common/Formulary/InputsProjectTypes/InputsProjectTypes';
import { Button, ButtonRed } from '../../../common/Reuse/Button/Buttons/Buttons';
import TitleModal from '../../../common/Reuse/TitleModal/TitleModal';
import * as S from '../../../common/StyledCommonModals/StyledCommonModals';
import * as L from './styled';

const RegisterProjectTypes = (props) => {
  const {
    states: {
      globalState: { formData }
    },
    setters: { setGlobalState },
    functions: { headerGlobal, successToast, sayAlteration, errorToast, toggleData }
  } = useContext(GlobalContext);

  const [clear, setClear] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, setRegister, registerSub, setRegisterSub, setRegisterOnEdit } = props;
  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    clear,
    setClear,
    setFieldErrors
  };

  const handlePost = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const resultValidateForm = validateForm({
      specificValidations: requiredProjectType,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    const dataToSend = DATA_PROJECTTYPE(dataForm);
    try {
      const response = await axios.post(URL_PROJECT_TYPE, dataToSend, {
        headers: headerGlobal()
      });
      sayAlteration();
      toggleData('id', response.data.id);
      successToast(asDefaultRegister);
      settingState(setRegister, !register);
      settingState(setRegisterSub, !registerSub);
      settingState(setRegisterOnEdit, false);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorProjectType,
        errorToast
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      formData: {
        verifySelect: asCustomerPhysical,
        noEdit: false,
        inputDisable: false
      }
    }));
  }, []);

  return (
    <S.Main>
      <Formulary onClick={() => settingState(setRegister, !register)}>
        <S.DivGap>
          <TitleModal text={asCadastrar} />
          <InputsProjectTypes {...propsList} />
        </S.DivGap>
        <L.DivGap>
          <ButtonRed
            onClick={formData.inputDisable !== true ? () => settingState(setClear, !clear) : undefined}
            named={'limpar'}
          />
          <Button
            type={formData.inputDisable !== true ? asSubmit : undefined}
            named={isSubmitting ? asSending : asSend}
            onClick={(event) => {
              if (!isSubmitting) {
                handlePost(event);
              }
            }}
            disabled={isSubmitting}
          />
        </L.DivGap>
      </Formulary>
      <ClearDialog {...propsList} />
    </S.Main>
  );
};
export default RegisterProjectTypes;
