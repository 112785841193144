import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import * as M from '@mui/material';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from '../../../../../global/GlobalContext';
import { Button, ButtonRed } from '../../Button/Buttons/Buttons';
import * as S from './styled';

const InputFileDinamic = (props) => {
  const {
    states: {
      globalState: { id, formData },
    },
    functions: { headerGlobal, successToast },
  } = useContext(GlobalContext);
  const { name, type, formId, dataForm, setDataForm, title, url } = props;
  const fileInput = useRef(null);
  const [localPreviewModal, setLocalPreviewModal] = useState(false);
  const [confirmDel, setConfirmDel] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const handleUploadIconClick = () => {
    if (!formData?.inputDisable) {
      fileInput.current.click();
    }
  };

  const handleRemoveClick = () => {
    if (!formData.inputDisable) {
      setConfirmDel(true);
    }
  };

  const deleteFile = async () => {
    try {
      const urlToUse = `${url}${id}/delete-file/${name}/`;
      await axios.delete(urlToUse, {
        headers: headerGlobal(),
      });
      successToast('Imagem apagada');
    } catch (error) {
      console.error('Erro ao deletar o arquivo:', error);
    }
  };

  const handleConfirmDel = async () => {
    try {
      // Encontre o formulário pelo ID
      const newArray = [...dataForm];
      const targetIndex = newArray.findIndex((form) => form.id === formId);
      const targetObject = newArray[targetIndex];

      // Remova a propriedade [name] do objeto alvo
      if (targetObject && targetObject[name]) {
        URL.revokeObjectURL(targetObject[name]);
        delete targetObject[name];
      }

      // Atualize o objeto no array
      newArray[targetIndex] = targetObject;

      setDataForm(newArray);
      setConfirmDel(false);
      setImageUrl(null);

      await deleteFile();
    } catch (error) {
      console.error('Erro ao deletar o arquivo:', error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const newFormState = dataForm.map((form) => {
      if (form.id === formId) {
        return { ...form, [name]: file };
      }
      return form;
    });
    setDataForm(newFormState);
  };

  useEffect(() => {
    const currentDinamicValue = dataForm?.find((form) => form.id === formId);
    if (currentDinamicValue && typeof currentDinamicValue[name] === 'string') {
      setImageUrl(currentDinamicValue[name]);
    } else if (currentDinamicValue && currentDinamicValue[name]) {
      const objectUrl = URL.createObjectURL(currentDinamicValue[name]);
      setImageUrl(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [dataForm, name, formId]);

  const formObject = dataForm?.find((form) => form.id === formId) || {};
  const fileExists = formObject[name];

  return (
    <>
      <input
        type={type}
        accept=".pdf, .doc, .jpg, .png"
        name={name}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInput}
      />

      {/* {!dataForm[name] && required && statusImage !== null && <div style={{ color: 'red' }}>Erro: sem imagem</div>} */}

      {fileExists ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {title ? (
              <>
                <S.GapStyle>
                  <S.IconButtonModalExtraSmall onClick={() => setLocalPreviewModal(true)}>
                    <FindInPageIcon />
                  </S.IconButtonModalExtraSmall>

                  <S.IconButtonModalExtraSmall onClick={handleUploadIconClick}>
                    <AddPhotoAlternateIcon />
                  </S.IconButtonModalExtraSmall>

                  <S.IconButtonModalExtraSmallRed onClick={() => handleRemoveClick()}>
                    <DeleteIcon />
                  </S.IconButtonModalExtraSmallRed>
                </S.GapStyle>
                <S.Span> imagem </S.Span>
                <S.Span> {title} </S.Span>
              </>
            ) : (
              <S.GapColumn>
                <S.IconButtonModalExtraSmall onClick={() => setLocalPreviewModal(true)}>
                  <FindInPageIcon />
                </S.IconButtonModalExtraSmall>
                <S.IconButtonModalExtraSmall onClick={handleUploadIconClick}>
                  <AddPhotoAlternateIcon />
                </S.IconButtonModalExtraSmall>
                <S.IconButtonModalExtraSmallRed onClick={() => handleRemoveClick()}>
                  <DeleteIcon />
                </S.IconButtonModalExtraSmallRed>
              </S.GapColumn>
            )}
          </div>
        </>
      ) : (
        // se tem nome em baixo ou não
        <>
          {title ? (
            <S.GapColumn>
              <S.IconButtonModal onClick={handleUploadIconClick}>
                <AddPhotoAlternateIcon />
              </S.IconButtonModal>
              <S.Span> {title} </S.Span>
            </S.GapColumn>
          ) : (
            <S.IconButtonModal onClick={handleUploadIconClick}>
              <AddPhotoAlternateIcon />
            </S.IconButtonModal>
          )}
        </>
      )}

      {/* modal excluir */}
      <S.Dialog open={confirmDel}>
        <S.DialogTitle>Remover o arquivo?</S.DialogTitle>
        <M.DialogContent>
          <S.DialogContentText>Essa ação não pode ser desfeita</S.DialogContentText>
        </M.DialogContent>

        <S.DialogActions>
          <ButtonRed onClick={() => setConfirmDel(false)} named={'cancelar'}></ButtonRed>

          <Button onClick={handleConfirmDel} named={'aceitar'} />
        </S.DialogActions>
      </S.Dialog>

      {/* modal pré-visualizar */}
      <Modal
        open={localPreviewModal}
        onClose={() => {
          setLocalPreviewModal(false);
        }}
        onClick={() => setLocalPreviewModal(false)}
      >
        <S.Styled>
          <S.ContentStyle>
            {imageUrl && <img src={imageUrl} alt="Preview" style={{ height: '25rem' }} />}
          </S.ContentStyle>
        </S.Styled>
      </Modal>
    </>
  );
};

export default InputFileDinamic;
