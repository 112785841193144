import React from "react"
import * as M from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { styled } from "@mui/material/styles"
import { FormControl, TextField, Autocomplete, Checkbox } from "@mui/material"

const TagStyled = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "24px",
  margin: 2,
  lineHeight: "22px",
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa",
  border: `1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"}`,
  borderRadius: "2px",
  boxSizing: "content-box",
  padding: "0 0 0 2px",
  outline: "0",
  overflow: "hidden",
  "&:focus": {
    borderColor: theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff",
    backgroundColor: theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff",
  },
  "& span": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  "& svg": {
    fontSize: "12px",
    cursor: "pointer",
    padding: "4px",
  },
}))

export const Tag = ({ label, onDelete }) => (
  <TagStyled>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} style={{ cursor: "pointer", marginLeft: 4 }} />
  </TagStyled>
)

const InputAutoCompleteMultiple = ({
  label,
  name,
  value,
  onChange,
  options,
  error,
  disabled,
  conditionMessage,
  regexMessage,
}) => {
  const handleValueChange = (event, newValue) => {
    console.log({ target: { name, value: newValue.map((option) => option.value) } })
    onChange({ target: { name, value: newValue.map((option) => option.value) } })
  }

  const handleDelete = (optionToDelete) => () => {
    const newValue = value?.filter((optionValue) => optionValue !== optionToDelete.value)
    onChange({ target: { name, value: newValue } })
  }

  const isOptionSelected = (option) => value.includes(option.value)

  const errorMessage =
    error &&
    (conditionMessage[name] ? conditionMessage[name] + ". " : "") + (regexMessage[name] ? regexMessage[name] : "")

  return (
    <>
      <M.Tooltip title={errorMessage} placement="top-start">
        <FormControl fullWidth variant="outlined" error={error} disabled={disabled}>
          <Autocomplete
            multiple
            id={name}
            options={options}
            getOptionSelected={(option, value) => option.value === value.value}
            value={options?.filter((option) => isOptionSelected(option))}
            onChange={handleValueChange}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.label}
              </li>
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Tag
                  label={option.label}
                  {...getTagProps({ index })}
                  onDelete={handleDelete(option)}
                  sx={{ background: "black" }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                error={!!error}
                helperText={error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                }}
              />
            )}
          />
        </FormControl>
      </M.Tooltip>
    </>
  )
}

export default React.memo(InputAutoCompleteMultiple)
