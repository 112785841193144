import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  asDefaultAlteration,
  asDefaultErrorAxios,
  asEditar,
  asSend,
  asSending,
  asSubmit,
} from "../../../constants/constants/constAndRegex";
import { errorPropertie } from "../../../constants/constants/constError";
import { DATA_PROPERTIE } from "../../../constants/constants/dataToSend";
import { requiredPropertie } from "../../../constants/constants/requiredConst";
import {
  handleErrors,
  settingState,
  validateForm,
} from "../../../constants/functions/functions";
import { URL_PROPERTIE } from "../../../constants/urls/urls";
import { GlobalContext } from "../../../global/GlobalContext";
import useForm from "../../../hooks/useForm";
import ClearDialog from "../../common/Dialog/ClearDialog/ClearDialog";
import Formulary from "../../common/Formulary/Formulary/Formulary";
import InputsPropertie from "../../common/Formulary/InputsPropertie/InputsPropertie";
import { Button, ButtonRed } from "../../common/Reuse/Button/Buttons/Buttons";
import TitleModal from "../../common/Reuse/TitleModal/TitleModal";
import * as S from "../../common/StyledCommonModals/StyledCommonModals";
import * as L from "./styled";
import { CircularProgress } from "@mui/material";

const EditPropertie = (props) => {
  const {
    states: {
      globalState: { formData, id },
    },
    functions: { headerGlobal, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext);

  const [clear, setClear] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { edit, setEdit } = props;

  const {
    dataForm,
    setFieldErrors,
    fieldErrors,
    setDataForm,
    inputForm,
    regexMessage,
    setRegexMessage,
    conditionMessage,
    setConditionMessage,
  } = useForm();

  const propsList = {
    regexMessage,
    conditionMessage,
    dataForm,
    setDataForm,
    inputForm,
    fieldErrors,
    clear,
    setClear,
    setFieldErrors,
  };

  const handlePut = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const resultValidateForm = validateForm({
      specificValidations: requiredPropertie,
      dataForm,
      setFieldErrors,
      setRegexMessage,
      setConditionMessage,
    });

    if (!resultValidateForm) {
      setIsSubmitting(false);
      return errorToast(asDefaultErrorAxios);
    }

    const dataToSend = DATA_PROPERTIE(dataForm);

    // Remover campos de arquivo que não são objetos File
    ["image", "iptu_pdf", "registration_pdf"].forEach((field) => {
      if (!(dataForm[field] instanceof File)) {
        delete dataToSend[field];
      }
    });

    try {
      await axios.put(`${URL_PROPERTIE}${id}/`, dataToSend, {
        headers: headerGlobal(),
      });
      sayAlteration();
      successToast(asDefaultAlteration);
      settingState(setEdit, !edit);
    } catch (error) {
      handleErrors({
        error,
        setFieldErrors,
        errorMessages: errorPropertie,
        errorToast,
      });
      console.log("dataToSend", dataToSend);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getInfo = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${URL_PROPERTIE}${id}/`, {
        headers: headerGlobal(),
      });
      setDataForm(response.data);
    } catch (error) {
      console.log("Error getInfo", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <S.Main>
      <S.LoadingOverlay isLoading={loading}>
        <CircularProgress />
      </S.LoadingOverlay>
      <Formulary onClick={() => settingState(setEdit, !edit)}>
        <S.DivGap>
          <TitleModal text={asEditar} />
          <InputsPropertie {...propsList} />
        </S.DivGap>
        <L.DivGap>
          <ButtonRed
            onClick={() => settingState(setClear, !clear)}
            named={"limpar"}
          />
          <Button
            type={formData.inputDisable !== true ? asSubmit : undefined}
            named={isSubmitting ? asSending : asSend}
            onClick={(event) => {
              if (!isSubmitting) {
                handlePut(event);
              }
            }}
            disabled={isSubmitting}
          />
        </L.DivGap>
      </Formulary>
      <ClearDialog {...propsList} />
    </S.Main>
  );
};

export default EditPropertie;