import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { asDefaultErrorAxios } from "../../../../../constants/constants/constAndRegex"
import { DATA_TERM_CONDITION_TYPE } from "../../../../../constants/constants/dataToSend"
import { requiredConditionType } from "../../../../../constants/constants/requiredConst"
import { handleMultErrors, settingState, validateMultForm } from "../../../../../constants/functions/functions"
import { URL_TERMS_CONDITIONS_TYPE } from "../../../../../constants/urls/urls"
import { GlobalContext } from "../../../../../global/GlobalContext"
import useMultForm from "../../../../../hooks/useMultForm"
import ClearDialogDinamic from "../../../../common/Dialog/ClearDialog/ClearDialogDinamic"
import Formulary from "../../../../common/Formulary/Formulary/Formulary"
import InputsConditionType from "../../../../common/Formulary/InputsConditionType/InputsConditionType"
import { Button, ButtonRed } from "../../../../common/Reuse/Button/Buttons/Buttons"
import TitleModal from "../../../../common/Reuse/TitleModal/TitleModal"
import * as L from "./styled"

const RegisterConditionTypes = (props) => {
  const {
    states: {
      globalState: { id },
    },
    functions: { headerGlobalString, successToast, sayAlteration, errorToast },
  } = useContext(GlobalContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [clearDinamicDialog, setClearDinamicDialog] = useState(false)
  const { registerSub, setRegisterSub, open, setOpen } = props
  const registerOnEdit = true

  const {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
  } = useMultForm()

  const propsList = {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
    clearDinamicDialog,
    setClearDinamicDialog,
  }

  const errorHandlers = {
    email: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].email = errors.email[0]
      errorToast("E-mail já está em uso")
    },
    cpf: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].cpf = errors.cpf[0]
      errorToast("CPF já está em uso")
    },
  }

  const sendData = async (item) => {
    const dataToSend = DATA_TERM_CONDITION_TYPE(item)
    const combinedData = {
      ...dataToSend,
      term_condition: id,
    }

    await axios.post(URL_TERMS_CONDITIONS_TYPE, combinedData, {
      headers: headerGlobalString(),
    })

    return item.id
  }

  const handlePost = async (event) => {
    event.preventDefault()

    const allFormsValid = validateMultForm({
      specificValidations: requiredConditionType,
      multDataForm,
      setMultFieldErrors,
      setMultRegexMessage,
      setMultConditionMessage,
    })

    if (!allFormsValid) {
      errorToast(asDefaultErrorAxios)
      return
    }

    let newFieldErrors = { ...multFieldErrors }
    let updatedMultDataForm = [...multDataForm]

    for (const item of multDataForm) {
      try {
        await sendData(item)
        updatedMultDataForm = updatedMultDataForm.filter((form) => form.id !== item.id)
      } catch (error) {
        handleMultErrors({ error, item, newFieldErrors, errorHandlers })
      }
    }

    setMultFieldErrors(newFieldErrors)
    setMultDataForm(updatedMultDataForm)

    if (updatedMultDataForm.length === 0) {
      sayAlteration()
      successToast("Cadastro realizado")
      settingState(setRegisterSub, !registerSub)
      if (!registerOnEdit) {
        settingState(setOpen, !open)
      }
    }
  }

  useEffect(() => {
    addForm()
  }, [])

  return (
    <>
      <L.Main>
        <Formulary onClick={() => settingState(setRegisterSub, !registerSub)}>
          <L.Container>
            <TitleModal text={"cadastrar"} />
            {multDataForm?.length >= 1 && <L.H2>cláusulas</L.H2>}
            <L.ScroolingDiv>
              {multDataForm?.map((form) => (
                <span key={form.id}>
                  <InputsConditionType formId={form.id} {...propsList} />
                  <L.H2Custom>
                    remover
                    <L.IconButtonModalRed type="button" onClick={() => removeForm(form.id)}>
                      <RemoveIcon />
                    </L.IconButtonModalRed>
                  </L.H2Custom>
                </span>
              ))}
            </L.ScroolingDiv>

            <L.Span>
              adicionar cláusula
              <L.IconButtonModal type="button" onClick={addForm}>
                <AddIcon />
              </L.IconButtonModal>
            </L.Span>

            {multDataForm.length >= 1 && (
              <L.DivGap>
                <ButtonRed onClick={() => settingState(setClearDinamicDialog, !clearDinamicDialog)} named={"limpar"} />
                <Button
                  onClick={(event) => {
                    handlePost(event)
                  }}
                  named={"enviar"}
                />
              </L.DivGap>
            )}
          </L.Container>
        </Formulary>
      </L.Main>
      <ClearDialogDinamic {...propsList} />
    </>
  )
}
export default RegisterConditionTypes
