import React from 'react';
import Input from '../../Reuse/Input/Input/Input';
import InputPassword from '../../Reuse/Input/InputPassword/InputPassword';
import { as50 } from '../../../../constants/constants/constAndRegex';

const InputsRegister = ({
  dataForm,
  inputForm,
  fieldErrors,
  regexMessage,
  conditionMessage,
}) => {
  return (
    <>
      <Input
        label={'Nome'}
        name={'name'}
        value={dataForm?.name || ''}
        onChange={inputForm}
        length={as50}
        error={fieldErrors?.name || ''}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        required
      />

      <Input
        label={'E-mail'}
        name={'email'}
        value={dataForm?.email || ''}
        onChange={inputForm}
        length={as50}
        error={fieldErrors?.email || ''}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        required
      />

      <InputPassword
        label={'Password'}
        name={'password'}
        value={dataForm?.password || ''}
        onChange={inputForm}
        length={as50}
        error={fieldErrors?.password || ''}
        regexMessage={regexMessage}
        conditionMessage={conditionMessage}
        required
      />
    </>
  );
};
export default InputsRegister;
