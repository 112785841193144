import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../global/GlobalContext';
import ebsLog from '../../../images/logo/ebs_log.png';
import companyAnimated from '../../../images/system/systemAnimated.gif';
import companyStatic from '../../../images/system/systemStatic.png';
import Card from '../../Card/Card';
import MenuItem from '../MenuItem/MenuItem';
import * as S from './styledMenu';

const Menu = () => {
  const {
    functions: { changeComponent },
    states: {
      globalState: { card }
    }
  } = useContext(GlobalContext);
  const elementStatic1 = companyStatic;
  const elementAnimated1 = companyAnimated;
  const handleMenuItemClick = (nameComponentRendered) => {
    changeComponent(nameComponentRendered);
  };

  return (
    <S.Main>
      <S.High>
        <S.Div>
          <S.Img src={ebsLog} alt="imagem da logomarca" />
        </S.Div>
      </S.High>
      <AnimatePresence>
        {!card && (
          <S.Container>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.25 }}
            >
              <S.ItemColumn>
                <MenuItem
                  alt={'alt1'}
                  elementStatic={elementStatic1}
                  elementAnimated={elementAnimated1}
                  text="Cliente"
                  nameComponentRendered="Client"
                  onClick={() => {
                    handleMenuItemClick('Client');
                  }}
                ></MenuItem>

                <MenuItem
                  alt={'alt1'}
                  elementStatic={elementStatic1}
                  elementAnimated={elementAnimated1}
                  text="Procuração"
                  nameComponentRendered="LetterOfAttorney"
                  onClick={() => handleMenuItemClick('LetterOfAttorney')}
                ></MenuItem>

                <MenuItem
                  alt={'alt1'}
                  elementStatic={elementStatic1}
                  elementAnimated={elementAnimated1}
                  text="imóvel"
                  nameComponentRendered="Propertie"
                  onClick={() => handleMenuItemClick('Propertie')}
                ></MenuItem>

                <MenuItem
                  alt={'alt1'}
                  elementStatic={elementStatic1}
                  elementAnimated={elementAnimated1}
                  text="Projeto"
                  nameComponentRendered="Project"
                  onClick={() => handleMenuItemClick('Project')}
                ></MenuItem>

                <MenuItem
                  alt={'alt1'}
                  elementStatic={elementStatic1}
                  elementAnimated={elementAnimated1}
                  text="Proposta"
                  nameComponentRendered="Propost"
                  onClick={() => handleMenuItemClick('Propost')}
                ></MenuItem>
              </S.ItemColumn>
            </motion.div>
          </S.Container>
        )}
      </AnimatePresence>

      <S.Position>
        <AnimatePresence>
          {card && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.25 }}
            >
              <Card />
            </motion.div>
          )}
        </AnimatePresence>
      </S.Position>
    </S.Main>
  );
};

export default Menu;
