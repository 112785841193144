import styled from "styled-components";
import * as M from "@mui/material"

export const Dialog = styled(M.Dialog)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(13.5px);
  -webkit-backdrop-filter: blur(13.5px);
`;

export const DialogTitle = styled(M.DialogTitle)`
  display: flex;
  justify-content: center;
`;

export const DialogContentText = styled(M.DialogContentText)`
  display: flex;
  justify-content: center;
`;

export const DialogActions = styled(M.DialogActions)`
`;

export const ButtonRed = styled(M.Button)`
  && {
    background: none;
    border: none;
    cursor: pointer;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #cc0000;
    &:hover {
      background-color: #ff3333;
    }
  }
`;

export const Button = styled(M.Button)`
  && {
    border: none ;
    padding: 1rem;
    cursor: pointer;
    height: 3rem;
    width: 10rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #0a7377;
    &:hover {
      background-color: #128c8e;
    }
  }
`;
