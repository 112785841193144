import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {
  asDefaultAlteration,
  asDefaultErrorAxios,
  asSend,
  asSending,
  asSubmit
} from '../../../../../constants/constants/constAndRegex';
import { DATA_TERM_CONDITION_TYPE } from '../../../../../constants/constants/dataToSend';
import { requiredConditionType } from '../../../../../constants/constants/requiredConst';
import { handleMultErrors, settingState, validateMultForm } from '../../../../../constants/functions/functions';
import { URL_TERMS_CONDITIONS, URL_TERMS_CONDITIONS_TYPE } from '../../../../../constants/urls/urls';
import { GlobalContext } from '../../../../../global/GlobalContext';
import useMultForm from '../../../../../hooks/useMultForm';
import ClearDialogDinamic from '../../../../common/Dialog/ClearDialog/ClearDialogDinamic';
import { Button, ButtonRed } from '../../../../common/Reuse/Button/Buttons/Buttons';
import * as L from './styled';
import InputsConditionType from '../../../../common/Formulary/InputsConditionType/InputsConditionType';

const EditConditionTypes = (props) => {
  const {
    states: {
      globalState: { lookAlteration, id }
    },
    setters: { setGlobalState },
    functions: { headerGlobalString, successToast, sayAlteration, errorToast }
  } = useContext(GlobalContext);
  const [clearDinamicDialog, setClearDinamicDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { viewSub, setViewSub, erase, setErase, conditionsType, setConditionsType, registerOnEdit, setRegisterOnEdit, setRegisterSub, registerSub} =
    props;

  const {
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm
  } = useMultForm();

  const propsList = {
    conditionsType,
    setConditionsType,
    setClearDinamicDialog,
    clearDinamicDialog,
    multDataForm,
    setMultDataForm,
    multFieldErrors,
    setMultFieldErrors,
    multRegexMessage,
    setMultRegexMessage,
    multConditionMessage,
    setMultConditionMessage,
    addForm,
    removeForm,
    multInputForm,
    registerOnEdit, setRegisterSub, registerSub
  };

  const errorHandlers = {
    email: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].email = errors.email[0];
      errorToast('E-mail já está em uso');
    },
    cpf: (errors, formId, newFieldErrors) => {
      newFieldErrors[formId].cpf = errors.cpf[0];
      errorToast('CPF já está em uso');
    }
  };

  const sendData = async (item) => {
    const dataToSend = DATA_TERM_CONDITION_TYPE(item);
    const combinedData = {
      ...dataToSend,
      term_condition: id
    };

    await axios.put(`${URL_TERMS_CONDITIONS_TYPE}${item.id}/`, combinedData, {
      headers: headerGlobalString()
    });

    return item.id;
  };

  const handlePut = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const allFormsValid = validateMultForm({
      specificValidations: requiredConditionType,
      multDataForm,
      setMultFieldErrors,
      setMultRegexMessage,
      setMultConditionMessage
    });

    if (!allFormsValid) {
      setIsSubmitting(false);
      errorToast(asDefaultErrorAxios);
      return;
    }

    let newFieldErrors = { ...multFieldErrors };
    let updatedMultDataForm = [...multDataForm];

    for (const item of multDataForm) {
      try {
        await sendData(item);
        updatedMultDataForm = updatedMultDataForm.filter((form) => form.id !== item.id);
      } catch (error) {
        handleMultErrors({ error, item, newFieldErrors, errorHandlers });
      }
    }

    setMultFieldErrors(newFieldErrors);
    setMultDataForm(updatedMultDataForm);

    if (updatedMultDataForm.length === 0) {
      sayAlteration();
      setIsSubmitting(false);
      settingState(setViewSub, !viewSub);
      successToast(asDefaultAlteration);
    }
  };

  const getInfo = async () => {
    try {
      const response = await axios.get(`${URL_TERMS_CONDITIONS}${id}/`, {
        headers: headerGlobalString()
      });
      let data = response.data.termscondition_termsconditiontypes;
      // Ordena os dados pelo campo 'id' em ordem crescente
      data.sort((a, b) => a.id - b.id);
      setMultDataForm(data);
    } catch (error) {
      console.log('Error getInfo');
    }
  };

  useEffect(() => {
    getInfo();
  }, [lookAlteration]);

  return (
    <L.Main>
    {multDataForm.length >= 1 && (
  <L.H2Custom2>Cláusulas</L.H2Custom2>
)}
      <L.ScroolingDiv>
        {Array.isArray(multDataForm) &&
          multDataForm?.map((form) => (
            <span key={form.id}>
              <InputsConditionType formId={form.id} {...propsList} />
              <L.H2Custom>
                Deletar
                <L.IconButtonModalRed
                  type="button"
                  onClick={(event) => {
                    settingState(setErase, !erase);
                    setGlobalState((prevState) => ({
                      ...prevState,
                      subId: form.id
                    }));
                  }}
                >
                  <RemoveIcon />
                </L.IconButtonModalRed>
              </L.H2Custom>
            </span>
          ))}
      </L.ScroolingDiv>

      <L.H2>
        adicionar cláusula
        <L.IconButtonModal
          type="button"
          onClick={() => {
            settingState(setRegisterSub, !registerSub);
          }}
        >
          <AddIcon />
        </L.IconButtonModal>
      </L.H2>

      <L.DivGap>
        {multDataForm.length >= 1 && (
          <>
            <ButtonRed onClick={() => settingState(setClearDinamicDialog, !clearDinamicDialog)} named={'limpar'} />
            <Button
              type={asSubmit}
              named={isSubmitting ? asSending : asSend}
              onClick={(event) => {
                if (!isSubmitting) {
                  handlePut(event);
                }
              }}
              disabled={isSubmitting}
            />
          </>
        )}
      </L.DivGap>

      {/* diálogo de limpar */}
      <ClearDialogDinamic {...propsList} />
    </L.Main>
  );
};

export default EditConditionTypes;
